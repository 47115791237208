import React from "react";

// Property Management
import entrataIcon from "assets/integrations/property-management/entrata-icon.webp";
import appFolioIcon from "assets/integrations/property-management/appfolio-icon.png";
import mriIcon from "assets/integrations/property-management/mri-icon2.png";
import realpageIcon from "assets/integrations/property-management/realpage-icon.png";
import remoteLandlordIcon from "assets/integrations/property-management/rl-icon.png";
import rentManagerIcon from "assets/integrations/property-management/rent-manager-icon.png";
import yardiIcon from "assets/integrations/property-management/yardi-icon.png";

// Access Control
import brivoIcon from "assets/integrations/access-control/brivo-icon.png";
import hartmannIcon from "assets/integrations/access-control/hartmann-icon.png";
import pdkIcon from "assets/integrations/access-control/pdk-icon.png";
import pdkIconGrayscale from "assets/integrations/access-control/pdk-icon-grayscale.png";
import threeXIcon from "assets/integrations/access-control/three-x-icon.png";
import saltoIcon from "assets/integrations/access-control/salto-icon.png";
import openpathIcon from "assets/integrations/access-control/openpath-icon.png";

// Video Management
import digitalWatchdogIcon from "assets/integrations/video-management/digital-watchdog-icon.png";
import eagleEyeIcon from "assets/integrations/video-management/eagle-eye-icon.png";
import openEyeIcon from "assets/integrations/video-management/open-eye-icon.png";

const iconStyles = {
  border: 0,
  borderRadius: "0 !important",
  overflow: "initial",
  width: 30,
  marginLeft: 0,
  marginRight: 7,
};

// Property Management
export const EntrataIcon = () => <img src={entrataIcon} style={iconStyles} alt="Entrata Icon" />;

export const AppFolioIcon = () => <img src={appFolioIcon} style={iconStyles} alt="AppFolio Icon" />;

export const MRIIcon = () => <img src={mriIcon} style={iconStyles} alt="MRI Icon" />;

export const RealpageIcon = () => <img src={realpageIcon} style={iconStyles} alt="Realpage Icon" />;

export const RemoteLandlordIcon = () => (
  <img src={remoteLandlordIcon} style={iconStyles} alt="Remote Landlord Icon" />
);

export const RentManagerIcon = () => (
  <img src={rentManagerIcon} style={iconStyles} alt="Rent Manager Icon" />
);

export const YardiIcon = () => <img src={yardiIcon} style={iconStyles} alt="Yardi Icon" />;

// Access Control
export const BrivoIcon = () => <img src={brivoIcon} style={iconStyles} alt="Brivo Icon" />;

export const PDKIcon = () => <img src={pdkIcon} style={iconStyles} alt="PDK Icon" />;

export const PDKIconGrayscale = () => (
  <img
    src={pdkIconGrayscale}
    style={{ ...iconStyles, width: "26px", height: "26px", marginRight: "12px", marginLeft: "4px" }}
    alt="PDK Icon"
  />
);

export const HartmannIcon = () => <img src={hartmannIcon} style={iconStyles} alt="HARTMANN Icon" />;

export const OpenpathIcon = () => <img src={openpathIcon} style={iconStyles} alt="Openpath Icon" />;

export const SaltoIcon = () => <img src={saltoIcon} style={iconStyles} alt="Salto Icon" />;

export const ThreeXIcon = () => <img src={threeXIcon} style={iconStyles} alt="3X Infinias Icon" />;

// Video Management
export const DigitalWatchdogIcon = () => (
  <img src={digitalWatchdogIcon} style={iconStyles} alt="Digital Watchdog Icon" />
);

export const EagleEyeIcon = () => <img src={eagleEyeIcon} style={iconStyles} alt="EagleEye Icon" />;

export const OpenEyeIcon = () => <img src={openEyeIcon} style={iconStyles} alt="OpenEye Icon" />;

import React from "react";
import styled from "styled-components";

export const LoadingOverlay =  styled.div`
  position: absolute;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  height: 100%;
  border-radius: 5px;
  z-index: 1;
  .access-loader{
    height: 50vh;
  }
`;
const LoadingStyles = styled.div`
  display: flex;
  justify-content: center;
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #0A1B68;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(19px, 0);
    }
  }
  .loader-outer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5em;
  }
  .logo-comp{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .logo-comp .bar-top:nth-child(1),
  .logo-comp .bar-top:nth-child(2),
  .logo-comp .bar-top:nth-child(3){
    background: transparent;
  }
  .bar-top{
    background: #52Af96;
    background: linear-gradient(#43A88E, #5CBAA4);
    height: 12px;
    width: 15px;
    transform: skew(25deg);
    opacity: .9;
    animation: bar-top 1s infinite;
    margin-bottom: .5em;
  }

  @keyframes bar-top{
    0%{
      opacity: 0%;
    }

    100%{
      opacity: 100%;
      margin-left: -7px;
    }
  }
  .bar-1{
    background: #d51279;
    background: linear-gradient(#932A8E, #CC247C);
    height: 35px;
    width: 15px;
    transform: skew(-25deg);
    opacity: .7;
    animation: bar1 1s infinite;
  }
  .bar-2{
    background: #335ba4;
    background: linear-gradient(#315DA9, #253B93);
    height: 35px;
    width: 15px;
    transform: skew(25deg);
    opacity: .7;
    animation: bar2 1s infinite;
  }
  .bar-3{
    background: #387aba;
    background: linear-gradient(#3574BA, #3A82C4);
    height: 35px;
    width: 15px;
    transform: skew(-25deg);
    opacity: .7;
    animation: bar3 1s infinite;
  }
  .bar-4{
    background: #52Af96;
    background: -webkit-linear-gradient(#43A88E, #5CBAA4);
    background: -o-linear-gradient(#43A88E, #5CBAA4);
    background: -moz-linear-gradient(#43A88E, #5CBAA4);
    background: linear-gradient(#43A88E, #5CBAA4);
    height: 35px;
    width: 15px;
    -webkit-transform: skew(25deg);
    -moz-transform: skew(25deg);
    -o-transform: skew(25deg);
    transform: skew(25deg);
    opacity: .7;
    animation: bar4 1s infinite;
  }
  @keyframes bar1{
    0%{
      -webkit-transform:  translateX(145px);
      -ms-transform: translateX(145px);
      -o-transform:  translateX(145px);
      transform:  translateX(145px);
    }
    100%{
      -webkit-transform: translateX(0px);
      -ms-transform: translateX(0px);
      -o-transform: translateX(0px);
      transform: translateX(0px);
      -webkit-transform: skew(-25deg);
      -moz-transform: skew(-25deg);
      -o-transform: skew(-25deg);
      transform: skew(-25deg);
    }
  }
  @keyframes bar2{
    0%{
      -webkit-transform:  translateX(100px);
      -ms-transform: translateX(100px);
      -o-transform:  translateX(100px);
      transform:  translateX(100px);
    }
    100%{
      -webkit-transform: translateX(45px);
      -ms-transform: translateX(45px);
      -o-transform: translateX(45px);
      transform: translateX(45px);
      -webkit-transform: skew(25deg);
      -moz-transform: skew(25deg);
      -o-transform: skew(25deg);
      transform: skew(25deg);
    }
  }
  @keyframes bar3{
    0%{
      -webkit-transform:  translateX(55px);
      -ms-transform: translateX(55px);
      -o-transform:  translateX(55px);
      transform:  translateX(55px);
    }
    100%{
      -webkit-transform: translateX(190);
      -ms-transform: translateX(190px);
      -o-transform: translateX(190px);
      transform: translateX(190px);
      -webkit-transform: skew(-25deg);
      -moz-transform: skew(-25deg);
      -o-transform: skew(-25deg);
      transform: skew(-25deg);
    }
  }
  @keyframes bar4{
    0%{
      -webkit-transform:  translateX(10px);
      -ms-transform: translateX(10px);
      -o-transform:  translateX(10px);
      transform:  translateX(10px);
    }
    100%{
      -webkit-transform: translateX(235px);
      -ms-transform: translateX(235px);
      -o-transform: translateX(235px);
      transform: translateX(235px);
      -webkit-transform: skew(25deg);
      -moz-transform: skew(25deg);
      -o-transform: skew(25deg);
      transform: skew(25deg);
    }
  }
`;

const Loading = () => (
  <LoadingStyles>
    <div className="loader-outer">
      <div className="logo-comp">
       <div className="bar-top" />
       <div className="bar-top" />
       <div className="bar-top" />
       <div className="bar-top" />
      </div>
      <div className="logo-comp">
       <div className="bar-1" />
       <div className="bar-2" />
       <div className="bar-3" />
       <div className="bar-4" />
      </div>
    </div>
  </LoadingStyles>
);

export default Loading;

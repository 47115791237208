import _get from "lodash/get";

import api from "redux/api";
import { notifyError } from "redux/action-utils";

const API = api.api;
const MAX_RESULTS_FOR_PAGE = 20;

const getLastSlice = (value, delimeter = ".") => {
  return value.split(delimeter).pop();
};

export const getOptions =
  (baseUrl, page, filter, queryString, namespace, limit = 20) =>
  (dispatch, getState) => {
    const generateUrlArgs = () => {
      return (
        "offset=" +
        (page * limit - limit) +
        "&" +
        ("limit=" + limit) +
        (queryString ? "&q=" + queryString : "") +
        (filter ? "&" + filter : "")
      );
    };

    const concatedOptions = (data) => {
      const storedOptions = _get(getState(), namespace).options;
      return storedOptions.concat(data);
    };

    dispatch({
      type: "SEARCH_SELECT_SET_FETCHING",
      payload: { isFetching: true, namespace: getLastSlice(namespace) },
    });

    API.get(`${baseUrl}?${generateUrlArgs()}`)
      .then(({ data }) => {
        dispatch({
          type: "SEARCH_SELECT_SET_OPTIONS",
          payload: {
            options: page === 1 ? data : concatedOptions(data),
            page,
            queryString,
            namespace: getLastSlice(namespace),
            allPagesLoaded: data ? (data.length < MAX_RESULTS_FOR_PAGE ? true : false) : false,
          },
        });
      })
      .catch((err) => {
        notifyError(err.response.data);
      });
  };

export const setFilter = (filter, namespace) => (dispatch) => {
  dispatch({ type: "SEARCH_SELECT_SET_FILTER", payload: { filter, namespace: getLastSlice(namespace) } });
};

export const setSelectedOption = (selectedOption, namespace) => (dispatch) => {
  dispatch({
    type: "SEARCH_SELECT_SET_SELECTED_OPTION",
    payload: { selectedOption, namespace: getLastSlice(namespace) },
  });
};

import React, { Component } from "react";
import PropTypes from "prop-types";
import MaskedTextInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const numberMask = createNumberMask({
  prefix: "$",
  allowDecimal: true
});

class MoneyField extends Component {
  static propTypes = {
    input: PropTypes.object, //Provided by Final Form
    meta: PropTypes.object, //Provided by Final Form
    label: PropTypes.node,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.oneOf(["text", "password", "select", "textarea", "email"]),
    extraContent: PropTypes.node,
    asterisk: PropTypes.bool
  };
  renderInput = () => {
    const { input, meta, inputClassName, asterisk, className, ...rest } = this.props;
    const isError = (meta.error && meta.touched) || meta.submitError;
    const classes = `${isError ? "is-invalid" : ""} ${meta.valid && meta.touched ? "is-valid" : ""}`;
    return (
      <MaskedTextInput
        mask={numberMask}
        className={`form-control ${classes}`}
        guide={false}
        // pattern="[0-9-]*"
        {...input}
        placeholder={rest.placeholder}
        disabled={rest.disabled}
        maxLength={rest.maxLength}
        minLength={rest.minLength}
        required={rest.required}
        style={rest.style}
        onChange={e => {
          input.onChange(e.target.value.replace(/[$,]/g, ""));
        }}
      />
    );
  };
  render() {
    const { input, meta, ...rest } = this.props;
    const isError = (meta.error && meta.touched) || meta.submitError;
    return (
      <div className={`form-group ${rest.className || ""}`}>
        {rest.label && (
          <label htmlFor={rest.name}>
            {rest.label} {this.props.asterisk && <span className="asterisk">*</span>}
          </label>
        )}
        {this.renderInput()}
        {isError && <div className="invalid-feedback">{meta.error || meta.submitError}</div>}
        {rest.extraContent}
      </div>
    );
  }
}

export default MoneyField;

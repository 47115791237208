import { createReducer, createActions } from "reduxsauce";
import { notifySuccess, asyncCreatorFactory } from "../action-utils";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  resetFetching: ["actionId"],
  resetSuccess: ["actionId"],
  resetFailure: ["error", "actionId"],
  resetSet: ["data", "attr", "actionId"],
  resetReset: null
});

/* ------------- Initial state ------------- */
const INITIAL_STATE = {
  security_answer: null,
  fetching: [],
  error: []
};

/* ------------- Reducers ------------- */
export const fetching = (state, { actionId }) => ({
  ...state,
  fetching: [...state.fetching, actionId],
  error: state.error.filter(i => i.actionId !== actionId)
});
export const set = (state, { data, attr, actionId }) => ({
  ...state,
  [attr]: data
});
export const success = (state, { actionId }) => ({
  ...state,
  fetching: state.fetching.filter(i => i !== actionId),
  error: state.error.filter(i => i.actionId !== actionId)
});
export const failure = (state, { error, actionId }) => ({
  ...state,
  fetching: state.fetching.filter(i => i !== actionId),
  error: [...state.error, { error, actionId }]
});
export const reset = state => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */
const resetReducer = createReducer(INITIAL_STATE, {
  [Types.RESET_FETCHING]: fetching,
  [Types.RESET_SUCCESS]: success,
  [Types.RESET_FAILURE]: failure,
  [Types.RESET_SET]: set,
  [Types.RESET_RESET]: reset
});

/* ------------- Async Actions ------------- */

const asyncCreator = asyncCreatorFactory(
  Creators.resetFetching,
  Creators.resetSuccess,
  Creators.resetFailure
);

export const forgotUsername = data =>
  asyncCreator({
    actionId: "forgotUsername",
    apiCall: api => api.resident.forgotUsername(data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess(`Your usernames have been successfully sent to ${response.data.data.message}`);
    },
    errorPath: "data.data.message",
    isNotifyError: false
  });

export const forgotPasswordQuestion = data =>
  asyncCreator({
    actionId: "forgotPasswordQuestion",
    apiCall: api => api.resident.forgotPasswordQuestion(data),
    isNotifyError: true,
    errorPath: "data.message"
  });

export const forgotPasswordAnswer = data =>
  asyncCreator({
    actionId: "forgotPasswordAnswer",
    apiCall: api => api.resident.forgotPasswordAnswer(data),
    onSuccess: (dispatch, response, getState) => {
      dispatch(Creators.resetSet(data.security_answer, "security_answer"));
    },
    isNotifyError: true,
    errorPath: "data.message"
  });

export const forgotPasswordReset = data =>
  asyncCreator({
    actionId: "forgotPasswordReset",
    apiCall: api => api.resident.forgotPasswordReset(data),
    isNotifyError: true,
    errorPath: "data.message"
  });

export default resetReducer;

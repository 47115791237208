const baseContainer = () => {
  return {
    options: [],
    page: 1,
    queryString: '',
    filter: null,
    selectedOption: null,
    allPagesLoaded: false,
    isFetching: false,
  }
}

const initialState = {
  dealers: baseContainer(),
  companies: baseContainer(),
  buildings: baseContainer(),
};


const searchSelectReducer = (state = initialState, action) => {
  const data = action.payload;

  switch (action.type) {
    case "SEARCH_SELECT_SET_OPTIONS": {
      return {
        ...state,
        [data.namespace]: {
          ...state[data.namespace],
          options: data.options,
          allPagesLoaded: data.allPagesLoaded,
          page: data.page,
          queryString: data.queryString,
          isFetching: false,
        }
      }
    }

    case "SEARCH_SELECT_SET_FILTER": {
      return {
        ...state,
        [data.namespace]: {
          ...state[data.namespace],
          options: [],
          filter: data.filter,
          selectedOption: null,
          queryString: '',
          page: 1,
        }
      }
    }

    case "SEARCH_SELECT_SET_SELECTED_OPTION": {
      return {
        ...state,
        [data.namespace]: {
          ...state[data.namespace],
          selectedOption: data.selectedOption,
          queryString: '',
        }
      }
    }

    case "SEARCH_SELECT_SET_FETCHING": {
      return {
        ...state,
        [data.namespace]: {
          ...state[data.namespace],
          isFetching: data.isFetching,
        }
      }
    }

    default:
      return state;
  }
};

export default searchSelectReducer;
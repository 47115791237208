import { createReducer, createActions } from "reduxsauce";
import { notifyError, notifySuccess, asyncCreatorFactory } from "../action-utils";
import { setSyncComplete } from "redux/reducers/brivo";
import _get from "lodash/get";
import _set from "lodash/set";
import _pick from "lodash/pick";
import { PermissionNames } from "components/layout/navigation/menu-utils";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  buildingFetching: ["actionId"],
  buildingSuccess: ["actionId"],
  buildingFailure: ["error", "actionId"],
  buildingSet: ["data", "attr", "actionId"],
  buildingReset: ["exclude"],
});
export const actions = Creators;

/* ------------- Initial state ------------- */
const INITIAL_STATE = {
  data: undefined,
  unit_listing: undefined,
  residents: undefined,
  access: {
    data: [],
    meta: {},
  },
  messages: undefined,
  delivery_packages: [],
  messagesKeycom: undefined,
  messagesText: undefined,
  inviteMessages: {
    email_message: "",
    sms_message: "",
    mykey_link_text: "",
  },
  events: undefined,
  notices: undefined,
  info: undefined,
  keycoms: null,
  sabathMode: undefined,
  logo: undefined,
  typesOfLegalPhoto: undefined,
  buildingGroups: undefined,
  analytics: undefined,
  analyticsAccountActivated: undefined,
  analyticsAtLeast1MyKey: undefined,
  analyticsSignIntoApp: undefined,
  flags: undefined,
  flagReasons: undefined,
  flagsSummary: { meta: { "total-rows": null, "select-fields": null }, data: null },
  promos: undefined,
  alerts: { data: null, total_alerts: null },
  brivo: undefined,
  webrelays: undefined,
  superintendentUsers: [],
  fetching: [],
  error: [],
  urlButtonLoading: false,
};

/* ------------- Reducers ------------- */
export const fetching = (state, { actionId }) => ({
  ...state,
  fetching: [...state.fetching, actionId],
  error: state.error.filter((i) => i.actionId !== actionId),
});
export const set = (state, { data, attr, actionId }) => {
  switch (attr) {
    case "flagsSummary": {
      return {
        ...state,
        [attr]: {
          meta: {
            ...state[attr]["meta"],
            "total-rows": data["total-rows"],
          },
          data: data.data,
        },
      };
    }
    case "flagsSummaryFilters": {
      const myKeysSpace = "flagsSummary";
      return {
        ...state,
        [myKeysSpace]: {
          ...state[myKeysSpace],
          meta: {
            ...state[myKeysSpace]["meta"],
            "select-fields": data["select-fields"],
          },
        },
      };
    }
    default: {
      return {
        ...state,
        [attr]: data,
      };
    }
  }
};

export const success = (state, { actionId }) => ({
  ...state,
  fetching: state.fetching.filter((i) => i !== actionId),
  error: state.error.filter((i) => i.actionId !== actionId),
});
export const failure = (state, { error, actionId }) => ({
  ...state,
  fetching: state.fetching.filter((i) => i !== actionId),
  error: [...state.error, { error, actionId }],
});
export const reset = (state, { exclude = [] }) => ({
  ...INITIAL_STATE,
  ..._pick(state, exclude),
});

/* ------------- Hookup Reducers To Types ------------- */
const buildingReducer = createReducer(INITIAL_STATE, {
  [Types.BUILDING_FETCHING]: fetching,
  [Types.BUILDING_SUCCESS]: success,
  [Types.BUILDING_FAILURE]: failure,
  [Types.BUILDING_SET]: set,
  [Types.BUILDING_RESET]: reset,
});

/* ------------- Async Actions ------------- */
const asyncCreator = asyncCreatorFactory(
  Creators.buildingFetching,
  Creators.buildingSuccess,
  Creators.buildingFailure
);

export const getBuildingUnitList = (id) =>
  asyncCreator({
    actionId: "getBuildingUnitList",
    apiCall: (api) => api.management.buildingUnitList(id),
    onSuccess: (dispatch, response, getState) => {
      const state = getState();
      const meta = _get(state, "building.unit_listing.meta", {});
      const { building_id, building_dl, floors } = response.data;
      const units = {
        data: {
          building_id,
          floors,
          building_units_list: building_dl,
        },
        meta,
      };
      dispatch(Creators.buildingSet(units, "unit_listing"));
    },
    errorPath: "data.description",
    isNotifyError: false,
  });

export const getBuildingUnitListPaginated = (id, page, sizePerPage, params) =>
  asyncCreator({
    actionId: "getBuildingUnitListPaginated",
    apiCall: (api) => api.management.getBuildingUnitListPaginated(id, page, sizePerPage, params),
    onSuccess: (dispatch, response, getState) => {
      const state = getState();
      const meta = _get(state, "building.unit_listing.meta", {});
      const units = {
        data: response.data,
        meta,
      };

      dispatch(Creators.buildingSet(units, "unit_listing"));
    },
    errorPath: "data.description",
    isNotifyError: false,
  });

export const getBuildingUnitListFilters = (id) =>
  asyncCreator({
    actionId: "getBuildingUnitListFilters",
    apiCall: (api) => api.management.getBuildingUnitListFilters(id),
    onSuccess: (dispatch, response, getState) => {
      const state = getState();
      const data = _get(state, "building.unit_listing.data", {});
      const units = {
        data,
        meta: response.data,
      };
      dispatch(Creators.buildingSet(units, "unit_listing"));
    },
    errorPath: "data.message",
  });

export const updateUnit = (id, data, unit_name) =>
  asyncCreator({
    actionId: "updateUnit",
    apiCall: (api) => api.management.updateUnit(id, data),
    onSuccess: (dispatch, response, getState) => {
      const { selectedBuilding } = getState().navigation;
      selectedBuilding && dispatch(getBuildingUnitListPaginated(selectedBuilding));
    },
    errorPath: "data.description",
  });

export const addMykey = (data) =>
  asyncCreator({
    actionId: "addMykey",
    apiCall: (api) => api.management.addMykey(data),
    onSuccess: (dispatch, response) => {
      notifySuccess("MyKey was succesfully added");
    },
    errorPath: "data.message",
  });

export const assignOnboardingPackage = (data) =>
  asyncCreator({
    actionId: "assignOnboardingPackage",
    apiCall: (api) => api.management.assignOnboardingPackage(data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Onboarding package was succesfully assigned");
      const { selectedBuilding } = getState().navigation;
      selectedBuilding && dispatch(getBuildingUnitListPaginated(selectedBuilding));
    },
    errorPath: "data.message",
  });

export const moveIn = (id, data, unit_name) =>
  asyncCreator({
    actionId: "moveIn",
    apiCall: (api) => api.management.moveIn(id, data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess(`New resident added to unit ${unit_name}`);
      const { selectedBuilding } = getState().navigation;
      selectedBuilding && dispatch(getBuildingUnitListPaginated(selectedBuilding));
    },
    errorPath: "data.message",
  });

export const onboard = (id, data, unit_name) =>
  asyncCreator({
    actionId: "onboard",
    apiCall: (api) => api.management.onboard(id, data),
    onSuccess: (dispatch, response, getState) => {
      const { selectedBuilding } = getState().navigation;
      selectedBuilding && dispatch(getBuildingUnitListPaginated(selectedBuilding));
    },
    errorPath: "data.message",
  });

export const moveOut = (id, unit_name) =>
  asyncCreator({
    actionId: "moveOut",
    apiCall: (api) => api.management.moveOut(id),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess(`Unit ${unit_name} Vacated`);
      const { selectedBuilding } = getState().navigation;
      selectedBuilding && dispatch(getBuildingUnitListPaginated(selectedBuilding));
    },
    errorPath: "data.message",
  });

export const typesOfLegalPhoto = () =>
  asyncCreator({
    actionId: "typesOfLegalPhoto",
    apiCall: (api) => api.management.typesOfLegalPhoto(),
    onSuccess: (dispatch, response, getState) => {
      dispatch(Creators.buildingSet(response.data, "typesOfLegalPhoto"));
    },
    isNotifyError: false,
    errorPath: "data.message",
  });

export const getBuildingGroups = (id) =>
  asyncCreator({
    actionId: "getBuildingGroups",
    apiCall: (api) => api.management.getBuildingGroups(id),
    onSuccess: (dispatch, response, getState) => {
      dispatch(Creators.buildingSet(response.data.data, "buildingGroups"));
    },
    isNotifyError: false,
    errorPath: "data.message",
  });

export const getPrimaryPhoto = (id) =>
  asyncCreator({
    actionId: "getPrimaryPhoto",
    apiCall: (api) => api.management.getPrimaryPhoto(id),
    errorPath: "data.message",
  });

export const updatePrimaryPhoto = (id, data) =>
  asyncCreator({
    actionId: "updatePrimaryPhoto",
    apiCall: (api) => api.management.updatePrimaryPhoto(id, data),
    errorPath: "data.message",
  });

export const deletePrimaryPhoto = (id) =>
  asyncCreator({
    actionId: "deletePrimaryPhoto",
    apiCall: (api) => api.management.deletePrimaryPhoto(id),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Primary photo deleted successfully");
    },
    errorPath: "data.message",
  });

export const getLegalPhoto = (id) =>
  asyncCreator({
    actionId: "getLegalPhoto",
    apiCall: (api) => api.management.getLegalPhoto(id),
    errorPath: "data.message",
  });

export const updateLegalPhoto = (id, data) =>
  asyncCreator({
    actionId: "updateLegalPhoto",
    apiCall: (api) => api.management.updateLegalPhoto(id, data),
    errorPath: "data.message",
  });

export const deleteLegalPhoto = (id) =>
  asyncCreator({
    actionId: "deleteLegalPhoto",
    apiCall: (api) => api.management.deleteLegalPhoto(id),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Legal photo deleted successfully");
    },
    errorPath: "data.message",
  });

export const getDirectoryPhoto = (id) =>
  asyncCreator({
    actionId: "getDirectoryPhoto",
    apiCall: (api) => api.management.getDirectoryPhoto(id),
    errorPath: "data.message",
  });

export const updateDirectoryPhoto = (id, data) =>
  asyncCreator({
    actionId: "updateDirectoryPhoto",
    apiCall: (api) => api.management.updateDirectoryPhoto(id, data),
    errorPath: "data.message",
  });

export const deleteDirectoryPhoto = (id) =>
  asyncCreator({
    actionId: "deleteDirectoryPhoto",
    apiCall: (api) => api.management.deleteDirectoryPhoto(id),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Directory photo deleted successfully");
      const { selectedBuilding } = getState().navigation;
      selectedBuilding && dispatch(getBuildingUnitListPaginated(selectedBuilding));
    },
    errorPath: "data.message",
  });

export const getBuildingResidents = (id) =>
  asyncCreator({
    actionId: "getBuildingResidents",
    apiCall: (api) => api.management.buildingResidents(id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.buildingSet(response.data.data, "residents"));
    },
    errorPath: "data.message",
  });

export const getBuildingResidentsFilters = (building_id) =>
  asyncCreator({
    actionId: "getBuildingResidentsFilters",
    apiCall: (api) => api.management.getBuildingResidentsFilters(building_id),
    onSuccess: (dispatch, response, getState) => {
      const state = getState();
      const data = _get(state, "building.residents.data", {});
      const residents = {
        data,
        meta: response.data,
      };
      dispatch(Creators.buildingSet(residents, "residents"));
    },
    errorPath: "data.message",
  });

export const getBuildingResidentsPaginated = (building_id, page, sizePerPage, params, offset) =>
  asyncCreator({
    actionId: "getBuildingResidentsPaginated",
    apiCall: (api) =>
      api.management.getBuildingResidentsPaginated(building_id, page, sizePerPage, params, offset),
    onSuccess: (dispatch, response, getState) => {
      const state = getState();
      const meta = _get(state, "building.residents.meta", {});
      const residents = {
        data: response.data,
        meta,
      };
      dispatch(Creators.buildingSet(residents, "residents"));
    },
    errorPath: "data.message",
  });

export const getResidentDetails = (data) =>
  asyncCreator({
    actionId: "getResidentDetails",
    apiCall: (api) => api.management.getResidentDetails(data),
    errorPath: "data.message",
  });

export const updateResidentInfo = (data) =>
  asyncCreator({
    actionId: "updateResidentInfo",
    apiCall: (api) => api.management.updateResidentInfo(data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Resident User updated succesfully");
    },
    errorPath: "data.message",
  });

export const setFacialRek = (data) =>
  asyncCreator({
    actionId: "setFacialRek",
    apiCall: (api) => api.management.setFacialRek(data),
    onSuccess: (dispatch, response, getState) => {
      const { selectedBuilding } = getState().navigation;
      dispatch(getBuildingResidentsPaginated(selectedBuilding));
    },
    errorPath: "data.message",
  });

export const resetMyKey = (id) =>
  asyncCreator({
    actionId: "resetMyKey",
    apiCall: (api) => api.management.resetMyKey(id),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("MyKey has been reset successfully");
      const { selectedBuilding } = getState().navigation;
      dispatch(getBuildingResidentsPaginated(selectedBuilding));
    },
    errorPath: "data.message",
  });

export const denyAccess = (data) =>
  asyncCreator({
    actionId: "denyAccess",
    apiCall: (api) => api.management.denyAccess(data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Access denied succesfully");
      const { selectedBuilding } = getState().navigation;
      dispatch(getBuildingResidentsPaginated(selectedBuilding));
    },
    errorPath: "data.message",
  });

export const allowAccess = (data) =>
  asyncCreator({
    actionId: "allowAccess",
    apiCall: (api) => api.management.allowAccess(data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Access re-allowed succesfully");
      const { selectedBuilding } = getState().navigation;
      dispatch(getBuildingResidentsPaginated(selectedBuilding));
    },
    errorPath: "data.message",
  });

export const activateUser = (data) =>
  asyncCreator({
    actionId: "activateUser",
    apiCall: (api) => api.management.activateUser(data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess(response.data);
      const { selectedBuilding } = getState().navigation;
      dispatch(getBuildingResidentsPaginated(selectedBuilding));
    },
    errorPath: "data.message",
  });

export const changeResidentRole = (residentId, role) =>
  asyncCreator({
    actionId: "changeResidentRole",
    apiCall: (api) => api.management.changeResidentRole({ id: residentId, role_id: role }),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess(response.data);
      const { selectedBuilding } = getState().navigation;
      dispatch(getBuildingResidentsPaginated(selectedBuilding));
    },
    errorPath: "data.message",
  });

export const deleteMyKey = (id) =>
  asyncCreator({
    actionId: "deleteMyKey",
    apiCall: (api) => api.management.deleteMyKey(id),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("MyKey deleted succesfully");
      const { selectedBuilding } = getState().navigation;
      dispatch(getBuildingResidentsPaginated(selectedBuilding));
    },
    errorPath: "data.message",
  });

export const firstTouches = (data) =>
  asyncCreator({
    actionId: "firstTouches",
    apiCall: (api) => api.management.firstTouches(data),
    isNotifyError: false,
    errorPath: "data.message",
  });

export const getBuildingAccess = (id) =>
  asyncCreator({
    actionId: "getBuildingAccess",
    apiCall: (api) => api.management.buildingAccess(id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.buildingSet(response.data.data, "access"));
    },
    errorPath: "data.message",
  });

export const getBuildingAccessPaginated = (id, page, sizePerPage, params) =>
  asyncCreator({
    actionId: "getBuildingAccessPaginated",
    apiCall: (api) => api.management.buildingAccessPaginated(id, page, sizePerPage, params),
    onSuccess: (dispatch, response, getState) => {
      const state = getState();
      const accessMeta = _get(state, "building.access.meta", {});
      const access = {
        data: response.data.data,
        meta: {
          ...accessMeta,
          ...response.data.meta,
        },
      };
      dispatch(Creators.buildingSet(access, "access"));
    },
    errorPath: "data.message",
  });

export const getBuildingAccessMeta = (building_id) =>
  asyncCreator({
    actionId: "getBuildingAccessMeta",
    apiCall: (api) => api.management.buildingAccessMeta(building_id),
    onSuccess: (dispatch, response, getState) => {
      const state = getState();
      const prevAccessMeta = _get(state, "building.access.meta", {});
      const prevAccessData = _get(state, "building.access.data");
      const hasValidCoordinates = _get(response, "data.has_valid_coordinates", false);
      const access = {
        data: prevAccessData,
        meta: {
          ...prevAccessMeta,
          ...response.data.meta,
          hasValidCoordinates,
        },
      };
      dispatch(Creators.buildingSet(access, "access"));
    },
    errorPath: "data.message",
  });

export const getBuildingActivities = (id) =>
  asyncCreator({
    actionId: "getBuildingActivities",
    apiCall: (api) => api.management.buildingActivities(id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.buildingSet(response.data.data, "activity"));
    },
    errorPath: "data.message",
  });

export const getBuildingActivitiesPaginated = (id, page, sizePerPage, params) =>
  asyncCreator({
    actionId: "getBuildingActivitiesPaginated",
    apiCall: (api) => api.management.getBuildingActivitiesPaginated(id, page, sizePerPage, params),
    onSuccess: (dispatch, response, getState) => {
      const state = getState();
      const meta = _get(state, "building.activities.meta", {});
      const events = {
        data: response.data,
        meta,
      };

      dispatch(Creators.buildingSet(events, "activities"));
    },
    errorPath: "data.description",
  });

export const getBuildingActivitiesFilters = (id) =>
  asyncCreator({
    actionId: "getBuildingActivitiesFilters",
    apiCall: (api) => api.management.getBuildingActivitiesFilters(id),
    onSuccess: (dispatch, response, getState) => {
      const state = getState();
      const data = _get(state, "building.activities.data", {});
      const events = {
        data,
        meta: response.data,
      };
      dispatch(Creators.buildingSet(events, "activities"));
    },
    errorPath: "data.message",
  });

export const getBuildingMessages = (id) =>
  asyncCreator({
    actionId: "getBuildingMessages",
    apiCall: (api) => api.management.buildingMessages(id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.buildingSet(response.data.data, "messages"));
    },
    errorPath: "data.message",
  });

export const getBuildingMessagesKeycom = (building_id, page, sizePerPage, params) =>
  asyncCreator({
    actionId: "getBuildingMessagesKeycom",
    apiCall: (api) => api.management.buildingMessagesKeycom(building_id, page, sizePerPage, params),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.buildingSet(response.data, "messagesKeycom"));
    },
    errorPath: "data.message",
  });

export const getBuildingMessagesText = (building_id, page, sizePerPage, params) =>
  asyncCreator({
    actionId: "getBuildingMessagesText",
    apiCall: (api) => api.management.buildingMessagesText(building_id, page, sizePerPage, params),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.buildingSet(response.data, "messagesText"));
    },
    errorPath: "data.message",
  });

export const deleteMessage = (id) =>
  asyncCreator({
    actionId: "deleteMessage",
    apiCall: (api) => api.management.deleteMessage(id),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Message Deleted succesfully");
      const { selectedBuilding } = getState().navigation;
      dispatch(getBuildingMessagesKeycom(selectedBuilding));
      dispatch(getBuildingMessagesText(selectedBuilding));
    },
    errorPath: "data.message",
  });

export const listenedTo = (id, type) =>
  asyncCreator({
    actionId: "listenedTo",
    apiCall: (api) => api.account.listenedTo(id),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Message Read");
      if (type) {
        //We change the array instead of fetching the messages because fetching will reorder the table adn break usability
        const state = getState();
        const array = _get(state, `building.${type}.messages`, []);
        const newArray = array.map((i) => (i.id === id ? { ...i, listened: i.listened + 1 } : i));
        dispatch(Creators.buildingSet({ messages: newArray }, type));
      }
    },
    errorPath: "data.message",
  });

export const getBuildingEvents = (id) =>
  asyncCreator({
    actionId: "getBuildingEvents",
    apiCall: (api) => api.management.buildingEvents(id),
    onSuccess: (dispatch, response, getState) => {
      dispatch(Creators.buildingSet(response.data.data, "events"));
    },
    errorPath: "data.message",
  });

export const createBuildingEvent = (data) =>
  asyncCreator({
    actionId: "createBuildingEvent",
    apiCall: (api) => api.management.createBuildingEvent(data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Building Calendar event added successfully");
      const stateEvents = _get(getState(), "building.events", []);
      const events = [...stateEvents, _get(response, "data.data", {})];
      dispatch(Creators.buildingSet(events, "events"));
    },
    errorPath: "data.message",
  });

export const editBuildingEvent = (id, data) =>
  asyncCreator({
    actionId: "editBuildingEvent",
    apiCall: (api) => api.management.editBuildingEvent(id, data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Building Calendar event updated succesfully");
      const stateEvents = _get(getState(), "building.events", []);
      const updatedEvent = _get(response, "data.data", {});
      const events = stateEvents.map((i) => (i.id === id ? updatedEvent : i));
      dispatch(Creators.buildingSet(events, "events"));
    },
    errorPath: "data.message",
  });

export const deleteBuildingEvent = (id) =>
  asyncCreator({
    actionId: "deleteBuildingEvent",
    apiCall: (api) => api.management.deleteBuildingEvent(id),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Building Calendar event deleted succesfully");
      const stateEvents = _get(getState(), "building.events", []);
      const events = stateEvents.filter((i) => i.id !== id);
      dispatch(Creators.buildingSet(events, "events"));
    },
    errorPath: "data.message",
  });

export const getBuildingNotices = (id) =>
  asyncCreator({
    actionId: "getBuildingNotices",
    apiCall: (api) => api.management.buildingNotices(id),
    onSuccess: (dispatch, response, getState) => {
      dispatch(Creators.buildingSet(response.data.data, "notices"));
    },
    errorPath: "data.message",
  });

export const createBuildingNotice = (data) =>
  asyncCreator({
    actionId: "createBuildingNotice",
    apiCall: (api) => api.management.createBuildingNotice(data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Building Notice added successfully");
      const { selectedBuilding } = getState().navigation;
      dispatch(getBuildingNotices(selectedBuilding));
    },
    errorPath: "data.message",
  });

export const editBuildingNotice = (id, data) =>
  asyncCreator({
    actionId: "editBuildingNotice",
    apiCall: (api) => api.management.editBuildingNotice(id, data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Building Notice updated succesfully");
      const stateNotices = _get(getState(), "building.notices", []);
      const updatedNotice = _get(response, "data.data", {});
      const notices = stateNotices.map((i) => (i.id === id ? updatedNotice : i));
      dispatch(Creators.buildingSet(notices, "notices"));
    },
    errorPath: "data.message",
  });

export const deleteBuildingNotice = (id) =>
  asyncCreator({
    actionId: "deleteBuildingNotice",
    apiCall: (api) => api.management.deleteBuildingNotice(id),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Building Notice deleted succesfully");
      const stateNotices = _get(getState(), "building.notices", []);
      const notices = stateNotices.filter((i) => i.id !== id);
      dispatch(Creators.buildingSet(notices, "notices"));
    },
    errorPath: "data.message",
  });

export const getBuildingInviteMessages = (id) =>
  asyncCreator({
    actionId: "getBuildingInviteMessages",
    apiCall: (api) => api.management.getBuildingInviteMessages(id),
    onSuccess: (dispatch, response, getState) => {
      dispatch(Creators.buildingSet(response.data.data, "inviteMessages"));
    },
    errorPath: "data.message",
  });

export const updateBuildingInviteMessages = (id, messages) =>
  asyncCreator({
    actionId: "updateBuildingInviteMessages",
    apiCall: (api) => api.management.updateBuildingInviteMessages(id, messages),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Building Visitors MyKey Invite messages updated succesfully");
      dispatch(Creators.buildingSet(response.data.data, "inviteMessages"));
    },
    errorPath: "data.message",
  });

export const getBuildingInfo = (id) =>
  asyncCreator({
    actionId: "getBuildingInfo",
    apiCall: (api) => api.management.getBuildingInfo(id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.buildingSet(response.data.data, "info"));
    },
    errorPath: "data.message",
  });

export const updateBuildingInfo = (id, data) =>
  asyncCreator({
    actionId: "updateBuildingInfo",
    apiCall: (api) => api.management.updateBuildingInfo(id, data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Building info updated succesfully");
      dispatch(Creators.buildingSet(response.data.data, "info"));
    },
    errorPath: "data.message",
  });

export const getBuildingSabbathMode = (id) =>
  asyncCreator({
    actionId: "getBuildingSabbathMode",
    apiCall: (api) => api.management.getBuildingSabbathMode(id),
    onSuccess: (dispatch, response, getState) => {
      dispatch(Creators.buildingSet(response.data, "is_sabbath_aware"));
    },
    errorPath: "data.message",
    isNotifyError: false,
  });

export const updateBuildingSabbathMode = (id, data) =>
  asyncCreator({
    actionId: "updateBuildingSabbathMode",
    apiCall: (api) => api.management.updateBuildingSabbathMode(id, data),
    onSuccess: (dispatch, response, getState) => {
      dispatch(Creators.buildingSet(data.is_sabbath_aware, "is_sabbath_aware"));
      notifySuccess("Building sabbath / holiday mode updated successfully");
    },
    errorPath: "data.message",
    isNotifyError: false,
  });

export const updateBuildingSettings = (id, fieldName, fieldValue, changeUnits = false) =>
  asyncCreator({
    actionId: "updateBuildingSettings",
    apiCall: (api) => api.management.updateBuildingSettings(id, { fieldName, fieldValue, changeUnits }),
    onSuccess: (dispatch, response, getState) => {
      const currentInfo = _get(getState(), "building.info", {});

      const previousValue = _get(currentInfo, fieldName);
      const resultFieldValue = _get(response.data, fieldName, previousValue);

      const nextInfo = _set(currentInfo, fieldName, resultFieldValue);

      if (response.data.changeUnits === true) {
        const unitListing = _get(getState(), "building.unit_listing", {});
        const units = _get(getState(), "building.unit_listing.data.building_units_list", []);
        const newUnits = units.map((unit) => {
          unit[fieldName] = resultFieldValue;
          return unit;
        });
        const data = { ...unitListing, building_units_list: newUnits };
        dispatch(Creators.buildingSet(data, "unit_listing"));
      }

      dispatch(Creators.buildingSet(nextInfo, "info"));
      notifySuccess("Info updated successfully");
    },
    errorPath: "data.message",
    isNotifyError: true,
  });

export const updateUnitSettings = (buildingId, unitId, fieldName, fieldValue) =>
  asyncCreator({
    actionId: "updateUnitSettings",
    apiCall: (api) => api.management.updateUnitSettings(buildingId, unitId, { fieldName, fieldValue }),
    onSuccess: (dispatch, response, getState) => {
      const unitListing = _get(getState(), "building.unit_listing", {});
      const units = _get(getState(), "building.unit_listing.data.building_units_list", []);

      const newUnits = units.map((unit) => {
        if (unit.unit_id === response.data.unit_id) unit[fieldName] = response.data[fieldName];
        return unit;
      });

      const data = { ...unitListing, building_units_list: newUnits };
      dispatch(Creators.buildingSet(data, "unit_listing"));
      notifySuccess("Info updated successfully");
    },
    errorPath: "data.message",
    isNotifyError: true,
  });

export const updateBuildingPermissionToSendVisitorMyKeys = (id, data) =>
  asyncCreator({
    actionId: "updateBuildingPermissionToSendVisitorMyKeys",
    apiCall: (api) => api.management.updateBuildingPermissionToSendVisitorMyKeys(id, data),
    onSuccess: (dispatch, response, getState) => {
      const currentInfo = _get(getState(), "building.info", {});
      const nextInfo = _set(
        currentInfo,
        PermissionNames.can_send_visitor_mykeys,
        data.can_send_visitor_mykeys
      );

      dispatch(Creators.buildingSet(nextInfo, "info"));
      notifySuccess("Building permissions updated successfully");
    },
    errorPath: "data.message",
    isNotifyError: false,
  });

export const getBuildingLogo = (id) =>
  asyncCreator({
    actionId: "getBuildingLogo",
    apiCall: (api) => api.management.getBuildingLogo(id),
    onSuccess: (dispatch, response, getState) => {
      dispatch(Creators.buildingSet(response.data, "logo"));
    },
    isNotifyError: false,
  });

export const updateBuildingLogo = (id, data) =>
  asyncCreator({
    actionId: "updateBuildingLogo",
    apiCall: (api) => api.management.updateBuildingLogo(id, data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Logo uploaded succesfully");
      dispatch(Creators.buildingSet(response.data.data.s3_url, "logo"));
    },
    errorPath: "data.message",
  });

export const deleteBuildingLogo = (id) =>
  asyncCreator({
    actionId: "deleteBuildingLogo",
    apiCall: (api) => api.management.deleteBuildingLogo(id),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Logo deleted succesfully");
      dispatch(Creators.buildingSet(null, "logo"));
    },
    errorPath: "data.message",
  });

export const updateCustomManagementLogo = (id, data) =>
  asyncCreator({
    actionId: "updateCustomManagementLogo",
    apiCall: (api) => api.management.updateCustomManagementLogo(id, data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Custom Management Logo uploaded succesfully");
      const currentInfo = _get(getState(), "building.info", {});
      const nextInfo = { ...currentInfo, custom_management_logo_link: response.data.data.s3_url };
      dispatch(Creators.buildingSet(nextInfo, "info"));
    },
    errorPath: "data.message",
  });

export const deleteCustomManagementLogo = (id) =>
  asyncCreator({
    actionId: "deleteCustomManagementLogo",
    apiCall: (api) => api.management.deleteCustomManagementLogo(id),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Custom Management Logo deleted succesfully");
      const currentInfo = _get(getState(), "building.info", {});
      const nextInfo = { ...currentInfo, custom_management_logo_link: null };
      dispatch(Creators.buildingSet(nextInfo, "info"));
    },
    errorPath: "data.message",
  });

export const uploadBuildingResidents = (id, data) =>
  asyncCreator({
    actionId: "uploadBuildingResidents",
    apiCall: (api) => api.management.uploadBuildingResidents(id, data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess(response.data.message);
    },
    errorPath: "data.message",
  });

export const getAnalytics = (id) =>
  asyncCreator({
    actionId: "getAnalytics",
    apiCall: (api) => api.management.getAnalytics(id),
    onSuccess: (dispatch, response, getState) => {
      const state = getState();
      const currentAnalytics = _get(state, "building.analytics", []);
      const analytics = {
        ...currentAnalytics,
        ...response.data,
      };
      dispatch(Creators.buildingSet(analytics, "analytics"));
    },
    errorPath: "data.message",
  });

export const getEntriesByTypesAnalytics = (id) =>
  asyncCreator({
    actionId: "getEntriesByTypesAnalytics",
    apiCall: (api) => api.management.getEntriesByTypesAnalytics(id),
    onSuccess: (dispatch, response, getState) => {
      const state = getState();
      const analytics = _get(state, "building.analytics", []);
      const entriesByTypes = {
        ...analytics,
        entriesByTypes: response.data,
      };
      dispatch(Creators.buildingSet(entriesByTypes, "analytics"));
    },
    errorPath: "data.message",
  });

export const getTotalEntriesAnalytics = (id) =>
  asyncCreator({
    actionId: "getTotalEntriesAnalytics",
    apiCall: (api) => api.management.getTotalEntriesAnalytics(id),
    onSuccess: (dispatch, response, getState) => {
      const state = getState();
      const analytics = _get(state, "building.analytics", []);
      const totalEntries = {
        ...analytics,
        totalEntries: response.data,
      };
      dispatch(Creators.buildingSet(totalEntries, "analytics"));
    },
    errorPath: "data.message",
  });

export const getAnalyticsAtLeast1MyKey = (id) =>
  asyncCreator({
    actionId: "getAnalyticsAtLeast1MyKey",
    apiCall: (api) => api.management.getAnalyticsAtLeast1MyKey(id),
    onSuccess: (dispatch, response, getState) => {
      dispatch(Creators.buildingSet(response.data.data, "analyticsAtLeast1MyKey"));
    },
    errorPath: "data.message",
  });

export const getAnalyticsAccountActivated = (id) =>
  asyncCreator({
    actionId: "getAnalyticsAccountActivated",
    apiCall: (api) => api.management.getAnalyticsAccountActivated(id),
    onSuccess: (dispatch, response, getState) => {
      dispatch(Creators.buildingSet(response.data.data, "analyticsAccountActivated"));
    },
    errorPath: "data.message",
  });

export const getAnalyticsSignIntoApp = (id) =>
  asyncCreator({
    actionId: "getAnalyticsSignIntoApp",
    apiCall: (api) => api.management.getAnalyticsSignIntoApp(id),
    onSuccess: (dispatch, response, getState) => {
      dispatch(Creators.buildingSet(response.data.data, "analyticsSignIntoApp"));
    },
    errorPath: "data.message",
  });

//Flags
export const getFlags = (id) =>
  asyncCreator({
    actionId: "getFlags",
    apiCall: (api) => api.management.getFlags(id),
    onSuccess: (dispatch, response, getState) => {
      dispatch(Creators.buildingSet(response.data.data, "flags"));
    },
    errorPath: "data.message",
  });

export const getFlagsPaginated = (id, page, sizePerPage, params) =>
  asyncCreator({
    actionId: "getFlagsPaginated",
    apiCall: (api) => api.management.getFlagsPaginated(id, page, sizePerPage, params),
    onSuccess: (dispatch, response, getState) => {
      dispatch(Creators.buildingSet(response.data, "flags"));
    },
    errorPath: "data.message",
  });

export const getFlagsDetails = (id) =>
  asyncCreator({
    actionId: "getFlagsDetails",
    apiCall: (api) => api.management.getFlagsDetails(id),
    errorPath: "data.message",
  });

export const unflagFlag = (id) =>
  asyncCreator({
    actionId: "unflagFlag",
    apiCall: (api) => {
      const data = { flagged: false, comment: "" };
      return api.management.updateFlag(id, data);
    },
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Flag was unflagged");
    },
    errorPath: "data.message",
  });

export const updateFlag = (id, data) =>
  asyncCreator({
    actionId: "updateFlag",
    apiCall: (api) => api.management.updateFlag(id, data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Flag updated succesfully");
    },
    errorPath: "data.message",
  });

export const getFlagReasons = () =>
  asyncCreator({
    actionId: "getFlagReasons",
    apiCall: (api) => api.management.getFlagReasons(),
    onSuccess: (dispatch, response, getState) => {
      dispatch(Creators.buildingSet(response.data, "flagReasons"));
    },
    errorPath: "data.message",
  });

export const getFlagsSummary = (page, sizePerPage, id, datePeriod = null) =>
  asyncCreator({
    actionId: "getFlagsSummary",
    apiCall: (api) => api.management.getFlagsSummary(page, sizePerPage, id, datePeriod),
    onSuccess: (dispatch, response, getState) => {
      dispatch(Creators.buildingSet(response.data, "flagsSummary"));
    },
    errorPath: "data.message",
  });

export const getFlagsSummaryFilters = (id) =>
  asyncCreator({
    actionId: "getFlagsSummaryFilters",
    apiCall: (api) => api.management.getFlagsSummaryFilters(id),
    onSuccess: (dispatch, response, getState) => {
      dispatch(Creators.buildingSet(response.data, "flagsSummaryFilters"));
    },
    errorPath: "data.message",
  });

//Promos
export const getPromos = (id) =>
  asyncCreator({
    actionId: "getPromos",
    apiCall: (api) => api.dealer.getPromos(id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.buildingSet(response.data.data, "promos"));
    },
    errorPath: "data.message",
  });

export const addNewPromo = (data, { ...args }) =>
  asyncCreator({
    actionId: "addNewPromo",
    apiCall: (api) => api.dealer.addNewPromo(data, { ...args }),
    onSuccess: (dispatch, response, getState) => {
      const selectedBuilding = _get(getState(), "navigation.selectedBuilding");
      selectedBuilding && dispatch(getPromos(selectedBuilding));
      notifySuccess("New promo was successfully created");
    },
    errorPath: "data.message",
    onError: (dispatch, response, getState, errorPath) => {
      const { problem } = response;
      const error = _get(response, errorPath);
      if (problem === "CANCEL_ERROR") {
        notifyError("Uploading canceled by user.");
      } else {
        console.log(error);
        notifyError(error);
      }
    },
    isNotifyError: false,
  });

export const deletePromo = (id) =>
  asyncCreator({
    actionId: "deletePromo",
    apiCall: (api) => api.dealer.deletePromo(id),
    onSuccess: (dispatch, response, getState) => {
      const selectedBuilding = _get(getState(), "navigation.selectedBuilding");
      selectedBuilding && dispatch(getPromos(selectedBuilding));
      notifySuccess("The promo was successfully deleted");
    },
    errorPath: "data.message",
  });

export const updatePromo = (id, data, { ...args }) =>
  asyncCreator({
    actionId: "updatePromo",
    apiCall: (api) => api.dealer.updatePromo(id, data, { ...args }),
    onSuccess: (dispatch, response, getState) => {
      const selectedBuilding = _get(getState(), "navigation.selectedBuilding");
      selectedBuilding && dispatch(getPromos(selectedBuilding));
      notifySuccess("The promo was successfully updated");
    },
    errorPath: "data.message",
    onError: (dispatch, response, getState, errorPath) => {
      const { problem } = response;
      const error = _get(response, errorPath);
      if (problem === "CANCEL_ERROR") {
        notifyError("Uploading canceled by user.");
      } else {
        console.log(error);
        notifyError(error);
      }
    },
    isNotifyError: false,
  });

export const togglePromoStatus = (id) =>
  asyncCreator({
    actionId: "togglePromoStatus",
    apiCall: (api) => api.dealer.togglePromoStatus(id),
    onSuccess: (dispatch, response, getState) => {
      const selectedBuilding = _get(getState(), "navigation.selectedBuilding");
      selectedBuilding && dispatch(getPromos(selectedBuilding));
      notifySuccess("Status has been successfully changed");
    },
    errorPath: "data.message",
  });

export const requestRemoval = (id) =>
  asyncCreator({
    actionId: "requestRemoval",
    apiCall: (api) => api.dealer.requestRemoval(id),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Delete request sent successfully");
    },
    errorPath: "data.message",
  });

//Alerts
export const getAlerts = (id, time_period, page, sizePerPage) =>
  asyncCreator({
    actionId: "getAlerts",
    apiCall: (api) => api.management.getAlerts(id, time_period, page, sizePerPage),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.buildingSet(response.data, "alerts"));
    },
    errorPath: "data.message",
  });

//KeyComs
export const getKeyComList = (id) =>
  asyncCreator({
    actionId: "getKeyComList",
    apiCall: (api) => api.building.getKeyComList(id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.buildingSet(response.data.data, "keycoms"));
    },
    errorPath: "data.description",
  });

//Access List
export const getAccessList = (id) =>
  asyncCreator({
    actionId: "getAccessList",
    apiCall: (api) => api.building.getAccessList(id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.buildingSet(response.data.data, "access_list"));
    },
    errorPath: "data.description",
  });

export const getSuperintedentUserList = (id) =>
  asyncCreator({
    actionId: "getSuperintedentUserList",
    apiCall: (api) => api.building.getSuperintedentUserList(id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.buildingSet(response.data.data, "superintendentUsers"));
    },
    errorPath: "data.description",
  });

export const createAccess = (id, data) =>
  asyncCreator({
    actionId: "createAccess",
    apiCall: (api) => api.building.createAccess(id, data),
    onSuccess: (dispatch, response) => {
      notifySuccess("Building Access successfully created");
      dispatch(getAccessList(id));
    },
    errorPath: "data.description",
  });

export const updateAccess = (id, access_id, data) =>
  asyncCreator({
    actionId: "updateAccess",
    apiCall: (api) => api.building.updateAccess(id, access_id, data),
    onSuccess: (dispatch, response) => {
      notifySuccess("Building Access successfully updated");
      dispatch(getAccessList(id));
    },
    errorPath: "data.description",
  });

export const getBuildingBrivoConfig = (buildingId) =>
  asyncCreator({
    actionId: "getBuildingBrivoConfig",
    apiCall: (api) => api.brivo.getBuildingBrivoConfig(buildingId),
    onSuccess: (dispatch, response, getState) => {
      const { is_sync_complete } = response.data;
      dispatch(setSyncComplete(is_sync_complete));
      dispatch(Creators.buildingSet(response.data, "brivo"));
    },
    errorPath: "data.description",
    isNotifyError: false,
  });

export const deleteAllUnits = (id) =>
  asyncCreator({
    actionId: "deleteAllUnits",
    apiCall: (api) => api.building.deleteAllUnits(id),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Building units deleted succesfully");
      id && dispatch(getBuildingUnitListPaginated(id));
    },
    errorPath: "data.message",
  });

export const deleteAllResidents = (id) =>
  asyncCreator({
    actionId: "deleteAllResidents",
    apiCall: (api) => api.building.deleteAllResidents(id),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Building residents deleted succesfully");
      dispatch(getBuildingResidentsPaginated(id));
    },
    errorPath: "data.message",
  });

export const getAllWebrelays = (id) =>
  asyncCreator({
    actionId: "getAllWebrelays",
    apiCall: (api) => api.building.getAllWebrelays(id),
    onSuccess: (dispatch, response, getState) => {
      const rawWebrelays = _get(response, "data", []) || [];
      const webrelays = rawWebrelays.map((item) => {
        item.trigger_value = {
          [item.trigger_type]: JSON.parse(item.trigger_value),
        };
        item.works_with = JSON.parse(item.works_with);
        return item;
      });
      dispatch(Creators.buildingSet(webrelays, "webrelays"));
    },
    errorPath: "data.message",
  });

export const addNewWebrelay = (id, data) =>
  asyncCreator({
    actionId: "addNewWebrelay",
    apiCall: (api) => api.building.addNewWebrelay(id, data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Webrelay added succesfully");
      dispatch(getAllWebrelays(id));
    },
    errorPath: "data.message",
  });

export const toggleWebrelay = (id) =>
  asyncCreator({
    actionId: "toggleWebrelay",
    apiCall: (api) => api.building.toggleWebrelay(id),
    onSuccess: (dispatch, response, getState) => {
      if (response && response.data && response.data.text) notifySuccess(response.data.text);
    },
    errorPath: "data.message",
  });

export const checkWebrelayUrl = (url) =>
  asyncCreator({
    actionId: "checkWebrelayUrl",
    apiCall: (api) => api.building.checkWebrelayUrl(url),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Webrelay url working succesfully");
    },
    errorPath: "data.message",
  });

export const removeWebrelay = (id) =>
  asyncCreator({
    actionId: "removeWebrelay",
    apiCall: (api) => api.building.removeWebrelay(id),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Webrelay removed succesfully");
      const state = getState();
      const buildingId =
        _get(state, "building.info.id", null) || _get(state, "navigation.selectedBuilding", null);
      buildingId && dispatch(getAllWebrelays(buildingId));
    },
    errorPath: "data.message",
  });

export const updateWebrelay = (id, data) =>
  asyncCreator({
    actionId: "updateWebrelay",
    apiCall: (api) => api.building.updateWebrelay(id, data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Webrelay updated succesfully");
      const state = getState();
      const buildingId =
        _get(state, "building.info.id", null) || _get(state, "navigation.selectedBuilding", null);
      buildingId && dispatch(getAllWebrelays(buildingId));
    },
    errorPath: "data.message",
  });

export const getDeliveryPackagesPaginated = (building_id, page, sizePerPage, params) =>
  asyncCreator({
    actionId: "getDeliveryPackagesPaginated",
    apiCall: (api) => api.building.getDeliveryPackagesPaginated(building_id, page, sizePerPage, params),
    onSuccess: (dispatch, response, getState) => {
      const { meta } = _get(getState(), "building.deliveryPackages", {});
      const deliveryPackages = {
        data: response.data.data,
        meta: { ...meta, total_count: _get(response.data, "total_count") },
      };
      dispatch(Creators.buildingSet(deliveryPackages, "deliveryPackages"));
    },
    errorPath: "data.message",
  });

export const getDeliveryPackagesFilters = (building_id) =>
  asyncCreator({
    actionId: "getDeliveryPackagesFilters",
    apiCall: (api) => api.building.getDeliveryPackagesFilters(building_id),
    onSuccess: (dispatch, response, getState) => {
      const { data, meta } = _get(getState(), "building.deliveryPackages", {});
      const deliveryPackages = {
        data,
        meta: { ...meta, ...response.data },
      };
      dispatch(Creators.buildingSet(deliveryPackages, "deliveryPackages"));
    },
    errorPath: "data.message",
  });

export const updateDeliveryPackage = (delivery_package_id, data) =>
  asyncCreator({
    actionId: "updateDeliveryPackage",
    apiCall: (api) => api.building.updateDeliveryPackage(delivery_package_id, data),
    onSuccess: (dispatch, response, getState) => {
      const { selectedBuilding } = _get(getState(), "navigation", {});

      if (selectedBuilding) {
        dispatch(getDeliveryPackagesFilters(selectedBuilding));
        dispatch(getDeliveryPackagesPaginated(selectedBuilding));
        notifySuccess(response.data.message);
      }
    },
    errorPath: "data.message",
  });

export const clearResidents = () => actions.buildingSet(null, "residents");
export const setWebrelays = (webrelays) => actions.buildingSet(webrelays, "webrelays");

export const updateBuildingAddressLocally = (data) => (dispatch) =>
  dispatch(Creators.buildingSet(data, "info"));

export default buildingReducer;

import injectGlobal from "./injectGlobal";

const modals = theme => injectGlobal`
  .modal-dialog {
    -webkit-transform: translate(0,50px) !important;
    transform: translate(0,50px) !important;
    &.view-activity-details{
      min-width: 70vw;
    }
    &.view-access-details{
      max-width: 920px;
    }
    &.photo-modal{
      -webkit-transform: translate(0,50%) !important;
      transform: translate(0, 50%) !important;
    }
  }
  .modal-header{
    padding: 12px;
    button{
      span{
        color: white;
      }
    }
  }
  .modal-title{
    font-size: 16px;
    letter-spacing: .1em;
    font-weight: 300;
    text-transform: capitalize;
  }
  .modal-body{
    padding: 2em;
    margin: 1em;
    border-radius: 5px;
    background: rgba(240, 240, 240, .313);
    border: 1px solid rgba(230, 230, 230, .63);
    &.modal-body__no-border {
      background: transparent;
      border: 0;
    }
  }
  .modal-footer{
    justify-content: center;
    &.modal-footer__between {
      justify-content: space-between;
    }
  }
  .modal-image{
    height: 100px;
    width: 200px;
    object-fit: contain;
    margin-bottom: 1em;
    &.big{
      width: 400px;
      height: 200px;
    }
    &.mini{
      width: 100px !important;
      margin-right: 1em;
    }
  }
  .nested-modal{
    .modal-content{
      margin: 2em;
      margin-top: 15%;
      background: rgba(0,0,0, .76);
      .modal-body{
        background: transparent;
        color: white;
        border: 0;
      }
      .modal-footer{
        border: 0;
        .submit-button{
          &:hover{
            background-color: #990000 !important;
          }
        }
      }
    }
    &.view-modal{
      &.modal-dialog{
        min-width: 100vw;
        min-height: 100vh;
        transform: translate(0,-1%) !important;
        margin: 0 !important;
        & .modal-content{
          width: 100vw !important;
          margin: 0 !important;
          margin-top: 0%;
          background: rgba(0,0,0, .76);
          & .modal-body{
            background: transparent;
            color: white;
            border: 0;
          }
          & .modal-header{
            line-height: 0;
          }
          & .modal-footer{
            border: 0;
            .submit-button{
              &:hover{
                background-color: #990000 !important;
              }
            }
          }
        }
      }
    }
    &.upgrade-modal{
      .modal-content{
        margin: 2em;
        margin-top: 19%;
        background: rgba(0,0,0, .76);
        width: 270px;
        height: 276px;
        .modal-body{
          background: transparent;
          color: white;
          border: 0;
          text-align: center;
        }
        .modal-footer{
          border: 0;
          .submit-button{
            &:hover{
              background-color: #990000 !important;
            }
          }
        }
      }
    }
    &.delete-primary, &.delete-legal, &.delete-directory{
      .modal-content{
        margin: 2em;
        margin-top: 39%;
        background: rgba(0,0,0, .76);
        width: 195px;
        height: 250px;
        .modal-body{
          background: transparent;
          color: white;
          border: 0;
        }
        .modal-footer{
          border: 0;
          .submit-button{
            &:hover{
              background-color: #990000 !important;
            }
          }
        }
      }
    }
    &.delete-primary{
      .modal-content{
        margin-right: 520px;
      }
    }
    &.delete-directory{
      .modal-content{
        margin-left: 520px;
      }
    }
  }
  @media (max-width: ${theme.breakpoints.small}) {
    .body-content{
      & .col-9{
        max-width: 100%;
        flex: 0 0 100%;
        & .row{
          display: block;
          text-align: center;
        }
        & .btn{
          margin-top: 10px;
          margin-bottom: 10px;
          width: 100%;
        }
      }
      & .btn-download{
        width: 100%;
      }
      & .form-row{
        display: block;
      }
    }
    &.view-modal{
      &.modal-dialog{
        min-width: 100vw;
        min-height: 100vh;
        transform: translate(0,-1%) !important;
        margin: 0 !important;
        & .modal-content{
          width: 100vw !important;
          margin: 0 !important;
          margin-top: 0%;
          background: rgba(0,0,0, .76);
          & .modal-body{
            background: transparent;
            color: white;
            border: 0;
            & div{
              top: 0;
              position: absolute;
              right: 0;
              left: 0;
              width: 100%;
              & img{
                width: 100%;
              }
            }
          }
          & .modal-header{
            line-height: 0;
          }
          & .modal-footer{
            border: 0;
            .submit-button{
              &:hover{
                background-color: #990000 !important;
              }
            }
          }
        }
      }
    }
  }
  }
`;

export default modals;

import { createReducer, createActions } from "reduxsauce";
import {
  actions as dealerActions,
  getMetaInformation as getMetaInformationForDealer,
} from "redux/reducers/dealer";
import { actions as managementActions, getMetaInformation } from "redux/reducers/management";
import { actions as buildingActions, getBuildingInfo } from "redux/reducers/building";
import { actions as brivoActions } from "redux/reducers/brivo";
import { setFilter } from "redux/actions/searchSelect";
import _get from "lodash/get";

import { getSequenceKeys, getNewBreadcrumbs, getBreadcrumbObject } from "utils/breadcrumbs";

export const findBuildingIdInUrl = (path) => {
  const index = path.split("/").findIndex((i) => i === "buildings");
  return index > 0 ? parseInt(path.split("/")[index + 1], 10) : null;
};

export const findTitleInUrl = (path) => {
  const index = path.split("/").findIndex((i) => i === "app");
  return index > 0 ? parseInt(path.split("/")[index + 1], 10) : null;
};

export const findManagerIdInUrl = (path) => {
  const index = path.split("/").findIndex((i) => i === "management_companies");
  return index > 0 ? parseInt(path.split("/")[index + 1], 10) : null;
};

export const findDealerIdInUrl = (path) => {
  const index = path.split("/").findIndex((i) => i === "dealers");
  return index > 0 ? parseInt(path.split("/")[index + 1], 10) : null;
};

/* ------------- Types and Action Creators ------------- */
export const { Types, Creators } = createActions({
  navSelectBuilding: ["id"],
  navSelectManager: ["id"],
  navSelectDealer: ["id"],
  navSelectTitle: ["id"],
  navToggleSideMenu: null,
  navToggleSideMobileMenu: null,
  navSetIsDesktop: ["flag"],
  navSetBreadcrumbs: ["data"],
  navReset: null,
  titleReset: null,
});

export const actions = Creators;

/* ------------- Initial state ------------- */
const INITIAL_STATE = {
  selectedBuilding: null,
  selectedManager: 0,
  selectedDealer: 0,
  selectedTitle: null,
  isMenuHorizontallyCollapsed: false, //Is the menu collapsed HORIZONTALLY?
  isSideMenuMobileShown: false,
  isDesktop: null,
  breadcrumbs: [],
};

/* ------------- Reducers ------------- */
export const selectBuilding = (state, { id }) => ({ ...state, selectedBuilding: id });
export const selectManager = (state, { id }) => ({ ...state, selectedManager: id });
export const selectDealer = (state, { id }) => ({ ...state, selectedDealer: id });
export const selectTitle = (state, { id }) => ({ ...state, selectedTitle: id });
export const selectDeviceType = (state, { flag }) => ({ ...state, isDesktop: flag });
export const setBreadcrumbs = (state, { data }) => ({ ...state, breadcrumbs: data });
export const toggleSideMenu = (state) => ({
  ...state,
  isMenuHorizontallyCollapsed: !state.isMenuHorizontallyCollapsed,
});
export const toggleSideMobileMenu = (state) => ({
  ...state,
  isSideMenuMobileShown: !state.isSideMenuMobileShown,
});
export const reset = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */
const navigationReducer = createReducer(INITIAL_STATE, {
  [Types.NAV_SELECT_BUILDING]: selectBuilding,
  [Types.NAV_SELECT_MANAGER]: selectManager,
  [Types.NAV_SELECT_DEALER]: selectDealer,
  [Types.NAV_SELECT_TITLE]: selectTitle,
  [Types.NAV_SET_IS_DESKTOP]: selectDeviceType,
  [Types.NAV_TOGGLE_SIDE_MENU]: toggleSideMenu,
  [Types.NAV_TOGGLE_SIDE_MOBILE_MENU]: toggleSideMobileMenu,
  [Types.NAV_RESET]: reset,
  [Types.NAV_SET_BREADCRUMBS]: setBreadcrumbs,
});

export const setSelectedDealer = (id) => (dispatch, getState, api) => {
  //sets navigation manager id
  dispatch(actions.navSelectDealer(id));
  //Resets manager reducer to avoid flashing previous data
  dispatch(dealerActions.dealerReset(["companies", "users"]));
  if (id) {
    dispatch(getMetaInformationForDealer(id));

    const parentFilter = `dealer_account_id=${id}`;
    dispatch(setFilter(parentFilter, "companies"));
    dispatch(setFilter(parentFilter, "buildings"));
  }
};

export const setSelectedManager = (id) => (dispatch, getState, api) => {
  //sets navigation m anager id
  dispatch(actions.navSelectManager(id));
  //Resets manager reducer to avoid flashing previous data
  dispatch(managementActions.managementReset(["buildings", "users", "heartbeats"]));
  if (id) {
    dispatch(getMetaInformation(id));
    dispatch(setFilter(`management_account_id=${id}`, "buildings"));
  }
};

export const setSelectedBuilding = (id) => async (dispatch, getState) => {
  //sets navigation building id
  const currentBuildingId = _get(getState(), "navigation.selectedBuilding");

  await dispatch(actions.navSelectBuilding(id));

  if (id && currentBuildingId !== id) {
    //Resets building reducer to avoid flashing previous data
    await dispatch(buildingActions.buildingReset(["residents"]));
    await dispatch(brivoActions.brivoReset());

    await dispatch(getBuildingInfo(id));
  }
};

export const setSelectedTitle = (path) => (dispatch, getState) => {
  const { resident } = getState();
  const role = _get(resident, "data.role.name", {});

  const newBreadcrumb = getBreadcrumbObject(path);
  const mainSequenceKeys = getSequenceKeys(role);
  const newBreadcrumbs = getNewBreadcrumbs(newBreadcrumb, mainSequenceKeys);

  dispatch(actions.navSelectTitle(newBreadcrumb.title));
  dispatch(actions.titleReset());
  dispatch(actions.navSetBreadcrumbs(newBreadcrumbs));
};

export const resetNavigation = () => (dispatch, getState) => {
  dispatch(actions.titleReset());
  dispatch(actions.navSetBreadcrumbs([]));
  dispatch(actions.navSelectBuilding(null));
  dispatch(actions.navSelectManager(0));
  dispatch(actions.navSelectDealer(0));
};

export const setDeviceType = (flag) => (dispatch) => void dispatch(actions.navSetIsDesktop(flag));

export default navigationReducer;

import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _union from "lodash/union";
import _without from "lodash/without";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import tableUtils from "utils/table";

const FieldStyles = styled.div`
  .clients-box {
    padding: 20px;
    border: 1px solid #cecece;
    background: white;
    border-radius: 5px;
  }
  .react-bs-container-body {
    overflow: auto;
  }
`;

class UnitsFields extends Component {
  static propTypes = {
    units: PropTypes.array
  };

  static defaultProps = {
    units: []
  };

  onCheckChange = unit => e => {
    const { input } = this.props
    const currentValue = input.value;
    if (e.target.checked) {
      const newValue = _union(currentValue, [unit.unit_id]);
      input.onChange(newValue);
    } else {
      const newValue = _without(currentValue, unit.unit_id);
      input.onChange(newValue);
    }
  };

  handleRowSelect = (row, isSelected, e) => {
    const { input } = this.props
    const currentValue = input.value;
    if (isSelected) {
      const newValue = _union(currentValue, [row.unit_id]);
      input.onChange(newValue);
    } else {
      const newValue = _without(currentValue, row.unit_id);
      input.onChange(newValue);
    }
  };

  handleSelectAll = (isSelected, rows) => {
    const { units, input } = this.props
    if (isSelected) {
      const newValue = units.map(i => i.unit_id);
      input.onChange(newValue);
    } else {
      input.onChange([]);
    }
  };
  render() {
    const { input, meta, units } = this.props;
    const isError = (meta.error && meta.touched) || meta.submitError;
    return (
      <FieldStyles>
        {isError && <small className="text-danger">{meta.error || meta.submitError}</small>}
        <BootstrapTable
          data={units}
          bordered={false}
          pagination={false}
          search={false}
          condensed={false}
          striped={true}
          tableHeaderClass="table-data"
          tableBodyClass="table-data"
          exportCSV={false}
          height={300}
          version="4"
          selectRow={{
            mode: "checkbox",
            selected: input.value || [],
            onSelect: this.handleRowSelect,
            onSelectAll: this.handleSelectAll
          }}
        >
          <TableHeaderColumn dataField="unit_id" isKey={true} export={false} hidden />
          <TableHeaderColumn
            dataField="unit_name"
            dataSort={true}
            children="Unit Name"
            dataFormat={tableUtils.formatDefault()}
            formatExtraData={{ name: "Unit Name" }}
          />
          <TableHeaderColumn
            dataField="listing_name"
            dataSort={true}
            children="Listing Name"
            dataFormat={tableUtils.formatDefault()}
            formatExtraData={{ name: "Listing Name" }}
          />
        </BootstrapTable>
      </FieldStyles>
    );
  }
}

export default UnitsFields;

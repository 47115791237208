import { createReducer, createActions } from "reduxsauce";
import { notifySuccess, notifyError, asyncCreatorFactory } from "../action-utils";
import _get from "lodash/get";
import { getBuildingBrivoConfig } from "redux/reducers/building";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  brivoFetching: ["actionId"],
  brivoSuccess: ["actionId"],
  brivoFailure: ["error", "actionId"],
  brivoSet: ["data", "attr", "actionId"],
  brivoReset: null,
});
export const actions = Creators;

/* ------------- Initial state ------------- */
const INITIAL_STATE = {
  user: undefined,
  reused: false,
  reusedBuildingIds: [],
  sites: null,
  groups: null,
  users: null,
  settings: null,
  authSettings: null,
  doors: [],
  access_points: [],
  accessPointsBySiteId: [],
  defaultSchedule: [],
  isUnsyncStarting: false,
  isSyncStarting: false,
  isSyncComplete: false,
  fetching: [],
  error: [],
};

/* ------------- Reducers ------------- */
export const fetching = (state, { actionId }) => ({
  ...state,
  fetching: [...state.fetching, actionId],
  error: state.error.filter((i) => i.actionId !== actionId),
});
export const set = (state, { data, attr, actionId }) => ({
  ...state,
  [attr]: data,
});
export const success = (state, { actionId }) => ({
  ...state,
  fetching: state.fetching.filter((i) => i !== actionId),
  error: state.error.filter((i) => i.actionId !== actionId),
});
export const failure = (state, { error, actionId }) => ({
  ...state,
  fetching: state.fetching.filter((i) => i !== actionId),
  error: [...state.error, { error, actionId }],
});
export const reset = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */
const brivoReducer = createReducer(INITIAL_STATE, {
  [Types.BRIVO_FETCHING]: fetching,
  [Types.BRIVO_SUCCESS]: success,
  [Types.BRIVO_FAILURE]: failure,
  [Types.BRIVO_SET]: set,
  [Types.BRIVO_RESET]: reset,
});

/* ------------- Async Actions ------------- */
const _asyncCreator = asyncCreatorFactory(
  Creators.brivoFetching,
  Creators.brivoSuccess,
  Creators.brivoFailure
);

const asyncCreator = (props) => {
  return _asyncCreator({
    ...props,
    isNotifyError: false,
    onError: (dispatch, response, getState, errorPath, isNotifyError) => {
      const errorMessage = _get(response, errorPath);
      if (!/((?=.*auth)|(?=.*log))(?=.*brivo).*/i.test(errorMessage)) {
        if (isNotifyError) {
          notifyError(errorMessage);
        }
      } else {
        dispatch(Creators.brivoReset());
      }

      if (props.onError) {
        props.onError(dispatch, response, getState);
      }
    },
  });
};

export const getBuildingUsers = (buildingId) =>
  asyncCreator({
    actionId: "getBuildingUsers",
    apiCall: (api) => api.brivo.getBuildingUsers(buildingId),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.brivoSet(response.data, "users"));
    },
    onError: (dispatch, response) => {
      if (response.status === 400) {
        dispatch(Creators.brivoSet([], "users"));
      }
    },
    errorPath: "data.message",
  });

export const getAllSites = (buildingId) =>
  asyncCreator({
    actionId: "getAllSites",
    apiCall: (api) => api.brivo.getAllSites(buildingId),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.brivoSet(response.data, "sites"));
    },
    errorPath: "data.message",
  });

export const getSiteGroups = (buildingId, isNotifyError = true) =>
  asyncCreator({
    actionId: "getSiteGroups",
    apiCall: (api) => api.brivo.getSiteGroups(buildingId),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.brivoSet(response.data, "groups"));
    },
    errorPath: "data.message",
    isNotifyError: isNotifyError,
  });

export const getAccessPoints = (building_id) =>
  asyncCreator({
    actionId: "getAccessPoints",
    apiCall: (api) => api.brivo.getAccessPoints(building_id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.brivoSet(response.data, "access_points"));
    },
    errorPath: "data.message",
  });

export const getAccessPointsBySiteId = (building_id, site_id) =>
  asyncCreator({
    actionId: "getAccessPointsBySiteId",
    apiCall: (api) => api.brivo.getAccessPointsBySiteId(building_id, site_id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.brivoSet(response.data, "accessPointsBySiteId"));
    },
    errorPath: "data.message",
  });

export const getDefaultSchedule = (selectedBuilding) =>
  asyncCreator({
    actionId: "getDefaultSchedule",
    apiCall: (api) => api.brivo.getDefaultSchedule(selectedBuilding),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.brivoSet(response.data, "defaultSchedule"));
    },
    errorPath: "data.message",
  });

export const getDoors = (building_id) =>
  asyncCreator({
    actionId: "getDoors",
    apiCall: (api) => api.brivo.getDoors(building_id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.brivoSet(response.data.data, "doors"));
    },
    errorPath: "data.message",
  });

export const addDoor = (buildingId, deviceId, data = {}) =>
  asyncCreator({
    actionId: "addDoor",
    apiCall: (api) => api.brivo.addDoor(buildingId, deviceId, data),
    onSuccess: (dispatch, response, getState) => {
      const state = getState();
      const selectedBuilding = _get(state, "navigation.selectedBuilding");
      notifySuccess(`The device was created successfully`);
      dispatch(getDoors(selectedBuilding));
    },
    errorPath: "data.message",
  });

export const deleteDoor = (buildingId, doorId) =>
  asyncCreator({
    actionId: "deleteDoor",
    apiCall: (api) => api.brivo.deleteDoor(buildingId, doorId),
    onSuccess: (dispatch, response, getState) => {
      const state = getState();
      const selectedBuilding = _get(state, "navigation.selectedBuilding");
      notifySuccess(`The device was deleted successfully`);
      dispatch(getDoors(selectedBuilding));
    },
    errorPath: "data.message",
  });

export const createSubscription = (buildingId, doorId, data = {}) =>
  asyncCreator({
    actionId: "createSubscription",
    apiCall: (api) => api.brivo.createSubscription(buildingId, doorId, data),
    onSuccess: (dispatch, response, getState) => {
      const state = getState();
      const selectedBuilding = _get(state, "navigation.selectedBuilding");
      notifySuccess(`Subscription added successfully`);
      dispatch(getDoors(selectedBuilding));
    },
    errorPath: "data.message",
  });

export const deleteSubscription = (selectedBuilding, doorId) =>
  asyncCreator({
    actionId: "deleteSubscription",
    apiCall: (api) => api.brivo.deleteSubscription(selectedBuilding, doorId),
    onSuccess: (dispatch, response, getState) => {
      const state = getState();
      const selectedBuilding = _get(state, "navigation.selectedBuilding");
      notifySuccess(`Subscription deleted successfully`);
      dispatch(getDoors(selectedBuilding));
    },
    errorPath: "data.message",
  });

export const whoami = (building_id) =>
  asyncCreator({
    actionId: "whoami",
    apiCall: (api) => api.brivo.whoami(building_id),
    onSuccess: (dispatch, response) => {
      const { user, reused, reused_building_ids } = response.data || {};
      dispatch(Creators.brivoSet(reused_building_ids, "reusedBuildingIds"));
      dispatch(Creators.brivoSet(reused, "reused"));
      dispatch(Creators.brivoSet(user, "user"));
    },
    errorPath: "data.message",
  });

export const unauthorize = (building_id) =>
  asyncCreator({
    actionId: "unauthorize",
    apiCall: (api) => api.brivo.unauthorize(building_id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.brivoReset());
      dispatch(retrieveAuthSettings(building_id));
    },
    errorPath: "data.message",
  });

export const updateBuilding = (buildingId, data = {}) =>
  asyncCreator({
    actionId: "updateBuilding",
    apiCall: (api) => api.brivo.updateBuilding(buildingId, data),
    onSuccess: () => {
      notifySuccess(`The building was updated successfully`);
    },
    errorPath: "data.message",
  });

export const setBrivoCommunitySite = (buildingId) =>
  asyncCreator({
    actionId: "setBrivoCommunitySite",
    apiCall: (api) => api.brivo.setBrivoCommunitySite(buildingId),
    onSuccess: (dispatch) => {
      notifySuccess(`The building was updated successfully`);
      dispatch(getBuildingBrivoConfig(buildingId));
    },
    errorPath: "data.message",
  });

export const syncSite = (buildingId, data) =>
  asyncCreator({
    actionId: "syncBuilding",
    apiCall: (api) => api.brivo.syncBuilding(buildingId),
    onSuccess: (dispatch, response, getState) => {
      const isUnsyncStarting = getState().brivo.isUnsyncStarting;
      const { is_sync_starting } = response.data;
      if (is_sync_starting || isUnsyncStarting)
        notifySuccess(`The synchronization process was started successfully. It may take a while`);
      else notifyError("The synchronization process is running. Please try again later.");
      dispatch(Creators.brivoSet(is_sync_starting, "isSyncStarting"));
      dispatch(Creators.brivoSet(false, "isSyncComplete"));
    },
    errorPath: "data.message",
  });

export const importAllData = (buildingId) =>
  asyncCreator({
    actionId: "importAllData",
    apiCall: (api) => api.brivo.importAllData(buildingId),
    onSuccess: (dispatch, response, getState) => {
      const isUnsyncStarting = getState().brivo.isUnsyncStarting;
      const { is_sync_starting } = response.data;
      if (is_sync_starting || isUnsyncStarting)
        notifySuccess(`The synchronization process was started successfully. It may take a while`);
      else notifyError("The synchronization process is running. Please try again later.");
      dispatch(Creators.brivoSet(is_sync_starting, "isSyncStarting"));
      dispatch(Creators.brivoSet(false, "isSyncComplete"));
    },
    errorPath: "data.message",
  });

export const syncBuildingResidents = (buildingId, data) =>
  asyncCreator({
    actionId: "syncBuildingResidents",
    apiCall: (api) => api.brivo.syncBuildingResidents(buildingId, data),
    onSuccess: (dispatch, response) => {
      notifySuccess(`The synchronization process was started successfully. It may take a while`);
    },
    errorPath: "data.message",
  });

export const unsyncSite = (buildingId) =>
  asyncCreator({
    actionId: "unsyncSite",
    apiCall: (api) => api.brivo.unsyncSite(buildingId),
    onSuccess: (dispatch, response, getState) => {
      const isSyncStarting = getState().brivo.isSyncStarting;
      const { is_unsync_starting } = response.data;
      if (is_unsync_starting || isSyncStarting)
        notifySuccess(`The synchronization process was started successfully. It may take a while`);
      else notifyError("The synchronization process is running. Please try again later.");
      dispatch(Creators.brivoSet(is_unsync_starting, "isUnsyncStarting"));
    },
    errorPath: "data.message",
  });

export const disassociateSite = (buildingId) =>
  asyncCreator({
    actionId: "disassociateSite",
    apiCall: (api) => api.brivo.disassociateSite(buildingId),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("PDK Node successfully disassociated");
      dispatch(getBuildingBrivoConfig(buildingId));
    },
    errorPath: "data.message",
  });

export const getSettings = (building_id) =>
  asyncCreator({
    actionId: "getSettings",
    apiCall: (api) => api.brivo.getSettings(building_id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.brivoSet(response.data.data, "settings"));
    },
    errorPath: "data.message",
  });

export const updateSettings = (building_id, data) =>
  asyncCreator({
    actionId: "updateSettings",
    apiCall: (api) => api.brivo.updateSettings(building_id, data),
    onSuccess: (dispatch, response) => {
      notifySuccess(`Brivo settings was updated successfully`);
      dispatch(getSettings(building_id));
    },
    errorPath: "data.message",
  });

export const retrieveAuthSettings = (building_id) =>
  asyncCreator({
    actionId: "retrieveAuthSettings",
    apiCall: (api) => api.brivo.retrieveAuthSettings(building_id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.brivoSet(response.data.data, "authSettings"));
    },
    errorPath: "data.message",
  });

export const updateAuthSettings = (building_id, data) =>
  asyncCreator({
    actionId: "updateAuthSettings",
    apiCall: (api) => api.brivo.updateAuthSettings(building_id, data),
    onSuccess: (dispatch) => {
      notifySuccess(`Brivo auth settings was updated successfully`);
    },
    errorPath: "data.message",
  });

export const deleteAuthSettings = (building_id) =>
  asyncCreator({
    actionId: "deleteAuthSettings",
    apiCall: (api) => api.brivo.deleteAuthSettings(building_id),
    onSuccess: (dispatch) => {
      notifySuccess("Brivo Auth settings removed successfully");
    },
    errorPath: "data.message",
  });

export const clearUsers = () => actions.brivoSet(null, "users");
export const clearErrors = () => actions.brivoSet([], "error");
export const clearGroups = () => actions.brivoSet(null, "groups");
export const clearDoors = () => actions.brivoSet(null, "doors");
export const setDoors = (doors) => actions.brivoSet(doors, "doors");
export const clearUnsync = () => actions.brivoSet(false, "isUnsyncStarting");
export const clearSync = () => actions.brivoSet(false, "isSyncStarting");
export const clearSyncComplete = () => actions.brivoSet(false, "isSyncComplete");
export const clearAccessPoints = () => actions.brivoSet([], "accessPointsBySiteId");
export const setSyncComplete = (isSyncComplete) => actions.brivoSet(isSyncComplete, "isSyncComplete");

export default brivoReducer;

import React, { Component } from "react";
import styled from "styled-components";
import _union from "lodash/union";
import _without from "lodash/without";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import tableUtils from "utils/table";

const FieldStyles = styled.div`
  .clients-box {
    padding: 20px;
    border: 1px solid #cecece;
    background: white;
    border-radius: 5px;
  }
  .react-bs-container-body {
    overflow: auto;
  }
`;

export const USER_TYPES = [
    { value: 'visitor', name: 'Visitor' },
    { value: 'resident', name: 'Resident' },
    { value: 'management', name: 'Management' },
    { value: 'delivery', name: 'Delivery' },
    { value: 'vendor', name: 'Vendor' },
    { value: 'admin', name: 'Admin' },
    { value: 'dealer', name: 'Dealer' },
]

export const UserTypesColumn = <TableHeaderColumn
    dataField="name"
    dataSort={true}
    children="User Type"
    dataFormat={tableUtils.formatDefault()}
    formatExtraData={{ name: "User Type" }}
/>

export const DAYS = [
  { value: 'monday', name: 'Monday'},
  { value: 'tuesday', name: 'Tuesday'},
  { value: 'wednesday', name: 'Wednesday'},
  { value: 'thursday', name: 'Thursday'},
  { value: 'friday', name: 'Friday'},
  { value: 'saturday', name: 'Saturday'},
  { value: 'sunday', name: 'Sunday'},
]

export const DaysColumn = <TableHeaderColumn
  dataField="name"
  dataSort={true}
  children="Days"
  dataFormat={tableUtils.formatDefault()}
  formatExtraData={{ name: "Days" }}
/>

export const HoursColumn = <TableHeaderColumn
  dataField="name"
  dataSort={true}
  children="Hours"
  dataFormat={tableUtils.formatDefault()}
  formatExtraData={{ name: "Hours" }}
/>

class CommonUseFields extends Component {
  onCheckChange = type => e => {
    const { input } = this.props
    const currentValue = input.value;
    if (e.target.checked) {
      const newValue = _union(currentValue, [type.value]);
      input.onChange(newValue);
    } else {
      const newValue = _without(currentValue, type.value);
      input.onChange(newValue);
    }
  };

  handleRowSelect = (row, isSelected, e) => {
    const { input } = this.props
    const currentValue = input.value;
    if (isSelected) {
      const newValue = _union(currentValue, [row.value]);
      input.onChange(newValue);
    } else {
      const newValue = _without(currentValue, row.value);
      input.onChange(newValue);
    }
  };

  handleSelectAll = (isSelected, rows) => {
    const { input, data } = this.props
    if (isSelected) {
      const newValue = data.map(i => i.value);
      input.onChange(newValue);
    } else {
      input.onChange([]);
    }
  };
  render() {
    const { 
      input, 
      meta, 
      height, 
      data,
      children
    } = this.props;
    const isError = (meta.error && meta.touched) || meta.submitError;
    return (
      <FieldStyles>
        {isError && <small className="text-danger">{meta.error || meta.submitError}</small>}
        <BootstrapTable
          data={data}
          bordered={false}
          pagination={false}
          search={false}
          condensed={false}
          striped={true}
          tableHeaderClass="table-data"
          tableBodyClass="table-data"
          exportCSV={false}
          height={height ? height : 300}
          version="4"
          selectRow={{
            mode: "checkbox",
            selected: input.value || [],
            onSelect: this.handleRowSelect,
            onSelectAll: this.handleSelectAll
          }}
        >
          <TableHeaderColumn dataField="value" isKey={true} export={false} hidden />
          {children}
        </BootstrapTable>
      </FieldStyles>
    );
  }
}

export default CommonUseFields;

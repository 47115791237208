import {createActions, createReducer} from "reduxsauce";
import {asyncCreatorFactory} from "../action-utils";
import _pick from "lodash/pick";
import AccessManager from "utils/accessManager";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  planFetching: ["actionId"],
  planSuccess: ["actionId"],
  planFailure: ["error", "actionId"],
  planSet: ["data", "attr", "actionId"],
  planReset: null
});
export const actions = Creators;

/* ------------- Initial state ------------- */
const INITIAL_STATE = {
  features: undefined,
  fetching: [],
  error: [],
};

/* ------------- Reducers ------------- */
export const fetching = (state, { actionId }) => ({
  ...state,
  fetching: [...state.fetching, actionId],
  error: state.error.filter(i => i.actionId !== actionId)
});
export const set = (state, { data, attr, actionId }) => ({
  ...state,
  [attr]: data
});
export const success = (state, { actionId }) => ({
  ...state,
  fetching: state.fetching.filter(i => i !== actionId),
  error: state.error.filter(i => i.actionId !== actionId)
});
export const failure = (state, { error, actionId }) => ({
  ...state,
  fetching: state.fetching.filter(i => i !== actionId),
  error: [...state.error, { error, actionId }]
});
export const reset = (state, { exclude = [] }) => ({
  ...INITIAL_STATE,
  ..._pick(state, exclude)
});

/* ------------- Hookup Reducers To Types ------------- */
const completePlanReducer = createReducer(INITIAL_STATE, {
  [Types.PLAN_FETCHING]: fetching,
  [Types.PLAN_SUCCESS]: success,
  [Types.PLAN_FAILURE]: failure,
  [Types.PLAN_SET]: set,
  [Types.PLAN_RESET]: reset
});

/* ------------- Async Actions ------------- */
const asyncCreator = asyncCreatorFactory(
  Creators.planFetching,
  Creators.planSuccess,
  Creators.planFailure
);

export const getCompletePlans = () =>
  asyncCreator({
    actionId: "getCompletePlans",
    apiCall: api => api.plans.getCompletePlans(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.planSet(response.data.features, "features"));
      AccessManager.setup(response.data)
    },
    isNotifyError: false,
  });


export const updateCompletePlans = (id, data) =>
  asyncCreator({
    actionId: "updateCompletePlans",
    apiCall: api => api.plans.updateCompletePlans(id, data),
    onSuccess: (dispatch, response, getState) => {
      dispatch(getCompletePlans());
    },
    isNotifyError: false,
  });


export default completePlanReducer;

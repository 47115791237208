import { create } from "apisauce";
import store from "./store";
import { logout } from "./reducers/auth";
import { notifyError } from "redux/action-utils";
import ChunkedUploader from "utils/chunkedUploader";

const baseURL = process.env.REACT_APP_API_URL;
const expiredTokenMonitor = (response) => {
  if (response.status === 401 && window.localStorage.getItem("jwtToken")) {
    notifyError(response.data);
    store.dispatch(logout());
  }
  if (response.status === 403 && response.data === "Access denied. Insufficient permissions") {
    notifyError(response.data);
  }
};

const api = create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  timeout: 60000,
});
api.addMonitor(expiredTokenMonitor);

const chunkedUploader = new ChunkedUploader(api);

//Auth
const auth = {
  login: (username, password) => api.post("/auth", { username, password }),
  setToken: (token) => api.setHeader("Authorization", `Bearer ${token}`),
  clearToken: () => api.setHeader("Authorization", ""),
};
//Set token if available in localSotorage
if (localStorage.getItem("jwtToken")) {
  auth.setToken(localStorage.getItem("jwtToken"));
}

//User
const user = {
  verifySecurityCode: (security_code) => api.get(`/user/token/${security_code}`),
  getUserDataByToken: (token) => api.get(`/user/${token}`),
  agreeToTerms: (user_id) => api.post(`/user/${user_id}/agree`),
  getSecurityQuestions: () => api.get(`/user/security_questions`),
  activateAccount: (user_id, data) => api.post(`/user/${user_id}/activate`, data),
  verifyEmail: (token) => api.post(`/user/verify_email/${token}`),
  updateUserInfo: (data) => api.put(`/user/current`, data),
  sendPwResetLink: (data) => api.post(`/user/send_password_reset_email`, data),
  resetPwByLink: ({ token, data = {} }) => api.post(`/user/password_reset_from_link/${token}`, data),
};

//Account
const account = {
  checkCurrentPassword: (password) => api.post("/user/password_check", password),
  resetPassword: (password) => api.post("/user/password_reset", password),
  getMessages: () => api.get(`/account/messages`),
  deleteMessage: (id) => api.delete(`/account/message/${id}`),
  listenedTo: (id) => api.get(`/account/message_listened_to/${id}`),
  getActivities: () => api.get(`/account/activities`),
  getMessage: (id) => api.get(`/account/message/${id}`),
  getResidents: () => api.get(`/account/residents`),
  getThumbnailImage: () => api.get(`/account/get_thumbnail_image`),
  uploadThumbnailImage: (data) => api.post(`/account/upload_thumbnail_image`, data),
  deleteThumbnailImage: () => api.delete(`/account/delete_thumbnail_image`),
  resetMyKeyUsers: (id) => api.put(`/account/mykey_reset/${id}`),
  myKeys: () => api.get(`/account/mykeys`),
  myKeysResident: () => api.get(`/account/mykeys_resident`),
  myKeysVisitor: () => api.get(`/account/mykeys_visitor`),
  getMyKeyPic: (id) => api.get(`/account/mykey_pic/${id}`),
};

const plans = {
  getCompletePlans: () => api.get(`/complete_plans/plans`),
  updateCompletePlans: (id, data) => api.patch(`/complete_plans/update/${id}`, data),
};

//Building
const building = {
  getCurrent: () => api.get(`/building/current`),
  getNotices: () => api.get(`/building/notices`),
  getEvents: () => api.get(`/building/events`),
  getPicture: (id) => api.get(`/building/logo/${id}`),
  getKeyComList: (id) => api.get(`building/${id}/kiosks`),
  getAccessList: (id) => api.get(`building/${id}/access_list`),
  createAccess: (id, data) => api.post(`building/${id}/access_list`, data),
  updateAccess: (id, access_id, data) => api.put(`building/${id}/access_list/${access_id}`, data),
  getSuperintedentUserList: (id) => api.get(`building/${id}/superintendent_users`),
  deleteAllUnits: (id) => api.delete(`building/${id}/units`),
  deleteAllResidents: (id) => api.delete(`building/${id}/residents`),
  getAllWebrelays: (id) => api.get(`building/${id}/webrelay`),
  addNewWebrelay: (id, data) => api.post(`building/${id}/webrelay`, data),
  updateWebrelay: (id, data) => api.put(`building/webrelay/${id}`, data),
  checkWebrelayUrl: (url) => api.get(`building/webrelay-check?url=${encodeURI(url)}`),
  toggleWebrelay: (id) => api.get(`building/toggle-webrelay/${id}`),
  removeWebrelay: (id) => api.delete(`building/webrelay/${id}`),
  getDeliveryPackagesPaginated: (building_id, page = 1, sizePerPage = 50, params = {}) =>
    api.get(`delivery_packages/${building_id}/${page}/${sizePerPage}`, params),
  getDeliveryPackagesFilters: (building_id) => api.get(`delivery_packages/${building_id}/filters`),
  updateDeliveryPackage: (delivery_package_id, data) =>
    api.put(`delivery_packages/${delivery_package_id}`, data),
};

//Resident
const resident = {
  current: () => api.get("/resident/current"),
  updateCurrent: (data, options) => api.put("/resident/current", data, options),
  checkUsername: (username) => api.post(`/resident/check_unique_username/${username}`),
  sendVerificationEmail: (data) => api.post("/resident/send_email_verification_again", data),

  forgotUsername: (data) => api.post(`/resident/forgot_username`, data),
  forgotPasswordQuestion: (data) => api.post(`/resident/forgot_password_question`, data),
  forgotPasswordAnswer: (data) => api.post(`/resident/forgot_password_answer`, data),
  forgotPasswordReset: (data) => api.post(`/resident/forgot_password_reset`, data),

  getProfilePic: () => api.get(`/resident/get_profile_pic`),
  uploadProfilePic: (data) => api.post(`/resident/upload_profile_pic`, data),
  deleteProfilePic: () => api.delete(`resident/delete_profile_pic`),

  getCurrentUnit: () => api.get("/resident/current/unit"),
  deleteResident: (id) => api.delete(`/resident/${id}/remove`),

  getSecondaryPic: (id) => api.get(`/resident/get_secondary_pic/${id}`),
  addResident: (data) => api.post("/resident/v2/add_resident", data),
  managementAddResident: (unit_id, data) => api.post(`/resident/v2/management_add_resident/${unit_id}`, data),

  requestAdditionalMyKeys: () => api.post(`/resident/request_additional_mykeys`),
  createSendDigitalMykey: (data) => api.post("/resident/create_send_digital_mykey", data),
  deleteMyKey: (id) => api.delete(`/resident/mykey/remove`, {}, { data: { mykey_id: id } }),
  updateMyKey: (id, data) => api.put(`/resident/mykey/${id}`, data),
};

//Resident
const management = {
  updateBuildingSubscriptionPlan: (buildingID, subscriptionPlan) =>
    api.get(`/management/set_building_subscription/${buildingID}/${subscriptionPlan}`),
  getCurrentCompany: (management_id = 0) => api.get(`/management/current/${management_id}`),
  updateCurrentCompany: (management_id = 0, data) => api.put(`/management/current/${management_id}`, data),
  getCompanyLogo: (management_id = 0) => api.get(`/management/management_logo/${management_id}`),
  updateCompanyLogo: (management_id = 0, data) =>
    api.put(`/management/management_logo/${management_id}`, data),
  deleteCompanyLogo: (management_id = 0) => api.delete(`/management/management_logo/${management_id}`),
  deleteCompany: (management_id = 0) => api.delete(`/management/current/${management_id}`),

  //Buildings
  buildings: (management_id = 0, params = {}) => api.get(`/management/v2/buildings/${management_id}`, params),
  getBuildingsLazy: (management_id = 0, page = 1, params = {}) =>
    api.get(`/management/v2/buildings/${management_id}/${page}`, params),
  addBuilding: (data) => api.post(`/building`, data),
  deleteBuilding: (building_id) => api.delete(`/building/${building_id}`),
  buildingUnitList: (id) => api.get(`/management/units/${id}`),
  getBuildingUnitListPaginated: (
    id,
    page = 1,
    perPage = 50,
    params = { sorting_params: '[{"order": "asc","sortField": "unit_name"}]' }
  ) => api.post(`/management/v2/units/${id}/${page}/${perPage}`, params),
  getBuildingUnitListFilters: (building_id = 0) => api.get(`/management/v2/units/filters/${building_id}`),

  addUnit: (building_id, data) => api.post(`/management/unit/${building_id}`, data),
  updateUnit: (id, data) => api.put(`/management/unit/${id}`, data),
  deleteUnit: (id) => api.delete(`/management/unit/${id}`),
  getBuildingGroups: (id) => api.get(`/management/groups/${id}`),

  uploadBuildingResidents: (id, data) => api.post(`/management/upload_building_residents/${id}`, data),

  getBuildingInviteMessages: (id) => api.get(`/management/communication_templates/${id}`),
  updateBuildingInviteMessages: (id, messages) =>
    api.put(`/management/communication_templates/${id}`, messages),

  getBuildingInfo: (id) => api.get(`/management/building_info/${id}`),
  updateBuildingInfo: (id, data) => api.put(`/management/building_info/${id}`, data),
  getBuildingSabbathMode: (id) => api.get(`/management/sabbath_mode/${id}`),
  updateBuildingSabbathMode: (id, data) => api.put(`/management/sabbath_mode/${id}`, data),
  updateBuildingSettings: (id, data) => api.patch(`/management/building_with_units_settings/${id}`, data),
  updateUnitSettings: (buildingId, unitId, data) =>
    api.patch(`/management/unit_settings/${buildingId}/${unitId}`, data),

  getBuildingLogo: (id) => api.get(`/management/update_building_logo/${id}`),
  updateBuildingLogo: (id, data) => api.put(`/management/update_building_logo/${id}`, data),
  deleteBuildingLogo: (id) => api.delete(`/management/update_building_logo/${id}`),
  updateCustomManagementLogo: (id, data) => api.put(`/management/update_custom_management_logo/${id}`, data),
  deleteCustomManagementLogo: (id) => api.delete(`/management/update_custom_management_logo/${id}`),
  getDeliveryAddKeyPermission: (id) => api.get(`/management/delivery_key_permission/${id}`),
  buildingResidents: (id) => api.get(`/management/${id}/residents/0`),
  getBuildingResidentsFilters: (building_id = 0) => api.get(`/management/residents/filters/${building_id}`),
  getBuildingResidentsPaginated: (
    building_id,
    page = 1,
    perPage = 50,
    params = { sorting_params: '[{"order": "asc","sortField": "date"}]' },
    offset = 0
  ) => api.post(`/management/v2/residents/${building_id}/${page}/${perPage}/${offset}`, params),
  getResidentDetails: (data) => api.post(`/management/resident_user_details`, data),
  addMykey: (data) => api.post("/management/assign_additional_mykey_package", data),
  assignOnboardingPackage: (data) => api.post("/management/assign_onboarding_package", data),
  resetMyKey: (id) => api.post(`/management/reset_mykey/${id}`),
  deleteMyKey: (id) => api.post(`/management/delete_mykey/${id}`),
  onboard: (id, data) => api.post(`/management/onboard/${id}`, data),
  moveIn: (id, data) => api.post(`/management/move_in/${id}`, data),
  moveOut: (id) => api.get(`/management/move_out/${id}`),
  firstTouches: (data) => api.post(`/management/first_touches`, data),
  denyAccess: (data) => api.post(`/management/deny_access`, data),
  allowAccess: (data) => api.post(`/management/allow_access`, data),
  activateUser: (data) => api.post(`/management/activate_user`, data),
  changeResidentRole: (data) => api.put("/management/change_resident_role", data),
  setFacialRek: (data) => api.put(`/management/facial_rek`, data),
  updateResidentInfo: (data) => api.post(`/management/resident_user_info`, data),

  typesOfLegalPhoto: (data) => api.get(`/management/types_of_legal_photo`),
  getPrimaryPhoto: (id) => api.get(`/management/primary_resident_signup/${id}`),
  updatePrimaryPhoto: (id, data) => api.put(`/management/primary_resident_signup/${id}`, data),
  deletePrimaryPhoto: (id, data) => api.delete(`/management/primary_resident_signup/${id}`, data),
  getLegalPhoto: (id) => api.get(`/management/legal_photo/${id}`),
  updateLegalPhoto: (id, data) => api.put(`/management/legal_photo/${id}`, data),
  deleteLegalPhoto: (id, data) => api.delete(`/management/legal_photo/${id}`, data),
  getDirectoryPhoto: (id) => api.get(`/management/directory_photo/${id}`),
  updateDirectoryPhoto: (id, data) => api.put(`/management/directory_photo/${id}`, data),
  deleteDirectoryPhoto: (id, data) => api.delete(`/management/directory_photo/${id}`, data),

  buildingAccess: (id) => api.get(`/management/access/${id}`),
  buildingActivities: (id) => api.get(`/management/activities/${id}`),
  getBuildingActivitiesPaginated: (
    id,
    page,
    perPage,
    params = { sorting_params: '[{"order": "asc","sortField": "group"}]' }
  ) => api.post(`/management/v2/activities/${id}/${page}/${perPage}`, params),
  getBuildingActivitiesFilters: (building_id = 0) =>
    api.get(`/management/v2/activities/filters/${building_id}`),
  buildingAccessPaginated: (
    id,
    page,
    perPage,
    params = { sorting_params: '[{"order": "asc","sortField": "date"}]' }
  ) => api.post(`/management/v2/access/${id}/${page}/${perPage}`, params),
  buildingAccessMeta: (building_id) => api.get(`/management/access_meta/${building_id}`),
  buildingMessages: (id, management_id = 0) => api.get(`/management/messages/${id}/${management_id}`),
  buildingMessagesKeycom: (
    building_id,
    page = 1,
    sizePerPage = 50,
    params = { sorting_params: '[{"order": "desc","sortField": "created_date"}]' },
    management_id = 0
  ) => api.post(`/management/messages/keycom/${building_id}/${management_id}/${page}/${sizePerPage}`, params),
  buildingMessagesText: (
    building_id,
    page = 1,
    sizePerPage = 50,
    params = { sorting_params: '[{"order": "desc","sortField": "created_date"}]' },
    management_id = 0
  ) => api.post(`/management/messages/text/${building_id}/${management_id}/${page}/${sizePerPage}`, params),
  deleteMessage: (id) => api.delete(`/management/message/${id}`),

  buildingEvents: (id) => api.get(`/management/events/${id}`),
  createBuildingEvent: (data) => api.post(`/management/event`, data),
  editBuildingEvent: (id, data) => api.put(`/management/event/${id}`, data),
  deleteBuildingEvent: (id) => api.delete(`/management/event/${id}`),

  buildingNotices: (id) => api.get(`/management/notices/${id}`),
  createBuildingNotice: (data) => api.post(`/management/notice`, data),
  editBuildingNotice: (id, data) => api.put(`/management/notice/${id}`, data),
  deleteBuildingNotice: (id) => api.delete(`/management/notice/${id}`),

  validateActivationCode: ({ data, code } = {}) =>
    api.post(`/management/validate_activation_code/${code}`, data),

  getUsers: (management_id = 0) => api.get(`/management/users/${management_id}`),
  createUser: (data, management_id = 0) => api.post(`/management/${management_id}`, data),
  updateUser: (user_id, data, management_id = 0) => api.put(`/management/${user_id}/${management_id}`, data),
  deleteUser: (user_id, management_id = 0) => api.delete(`/management/${user_id}/${management_id}`),
  getUserRoles: () => api.get(`/management/user_role_options`),
  getUserPic: (id) => api.get(`/management/user_pic/${id}`),
  resendManagementEmail: (id) => api.get(`/management/resend_management_welcome_email/${id}`),
  uploadUserPic: (id, data) => api.post(`/management/user_picx/${id}`, data),
  getUnitUsers: (account_id) => api.get(`/management/residents_in_unit/${account_id}`),
  activateMyKey: (data) => api.post(`/management/user/activate_mykey`, data),
  removeMyKey: (id) => api.delete(`/management/remove_mykey/${id}`),

  resendEmail: (unit_id) => api.get(`/management/resend_welcome_email/${unit_id}`),
  resendSMS: (unit_id) => api.get(`/management/resend_welcome_sms/${unit_id}`),
  sendBuildingWelcomeSMS: (building_id) => api.get(`/management/resend_welcome_sms_mass/${building_id}`),
  sendBuildingWelcomeEmails: (building_id) => api.get(`/management/resend_welcome_email_mass/${building_id}`),

  //alerts
  getAlerts: (id, time_period = "past_week", page, sizePerPage) =>
    api.get(`/management/alerts/${id}/${time_period}/${page}/${sizePerPage}`),

  //analytics
  getAnalytics: (id) => api.get(`/management/analytics/${id}`),
  getEntriesByTypesAnalytics: (id) => api.get(`/management/analytics/entries_by_types/${id}`),
  getTotalEntriesAnalytics: (id) => api.get(`/management/analytics/total_entries/${id}`),
  getAnalyticsAccountActivated: (id) => api.get(`/management/analytics/account_activated/${id}`),
  getAnalyticsAtLeast1MyKey: (id) => api.get(`/management/analytics/at_least_1_mykey/${id}`),
  getAnalyticsSignIntoApp: (id) => api.get(`/management/analytics/signed_into_app/${id}`),

  //flags
  getFlags: (id) => api.get(`/management/flags/${id}`),
  getFlagsDetails: (id) => api.get(`/management/flag/${id}`),
  updateFlag: (id, data) => api.put(`/management/flag/${id}`, data),
  getFlagReasons: () => api.get(`/management/flags/reasons`),
  getFlagsPaginated: (
    id,
    page,
    perPage,
    params = { sorting_params: '[{"order": "asc","sortField": "date"}]' }
  ) => api.post(`/management/v2/flags/${id}/${page}/${perPage}`, params),

  getFlagsSummary: (
    page,
    sizePerPage,
    id,
    params = { sorting_params: '[{"order": "asc","sortField": "default"}]' }
  ) => api.post(`/management/flags/summary/${page}/${sizePerPage}/${id}`, params),
  getFlagsSummaryFilters: (building_id = 0) => api.get(`/management/flags/summary/filters/${building_id}`),

  //Vendors
  getVendorsAccessPaginated: (
    page,
    perPage,
    params = { sorting_params: '[{"order": "asc","sortField": "date"}]' },
    management_id = 0
  ) => api.post(`/management/vendor/access/${page}/${perPage}/${management_id}`, params),
  getVendorsAccessFilters: (management_id = 0) =>
    api.get(`/management/vendor/access/filters/${management_id}`),
  getVendorsMyKeys: (management_id = 0) => api.get(`/management/vendor/mykeys/${management_id}`),
  getVendorsMyKeysTypes: () => api.get(`/management/vendor/mykeys_types`),
  addVendorMyKey: (data, management_id = 0) =>
    api.post(`/management/vendor/add_mykey/${management_id}`, data),
  updateVendorMyKey: (mykey_id, data) => api.put(`/management/vendor/mykey/${mykey_id}`, data),
  updateDeliveryMyKey: (mykey_id, data) => api.put(`/management/delivery/mykey/${mykey_id}`, data),
  sendVendorMyKey: (data, management_id = 0) =>
    api.post(`/management/vendor/send_mykey/${management_id}`, data),
  deleteVendorMyKey: (mykey_id) => api.post(`/management/vendor/delete_mykey/${mykey_id}`),
  resetVendorMyKey: (mykey_id) => api.post(`/management/vendor/reset_mykey/${mykey_id}`),
  allowAccessVendorMyKey: (mykey_id) => api.post(`/management/vendor/allow_access/${mykey_id}`),
  denyAccessVendorMyKey: (mykey_id) => api.post(`/management/vendor/deny_access/${mykey_id}`),
  companyFirstTouchesMyKey: (mykey_id) => api.get(`/management/company/first_touches/${mykey_id}`),

  //Deliveries
  getDeliveriesAccessPaginated: (
    page,
    perPage,
    params = { sorting_params: '[{"order": "asc","sortField": "date"}]' },
    management_id = 0
  ) => api.post(`/management/delivery/access/${page}/${perPage}/${management_id}`, params),
  getDeliveriesAccessFilters: (management_id = 0) =>
    api.get(`/management/delivery/access/filters/${management_id}`),
  getDeliveriesMyKeys: (management_id = 0) => api.get(`/management/delivery/mykeys/${management_id}`),
  addDeliveryMyKey: (data, management_id = 0) =>
    api.post(`/management/delivery/add_mykey/${management_id}`, data),
  deleteDeliveryMyKey: (mykey_id) => api.post(`/management/delivery/delete_mykey/${mykey_id}`),
  resetDeliveryMyKey: (mykey_id) => api.post(`/management/delivery/reset_mykey/${mykey_id}`),
  allowAccessDeliveryMyKey: (mykey_id) => api.post(`/management/delivery/allow_access/${mykey_id}`),
  denyAccessDeliveryMyKey: (mykey_id) => api.post(`/management/delivery/deny_access/${mykey_id}`),

  // Meta
  getMetaInformation: (management_id = 0) => api.get(`/management/meta/${management_id}`),
  getCommunities: (management_account_id = 0) => api.get(`/management/communities/${management_account_id}`),
  addCommunity: (data) => api.post(`/management/community`, data),
  updateCommunity: (id, data) => api.put(`/management/community/${id}`, data),
  deleteCommunity: (id) => api.delete(`/management/community/${id}`),
  activateCommunityKeycom: (params) => api.post(`/management/community/activate_keycom`, params),
};

const dealer = {
  activateKeycom: (data) => api.post(`/dealer/activate_keycom`, data),
  validateKioskSerialNumber: (serial_number) =>
    api.get(`/dealer/validate_kiosk_serial_number/${serial_number}`),
  getCurrentDealer: (dealer_id = 0) => api.get(`/dealer/current/${dealer_id}`),
  updateCurrentDealer: (dealer_id = 0, data) => api.put(`/dealer/current/${dealer_id}`, data),
  deleteDealer: (dealer_id) => api.delete(`/dealer/current/${dealer_id}`),
  getManagementCompanies: (dealer_id = 0, params = {}) =>
    api.get(`dealer/v2/management_companies/${dealer_id}`, params),
  getManagementCompaniesLazy: (dealer_id = 0, page = 1, params = {}) =>
    api.get(`dealer/management_companies/${dealer_id}/${page}`, params),
  addCompany: (data) => api.post(`/admin/create_management_company`, data),
  getDealerLogo: (dealer_id = 0) => api.get(`/dealer/logo/${dealer_id}`),
  updateDealerLogo: (dealer_id = 0, data) => api.put(`/dealer/logo/${dealer_id}`, data),
  deleteDealerLogo: (dealer_id = 0) => api.delete(`/dealer/logo/${dealer_id}`),
  getDealerUsers: (dealer_id = 0) => api.get(`/dealer/users/${dealer_id}`),
  updateUser: (user_id, data = 0) => api.put(`/dealer/${user_id}`, data),
  addUser: (dealer_id, data = 0) => api.post(`/dealer/${dealer_id}`, data),
  resendDealerWelcomeEmail: (user_id) => api.get(`/dealer/resend_dealer_welcome_email/${user_id}`),
  deleteUser: (user_id) => api.delete(`/dealer/${user_id}`),
  getBuildingKeyComList: (id) => api.get(`/dealer/${id}/kiosks`),
  updateKiosk: (id, data) => api.put(`/dealer/kiosk/${id}`, data),
  updateKioskAccessRestrictions: (id, data) => api.put(`/dealer/kiosk/access/${id}`, data),
  getMetaInformation: (dealer_id = 0) => api.get(`/dealer/meta/${dealer_id}`),

  //Promos
  getPromos: (id) => api.get(`/dealer/promos/${id}`),
  addNewPromo: (data, { ...args }) => chunkedUploader.uploadFile(`/dealer/promo`, data, { ...args }),
  deletePromo: (id) => api.delete(`/dealer/promo/${id}`),
  updatePromo: (id, data, { ...args }) =>
    chunkedUploader.uploadFile(`/dealer/promo/${id}`, data, { ...args }),
  requestRemoval: (id) => api.post(`/dealer/promo/${id}`),
  togglePromoStatus: (id) => api.post(`/dealer/promo/${id}/status`),
  updateDoor: (doorId, data) => api.patch(`/dealer/access_points/${doorId}`, data),
};

const admin = {
  getAdmins: () => api.get(`/admin/users`),
  createAdmin: (data) => api.post(`/admin/users`, data),
  editAdmin: (user_id, data) => api.put(`/admin/users/${user_id}`, data),
  deleteAdmin: (user_id) => api.delete(`/admin/users/${user_id}`),
  deleteUserPermanently: (user_id, data) => api.delete(`/admin/users/delete/${user_id}`, { data }),
  resendEmail: (user_id) => api.get(`/admin/resend_welcome_email/${user_id}`),
  getDealers: () => api.get(`admin/dealers`),
  getDealersLazy: (page = 1, params = {}) => api.get(`admin/dealers/${page}`, params),
  addDealer: (data) => api.post(`/admin/create_dealer`, data),
  getKeyComList: () => api.get(`admin/kiosks`),
  getKeyComStatusesFilters: () => api.get(`admin/kiosks_statuses/filters`),
  getKeyComStatusesListPaginated: (page = 1, sizePerPage = 50, params = {}) =>
    api.post(`admin/kiosks_statuses/${page}/${sizePerPage}`, params),
  updateKeyCom: (kiosk_id, data) => api.patch(`admin/kiosks/${kiosk_id}`, data),
  getKeyComAfterUpdate: (kiosk_id) => api.get(`admin/kiosks/${kiosk_id}`),
  updateHeartbeatStatus: (serial_number, status) => api.put(`/kiosk/heartbeats/${serial_number}/${status}`),
  getAnalytics: (dateRange = {}) => api.post(`admin/building_analytics`, dateRange),
  getGlobalKeycomAds: () => api.get(`admin/global_keycom_ads`),
  createGlobalKeycomAds: (data, { ...args }) =>
    chunkedUploader.uploadFile(`admin/global_keycom_ads`, data, { ...args }),
  getAvailableBuildings: () => api.get(`admin/available_buildings`),
  getAdAnalytics: () => api.get(`/admin/ad_analytics`),
  createDealer: (data) => api.post(`/admin/create_dealer`, data),
  getBuildingKeyComList: (id) => api.get(`admin/${id}/kiosks`),
  updateKiosk: (id, data) => api.put(`admin/kiosk/${id}`, data),
  getLoginRecords: (page, perPage, params = {}) => api.post(`admin/login_records/${page}/${perPage}`, params),
  getLoginRecordsFilters: () => api.get(`admin/login_records/filters`),
  getIntegrationsLoginRecords: (page, perPage, params = {}) =>
    api.post(`admin/integrations_login_records/${page}/${perPage}`, params),
  getIntegrationsLoginRecordFilters: () => api.get(`admin/integrations_login_records/filters`),
  getUsersRecords: (page = 1, perPage = 50, params = {}) =>
    api.post(`admin/all_users/${page}/${perPage}`, params),
  getFullAccessLog: (page, perPage, params) => api.post(`admin/full_access_log/${page}/${perPage}`, params),
  getFullAccessLogFilters: () => api.get(`admin/full_access_log/filters`),
  getEvents: () => api.get(`admin/events`),
  createMultipleEvents: (data) => api.post(`admin/events`, data),
  deleteEventsGroup: (group_id) => api.delete(`admin/events/${group_id}`),
  getNotices: () => api.get(`admin/notices`),
  createMultipleNotices: (data) => api.post(`admin/notices`, data),
  deleteNoticesGroup: (group_id) => api.delete(`admin/notices/${group_id}`),
  changeManager: ({ building_id, client_id } = {}) =>
    api.put(`admin/change_manager/${building_id}/${client_id}`),
  changeDealer: ({ dealer_id, client_id } = {}) => api.put(`admin/change_dealer/${client_id}/${dealer_id}`),
  getMykeyPromo: () => api.get(`/admin/promo`),
  addMykeyPromo: (data) => api.post(`/admin/promo`, data),
  uploadPromoImg: (data) => api.post(`/admin/upload_promo_img`, data),
  getMykeyPushedPromo: () => api.get(`/admin/pushed_promo`),
  getMetaInformation: () => api.get(`/admin/meta`),
  getCompanyGroups: () => api.get(`/admin/communities`),
  addCompanyGroup: (data) => api.post(`/admin/communities`, data),
  updateCompanyGroup: (id, data) => api.put(`/admin/communities/${id}`, data),
  deleteCompanyGroup: (id) => api.delete(`/admin/communities/${id}`),
  getPin: () => api.get("/admin/pin"),
  updatePin: (data) => api.put("/admin/pin", data),
  getTrainingVideos: (for_who) => api.get(`/training_videos/all/${for_who}`),
  updateTrainingVideo: (id, data, { cancelToken, progressHandler }) =>
    api.post(`/training_videos/update/${id}`, data, {
      cancelToken,
      timeout: 300000,
      ...progressHandler,
    }),
  addTrainingVideo: (data, { cancelToken, progressHandler }) =>
    api.post(`/training_videos/add`, data, {
      cancelToken,
      timeout: 300000,
      ...progressHandler,
    }),
  getKioskVersions: () => api.get(`/kiosk/available_versions`),
  getVersionsInKiosks: () => api.get(`/kiosk/versions_in_kiosks`),
  deleteTrainingVideo: (id) => api.get(`/training_videos/remove/${id}`),
  addKioskVersion: (data) => api.post(`/kiosk/add_kisok_version`, data),
  deleteKioskVersion: (data) => api.post(`/kiosk/remove_kisok_version`, data),
  deleteKiosk: (kioskId) => api.delete(`/admin/kiosks/${kioskId}`),
  getKioskSoftwareVersion: (kiosk_id) =>
    api.get(kiosk_id ? `/kiosk/software-version/${kiosk_id}` : "/kiosk/software-version"),
  updateKioskSoftwareVersion: (kiosk_id, data) =>
    api.post(kiosk_id ? `/kiosk/software-version/${kiosk_id}` : `/kiosk/software-version`, data),
  getPushNotificationRulesFilters: () => api.get(`/admin/push_notification_rules/filters`),
  getPushNotificationRulesPaginated: (
    page = 1,
    sizePerPage = 50,
    params = { sorting_params: '[{"order": "asc","sortField": "building_name"}]' }
  ) => api.post(`/admin/push_notification_rules/paginated/${page}/${sizePerPage}`, params),
  addPushNotificationRule: (ruleData) => api.post(`/admin/push_notification_rules`, ruleData),
  changePushNotificationRule: (ruleId, ruleData) =>
    api.put(`/admin/push_notification_rules/${ruleId}`, ruleData),
  deletePushNotificationRule: (ruleId) => api.delete(`/admin/push_notification_rules/${ruleId}`),
  getRoles: () => api.get(`/admin/roles`),
  updateRolesActiationCodeExpirationTime: (settings) => api.put(`/admin/roles`, settings),
  getDealerNames: () => api.get(`/admin/dealers/names`, { with_ids: true }),
  getManagementNames: ({ dealer_id, dealer_account_id }) =>
    api.get(`/dealer/management_companies/names`, { dealer_id, dealer_account_id, with_ids: true }),
  getBuildingNames: (params) => api.get(`/management/buildings/names`, params),
  getUnitNames: (building_id) => api.get(`/building/units/names`, { building_id }),
  moveUser: (params, user_id) => api.put(`/admin/move_user`, { ...params, user_id }),
  getSlowRequestsReport: (params) => api.get(`/admin/slow_requests_report`, params),
  getSlowRequestsReportFilters: () => api.get(`/admin/slow_requests_report/filters`),
  clearSlowRequestsReport: () => api.delete(`/admin/slow_requests_report`),
  getHelpCenterTickets: (params) => api.get(`/help_center/tickets`, params),
  getHelpCenterTicketsFilters: () => api.get(`/help_center/tickets/filters`),
  updateHelpCenterTicketStatus: (ticket_id, params) => api.put(`/help_center/ticket/${ticket_id}`, params),
  getBuildingChangeLog: (params) => api.get(`/admin/building_change_log`, params),
  getBuildingChangeLogFilters: () => api.get(`/admin/building_change_log/filters`),
};

const kiosk = {
  getSipProviders: () => api.get(`/kiosk/sip_providers`),
  getTrainingVideoUrl: () => api.get(`/kiosk/training_video`),
  uploadTrainingVideo: (data) => api.post(`/kiosk/training_video`, data),
  getScreenSaverVideoUrl: () => api.get(`/kiosk/screen_saver_video`),
  uploadScreenSaverVideo: (data) => chunkedUploader.uploadFile(`/kiosk/screen_saver_video`, data),
};

const brivo = {
  getDoors: (building_id) => api.get(`/brivo/buildings/${building_id}/doors`),
  addDoor: (building_id, device_id, data) =>
    api.post(`/brivo/buildings/${building_id}/doors/${device_id}`, data),
  deleteDoor: (building_id, door_id) => api.delete(`/brivo/buildings/${building_id}/doors/${door_id}`),
  createSubscription: (building_id, door_id, data) =>
    api.post(`/brivo/buildings/${building_id}/subscriptions/${door_id}`, data),
  deleteSubscription: (building_id, door_id) =>
    api.delete(`/brivo/buildings/${building_id}/subscriptions/${door_id}`),
  getAllSites: (building_id) => api.get(`/brivo/buildings/${building_id}/sites`),
  getSiteGroups: (building_id) => api.get(`/brivo/buildings/${building_id}/groups`),
  getAccessPoints: (building_id) => api.get(`/brivo/buildings/${building_id}/access_points`),
  getAccessPointsBySiteId: (building_id, site_id) =>
    api.get(`/brivo/buildings/${building_id}/access_points/${site_id}`),
  getDefaultSchedule: (building_id) => api.get(`/brivo/${building_id}/default_schedule`),
  getAuthUrl: (building_id) => api.get(`/brivo/auth_url/${building_id}`),
  whoami: (building_id) => api.get(`/brivo/whoami/${building_id}`),
  unauthorize: (building_id) => api.post(`/brivo/unauthorize/${building_id}`),
  updateBuilding: (building_id, data) => api.post(`/brivo/buildings/${building_id}`, data),
  setBrivoCommunitySite: (building_id) => api.patch(`/brivo/buildings/${building_id}`),
  getBuildingBrivoConfig: (building_id) => api.get(`/brivo/buildings/${building_id}`),
  syncBuilding: (building_id) => api.get(`/brivo/buildings/${building_id}/sync`),
  importAllData: (building_id) => api.get(`/brivo/buildings/${building_id}/import`),
  unsyncSite: (building_id) => api.post(`/brivo/buildings/${building_id}/unsync`),
  disassociateSite: (building_id) => api.get(`/brivo/buildings/${building_id}/disassociate`),
  getBuildingUsers: (building_id) => api.get(`/brivo/buildings/${building_id}/users`),
  unsyncUser: (building_id, user_id) => api.post(`/brivo/buildings/${building_id}/users/${user_id}/unsync`),
  getSettings: (building_id) => api.get(`/brivo/settings/${building_id}`),
  updateSettings: (building_id, data) => api.post(`/brivo/settings/${building_id}`, data),
  retrieveAuthSettings: (building_id) => api.get(`/brivo/auth/settings/${building_id}`),
  updateAuthSettings: (building_id, data) => api.post(`/brivo/auth/settings/${building_id}`, data),
  deleteAuthSettings: (building_id) => api.delete(`/brivo/auth/settings/${building_id}`),
};

const pdk = {
  getPdkDoors: (building_id) => api.get(`/pdk/buildings/${building_id}/doors`),
  getPdkDevices: (building_id) => api.get(`/pdk/buildings/${building_id}/devices`),
  addPdkDoor: (building_id, device_id, data) =>
    api.post(`/pdk/buildings/${building_id}/doors/${device_id}`, data),
  deletePdkDoor: (building_id, door_id) => api.delete(`/pdk/buildings/${building_id}/doors/${door_id}`),
  createPdkSubscription: (building_id, door_id, data) =>
    api.post(`/pdk/buildings/${building_id}/subscriptions/${door_id}`, data),
  deletePdkSubscription: (building_id, door_id) =>
    api.delete(`/pdk/buildings/${building_id}/subscriptions/${door_id}`),

  whoami: (building_id) => api.get(`/pdk/whoami/${building_id}`),
  updateAuthSettings: (building_id, data) => api.post(`/pdk/auth/settings/${building_id}`, data),
  deleteAuthSettings: (building_id, settings_id) =>
    api.delete(`/pdk/auth/settings/${building_id}`, { settings_id }),
  disassociateNode: (building_id, settings_id) =>
    api.post(`/pdk/buildings/${building_id}/disassociate`, { settings_id }),
  retrieveAuthSettings: (building_id) => api.get(`/pdk/auth/settings/${building_id}`),
  getBuildingPdkConfig: (building_id) => api.get(`/pdk/buildings/${building_id}`),
  updateBuildingSettings: (building_id, data) => api.post(`/pdk/buildings/${building_id}`, data),
  setPdkCommunityPanel: (building_id) => api.patch(`/pdk/buildings/${building_id}`),
  getAllPanels: (building_id) => api.get(`/pdk/buildings/${building_id}/panels`),
  getSyncedCountByGroup: (building_id) => api.get(`/pdk/buildings/${building_id}/synced`),
  getPdkGroups: (building_id) => api.get(`/pdk/buildings/${building_id}/groups`),
  syncBuilding: (building_id, panel_id) => api.post(`/pdk/buildings/${building_id}/sync`, { panel_id }),
  importAllData: (building_id) => api.get(`/pdk/buildings/${building_id}/import`),
  unsyncNode: (building_id, settings_id) => api.post(`/pdk/buildings/${building_id}/unsync`, { settings_id }),
  getAvailablePanels: (params) => api.get(`/pdk/admin/available_panels`, params),
};

const hartmann = {
  retrieveAuthSettings: (building_id) => api.get(`/hartmann/auth/settings/${building_id}`),
  updateAuthSettings: (building_id, data) => api.post(`/hartmann/auth/settings/${building_id}`, data),
  deleteAuthSettings: (building_id) => api.delete(`/hartmann/auth/settings/${building_id}`),
  checkConnection: (building_id) => api.get(`/hartmann/auth/check/${building_id}`),
  getPartitions: (building_id) => api.get(`/hartmann/partitions/${building_id}`),
  getPanels: (building_id, partition_id) =>
    api.get(`/hartmann/buildings/${building_id}/${partition_id}/panels`),
  getDevices: (building_id, partition_id) =>
    api.get(`/hartmann/buildings/${building_id}/${partition_id}/devices`),
  getDoors: (building_id) => api.get(`/hartmann/buildings/${building_id}/doors`),
  addDoor: (building_id, device_id, data) =>
    api.post(`/hartmann/buildings/${building_id}/doors/${device_id}`, data),
  deleteDoor: (building_id, door_id) => api.delete(`/hartmann/buildings/${building_id}/doors/${door_id}`),
  getReaders: (building_id) => api.get(`/hartmann/buildings/${building_id}/readers`),
  getBuildingHartmannConfig: (building_id) => api.get(`/hartmann/buildings/${building_id}`),
  updateBuildingSettings: (building_id, data) => api.post(`/hartmann/buildings/${building_id}`, data),
  setHartmannCommunityPanel: (building_id) => api.patch(`/hartmann/buildings/${building_id}`),
  getHartmannGroups: (building_id) => api.get(`/hartmann/buildings/${building_id}/groups`),
  syncPartition: (building_id) => api.get(`/hartmann/buildings/${building_id}/sync`),
  importAllData: (building_id) => api.get(`/hartmann/buildings/${building_id}/import`),
  unsyncPartition: (building_id) => api.post(`/hartmann/buildings/${building_id}/unsync`),
  disassociatePartition: (building_id) => api.get(`/hartmann/buildings/${building_id}/disassociate`),
  createSubscription: (building_id, door_id, data) =>
    api.post(`/hartmann/buildings/${building_id}/subscriptions/${door_id}`, data),
  deleteSubscription: (building_id, door_id) =>
    api.delete(`/hartmann/buildings/${building_id}/subscriptions/${door_id}`),
};

const entrata = {
  retrieveAuthSettings: (building_id) => api.get(`/entrata/auth/settings/${building_id}`),
  updateAuthSettings: (building_id, data) => api.post(`/entrata/auth/settings/${building_id}`, data),
  deleteAuthSettings: (building_id) => api.delete(`/entrata/auth/settings/${building_id}`),
  isAuthenticated: (building_id) => api.get(`/entrata/auth/check/${building_id}`),
};

const mobile = {
  getIosMinVersion: () => api.get(`/mobile/ios_min_version`),
  getAndroidMinVersion: () => api.get(`/mobile/android_min_version`),
  updateIosMinVersion: (data) => api.put(`/mobile/ios_min_version`, data),
  updateAndroidMinVersion: (data) => api.put(`/mobile/android_min_version`, data),
};

const fmSockets = {
  openDoor: (kioskId, residentId = null) =>
    api.get(`/fm_sockets/open_door/${kioskId}/${residentId ? residentId : ""}`),
  rebootKeyCom: (kioskId) => api.get(`/fm_sockets/reboot_keycom/${kioskId}`),
};

export default {
  api,
  user,
  auth,
  account,
  resident,
  building,
  management,
  dealer,
  admin,
  kiosk,
  brivo,
  mobile,
  fmSockets,
  plans,
  pdk,
  hartmann,
  entrata,
};

import React from "react";
import _get from "lodash/get";

import {
  BUILDING_MENU_VISITORS,
  BUILDING_MENU_INFO,
  BUILDING_MENU_DIRECTORY,
  BUILDING_MENU_UNIT_SETTINGS,
} from "utils/clients";
import { BUILDING_PATH_KEY } from "utils/breadcrumbs";
import { renderBadge, renderRightBadge } from "../menu-utils";
import {
  AppFolioIcon,
  EntrataIcon,
  MRIIcon,
  RealpageIcon,
  RemoteLandlordIcon,
  RentManagerIcon,
  YardiIcon,
  BrivoIcon,
  HartmannIcon,
  PDKIcon,
  SaltoIcon,
  ThreeXIcon,
  OpenpathIcon,
  DigitalWatchdogIcon,
  EagleEyeIcon,
  OpenEyeIcon,
} from "components/ui/logos/integrationLogos";
import {
  NOPLAN_SUBSCRIPTION_PLAN,
  LIFETIME_SUBSCRIPTION_PLAN,
  UNSUBSCRIBED_SUBSCRIPTION_PLAN,
  ESSENTIALS_SUBSCRIPTION_PLAN,
} from "components/ui/subscribed/SubscribedPlan";
import { combineMenuWithPermissions, getListMenuItems, denyAccessToSections } from "../menu-utils";
import { isDealer, isDealerUpRole, isManagerUpRole } from "components/ui/authorized/AuthorizedRole";
import { PermissionNames } from "components/layout/navigation/menu-utils";
import { PERMISSION_TYPE_SHOW } from "utils/accessManager";
import { accessValues } from "utils/accessValues";

export const BG_COLOR = "#5cbea8";
export const LIGHT_BG_COLOR = "#8AD0C0";
export const SELECT_PATH = `/app/${BUILDING_PATH_KEY}`;
export const MESSAGES_SUBMENU_NAME = "Messages";
export const FLAGS_SUBMENU_NAME = "Flags";
export const ENTRATA_SUBMENU_NAME = "Entrata";
export const BRIVO_SUBMENU_NAME = "Brivo";
export const PDK_SUBMENU_NAME = "PDK";
export const HARTMANN_SUBMENU_NAME = "Hartmann";

export const getActiveTab = (displayValue) => displayValue(BUILDING_MENU_DIRECTORY);

export const MENU_KEYS = {
  DIRECTORY: "building_directory",
  UNITSETTINGS: "building_settings",
  VISITORS: "building_visitors",
  MESSAGES: "building_messages",
  DELIVERY_PACKAGES: "delivery_packages",
  BULLETINBOARD: "building_bulletinBoard",
  INFO: "building_info",
  ACCOUNTCHANGES: "building_accountChanges",
  ACCESSLOG: "building_accessLog",
  FLAGS: "building_flags",
  ALERTS: "building_alerts",
  ANALYTICS: "building_analytics",
  MAINTENANCE: "building_maintenance",
  PROMOS: "building_promos",
  DEVICES: "building_devices",
  ACCESSLIST: "building_accessList",
  INTEGRATIONS: "building_integrations",
  BRIVO: "building_brivo",
  BRIVO_AUTH: "building_brivo_auth",
  BRIVO_SETTINGS: "building_brivo_settings",
  BRIVO_SYNC: "building_brivo_sync",
  PDK: "building_pdk",
  PDK_AUTH: "building_pdk_auth",
  PDK_SETTINGS: "building_pdk_settings",
  PDK_SYNC: "building_pdk_sync",
  HARTMANN: "building_hartmann",
  HARTMANN_AUTH: "building_hartmann_auth",
  HARTMANN_SETTINGS: "building_hartmann_settings",
  HARTMANN_SYNC: "building_hartmann_sync",
  ENTRATA: "building_entrata",
  ENTRATA_AUTH: "building_entrata_auth",
  ENTRATA_SETTINGS: "building_entrata_settings",
  ENTRATA_SYNC: "building_entrata_sync",
};

const getPlanEnablePermissionForMenuItem = (permissionName, selectedBuilding, plan) => {
  const menuItemPermissions = plan.features.find((item) => item.field_name === permissionName);
  const planName = _get(selectedBuilding, "building_subscription_plan.name", null);
  if (planName) {
    return menuItemPermissions[planName].enablePermissions;
  } else {
    return true;
  }
};

const getPlanShowPermissionForMenuItem = (permissionName, selectedBuilding, plan) => {
  const menuItemPermissions = plan.features.find((item) => item.field_name === permissionName);
  const planName = _get(selectedBuilding, "building_subscription_plan.name", null);
  if (planName) {
    return menuItemPermissions[planName].showPermissions;
  } else {
    return true;
  }
};

const getMenuItems = (displayValue, selectedBuilding, plan) => {
  return {
    [MENU_KEYS.DIRECTORY]: {
      name: displayValue(BUILDING_MENU_DIRECTORY),
      path: ({ navigation }) => `${SELECT_PATH}/${navigation.selectedBuilding}/unit_directory`,
      icon: <i className="material-icons">domain</i>,
      activeClassName: "active-with-bg active-with-border-left",
    },
    [MENU_KEYS.VISITORS]: {
      name: displayValue(BUILDING_MENU_VISITORS),
      path: ({ navigation }) => `${SELECT_PATH}/${navigation.selectedBuilding}/resident_users`,
      icon: <i className="material-icons">person</i>,
      activeClassName: "active-with-bg active-with-border-left",
    },
    [MENU_KEYS.ACCOUNTCHANGES]: {
      name: "Account Changes",
      path: ({ navigation }) => `${SELECT_PATH}/${navigation.selectedBuilding}/account_changes`,
      icon: <i className="fa fa-edit" />,
      activeClassName: "active-with-bg active-with-border-left",
    },
    [MENU_KEYS.ACCESSLOG]: {
      name: "Access Log",
      path: ({ navigation }) => `${SELECT_PATH}/${navigation.selectedBuilding}/access_log`,
      icon: <i className="material-icons">vpn_key</i>,
      activeClassName: "active-with-bg active-with-border-left",
    },
    [MENU_KEYS.FLAGS]: {
      name: "Flags",
      path: ({ navigation }) => `${SELECT_PATH}/${navigation.selectedBuilding}/flags`,
      icon: <i className="fa fa-flag" />,
      badge: renderBadge(selectedBuilding, "flags"),
      activeClassName: "active-with-bg active-with-border-left",
      children: [
        {
          name: "Summary",
          path: ({ navigation }) => `${SELECT_PATH}/${navigation.selectedBuilding}/flags/flag_summary`,
          className: "icon-with-border",
          activeClassName: "active-with-color icon-with-border-active",
          icon: <i className="fa fa-chart-bar" />,
        },
        {
          name: "Events",
          path: ({ navigation }) => `${SELECT_PATH}/${navigation.selectedBuilding}/flags/flag_events`,
          className: "icon-with-border",
          activeClassName: "active-with-color icon-with-border-active",
          icon: <i className="material-icons">watch_later</i>,
        },
      ],
    },
    [MENU_KEYS.ALERTS]: {
      name: "Alerts",
      path: ({ navigation }) => `${SELECT_PATH}/${navigation.selectedBuilding}/alerts`,
      icon: <i className="material-icons">notifications_active</i>,
      activeClassName: "active-with-bg active-with-border-left",
    },
    [MENU_KEYS.ANALYTICS]: {
      name: "Analytics",
      path: ({ navigation }) => `${SELECT_PATH}/${navigation.selectedBuilding}/analytics`,
      icon: <i className="material-icons">assessment</i>,
      activeClassName: "active-with-bg active-with-border-left",
    },
    [MENU_KEYS.MESSAGES]: {
      name: "Messages",
      path: ({ navigation }) => `${SELECT_PATH}/${navigation.selectedBuilding}/messages`,
      icon: <i className="material-icons">mail</i>,
      activeClassName: "active-with-bg active-with-border-left active-with-border-top-bottom",
      badge: renderBadge(selectedBuilding, "unread_messages_count"),
      children: [
        {
          name: "KeyCom® Messages",
          path: ({ navigation }) => `${SELECT_PATH}/${navigation.selectedBuilding}/messages/keycom_messages`,
          className: "icon-with-border",
          badge: renderRightBadge(selectedBuilding, "unread_vvm_messages_count"),
          activeClassName: "active-with-color icon-with-border-active",
          icon: <i className="fa fa-tv" />,
        },
        {
          name: "Text Messages",
          path: ({ navigation }) => `${SELECT_PATH}/${navigation.selectedBuilding}/messages/text_messages`,
          className: "icon-with-border",
          badge: renderRightBadge(selectedBuilding, "unread_text_messages_count"),
          activeClassName: "active-with-color icon-with-border-active",
          icon: <i className="fa fa-comment" />,
        },
      ],
    },
    [MENU_KEYS.DELIVERY_PACKAGES]: {
      name: "Packages",
      path: ({ navigation }) => `${SELECT_PATH}/${navigation.selectedBuilding}/delivery_packages`,
      icon: <i className="material-icons">inventory</i>,
      activeClassName: "active-with-bg active-with-border-left",
      applyStaticPermissionKey: undefined,
      disabled: !_get(selectedBuilding, "package_room_allowed", false),
      enablePermissionsBool: _get(selectedBuilding, "package_room_allowed", false),
      showPermissionsBool: true,
    },
    [MENU_KEYS.MAINTENANCE]: {
      name: "Maintenance",
      path: ({ navigation }) => `${SELECT_PATH}/${navigation.selectedBuilding}/maintenance`,
      icon: <i className="material-icons">build</i>,
      badge: renderBadge(selectedBuilding, "maintenance"),
      activeClassName: "active-with-bg active-with-border-left",
    },
    [MENU_KEYS.PROMOS]: {
      name: "Promos",
      path: ({ navigation }) => `${SELECT_PATH}/${navigation.selectedBuilding}/promos`,
      icon: <i className="material-icons">card_giftcard</i>,
      activeClassName: "active-with-bg active-with-border-left",
    },
    [MENU_KEYS.BULLETINBOARD]: {
      name: "Bulletin Board",
      path: ({ navigation }) => `${SELECT_PATH}/${navigation.selectedBuilding}/bulletin_board`,
      icon: <i className="material-icons">assignment</i>,
      activeClassName: "active-with-bg active-with-border-left",
    },
    [MENU_KEYS.INFO]: {
      name: displayValue(BUILDING_MENU_INFO),
      path: ({ navigation }) => `${SELECT_PATH}/${navigation.selectedBuilding}/building_info`,
      icon: <i className="fa fa-building" />,
      activeClassName: "active-with-bg active-with-border-left",
    },
    [MENU_KEYS.DEVICES]: {
      name: "Devices",
      path: ({ navigation }) => `${SELECT_PATH}/${navigation.selectedBuilding}/keycom_settings`,
      icon: <i className="fa fa-tv" />,
      activeClassName: "active-with-bg active-with-border-left",
    },
    [MENU_KEYS.UNITSETTINGS]: {
      name: displayValue(BUILDING_MENU_UNIT_SETTINGS),
      path: ({ navigation }) => `${SELECT_PATH}/${navigation.selectedBuilding}/unit_settings`,
      icon: <i className="material-icons">settings_cell</i>,
      activeClassName: "active-with-bg active-with-border-left",
    },
    [MENU_KEYS.INTEGRATIONS]: {
      name: "Integrations",
      path: ({ navigation }) => `${SELECT_PATH}/${navigation.selectedBuilding}/integrations`,
      icon: <i className="material-icons">settings_suggest</i>,
      activeClassName: "active-with-bg active-with-border-left",
      children: [
        {
          name: "Property Management",
          path: ({ navigation }) =>
            `${SELECT_PATH}/${navigation.selectedBuilding}/integrations/property_management`,
          icon: <i className="material-icons">real_estate_agent</i>,
          activeClassName: "active-with-bg active-with-border-left",
          children: [
            {
              name: "Appfolio",
              path: ({ navigation }) =>
                `${SELECT_PATH}/${navigation.selectedBuilding}/integrations/property_management/appfolio`,
              icon: <AppFolioIcon />,
              activeClassName: "active-with-color icon-with-border-active",
              applyStaticPermissionKey: undefined,
              disabled: true,
              enablePermissionsBool: false,
              showPermissionsBool: true,
            },
            {
              name: "Entrata",
              path: ({ navigation }) =>
                `${SELECT_PATH}/${navigation.selectedBuilding}/integrations/property_management/entrata`,
              icon: <EntrataIcon />,
              activeClassName: "active-with-color icon-with-border-active",
              applyStaticPermissionKey: MENU_KEYS.ENTRATA,
              enablePermissionsBool: getPlanEnablePermissionForMenuItem(
                accessValues.MANAGEMENT_ENTRATA,
                selectedBuilding,
                plan
              ),
              showPermissionsBool: getPlanShowPermissionForMenuItem(
                accessValues.MANAGEMENT_ENTRATA,
                selectedBuilding,
                plan
              ),
            },
            {
              name: "MRI",
              path: ({ navigation }) =>
                `${SELECT_PATH}/${navigation.selectedBuilding}/integrations/property_management/mri`,
              icon: <MRIIcon />,
              activeClassName: "active-with-color icon-with-border-active",
              applyStaticPermissionKey: undefined,
              disabled: true,
              enablePermissionsBool: false,
              showPermissionsBool: true,
            },
            {
              name: "Realpage",
              path: ({ navigation }) =>
                `${SELECT_PATH}/${navigation.selectedBuilding}/integrations/property_management/realpage`,
              icon: <RealpageIcon />,
              activeClassName: "active-with-color icon-with-border-active",
              applyStaticPermissionKey: undefined,
              disabled: true,
              enablePermissionsBool: false,
              showPermissionsBool: true,
            },
            {
              name: "Remote Landlord",
              path: ({ navigation }) =>
                `${SELECT_PATH}/${navigation.selectedBuilding}/integrations/property_management/remote_landlord`,
              icon: <RemoteLandlordIcon />,
              activeClassName: "active-with-color icon-with-border-active",
              applyStaticPermissionKey: undefined,
              disabled: true,
              enablePermissionsBool: false,
              showPermissionsBool: true,
            },
            {
              name: "Rent Manager",
              path: ({ navigation }) =>
                `${SELECT_PATH}/${navigation.selectedBuilding}/integrations/property_management/rent_manager`,
              icon: <RentManagerIcon />,
              activeClassName: "active-with-color icon-with-border-active",
              applyStaticPermissionKey: undefined,
              disabled: true,
              enablePermissionsBool: false,
              showPermissionsBool: true,
            },
            {
              name: "Yardi",
              path: ({ navigation }) =>
                `${SELECT_PATH}/${navigation.selectedBuilding}/integrations/property_management/yardi`,
              icon: <YardiIcon />,
              activeClassName: "active-with-color icon-with-border-active",
              applyStaticPermissionKey: undefined,
              disabled: true,
              enablePermissionsBool: false,
              showPermissionsBool: true,
            },
          ],
        },
        {
          name: "Access Control",
          path: ({ navigation }) =>
            `${SELECT_PATH}/${navigation.selectedBuilding}/integrations/access_control`,
          icon: <i className="material-icons">login</i>,
          activeClassName: "active-with-bg active-with-border-left",
          children: [
            {
              name: "Brivo",
              path: ({ navigation }) =>
                `${SELECT_PATH}/${navigation.selectedBuilding}/integrations/access_control/brivo`,
              icon: <BrivoIcon />,
              activeClassName: "active-with-color icon-with-border-active",
              applyStaticPermissionKey: MENU_KEYS.BRIVO,
              enablePermissionsBool: getPlanEnablePermissionForMenuItem(
                accessValues.MANAGEMENT_BRIVO,
                selectedBuilding,
                plan
              ),
              showPermissionsBool: getPlanShowPermissionForMenuItem(
                accessValues.MANAGEMENT_BRIVO,
                selectedBuilding,
                plan
              ),
            },
            {
              name: "HARTMANN",
              path: ({ navigation }) =>
                `${SELECT_PATH}/${navigation.selectedBuilding}/integrations/access_control/hartmann`,
              icon: <HartmannIcon />,
              activeClassName: "active-with-color icon-with-border-active",
              applyStaticPermissionKey: MENU_KEYS.HARTMANN,
              enablePermissionsBool: getPlanEnablePermissionForMenuItem(
                accessValues.MANAGEMENT_HARTMANN,
                selectedBuilding,
                plan
              ),
              showPermissionsBool: getPlanShowPermissionForMenuItem(
                accessValues.MANAGEMENT_HARTMANN,
                selectedBuilding,
                plan
              ),
            },
            {
              name: "Openpath",
              path: ({ navigation }) =>
                `${SELECT_PATH}/${navigation.selectedBuilding}/integrations/property_management/openpath`,
              icon: <OpenpathIcon />,
              activeClassName: "active-with-color icon-with-border-active",
              applyStaticPermissionKey: undefined,
              disabled: true,
              enablePermissionsBool: false,
              showPermissionsBool: true,
            },
            {
              name: "PDK",
              path: ({ navigation }) =>
                `${SELECT_PATH}/${navigation.selectedBuilding}/integrations/access_control/pdk`,
              icon: <PDKIcon />,
              activeClassName: "active-with-color icon-with-border-active",
              applyStaticPermissionKey: MENU_KEYS.PDK,
              enablePermissionsBool: getPlanEnablePermissionForMenuItem(
                accessValues.MANAGEMENT_PDK,
                selectedBuilding,
                plan
              ),
              showPermissionsBool: getPlanShowPermissionForMenuItem(
                accessValues.MANAGEMENT_PDK,
                selectedBuilding,
                plan
              ),
            },
            {
              name: "Salto",
              path: ({ navigation }) =>
                `${SELECT_PATH}/${navigation.selectedBuilding}/integrations/property_management/salto`,
              icon: <SaltoIcon />,
              activeClassName: "active-with-color icon-with-border-active",
              applyStaticPermissionKey: undefined,
              disabled: true,
              enablePermissionsBool: false,
              showPermissionsBool: true,
            },
            {
              name: "3X Infinias",
              path: ({ navigation }) =>
                `${SELECT_PATH}/${navigation.selectedBuilding}/integrations/property_management/three_x`,
              icon: <ThreeXIcon />,
              activeClassName: "active-with-color icon-with-border-active",
              applyStaticPermissionKey: undefined,
              disabled: true,
              enablePermissionsBool: false,
              showPermissionsBool: true,
            },
          ],
        },
        {
          name: "Video Management",
          path: ({ navigation }) =>
            `${SELECT_PATH}/${navigation.selectedBuilding}/integrations/video_management`,
          icon: <i className="material-icons">videocam</i>,
          activeClassName: "active-with-bg active-with-border-left",
          children: [
            {
              name: "Digital Watchdog",
              path: ({ navigation }) =>
                `${SELECT_PATH}/${navigation.selectedBuilding}/integrations/property_management/digital_watchdog`,
              icon: <DigitalWatchdogIcon />,
              activeClassName: "active-with-color icon-with-border-active",
              applyStaticPermissionKey: undefined,
              disabled: true,
              enablePermissionsBool: false,
              showPermissionsBool: true,
            },
            {
              name: "EagleEye",
              path: ({ navigation }) =>
                `${SELECT_PATH}/${navigation.selectedBuilding}/integrations/property_management/eagle_eye`,
              icon: <EagleEyeIcon />,
              activeClassName: "active-with-color icon-with-border-active",
              applyStaticPermissionKey: undefined,
              disabled: true,
              enablePermissionsBool: false,
              showPermissionsBool: true,
            },
            {
              name: "OpenEye",
              path: ({ navigation }) =>
                `${SELECT_PATH}/${navigation.selectedBuilding}/integrations/property_management/open_eye`,
              icon: <OpenEyeIcon />,
              activeClassName: "active-with-color icon-with-border-active",
              applyStaticPermissionKey: undefined,
              disabled: true,
              enablePermissionsBool: false,
              showPermissionsBool: true,
            },
          ],
        },
      ],
    },
  };
};

export const getPermissions = (subscriptionPlan, role) => {
  const PermissionsDeny = [PermissionNames.can_view_tabs_on_any_plan];
  const PermissionsAllow = [];

  const staticPermissions = {
    [MENU_KEYS.VISITORS]: {
      enablePermissions: [
        PermissionNames.can_view_photo_files_section,
        PermissionNames.can_view_facial_rec_column_user_changes,
      ],
    },
    [MENU_KEYS.MESSAGES]: {
      enablePermissions: [PermissionNames.can_enable_building_messages_tab],
    },
    [MENU_KEYS.ACCOUNTCHANGES]: {
      enablePermissions: [
        PermissionNames.can_view_flag_column_user_changes,
        PermissionNames.can_view_facial_rec_column_user_changes,
      ],
    },
    [MENU_KEYS.ACCESSLOG]: {
      enablePermissions: [
        PermissionNames.can_view_flag_column_access,
        PermissionNames.can_view_facial_rec_column_access,
        PermissionNames.can_view_interim_video,
      ],
    },
    [MENU_KEYS.FLAGS]: {
      enablePermissions: [PermissionNames.can_view_flag_summary_content],
    },
    [MENU_KEYS.ALERTS]: {
      enablePermissions: [PermissionNames.can_view_alerts_content],
    },
    [MENU_KEYS.ANALYTICS]: {
      enablePermissions: [PermissionNames.can_view_analytics_content],
    },
    [MENU_KEYS.DEVICES]: {
      showPermissions: [PermissionNames.can_view_building_keycom_tab],
    },
    [MENU_KEYS.ACCESSLIST]: {
      showPermissions: [PermissionNames.can_view_access_list_tab],
    },
    [MENU_KEYS.PROMOS]: {
      showPermissions: [PermissionNames.can_view_building_promo_tab],
    },
    [MENU_KEYS.UNITSETTINGS]: {
      enablePermissions: [PermissionNames.can_view_change_roommate_mode],
    },
    [MENU_KEYS.INTEGRATIONS]: {
      enablePermissions: PermissionsAllow,
    },
    // submenus (nested)
    [MENU_KEYS.ENTRATA]: {
      showPermissions: [PermissionNames.can_view_entrata_tab],
    },
    [MENU_KEYS.ENTRATA_AUTH]: {
      [PERMISSION_TYPE_SHOW]: isDealerUpRole(role) ? PermissionsAllow : PermissionsDeny,
    },
    [MENU_KEYS.ENTRATA_SETTINGS]: {
      [PERMISSION_TYPE_SHOW]: isDealerUpRole(role) ? PermissionsAllow : PermissionsDeny,
    },
    [MENU_KEYS.ENTRATA_SYNC]: {
      [PERMISSION_TYPE_SHOW]: isManagerUpRole(role) ? PermissionsAllow : PermissionsDeny,
    },
    // submenus (nested)
    [MENU_KEYS.BRIVO]: {
      showPermissions: [PermissionNames.can_view_brivo_tab],
    },
    [MENU_KEYS.BRIVO_AUTH]: {
      [PERMISSION_TYPE_SHOW]: isDealerUpRole(role) ? PermissionsAllow : PermissionsDeny,
    },
    [MENU_KEYS.BRIVO_SETTINGS]: {
      [PERMISSION_TYPE_SHOW]: isDealerUpRole(role) ? PermissionsAllow : PermissionsDeny,
    },
    [MENU_KEYS.BRIVO_SYNC]: {
      [PERMISSION_TYPE_SHOW]: isManagerUpRole(role) ? PermissionsAllow : PermissionsDeny,
    },
    // submenus (nested)
    [MENU_KEYS.PDK]: {
      showPermissions: [PermissionNames.can_view_pdk_tab],
    },
    [MENU_KEYS.PDK_AUTH]: {
      [PERMISSION_TYPE_SHOW]: isDealerUpRole(role) ? PermissionsAllow : PermissionsDeny,
    },
    [MENU_KEYS.PDK_SETTINGS]: {
      [PERMISSION_TYPE_SHOW]: isDealerUpRole(role) ? PermissionsAllow : PermissionsDeny,
    },
    [MENU_KEYS.PDK_SYNC]: {
      [PERMISSION_TYPE_SHOW]: isManagerUpRole(role) ? PermissionsAllow : PermissionsDeny,
    },
    // submenus (nested)
    [MENU_KEYS.HARTMANN]: {
      showPermissions: [PermissionNames.can_view_hartmann_tab],
    },
    [MENU_KEYS.HARTMANN_AUTH]: {
      [PERMISSION_TYPE_SHOW]: isDealerUpRole(role) ? PermissionsAllow : PermissionsDeny,
    },
    [MENU_KEYS.HARTMANN_SETTINGS]: {
      [PERMISSION_TYPE_SHOW]: isDealerUpRole(role) ? PermissionsAllow : PermissionsDeny,
    },
    [MENU_KEYS.HARTMANN_SYNC]: {
      [PERMISSION_TYPE_SHOW]: isManagerUpRole(role) ? PermissionsAllow : PermissionsDeny,
    },
  };

  const allowedSectionsForDealer = [MENU_KEYS.DEVICES, MENU_KEYS.ACCESSLIST, MENU_KEYS.BRIVO];

  switch (subscriptionPlan) {
    case ESSENTIALS_SUBSCRIPTION_PLAN:
      denyAccessToSections(staticPermissions, {
        forbiddenSections: [MENU_KEYS.FLAGS, MENU_KEYS.ALERTS, MENU_KEYS.ANALYTICS],
      });
      break;

    case LIFETIME_SUBSCRIPTION_PLAN:
      let allowedSectionsForLifetime = [
        MENU_KEYS.DIRECTORY,
        MENU_KEYS.BULLETINBOARD,
        MENU_KEYS.INFO,
        MENU_KEYS.INTEGRATIONS,
      ];

      if (isDealer(role)) {
        allowedSectionsForLifetime = [...allowedSectionsForLifetime, ...allowedSectionsForDealer];
      }

      denyAccessToSections(staticPermissions, {
        allowedSections: allowedSectionsForLifetime,
        menuKeys: MENU_KEYS,
      });
      break;

    case UNSUBSCRIBED_SUBSCRIPTION_PLAN:
      let allowedSectionsForUnsubscribed = [MENU_KEYS.DIRECTORY, MENU_KEYS.INFO, MENU_KEYS.INTEGRATIONS];

      if (isDealer(role)) {
        allowedSectionsForUnsubscribed = [...allowedSectionsForUnsubscribed, ...allowedSectionsForDealer];
      }

      denyAccessToSections(staticPermissions, {
        allowedSections: allowedSectionsForUnsubscribed,
        menuKeys: MENU_KEYS,
      });
      break;

    case NOPLAN_SUBSCRIPTION_PLAN:
      let allowedSectionsForNo = [MENU_KEYS.DIRECTORY, MENU_KEYS.INFO];

      if (isDealer(role)) {
        allowedSectionsForNo = [...allowedSectionsForNo, ...allowedSectionsForDealer];
      }

      denyAccessToSections(staticPermissions, {
        allowedSections: allowedSectionsForNo,
        menuKeys: MENU_KEYS,
      });

      break;

    default:
      break;
  }

  return staticPermissions;
};

export const getMenu = (displayValue, selectedBuilding, role, plan) => {
  const subscriptionPlan = _get(
    selectedBuilding,
    "building_subscription_plan.name",
    NOPLAN_SUBSCRIPTION_PLAN
  );

  const menuItems = getMenuItems(displayValue, selectedBuilding, plan);

  const permissions = getPermissions(subscriptionPlan, role);

  const menuItemsWithPermissions = combineMenuWithPermissions(menuItems, permissions);

  return getListMenuItems(menuItemsWithPermissions);
};

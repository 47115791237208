import injectGlobal from "./injectGlobal";

const tables = theme => injectGlobal`
  table.blue-header {
    thead{
      tr{
        background: #4a90e3;
        th{
          color: white !important;
        }
      }
    }
  }
  table.green-header {
    thead{
      tr{
        background: #5DBDA8;
        th{
          color: white !important;
        }
      }
    }
  }
  .overflow-initial,
  .react-bs-table table td.overflow-initial {
    overflow: initial
  }
  .sticky-toolbar {
    .react-bs-table-tool-bar {
      padding-top: 5px;
      margin-left: -20px;
      margin-right: -20px;
      padding-right: 20px;
      padding-left: 20px;
      position: sticky;
      top: 58px;
      transition: all .1s ease-in-out;
      z-index: 9;
      &.stuck {
        background:#f9f9f9;
        height: auto;
        top: 48px;
        padding-top: 15px;
      }
    }
    .react-bs-container-header {
      position: sticky;
      top: 105px;
    }
  }
  .sticky-toolbar-without-filters {
    .react-bs-table-tool-bar {
      padding-top: 5px;
      margin-left: -20px;
      margin-right: -20px;
      padding-right: 20px;
      padding-left: 20px;
      position: sticky;
      top: 0px;
      transition: all .1s ease-in-out;
      z-index:2;
      &.stuck {
        background:#f9f9f9;
      }
    }
    .react-bs-container-header {
      position: sticky;
      top: 0px;
    }
  }
  .upgrade-table{
    td{
      border: 1px solid #979797;
    }
    & thead tr th:nth-child(2){
      color: white;
      background: #C91481;
    }
    & thead tr th:nth-child(3){
      color: white;
      background: #00A8A1;
    }
    & thead tr th:last-child{
      color: white;
      background: navy;
    }
    & colgroup col:nth-child(2){
      background: #F9DDEC;
    }
    & colgroup col:nth-child(3){
      background: #E1F5F4;
    }
    & colgroup col:last-child{
      background: #C8DCE9;
    }
    &.sticky-toolbar {
      .react-bs-table-tool-bar {
        padding-top: 5px;
        margin-left: -20px;
        margin-right: -20px;
        padding-right: 20px;
        padding-left: 20px;
        position: sticky;
        top: 10px;
        transition: all .1s ease-in-out;
        z-index:2;
        &.stuck {
          background:#f9f9f9;
          height: auto;
        }
      }
      .react-bs-container-header {
        position: sticky;
        top: 68px;
      }
    }
  }
  .bd-table{
    &.header-navy{
      thead{
        tr{
          background: #3E568D;
          th{
            color: white !important;
          }
        }
      }
    }
    &.header-pink{
      thead{
        tr{
          background: #ec297b;
          th{
            color: white !important;
          }
        }
      }
    }
    &.header-purple{
      thead{
        tr{
          background: rgba(88,27,85,1);
          th{
            color: white !important;
          }
        }
      }
    }
    thead{
      tr{
        background: #5DBDA8;
        th{
          color: white !important;
        }
      }
    }
    .table-cell__value .material-icons {
      font-size: 16px;
    }
  }
  .res-table{
    thead{
      display: none;
    }
    img{
      border-radius: 50px !important;
      border: 1px solid lightgray;
    }
    tr td{
      padding: .7em;
    }
    table.table{
      border-radius: 5px;
    }
  }
  .react-bs-table{
    border: 1px solid rgba(211, 211, 211, .4);
    border-radius: 5px;
    td[data-video-log] {
      padding-top: 0;
      padding-bottom: 0;
      .video-log {
        height: 47px;
      }
    }
  }
  .react-bs-table table td, .react-bs-table table th {
    white-space: normal !important;
  }
  table.table {
    background: white;
    thead th {
      text-transform: uppercase;
      font-weight: normal;
      font-size: 0.7rem;
      border-top: 0;
      color: #6b747d;
      border-bottom-width: 1px;
    }
    td, th {
      vertical-align: middle;
    }
  }
  table.table.table-data {
    font-size: 0.8rem;
    &.header-green{
      thead{
        tr{
          background: #579578 !important;
          th{
            color: white !important;
          }
        }
      }
    }
    .btn.btn-sm {
      padding: .12rem .5rem;
    }
    .react-bs-table-expand-cell {
      padding-right: 0;
    }
    td, th {
      border-top: 0;
      vertical-align: middle;
    }
    &.table-striped.table-striped-expandable tbody {
      tr:nth-of-type(4n+1) {
        background: #f4f5f7;
      }
      tr:nth-of-type(4n+3) {
        background: white;
      }
    }
    &.table-striped tbody tr:nth-of-type(odd) {
      background-color: #f4f5f7;
    }
    @media (max-width: ${theme.breakpoints.small}) {
      display:block;
      tbody {
        display:block;
      }
      .toolbar-button-inner{
        &.justify-content-end{
          justify-content: center !important;
        }
      }
      .react-bs-container-header.table-header-wrapper {
        display: none;
      }
      .filter-select, filter-checkbox, .date-range-filter{
        max-width: 100% !important;
      }
      tbody tr {
        display:flex;
        flex-wrap: wrap;
        flex-direction: column;
        padding-top: 10px;
        padding-bottom: 10px;
        td {
          padding:.25rem .75rem;
        }
        .table-cell {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #d7dde2;
          .table-cell__value {
            white-space: normal;
            text-align: right;
          }
        }
      }
    }
  }
  .table td, .table th{
    padding: .5em;
  }
  .react-bs-container-header{
    &.table-header-wrapper{
      border-radius: 5px 5px 0px 0px !important;
      z-index: 2;
    }
  }
  .react-bs-table-pagination{
    .row{
      margin: 0 !important;
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      button{
        background: white;
        padding: .5em;
        font-size: 12px;
        color: gray;
        border: 1px solid lightgray;
      }
      a{
        font-size: .8em;
      }
      .col-md-6.col-xs-6.col-sm-6.col-lg-6{
        display: contents !important;
        .dropdown.react-bs-table-sizePerPage-dropdown{
          margin-right: 1em;
        }
      }
    }
  }
  .react-bs-table-pagination .dropdown .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px;
  }
  .overflow-auto {
    overflow: auto;
  }
`;

export default tables;

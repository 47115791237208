import React, { Component } from "react";
import { connect } from 'react-redux';
import queryString from "query-string";
import { Link } from "react-router-dom";

import { sendPwResetLink } from "redux/reducers/registration";

import PageForm from "components/ui/pageForm/PageForm";

class Contact extends Component {
  state = {
    email: null
  }

  handleResetPaw = async (username) => {
    const { dispatch } = this.props
    const { response } = await dispatch(sendPwResetLink({ data: { username } }))
    this.setState({ email: response.data })
  }

  render() {
    const {
      location: { search } 
    } = this.props;
    const {
      email
    } = this.state;
    console.log(email)
    const query = queryString.parse(search);

    return (
      <PageForm width="400px">
        <div className="text-center">
          <h6>
            {query.username ? 'Reset your password yourself or contact us.' : 'Retrieve Your Information!'}
          </h6>
          {query.username 
            && email 
              ? <p>A password reset link was sent to: {email}</p>
              : <button
                className="btn action-button c-red" 
                onClick={() => this.handleResetPaw(query.username)}
              >
                Reset Password
              </button>
          }
          <hr/>
          <h6>
            Email Us: <a href="mailto:support@mvisystems.com">support@mvisystems.com</a>  <br /><br />
            Contact Us: <a href="tel:18446847978">(844) MVI-SYSTEMS</a>
          </h6>
        </div>
        <Link to="/login" className="d-block text-center m-t-20">
          <small>Return to login</small>
        </Link>
      </PageForm>
    );
  }
}

export default connect()(Contact);

import React, { Component } from "react";
import PropTypes from "prop-types";
import MaskedTextInput from "react-text-mask";

const phoneMask = ["(", /\d/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
class PhoneField extends Component {
  static propTypes = {
    input: PropTypes.object, //Provided by Final Form
    meta: PropTypes.object, //Provided by Final Form
    label: PropTypes.node,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    extraContent: PropTypes.node
  };
  render() {
    const { input, meta, warn, ...rest } = this.props;
    const isError = (meta.error && meta.touched) || meta.submitError;
    const isWarning = warn ? warn(input.value) : null;
    const classes = `${isError ? "is-invalid" : ""} ${meta.valid && meta.touched && !isWarning ? "is-valid" : ""} ${isWarning ? "is-warning": ""}`;
    input.value = input.value.replace(/\D/g, "");
    return (
      <div className={`form-group ${rest.className}`}>
        {rest.label && <label htmlFor={rest.name}>{rest.label} {this.props.asterisk && <span className="asterisk">*</span>}</label>}
        <MaskedTextInput
          mask={phoneMask}
          className={`form-control ${classes}`}
          placeholder={rest.placeholder}
          disabled={rest.disabled}
          guide={false}
          {...input}
          onChange={e => {
            input.onChange(e.target.value.replace(/\D/g, ""));
          }}
        />
        {isWarning ? <div className="warning-feedback">{isWarning}</div>: null}
        {isError && <div className="invalid-feedback">{meta.error || meta.submitError}</div>}
        {rest.extraContent}
      </div>
    );
  }
}

export default PhoneField;

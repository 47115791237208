import React, { Component } from "react";
import Logo from "components/ui/logos/Logo"

class LogoCircle extends Component {
  render() {
    return (
        <div className="logo-outer d-flex justify-content-center position-relative m-b-40">
          <div className="logo-inner position-absolute d-flex justify-content-center align-items-center">
            <Logo/>
          </div>
        </div>
    );
  }
}

export default LogoCircle;

import { createActions, createReducer } from "reduxsauce";
import { asyncCreatorFactory, notifyError, notifySuccess } from "../action-utils";
import _pick from "lodash/pick";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  hartmannFetching: ["actionId"],
  hartmannSuccess: ["actionId"],
  hartmannFailure: ["error", "actionId"],
  hartmannSet: ["data", "attr", "actionId"],
  hartmannReset: null,
});
export const actions = Creators;

/* ------------- Initial state ------------- */
const INITIAL_STATE = {
  checkConnection: false,
  partitions: [],
  readers: [],
  doors: [],
  panels: [],
  devices: [],
  features: undefined,
  settings: undefined,
  config: undefined,
  user: undefined,
  groups: null,
  isUnsyncStarting: false,
  isSyncStarting: false,
  isSyncComplete: false,
  fetching: [],
  error: [],
};

/* ------------- Reducers ------------- */
export const fetching = (state, { actionId }) => ({
  ...state,
  fetching: [...state.fetching, actionId],
  error: state.error.filter((i) => i.actionId !== actionId),
});
export const set = (state, { data, attr, actionId }) => ({
  ...state,
  [attr]: data,
});
export const success = (state, { actionId }) => ({
  ...state,
  fetching: state.fetching.filter((i) => i !== actionId),
  error: state.error.filter((i) => i.actionId !== actionId),
});
export const failure = (state, { error, actionId }) => ({
  ...state,
  fetching: state.fetching.filter((i) => i !== actionId),
  error: [...state.error, { error, actionId }],
});
export const reset = (state, { exclude = [] }) => ({
  ...INITIAL_STATE,
  ..._pick(state, exclude),
});

/* ------------- Hookup Reducers To Types ------------- */
const hartmannReducer = createReducer(INITIAL_STATE, {
  [Types.HARTMANN_FETCHING]: fetching,
  [Types.HARTMANN_SUCCESS]: success,
  [Types.HARTMANN_FAILURE]: failure,
  [Types.HARTMANN_SET]: set,
  [Types.HARTMANN_RESET]: reset,
});

/* ------------- Async Actions ------------- */
const asyncCreator = asyncCreatorFactory(
  Creators.hartmannFetching,
  Creators.hartmannSuccess,
  Creators.hartmannFailure
);

export const checkConnection = (buildingId) =>
  asyncCreator({
    actionId: "checkConnection",
    apiCall: (api) => api.hartmann.checkConnection(buildingId),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.hartmannSet(response.data, "checkConnection"));
    },
    onError: (dispatch) => {
      dispatch(Creators.hartmannSet(false, "checkConnection"));
    },
    errorPath: "data.message",
  });

export const getPartitions = (buildingId) =>
  asyncCreator({
    actionId: "getPartitions",
    apiCall: (api) => api.hartmann.getPartitions(buildingId),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.hartmannSet(response.data, "partitions"));
    },
    errorPath: "data.message",
  });

export const getPanels = (buildingId, partitionId) =>
  asyncCreator({
    actionId: "getPanels",
    apiCall: (api) => api.hartmann.getPanels(buildingId, partitionId),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.hartmannSet(response.data, "panels"));
    },
    errorPath: "data.message",
  });

export const getDevices = (buildingId, partitionId) =>
  asyncCreator({
    actionId: "getDevices",
    apiCall: (api) => api.hartmann.getDevices(buildingId, partitionId),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.hartmannSet(response.data, "devices"));
    },
    errorPath: "data.message",
  });

export const getDoors = (buildingId) =>
  asyncCreator({
    actionId: "getDoors",
    apiCall: (api) => api.hartmann.getDoors(buildingId),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.hartmannSet(response.data, "doors"));
    },
    errorPath: "data.message",
  });

export const addDoor = (buildingId, deviceId, data = {}) =>
  asyncCreator({
    actionId: "addDoor",
    apiCall: (api) => api.hartmann.addDoor(buildingId, deviceId, data),
    onSuccess: (dispatch, response) => {
      notifySuccess(`The device was created successfully`);
      dispatch(getDoors(buildingId));
    },
    errorPath: "data.message",
  });

export const deleteDoor = (buildingId, doorId) =>
  asyncCreator({
    actionId: "deleteDoor",
    apiCall: (api) => api.hartmann.deleteDoor(buildingId, doorId),
    onSuccess: (dispatch, response) => {
      notifySuccess(`The device was deleted successfully`);
      dispatch(getDoors(buildingId));
    },
    errorPath: "data.message",
  });

export const getReaders = (buildingId) =>
  asyncCreator({
    actionId: "getReaders",
    apiCall: (api) => api.hartmann.getReaders(buildingId),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.hartmannSet(response.data, "readers"));
    },
    errorPath: "data.message",
  });

export const retrieveAuthSettings = (buildingId) =>
  asyncCreator({
    actionId: "retrieveAuthSettings",
    apiCall: (api) => api.hartmann.retrieveAuthSettings(buildingId),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.hartmannSet(response.data.data, "settings"));
    },
    errorPath: "data.message",
  });

export const updateAuthSettings = (buildingId, data) =>
  asyncCreator({
    actionId: "updateAuthSettings",
    apiCall: (api) => api.hartmann.updateAuthSettings(buildingId, data),
    onSuccess: (dispatch) => {
      notifySuccess("Hartmann auth settings updated successfully");
      dispatch(retrieveAuthSettings(buildingId));
    },
    errorPath: "data.message",
  });

export const deleteAuthSettings = (buildingId) =>
  asyncCreator({
    actionId: "deleteAuthSettings",
    apiCall: (api) => api.hartmann.deleteAuthSettings(buildingId),
    onSuccess: (dispatch) => {
      notifySuccess("Hartmann auth settings removed successfully");
      dispatch(retrieveAuthSettings(buildingId));
    },
    errorPath: "data.message",
  });

export const getBuildingHartmannConfig = (buildingId) =>
  asyncCreator({
    actionId: "getBuildingHartmannConfig",
    apiCall: (api) => api.hartmann.getBuildingHartmannConfig(buildingId),
    onSuccess: (dispatch, response) => {
      const { is_sync_complete } = response.data;
      dispatch(Creators.hartmannSet(is_sync_complete, "isSyncComplete"));
      dispatch(Creators.hartmannSet(response.data, "config"));
    },
    errorPath: "data.description",
    isNotifyError: false,
  });

export const updateBuildingSettings = (building_id, data) =>
  asyncCreator({
    actionId: "updateBuildingSettings",
    apiCall: (api) => api.hartmann.updateBuildingSettings(building_id, data),
    onSuccess: (dispatch) => {
      notifySuccess(`Hartmann settings was updated successfully`);
    },
    errorPath: "data.message",
  });

export const setHartmannCommunityPanel = (buildingId) =>
  asyncCreator({
    actionId: "setHartmannCommunityPanel",
    apiCall: (api) => api.hartmann.setHartmannCommunityPanel(buildingId),
    onSuccess: (dispatch) => {
      notifySuccess(`The building was updated successfully`);
      dispatch(getBuildingHartmannConfig(buildingId));
    },
    errorPath: "data.message",
  });

export const getHartmannGroups = (buildingId) =>
  asyncCreator({
    actionId: "getHartmannGroups",
    apiCall: (api) => api.hartmann.getHartmannGroups(buildingId),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.hartmannSet(response.data, "groups"));
    },
    errorPath: "data.message",
  });

export const syncPartition = (buildingId) =>
  asyncCreator({
    actionId: "syncPartition",
    apiCall: (api) => api.hartmann.syncPartition(buildingId),
    onSuccess: (dispatch, response, getState) => {
      const isUnsyncStarting = getState().hartmann.isUnsyncStarting;
      const { is_sync_starting } = response.data;
      if (is_sync_starting || isUnsyncStarting)
        notifySuccess(`The synchronization process was started successfully. It may take a while`);
      else notifyError("The synchronization process is running. Please try again later.");
      dispatch(Creators.hartmannSet(is_sync_starting, "isSyncStarting"));
    },
    errorPath: "data.message",
  });

export const importAllData = (buildingId) =>
  asyncCreator({
    actionId: "importAllData",
    apiCall: (api) => api.hartmann.importAllData(buildingId),
    onSuccess: (dispatch, response, getState) => {
      const isUnsyncStarting = getState().hartmann.isUnsyncStarting;
      const { is_sync_starting } = response.data;
      if (is_sync_starting || isUnsyncStarting)
        notifySuccess(`The synchronization process was started successfully. It may take a while`);
      else notifyError("The synchronization process is running. Please try again later.");
      dispatch(Creators.hartmannSet(is_sync_starting, "isSyncStarting"));
    },
    errorPath: "data.message",
  });

export const unsyncPartition = (buildingId) =>
  asyncCreator({
    actionId: "unsyncPartition",
    apiCall: (api) => api.hartmann.unsyncPartition(buildingId),
    onSuccess: (dispatch, response, getState) => {
      const isSyncStarting = getState().hartmann.isSyncStarting;
      const { is_unsync_starting } = response.data;
      if (is_unsync_starting || isSyncStarting)
        notifySuccess(`The synchronization process was started successfully. It may take a while`);
      else notifyError("The synchronization process is running. Please try again later.");
      dispatch(Creators.hartmannSet(is_unsync_starting, "isUnsyncStarting"));
    },
    errorPath: "data.message",
  });

export const disassociatePartition = (buildingId) =>
  asyncCreator({
    actionId: "disassociatePartition",
    apiCall: (api) => api.hartmann.disassociatePartition(buildingId),
    onSuccess: (dispatch, response) => {
      notifySuccess("The partition successfully disassociated.");
    },
    errorPath: "data.message",
  });

export const createSubscription = (buildingId, doorId, data = {}) =>
  asyncCreator({
    actionId: "createSubscription",
    apiCall: (api) => api.hartmann.createSubscription(buildingId, doorId, data),
    onSuccess: (dispatch, response) => {
      notifySuccess(`Subscription added successfully`);
      console.log(response);
      dispatch(getDoors(buildingId));
    },
    errorPath: "data.message",
  });

export const deleteSubscription = (buildingId, doorId) =>
  asyncCreator({
    actionId: "deleteSubscription",
    apiCall: (api) => api.hartmann.deleteSubscription(buildingId, doorId),
    onSuccess: (dispatch, response) => {
      notifySuccess(`Subscription deleted successfully`);
      dispatch(getDoors(buildingId));
    },
    errorPath: "data.message",
  });

export const clearErrors = () => actions.hartmannSet([], "error");
export const clearUnsync = () => actions.hartmannSet(false, "isUnsyncStarting");
export const clearSync = () => actions.hartmannSet(false, "isSyncStarting");
export const clearSyncComplete = () => actions.hartmannSet(false, "isSyncComplete");
export const clearGroups = () => actions.hartmannSet(null, "groups");
export const clearPanels = () => actions.hartmannSet([], "panels");
export const clearDevices = () => actions.hartmannSet([], "devices");
export const clearDoors = () => actions.hartmannSet([], "doors");
export const setDoors = (doors) => actions.hartmannSet(doors, "doors");

export default hartmannReducer;

import { createReducer, createActions } from "reduxsauce";
import { notifySuccess, asyncCreatorFactory } from "../action-utils";
import { identifyUser } from "utils/fullstory";
import _filter from "lodash/filter";
import { logout } from "./auth";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  residentFetching: ["actionId"],
  residentSuccess: ["actionId"],
  residentFailure: ["error", "actionId"],
  residentSet: ["data", "attr", "actionId"],
  residentReset: null
});

export const actions = Creators;

/* ------------- Initial state ------------- */
const INITIAL_STATE = {
  data: null,
  avatar: null,
  building: null,
  building_logo: null,
  notices: null,
  events: null,
  activities: null,
  unit: null,
  residents: null,
  thumbnail: null,
  mykeys: null,
  mykeysResident: null,
  mykeysVisitor: null,
  fetching: [],
  error: []
};

/* ------------- Reducers ------------- */
export const fetching = (state, { actionId }) => ({
  ...state,
  fetching: [...state.fetching, actionId],
  error: state.error.filter(i => i.actionId !== actionId)
});
export const set = (state, { data, attr, actionId }) => ({
  ...state,
  [attr]: data
});
export const success = (state, { actionId }) => ({
  ...state,
  fetching: state.fetching.filter(i => i !== actionId),
  error: state.error.filter(i => i.actionId !== actionId)
});
export const failure = (state, { error, actionId }) => ({
  ...state,
  fetching: state.fetching.filter(i => i !== actionId),
  error: [...state.error, { error, actionId }]
});
export const reset = state => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */
const residentReducer = createReducer(INITIAL_STATE, {
  [Types.RESIDENT_FETCHING]: fetching,
  [Types.RESIDENT_SUCCESS]: success,
  [Types.RESIDENT_FAILURE]: failure,
  [Types.RESIDENT_SET]: set,
  [Types.RESIDENT_RESET]: reset
});

/* ------------- Async Actions ------------- */
const asyncCreator = asyncCreatorFactory(
  Creators.residentFetching,
  Creators.residentSuccess,
  Creators.residentFailure
);

export const getCurrentResident = () =>
  asyncCreator({
    actionId: "getCurrentResident",
    apiCall: api => api.resident.current(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.residentSet(response.data.data, "data"));
      identifyUser(response.data.data)
      window.userId = response.data.data.id; //used for Error Boundary, deleted at logout
    },
    onError: (dispatch, response) => {
      if (response.status === 401) {
        dispatch(logout());
      }
    },
    isNotifyError: false,
    errorPath: "data.description"
  });

export const updateCurrentResident = data =>
  asyncCreator({
    actionId: "updateCurrentResident",
    apiCall: api => api.resident.updateCurrent(data),
    onSuccess: (dispatch, response) => {
      notifySuccess("Your account updated successfully");
      dispatch(Creators.residentSet(response.data.data, "data"));
    },
    errorPath: "data.message"
  });

export const uploadProfilePic = data =>
  asyncCreator({
    actionId: "uploadProfilePic",
    apiCall: api => api.resident.uploadProfilePic(data),
    onSuccess: (dispatch, response) => {
      notifySuccess("Your profile picture was uploaded");
      dispatch(Creators.residentSet(response.data.data.s3_url, "avatar"));
    },
    errorPath: "data.description"
  });

export const deleteProfilePic = () =>
  asyncCreator({
    actionId: "deleteProfilePic",
    apiCall: api => api.resident.deleteProfilePic(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.residentSet(null, "avatar"));
    },
    errorPath: "data.description"
  });

export const sendVerificationEmail = (data = {}) =>
  asyncCreator({
    actionId: "sendVerificationEmail",
    apiCall: api => api.resident.sendVerificationEmail(data),
    onSuccess: (dispatch, response) => {
      notifySuccess("We've sent a verification email. Please check your spam folder too");
    },
    errorPath: "data.description"
  });

export const getCurrentUnit = () =>
  asyncCreator({
    actionId: "getCurrentUnit",
    apiCall: api => api.resident.getCurrentUnit(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.residentSet(response.data.data, "unit"));
    },
    errorPath: "data.description"
  });

export const getProfilePic = () =>
  asyncCreator({
    actionId: "getProfilePic",
    apiCall: api => api.resident.getProfilePic(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.residentSet(response.data, "avatar"));
    },
    isNotifyError: false,
    errorPath: "data.description"
  });

export const getCurrentBuilding = () =>
  asyncCreator({
    actionId: "getCurrentBuilding",
    apiCall: api => api.building.getCurrent(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.residentSet(response.data.data, "building"));
    },
    errorPath: "data.description",
    isNotifyError: false,
  });

export const getCurrentBuildingNotices = () =>
  asyncCreator({
    actionId: "getCurrentBuildingNotices",
    apiCall: api => api.building.getNotices(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.residentSet(response.data.data, "notices"));
    },
    errorPath: "data.description",
    isNotifyError: false,
  });

export const getCurrentBuildingEvents = () =>
  asyncCreator({
    actionId: "getCurrentBuildingEvents",
    apiCall: api => api.building.getEvents(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.residentSet(response.data.data, "events"));
    },
    errorPath: "data.description",
    isNotifyError: false,
  });

export const getCurrentBuildingPicture = id =>
  asyncCreator({
    actionId: "getCurrentBuildingPicture",
    apiCall: api => api.building.getPicture(id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.residentSet(response.data, "building_logo"));
    },
    errorPath: "data.description"
  });

export const getActivities = () =>
  asyncCreator({
    actionId: "getActivities",
    apiCall: api => api.account.getActivities(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.residentSet(response.data.data || [], "activities"));
    },
    errorPath: "data.description"
  });

export const getThumbnailImage = () =>
  asyncCreator({
    actionId: "getThumbnailImage",
    apiCall: api => api.account.getThumbnailImage(),
    onSuccess: (dispatch, response, getState) => {
      dispatch(Creators.residentSet(response.data, "thumbnail"));
    },
    isNotifyError: false,
    errorPath: "data.description"
  });

export const uploadThumbnailImage = data =>
  asyncCreator({
    actionId: "uploadThumbnailImage",
    apiCall: api => api.account.uploadThumbnailImage(data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Image uploaded successfully");
      dispatch(Creators.residentSet(response.data.data.s3_url, "thumbnail"));
    },
    errorPath: "data.description"
  });

export const deleteThumbnailImage = () =>
  asyncCreator({
    actionId: "deleteThumbnailImage",
    apiCall: api => api.account.deleteThumbnailImage(),
    onSuccess: (dispatch, response, getState) => {
      dispatch(Creators.residentSet(null, "thumbnail"));
    },
    errorPath: "data.description"
  });

export const getResidents = (withProfilePic = true) =>
  asyncCreator({
    actionId: "getResidents",
    apiCall: api => api.account.getResidents(),
    onSuccess: (dispatch, response, getState) => {
      dispatch(Creators.residentSet(response.data.data, "residents"));
      if (withProfilePic) response.data.data.forEach(i => dispatch(getSecondaryPic(i.id)));
    },
    errorPath: "data.description"
  });

export const getSecondaryPic = id =>
  asyncCreator({
    actionId: "getResidents",
    apiCall: api => api.resident.getSecondaryPic(id),
    onSuccess: (dispatch, response, getState) => {
      const residents = getState().resident.residents;
      const profile_pic = response.data;
      const newResidents = residents.map(i => i.id === id ? {...i, profile_pic} : i)
      dispatch(Creators.residentSet(newResidents, "residents"));
    },
    isNotifyError: false,
    errorPath: "data.description"
  });

export const deleteResident = id =>
  asyncCreator({
    actionId: "deleteResident",
    apiCall: api => api.resident.deleteResident(id),
    onSuccess: (dispatch, response, getState) => {
      const residents = getState().resident.residents;
      const newResidents = _filter(residents, i => i.id !== id);
      dispatch(Creators.residentSet(newResidents, "residents"));
      notifySuccess("Account User deleted successfully");
    },
    errorPath: "data.description"
  });

export const addResident = data =>
  asyncCreator({
    actionId: "addResident",
    apiCall: api => api.resident.addResident(data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Account User added successfully");
      dispatch(getResidents());
    },
    errorPath: "data.message"
  });

export const managementAddResident = (unit_id, data) =>
  asyncCreator({
    actionId: "managementAddResident",
    apiCall: api => api.resident.managementAddResident(unit_id, data),
    onSuccess: () => {
      notifySuccess("Account User added successfully");
    },
    errorPath: "data.message"
  });



export const requestAdditionalMyKeys = () =>
  asyncCreator({
    actionId: "requestAdditionalMyKeys",
    apiCall: api => api.resident.requestAdditionalMyKeys(),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Additional MyKeys requested");
    },
    errorPath: "data.description"
  });

export const getMyKeys = (withProfilePic = true) =>
  asyncCreator({
    actionId: "getMyKeys",
    apiCall: api => api.account.myKeys(),
    onSuccess: (dispatch, response, getState) => {
      dispatch(Creators.residentSet(response.data.data, "mykeys"));
      if (withProfilePic) response.data.data.forEach(i => dispatch(getMyKeyPic(i.id, "mykeys")));
    },
    errorPath: "data.description"
  });

export const getMyKeysResident = (withProfilePic = true) =>
  asyncCreator({
    actionId: "getMyKeysResident",
    apiCall: api => api.account.myKeysResident(),
    onSuccess: (dispatch, response, getState) => {
      dispatch(Creators.residentSet(response.data.data, "mykeysResident"));
      if (withProfilePic) response.data.data.forEach(i => dispatch(getMyKeyPic(i.id, "mykeysResident")));
    },
    errorPath: "data.description"
  });

export const getMyKeysVisitor = () =>
  asyncCreator({
    actionId: "getMyKeysVisitor",
    apiCall: api => api.account.myKeysVisitor(),
    onSuccess: (dispatch, response, getState) => {
      dispatch(Creators.residentSet(response.data.data, "mykeysVisitor"));
    },
    errorPath: "data.description"
  });

export const getMyKeyPic = (id, addToAttr) =>
  asyncCreator({
    actionId: "getMyKeyPic",
    apiCall: api => api.account.getMyKeyPic(id),
    onSuccess: (dispatch, response, getState) => {
      if(addToAttr){
        const list = getState().resident[addToAttr];
        const profile_pic = response.data;
        const newList = list.map(i => i.id === id ? {...i, profile_pic} : i)
        dispatch(Creators.residentSet(newList, addToAttr));
      }
    },
    errorPath: "data.description"
  });

export const deleteMyKey = (id, fetchAction, name) =>
  asyncCreator({
    actionId: "deleteMyKey",
    apiCall: api => api.resident.deleteMyKey(id),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess(`${name} deleted successfully`);
      if(fetchAction) dispatch(fetchAction());
    },
    errorPath: "data.description"
  });

export const updateMyKey = (id, fetchAction, data, name) =>
  asyncCreator({
    actionId: "updateMyKey",
    apiCall: api => api.resident.updateMyKey(id, data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess(`${name} updated successfully`);
      if(fetchAction) dispatch(fetchAction());
    },
    errorPath: "data.description"
  });
export const resetMyKey = (id, fetchAction) =>
  asyncCreator({
    actionId: "resetMyKey",
    apiCall: api => api.account.resetMyKeyUsers(id),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("MyKey reset successfully");
      if(fetchAction) dispatch(fetchAction());
    },
    errorPath: "data.description"
  });

export const createSendDigitalMykey = data =>
  asyncCreator({
    actionId: "createSendDigitalMykey",
    apiCall: api => api.resident.createSendDigitalMykey(data),
    onSuccess: (dispatch) => {
      notifySuccess("Visitor MyKey sent successfully");
      dispatch(getMyKeysVisitor());
    },
    errorPath: "data.message"
  });

export default residentReducer;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import PageForm from "components/ui/pageForm/PageForm";
import queryString from "query-string";
import { Form, Field } from "react-final-form";
import { forgotPasswordAnswer } from "redux/reducers/reset";
import { StandardField } from "components/ui/forms";
import { validators, composeValidators } from "utils/forms";
import { FORM_ERROR } from "final-form";
import { connect } from "react-redux";

class DisplaySecurityQuestion extends Component {
  onSubmit = async values => {
    try {
      const { dispatch, history } = this.props
      await dispatch(forgotPasswordAnswer(values));
      history.push(`/reset_password?username=${values.username}`);
    } catch (error) {
      return { [FORM_ERROR]: "Your answer is incorrect. Please try again." };
    }
  };
  renderForm = form => {
    return (
      <form onSubmit={form.handleSubmit}>
        {form.submitError && <p className="text-danger">{form.submitError}</p>}
        <Field
          label={form.initialValues.question}
          component={StandardField}
          placeholder="Type your answer here"
          name="security_answer"
          validate={composeValidators(validators.required)}
        />
        <button disabled={form.submitting} className="btn btn-sm btn-primary btn-block">Submit answer</button>
      </form>
    );
  };
  render() {
    const { search } = this.props.location;
    const query = queryString.parse(search);
    return (
      <PageForm width="400px">
        <div className="text-center">
          <h6>Forgot your password? No problem</h6>
          <p>Your Security Question is:</p>
        </div>

        <React.Fragment>
            <hr />
            <Form
              onSubmit={this.onSubmit}
              render={this.renderForm}
              initialValues={{ 
                username: query.username, 
                question: query.question 
              }}
            />
        </React.Fragment>

        <div className="text-center">
          <Link to={`/contact?username=${query.username}`} className="d-block text-center m-t-20">
            <small>Forgot security answer?</small>
          </Link>
          <Link to="/login" className="d-block text-center m-t-20">
            <small>Return to login</small>
          </Link>
        </div>
      </PageForm>
    );
  }
}

export default connect()(DisplaySecurityQuestion);

import { createActions, createReducer } from "reduxsauce";
import { asyncCreatorFactory, notifyError, notifySuccess } from "../action-utils";
import _pick from "lodash/pick";
import { SSelectedBuilding } from "redux/selectors/navigation";
import _get from "lodash/get";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  pdkFetching: ["actionId"],
  pdkSuccess: ["actionId"],
  pdkFailure: ["error", "actionId"],
  pdkSet: ["data", "attr", "actionId"],
  pdkReset: null,
});
export const actions = Creators;

/* ------------- Initial state ------------- */
const INITIAL_STATE = {
  features: undefined,
  nodeSettings: [],
  config: undefined,
  user: undefined,
  panels: undefined,
  groups: null,
  isRevokeStarting: false,
  isSyncStarting: false,
  isSyncComplete: false,
  syncedCounts: {},
  startAutoSync: false,
  newNodeSerial: null,
  doors: [],
  devices: [],
  fetching: [],
  error: [],
  availablePanels: [],
};

/* ------------- Reducers ------------- */
export const fetching = (state, { actionId }) => ({
  ...state,
  fetching: [...state.fetching, actionId],
  error: state.error.filter((i) => i.actionId !== actionId),
});
export const set = (state, { data, attr, actionId }) => ({
  ...state,
  [attr]: data,
});
export const success = (state, { actionId }) => ({
  ...state,
  fetching: state.fetching.filter((i) => i !== actionId),
  error: state.error.filter((i) => i.actionId !== actionId),
});
export const failure = (state, { error, actionId }) => ({
  ...state,
  fetching: state.fetching.filter((i) => i !== actionId),
  error: [...state.error, { error, actionId }],
});
export const reset = (state, { exclude = [] }) => ({
  ...INITIAL_STATE,
  ..._pick(state, exclude),
});

/* ------------- Hookup Reducers To Types ------------- */
const pdkReducer = createReducer(INITIAL_STATE, {
  [Types.PDK_FETCHING]: fetching,
  [Types.PDK_SUCCESS]: success,
  [Types.PDK_FAILURE]: failure,
  [Types.PDK_SET]: set,
  [Types.PDK_RESET]: reset,
});

/* ------------- Async Actions ------------- */
const asyncCreator = asyncCreatorFactory(Creators.pdkFetching, Creators.pdkSuccess, Creators.pdkFailure);

export const whoami = (buildingId) =>
  asyncCreator({
    actionId: "whoami",
    apiCall: (api) => api.pdk.whoami(buildingId),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.pdkSet(response.data, "user"));
    },
    onError: (dispatch) => {
      dispatch(Creators.pdkSet(undefined, "user"));
    },
    errorPath: "data.message",
  });

export const retrieveAuthSettings = (buildingId) =>
  asyncCreator({
    actionId: "retrieveAuthSettings",
    apiCall: (api) => api.pdk.retrieveAuthSettings(buildingId),
    onSuccess: (dispatch, response, getState) => {
      const hasValidSettings = _get(response.data, "has_valid_settings", false);
      const nodeSettings = _get(response.data, "node_settings", []);
      const syncedCounts = getState().pdk.syncedCounts;
      let newCounts = { ...syncedCounts };
      nodeSettings.forEach((setting) => {
        newCounts = { ...newCounts, [setting.node_serial]: setting.synced_count };
      });
      dispatch(Creators.pdkSet(newCounts, "syncedCounts"));
      dispatch(Creators.pdkSet(hasValidSettings, "hasValidSettings"));
      dispatch(Creators.pdkSet(nodeSettings, "nodeSettings"));
    },
    errorPath: "data.message",
  });

export const getSyncedCountByGroup = (buildingId) =>
  asyncCreator({
    actionId: "getSyncedCountByGroup",
    apiCall: (api) => api.pdk.getSyncedCountByGroup(buildingId),
    onSuccess: (dispatch, response, getState) => {
      const { is_sync_complete } = response.data;
      const { synced_counts } = response.data;
      const { synced_count } = response.data;
      const currentConfig = _get(getState(), "config", {});
      const newConfig = {
        ...currentConfig,
        synced_count,
      };
      dispatch(Creators.pdkSet(is_sync_complete, "isSyncComplete"));
      dispatch(Creators.pdkSet(synced_counts, "syncedCounts"));
      dispatch(Creators.pdkSet(newConfig, "config"));
    },
    errorPath: "data.message",
  });

export const updateAuthSettings = (buildingId, data) =>
  asyncCreator({
    actionId: "updateAuthSettings",
    apiCall: (api) => api.pdk.updateAuthSettings(buildingId, data),
    onSuccess: (dispatch, response) => {
      const startAutoSync = _get(response, "data.start_auto_sync", false);
      const newNodeSerial = _get(response, "data.new_node_serial", false);
      dispatch(Creators.pdkSet(startAutoSync, "startAutoSync"));
      dispatch(Creators.pdkSet(newNodeSerial, "newNodeSerial"));
      notifySuccess("PDK Auth settings updated successfully");
      dispatch(retrieveAuthSettings(buildingId));
    },
    errorPath: "data.message",
  });

export const deleteAuthSettings = (buildingId, settingsId) =>
  asyncCreator({
    actionId: "deleteAuthSettings",
    apiCall: (api) => api.pdk.deleteAuthSettings(buildingId, settingsId),
    onSuccess: (dispatch) => {
      notifySuccess("PDK Auth settings removed successfully");
      dispatch(retrieveAuthSettings(buildingId));
    },
    errorPath: "data.message",
  });

export const disassociateNode = (buildingId, settingsId) =>
  asyncCreator({
    actionId: "disassociateNode",
    apiCall: (api) => api.pdk.disassociateNode(buildingId, settingsId),
    onSuccess: (dispatch) => {
      notifySuccess("PDK Node successfully disassociated");
      dispatch(retrieveAuthSettings(buildingId));
    },
    errorPath: "data.message",
  });

export const getBuildingPdkConfig = (buildingId) =>
  asyncCreator({
    actionId: "getBuildingPdkConfig",
    apiCall: (api) => api.pdk.getBuildingPdkConfig(buildingId),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.pdkSet(response.data, "config"));
    },
    errorPath: "data.description",
    isNotifyError: false,
  });

export const updateBuildingSettings = (building_id, data) =>
  asyncCreator({
    actionId: "updateBuildingSettings",
    apiCall: (api) => api.pdk.updateBuildingSettings(building_id, data),
    onSuccess: (dispatch) => {
      notifySuccess(`Pdk settings was updated successfully`);
    },
    errorPath: "data.message",
  });

export const setPdkCommunityPanel = (buildingId) =>
  asyncCreator({
    actionId: "setPdkCommunityPanel",
    apiCall: (api) => api.pdk.setPdkCommunityPanel(buildingId),
    onSuccess: (dispatch) => {
      notifySuccess(`The building was updated successfully`);
      dispatch(getBuildingPdkConfig(buildingId));
    },
    errorPath: "data.message",
  });

export const getAllPanels = (buildingId) =>
  asyncCreator({
    actionId: "getAllPanels",
    apiCall: (api) => api.pdk.getAllPanels(buildingId),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.pdkSet(response.data, "panels"));
    },
    errorPath: "data.message",
  });

export const getPdkGroups = (buildingId) =>
  asyncCreator({
    actionId: "getPdkGroups",
    apiCall: (api) => api.pdk.getPdkGroups(buildingId),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.pdkSet(response.data, "groups"));
    },
    errorPath: "data.message",
  });

export const syncBuilding = (buildingId, panelId) =>
  asyncCreator({
    actionId: "syncBuilding",
    apiCall: (api) => api.pdk.syncBuilding(buildingId, panelId),
    onSuccess: (dispatch, response, getState) => {
      const isRevokeStarting = getState().pdk.isRevokeStarting;
      const { is_sync_starting } = response.data;
      if (is_sync_starting || isRevokeStarting)
        notifySuccess(`The synchronization process was started successfully. It may take a while`);
      else notifyError("The synchronization process is running. Please try again later.");
      dispatch(Creators.pdkSet(is_sync_starting, "isSyncStarting"));
    },
    errorPath: "data.message",
  });

export const importAllData = (buildingId) =>
  asyncCreator({
    actionId: "importAllData",
    apiCall: (api) => api.pdk.importAllData(buildingId),
    onSuccess: (dispatch, response, getState) => {
      const isRevokeStarting = getState().pdk.isRevokeStarting;
      const { is_sync_starting } = response.data;
      if (is_sync_starting || isRevokeStarting)
        notifySuccess(`The synchronization process was started successfully. It may take a while`);
      else notifyError("The synchronization process is running. Please try again later.");
      dispatch(Creators.pdkSet(is_sync_starting, "isSyncStarting"));
    },
    errorPath: "data.message",
  });

export const unsyncNode = (buildingId, settingsId) =>
  asyncCreator({
    actionId: "unsyncNode",
    apiCall: (api) => api.pdk.unsyncNode(buildingId, settingsId),
    onSuccess: (dispatch, response, getState) => {
      const isSyncStarting = getState().pdk.isSyncStarting;
      const { is_revoke_starting } = response.data;
      if (is_revoke_starting || isSyncStarting)
        notifySuccess(`The synchronization process was started successfully. It may take a while`);
      else notifyError("The synchronization process is running. Please try again later.");
      dispatch(Creators.pdkSet(is_revoke_starting, "isRevokeStarting"));
    },
    errorPath: "data.message",
  });

export const getPdkDoors = (building_id) =>
  asyncCreator({
    actionId: "getPdkDoors",
    apiCall: (api) => api.pdk.getPdkDoors(building_id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.pdkSet(response.data.data, "doors"));
    },
    errorPath: "data.message",
  });

export const getPdkDevices = (building_id) =>
  asyncCreator({
    actionId: "getPdkDevices",
    apiCall: (api) => api.pdk.getPdkDevices(building_id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.pdkSet(response.data, "devices"));
    },
    errorPath: "data.message",
  });

export const addPdkDoor = (buildingId, deviceId, data = {}) =>
  asyncCreator({
    actionId: "addPdkDoor",
    apiCall: (api) => api.pdk.addPdkDoor(buildingId, deviceId, data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess(`The device was created successfully`);
      const selectedBuildingId = SSelectedBuilding(getState());
      dispatch(getPdkDevices(selectedBuildingId));
      dispatch(getPdkDoors(selectedBuildingId));
    },
    errorPath: "data.message",
  });

export const deletePdkDoor = (buildingId, doorId) =>
  asyncCreator({
    actionId: "deletePdkDoor",
    apiCall: (api) => api.pdk.deletePdkDoor(buildingId, doorId),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess(`The device was deleted successfully`);
      const selectedBuildingId = SSelectedBuilding(getState());
      dispatch(getPdkDoors(selectedBuildingId));
      dispatch(getPdkDevices(selectedBuildingId));
    },
    errorPath: "data.message",
  });

export const createPdkSubscription = (buildingId, doorId, data = {}) =>
  asyncCreator({
    actionId: "createPdkSubscription",
    apiCall: (api) => api.pdk.createPdkSubscription(buildingId, doorId, data),
    onSuccess: (dispatch, response, getState) => {
      if (_get(response, "data.subscribe")) {
        notifySuccess(`Subscription added successfully`);
        const selectedBuildingId = SSelectedBuilding(getState());
        dispatch(getPdkDoors(selectedBuildingId));
      } else notifyError(`Subscription added unsuccessfully`);
    },
    errorPath: "data.message",
  });

export const deletePdkSubscription = (selectedBuilding, doorId) =>
  asyncCreator({
    actionId: "deletePdkSubscription",
    apiCall: (api) => api.pdk.deletePdkSubscription(selectedBuilding, doorId),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess(`Subscription deleted successfully`);
      const selectedBuildingId = SSelectedBuilding(getState());
      dispatch(getPdkDoors(selectedBuildingId));
    },
    errorPath: "data.message",
  });

export const getAvailablePanels = (params = {}) =>
  asyncCreator({
    actionId: "getAvailablePanels",
    apiCall: (api) => api.pdk.getAvailablePanels(params),
    onSuccess: (dispatch, response, getState) => {
      const { data } = response.data;

      dispatch(Creators.pdkSet(data, "availablePanels"));
    },
    errorPath: "data.message",
  });

export const clearErrors = () => actions.pdkSet([], "error");
export const clearRevoke = () => actions.pdkSet(false, "isRevokeStarting");
export const clearSync = () => actions.pdkSet(false, "isSyncStarting");
export const clearSyncComplete = () => actions.pdkSet(false, "isSyncComplete");
export const clearGroups = () => actions.pdkSet(null, "groups");
export const clearDevices = () => actions.pdkSet([], "devices");
export const clearAutoSync = () => actions.pdkSet(false, "startAutoSync");
export const clearNewNodeSerial = () => actions.pdkSet(false, "newNodeSerial");
export const clearConfig = () => actions.pdkSet(undefined, "config");
export const setDoors = (doors) => actions.pdkSet(doors, "doors");

export default pdkReducer;

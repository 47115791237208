import React, { Component } from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { Form, Field } from "react-final-form";
import { validators, composeValidators } from "utils/forms";
import { FORM_ERROR } from "final-form";
import { connect } from "react-redux";
import _get from "lodash/get";

import { forgotPasswordReset } from "redux/reducers/reset";
import { resetPwByLink } from "redux/reducers/registration";

import PageForm from "components/ui/pageForm/PageForm";
import { StandardField } from "components/ui/forms";
import { isMobilePhone } from 'utils/utils';

const mobileLink = 'mvi://';
class ResetPassword extends Component {
  state = {
    isSuccess: false
  };


  componentDidMount() {
    const { search } = this.props.location;
    const query = queryString.parse(search);
    if (isMobilePhone()) window.location.href = mobileLink  + `reset_password?token=${query.token}`;

  }

  onSubmit = async ({ security_answer, token, username, ...otherValues }) => {
    try {
      const fn = security_answer 
        ? () => forgotPasswordReset({ security_answer, username, ...otherValues })
        : () => resetPwByLink({ token, data: otherValues });
      await this.props.dispatch(fn())
      this.setState({ isSuccess: true });
    } catch (error) {
      return { [FORM_ERROR]: error.message };
    }
  };

  renderForm = form => {
    return (
      <form onSubmit={form.handleSubmit}>
        {form.submitError && <p className="text-danger">{form.submitError}</p>}
        <Field
          component={StandardField}
          label="Password"
          name="new_password"
          type="password"
          placeholder="Password"
          validate={composeValidators(validators.required, validators.minLength(8), validators.password)}
          extraContent={
            <small   className="form-text text-muted">
              Your password must contain at least one number and one letter, and at least 8 or more
              characters.
            </small>
          }
        />

        <Field
          component={StandardField}
          label="Confirm Password"
          name="password2"
          type="password"
          placeholder="Confirm Password"
          validate={composeValidators(
            validators.required,
            validators.equalTo(form.values.new_password, "password")
          )}
        />
        <button className="btn btn-sm btn-primary btn-block">Reset Password</button>
      </form>
    );
  };

  renderPageContent = ({ username, security_answer, token }={}) => {
    return !this.state.isSuccess ? (
      <Form
        onSubmit={this.onSubmit}
        render={this.renderForm}
        initialValues={{ username, security_answer, token }}
      />
    ) : (
      <h6>Your password has been successfully reset!</h6>
    );
  };

  render() {
    const security_answer = _get(this, "props.reset.security_answer");
    const { search } = this.props.location;
    const query = queryString.parse(search);
    return (
      <PageForm width="400px">
        <div className="text-center">
          <h6>Reset your password</h6>
        </div>
        <hr />
        {security_answer || query.token ? (
          this.renderPageContent({ 
            security_answer, 
            username: query.username,
            token: query.token
          })
        ) : (
          <h6 className="text-center">You need to answer the security question to change your password</h6>
        )}
        <div className="text-center">
          <Link to="/login" className="d-block text-center m-t-20">
            <small>Return to login</small>
          </Link>
        </div>
      </PageForm>
    );
  }
}

export default connect(({ reset }) => ({ reset }))(ResetPassword);

import React, { Component } from "react";
import PropTypes from "prop-types";
import Quagga from "quagga";
import styled from "styled-components";
import "webrtc-adapter/src/js/adapter_core.js";

const Styles = styled.div`
  .barCodeCamera canvas.drawingBuffer {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    pointer-events:none;
  }

  .barCodeCamera > video {
    max-width: 100%;
    width: 100%;
  }
`;

class BarCodeScanner extends Component {
  static propTypes = {
    onDetected: PropTypes.func
  };
  componentWillUnmount() {
    Quagga.stop();
  }
  onDetected = result => {
    var code = result.codeResult.code;
    if (this.props.onDetected) {
      this.props.onDetected(code, result);
    }
  };
  componentDidMount() {
    Quagga.init(
      {
        locate: false,
        debug: false,
        inputStream: {
          name: "Live",
          type: "LiveStream",
          target: this.camera
        },
        decoder: {
          readers: ["code_128_reader"]
        }
      },
      function(err) {
        if (err) {
          console.log(err);
          return;
        }
        Quagga.start();
      }
    );

    Quagga.onDetected(this.onDetected);
  }

  render() {
    return (
      <Styles>
        <div className="barCodeCamera" ref={ref => (this.camera = ref)} />
      </Styles>
    );
  }
}

export default BarCodeScanner;

import React, { Component } from "react";
import PropTypes from "prop-types";

class MenuBadge extends Component {
  static propTypes = {
    text: PropTypes.node,
    style: PropTypes.object,
    type: PropTypes.oneOf(['filled', 'outlined'])
  };
  static defaultProps = {
    style: {},
    type: "filled"
  }
  render() {
    const classes = this.props.type === "filled" ? "c-white bg-pink" : "c-pink bg-white badge-outlined-pink"
    return (
      <span
        className={`d-flex align-items-center justify-content-center badge-circle ${classes}`}
        style={{
          position: "absolute",
          top: "7px",
          left: "24px",
          ...this.props.style
        }}
      >
        {this.props.text}
      </span>
    );
  }
}

export default MenuBadge;

import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _union from "lodash/union";
import _without from "lodash/without";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import tableUtils from "utils/table";

const FieldStyles = styled.div`
  .clients-box {
    padding: 20px;
    border: 1px solid #cecece;
    background: white;
    border-radius: 5px;
  }
  .react-bs-container-body {
    overflow: auto;
  }
`;

class ClientsField extends Component {
  static propTypes = {
    clients: PropTypes.array
  };

  static defaultProps = {
    clients: []
  };

  onCheckChange = client => e => {
    const { input } = this.props
    const currentValue = input.value;
    if (e.target.checked) {
      const newValue = _union(currentValue, [client.id]);
      input.onChange(newValue);
    } else {
      const newValue = _without(currentValue, client.id);
      input.onChange(newValue);
    }
  };

  handleRowSelect = (row, isSelected, e) => {
    const { input } = this.props
    const currentValue = input.value;
    if (isSelected) {
      const newValue = _union(currentValue, [row.id]);
      input.onChange(newValue);
    } else {
      const newValue = _without(currentValue, row.id);
      input.onChange(newValue);
    }
  };

  handleSelectAll = (isSelected, rows) => {
    const { clients, input } = this.props
    if (isSelected) {
      const newValue = clients.map(i => i.id);
      input.onChange(newValue);
    } else {
      input.onChange([]);
    }
  };
  render() {
    const { input, meta, clients } = this.props;
    const isError = (meta.error && meta.touched) || meta.submitError;
    return (
      <FieldStyles>
        {isError && <small className="text-danger">{meta.error || meta.submitError}</small>}
        <BootstrapTable
          data={clients}
          bordered={false}
          pagination={false}
          search={false}
          condensed={false}
          striped={true}
          tableHeaderClass="table-data"
          tableBodyClass="table-data"
          exportCSV={false}
          height={300}
          version="4"
          selectRow={{
            mode: "checkbox",
            selected: input.value || [],
            onSelect: this.handleRowSelect,
            onSelectAll: this.handleSelectAll
          }}
        >
          <TableHeaderColumn dataField="id" isKey={true} export={false} hidden />
          <TableHeaderColumn
            dataField="name"
            dataSort={true}
            children="Company Name"
            dataFormat={tableUtils.formatDefault()}
            formatExtraData={{ name: "Company Name" }}
          />
          <TableHeaderColumn
            dataField="city"
            dataSort={true}
            children="City"
            headerAlign="left"
            dataFormat={tableUtils.formatDefault(tableUtils.formatPhone)}
            formatExtraData={{ name: "City" }}
          />
          <TableHeaderColumn
            dataField="email"
            dataSort={true}
            children="Email"
            headerAlign="left"
            dataFormat={tableUtils.formatDefault(tableUtils.formatPhone)}
            formatExtraData={{ name: "Email" }}
          />
        </BootstrapTable>
      </FieldStyles>
    );
  }
}

export default ClientsField;

import { combineReducers } from "redux";
import auth from "./reducers/auth";
import resident from "./reducers/resident";
import admin from "./reducers/admin";
import dealer from "./reducers/dealer";
import management from "./reducers/management";
import building from "./reducers/building";
import navigation from "./reducers/navigation";
import account from "./reducers/account";
import registration from "./reducers/registration";
import reset from "./reducers/reset";
import kiosk from "./reducers/kiosk";
import brivo from "./reducers/brivo";
import plan from "./reducers/plan";
import pdk from "./reducers/pdk";
import hartmann from "redux/reducers/hartmann";
import entrata from "redux/reducers/entrata";
import searchSelect from "./reducers/searchSelect";
import layout from "./reducers/layout";

const appReducer = combineReducers({
  auth,
  resident,
  admin,
  dealer,
  management,
  building,
  registration,
  navigation,
  account,
  reset,
  kiosk,
  brivo,
  plan,
  pdk,
  hartmann,
  entrata,
  searchSelect,
  layout,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;

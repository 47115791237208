import React from "react";
import { confirmAlert } from 'react-confirm-alert';
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #444;

    h1 {
        font-size: 22px;
    }

    p {
        font-size: 16px;
    }
`

const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
`

export class CustomAlert extends React.Component {
    render() {
        const { onClose, onApply, heading, title } = this.props
        return (
            <Container>
              <h1>{heading}</h1>
              <p>{title}</p>
              <ButtonGroup>
                  <button 
                    className="btn action-button c-black cancel-button bg-gray"
                    onClick={onClose}
                  >
                    No
                  </button>
                  <button 
                    className="btn action-button c-white submit-button bg-primary-green m-l-10"
                    onClick={() => {
                        onApply && onApply()
                        onClose()
                    }}>
                    Yes
                  </button>
              </ButtonGroup>
            </Container>
        );
    }
}

export const openAlert = ({ 
    onApply, 
    heading="Main Heading", 
    title="Title"
}={}) => {
    confirmAlert({
      customUI: ({ onClose }) => 
        <CustomAlert 
            onClose={onClose} 
            onApply={onApply} 
            heading={heading} 
            title={title} 
        />
    });
}
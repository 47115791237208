import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MaskedTextInput from "react-text-mask";
import BarcodeScanner from "components/ui/barcodeScanner/BarcodeScanner.js";
import bipSoundPath from "assets/bip.mp3";

const NotificationStyles = styled.div`
  padding: 10px;
  border-radius: 5px;
  background-color: #ffffcc;
  text-align: center;
  font-size: 12px;
`

const bip = new Audio(bipSoundPath);

const hasGetUserMedia = () =>
  navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === "function";
const isGetUserMedia = hasGetUserMedia();
// prettier-ignore
export const activationCodeMask = [/\d/, /\d/, /\d/, /\d/,"-",/\d/,/\d/,/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/];

class ActivationCodeField extends Component {
  static propTypes = {
    input: PropTypes.object, //Provided by Final Form
    meta: PropTypes.object, //Provided by Final Form
    errorOnTouch: PropTypes.bool,
    label: PropTypes.node,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    extraContent: PropTypes.node,
    modalImage: PropTypes.string,
  };
  static defaultProps = {
    errorOnTouch: true,
  };
  state = {
    isBarcodeShown: false,
  };
  toggleBarcode = () => {
    this.setState({ isBarcodeShown: !this.state.isBarcodeShown });
  };
  onDetected = (input) => (code, result) => {
    if (code.length === 16) {
      input.onChange(code);
      bip.play();
      this.setState({ isBarcodeShown: false });
    }
  };
  render() {
    const { input, meta, errorOnTouch, ...rest } = this.props;
    const isError = (meta.error && (errorOnTouch ? meta.touched : true)) || meta.submitError;
    const classes = `${isError ? "is-invalid" : ""} ${meta.valid ? "is-valid" : ""}`;
    return (
      <div className="activation-code-field">
        {!this.state.isBarcodeShown && this.props.modalImage ? (
          <div className="d-flex justify-content-center">
            <img className="modal-image" src={this.props.modalImage ? this.props.modalImage : ""} alt="" />
          </div>
        ) : null}
        {this.state.isBarcodeShown && <BarcodeScanner onDetected={this.onDetected(input)} />}
        {rest.label && (
          <label htmlFor={rest.name}>
            {rest.label} {this.props.asterisk && <span className="asterisk">*</span>}
          </label>
        )}
        <div className={`d-flex ${!isGetUserMedia && "flex-column"}`}>
          <div className={`form-group flex-one`}>
            <MaskedTextInput
              mask={activationCodeMask}
              className={`form-control ${classes}`}
              placeholder="Activation code"
              guide={false}
              pattern="[0-9-]*"
              {...input}
              onChange={(e) => {
                input.onChange(e.target.value.replace(/-/g, ""));
              }}
            />
            {isError && <div className="invalid-feedback"> {meta.error || meta.submitError}</div>}
            {rest.extraContent}
          </div>
          <div>
            {isGetUserMedia ? (
              <div style={{ minWidth: 70 }} className="m-l-10">
                <button
                  className="btn btn-light action-button c-white scanner-button bg-blue"
                  type="button"
                  onClick={this.toggleBarcode}
                >
                  {this.state.isBarcodeShown ? (
                    <div className="justify-content-center d-flex flex-column">
                      <i className="material-icons material-icons-sm align-middle">close</i>
                      <span>Stop Scan</span>
                    </div>
                  ) : (
                    <div className="justify-content-center d-flex flex-column">
                      <i className="material-icons material-icons-sm align-middle">photo_camera</i>
                      <span>Scan Code</span>
                    </div>
                  )}
                </button>
              </div>
            ) : (
              <NotificationStyles>
                Please use the Safari browser to scan the activation code.
              </NotificationStyles>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ActivationCodeField;

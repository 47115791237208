import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import _get from "lodash/get";
import { REGISTRATION_TYPES } from "redux/reducers/registration"

export const MANAGER_ROLES = [
  "m_super_admin",
  "building_manager",
  "m_admin",
  "m_building_manager",
  "m_office_employee",
  "m_superintendent"
];

export const DEALER_ROLES = ["dealer", "dealer_admin"];
export const ADMIN_ROLES = ["admin"];
export const SECONDARY_RESIDENT_ROLES = ["resident"]
export const PRIMARY_RESIDENT_ROLES = ["primary_resident"]

export const MANAGER_UP_ROLES = [...MANAGER_ROLES, ...DEALER_ROLES, ...ADMIN_ROLES];
export const DEALER_UP_ROLES = [...DEALER_ROLES, ...ADMIN_ROLES];

/**
 * Beased on a role, si it and ADMIN, DEALER,  MANAGEMENT.. etc
 */
export const getRoleGroup = role => {
  if (MANAGER_ROLES.includes(role)) return "MANAGER_ROLES";
  else if (DEALER_ROLES.includes(role)) return "DEALER_ROLES";
  else if (ADMIN_ROLES.includes(role)) return "ADMIN_ROLES";
  else if (SECONDARY_RESIDENT_ROLES.includes(role)) return "SECONDARY_RESIDENT_ROLES";
  else if (PRIMARY_RESIDENT_ROLES.includes(role)) return "PRIMARY_RESIDENT_ROLES";
};

export const getRegistrationType = role => {
  const roleGroup = getRoleGroup(role)
  switch (roleGroup) {
    case "PRIMARY_RESIDENT_ROLES":
      return REGISTRATION_TYPES.PRIMARY_RESIDENT
    case "SECONDARY_RESIDENT_ROLES":
      return REGISTRATION_TYPES.SECONDARY_RESIDENT
    case "MANAGER_ROLES":
      return REGISTRATION_TYPES.MANAGEMENT
    case "DEALER_ROLES":
      return REGISTRATION_TYPES.DEALER
    case "ADMIN_ROLES":
      return REGISTRATION_TYPES.ADMIN
    default:
      return null
  }
}

export const isAdmin = (role) => getRoleGroup(role) === "ADMIN_ROLES"
export const isManager = (role) => getRoleGroup(role) === "MANAGER_ROLES"
export const isDealer = (role) => getRoleGroup(role) === "DEALER_ROLES"
export const isDealerOrManager = (role) => ["MANAGER_ROLES", "DEALER_ROLES"].includes(getRoleGroup(role))
export const isManagerUpRole = (role) => ["MANAGER_ROLES", "DEALER_ROLES", "ADMIN_ROLES"].includes(getRoleGroup(role))
export const isDealerUpRole = (role) => ["DEALER_ROLES", "ADMIN_ROLES"].includes(getRoleGroup(role))
export const isSecondaryResident = (role) => getRoleGroup(role) === "SECONDARY_RESIDENT_ROLES"
export const isPrimaryResident = (role) => getRoleGroup(role) === "PRIMARY_RESIDENT_ROLES"
export const isResidentialRole = (role) => ["SECONDARY_RESIDENT_ROLES", "PRIMARY_RESIDENT_ROLES"].includes(getRoleGroup(role))

// export const 

const AuthorizedRole = ({ children, roles }) => {
  const role = useSelector(state => _get(state, "resident.data.role.name"))

  return roles.includes(role) ? children : null;
}

AuthorizedRole.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.arrayOf(PropTypes.string)
};

AuthorizedRole.defaultProps = {
  roles: []
};

export const useRole = () => {
  const role = useSelector(state => _get(state, "resident.data.role.name"))
  return role
}

export default AuthorizedRole

import { createReducer, createActions } from "reduxsauce";
import _get from "lodash/get";
import { actions as adminActions } from "./admin";
import { actions as dealerActions } from "./dealer";
import { actions as managementActions } from "./management";
import { actions as buildingActions } from "./building";
import { actions as residentActions } from "./resident";
import { resetNavigation } from "./navigation";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  authFetching: null,
  authSuccess: ["token"],
  authFailure: ["error"],
  authReset: null,
});

/* ------------- Async Actions ------------- */
export const login = (username, password) => {
  return async (dispatch, getState, api) => {
    dispatch(Creators.authFetching());

    try {
      const response = await api.auth.login(username, password);
      if (response.ok) {
        const token = response.data.access_token;
        api.auth.setToken(token);
        localStorage.setItem("jwtToken", token);
        dispatch(Creators.authSuccess(token));
      } else {
        const error = _get(response, "data.message", response.problem);
        dispatch(Creators.authFailure(error));
      }
    } catch (error) {
      dispatch(Creators.authFailure(error));
    }
  };
};

export const logout = () => {
  return (dispatch, getState, api) => {
    api.auth.clearToken();
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("ManagementBuildings");
    localStorage.removeItem("ManagementCompanies");
    dispatch(Creators.authReset());

    // Reset all reducers
    dispatch(adminActions.adminReset());
    dispatch(buildingActions.buildingReset());
    dispatch(dealerActions.dealerReset());
    dispatch(managementActions.managementReset());
    dispatch(residentActions.residentReset());
    dispatch(resetNavigation());

    delete window.userId; //used for Error Boundary
  };
};

/* ------------- Initial state ------------- */
const INITIAL_STATE = {
  token: null,
  fetching: false,
  error: null,
};

const INITIAL_STATE_WITH_TOKEN = {
  ...INITIAL_STATE,
  token: localStorage.getItem("jwtToken") || null,
};

/* ------------- Reducers ------------- */
export const fetching = (state) => ({ ...state, fetching: true });
export const success = (state, { token }) => ({
  ...state,
  fetching: false,
  error: null,
  token,
});
export const failure = (state, { error }) => ({
  ...state,
  fetching: false,
  error,
});

export const reset = (state) => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */
const authReducer = createReducer(INITIAL_STATE_WITH_TOKEN, {
  [Types.AUTH_FETCHING]: fetching,
  [Types.AUTH_SUCCESS]: success,
  [Types.AUTH_FAILURE]: failure,
  [Types.AUTH_RESET]: reset,
});

export default authReducer;

import _slice from "lodash/slice";

import { isManager, isDealer } from "components/ui/authorized/AuthorizedRole"

export const DEALER_PATH_KEY = "dealers"
export const MANAGER_PATH_KEY = "management_companies"
export const BUILDING_PATH_KEY = "buildings"

export const getSequenceKeys = (role) => {
  const ADMIN_SEQUENCE_KEYS = [DEALER_PATH_KEY, MANAGER_PATH_KEY, BUILDING_PATH_KEY]
  const DEALER_SEQUENCE_KEYS = [MANAGER_PATH_KEY, BUILDING_PATH_KEY]
  const MANAGER_SEQUENCE_KEYS = [BUILDING_PATH_KEY]

  if (isDealer(role)) {
    return DEALER_SEQUENCE_KEYS
  }

  if(isManager(role)) {
    return MANAGER_SEQUENCE_KEYS
  }

  return ADMIN_SEQUENCE_KEYS
}

export const getBreadcrumbObject = (path) => {
  const pathArr = path.split("/");
  const params = _slice(pathArr, pathArr.length-1, pathArr.length).join(" ");
  const title = params.split("_").join(" ")

  return { path, pathArr, title }
}

export const getNewBreadcrumbs = (newBreadcrumb, mainSequenceKeys) => {
  const newBreadcrumbs = []
  const pathKey = newBreadcrumb.pathArr[2]

  const index = mainSequenceKeys.findIndex(key => key === pathKey)

  if (index !== -1) {
    const keysToInsert = mainSequenceKeys.slice(0, index + 1)

    keysToInsert.forEach(key => {
      const breadcrumb = getBreadcrumbObject(`/app/${key}`)
      newBreadcrumbs.push(breadcrumb)
    })

    const isEqual = newBreadcrumbs[newBreadcrumbs.length - 1].path !== newBreadcrumb.path

    if (isEqual) {
      newBreadcrumbs.push(newBreadcrumb)
    }

  } else {
    newBreadcrumbs.push(newBreadcrumb)
  }

  return newBreadcrumbs
}
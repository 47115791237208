import { createSelector } from "reselect";
import _uniq from "lodash/uniq";
import _orderBy from "lodash/orderBy";
import _get from "lodash/get";
import _filter from "lodash/filter";
import _without from "lodash/without";
import {
  NOPLAN_SUBSCRIPTION_PLAN,
  LIFETIME_SUBSCRIPTION_PLAN,
  UNSUBSCRIBED_SUBSCRIPTION_PLAN
} from "components/ui/subscribed/SubscribedPlan";
import { sortMixedString } from "utils/utils";
import _first from "lodash/first";

/**
 * Get a uniq array of options based on a path from state and a key from object to filter.
 * Mostly used to get the options for the filters of tables
 * @param {String} path The path from the state to apply the filters
 * @param {String} key The key from the object we want to filter from
 */
export const getFilterOptions = (path, key) =>
  createSelector([state => _get(state, path)], arr => {
    const a = arr ? arr.map(i => _get(i, key)) : [];
    const uniq = _without(_uniq(a), undefined);
    let ordered = _orderBy(uniq, [i => i.toLowerCase()], ["asc"]);
    ordered = ordered.sort(sortMixedString);
    return ordered;
  });

/**
 * Gets a list of management companies. It filters the list if there
 * is a dealer selected
 */
export const getFilteredManagers = createSelector(
  [state => state.navigation.selectedDealer, state => state.dealer.companies],
  (selectedDealer, companies) => {
    return selectedDealer ? _filter(companies, i => i.dealer.account.id === selectedDealer) : companies;
  }
);
/**
 * Users the getFilteredManagers selector and then adds appropiate info for the select
 */
export const getFilteredManagersForSelect = createSelector(
  [state => state.navigation.selectedDealer, getFilteredManagers],
  (selectedDealer, _companies) => {
    const companies = (_companies || []).map(i => ({ ...i, value: i.account.id, label: i.name }));
    if (selectedDealer) {
      companies.unshift({ value: "ALL_COMPANIES", label: "All Companies" });
    }
    return companies;
  }
);

/**
 * Gets a list of buildings. It filters the list if there
 * is a dealer or manager selected
 */
export const getFilteredBuildings = createSelector(
  [
    state => state.navigation.selectedDealer,
    state => state.navigation.selectedManager,
    state => state.management.buildings
  ],
  (selectedDealer, selectedManager, buildings) => {
    if (selectedManager) {
      return _filter(buildings, i => i.client.id === selectedManager);
    } else if (selectedDealer) {
      return _filter(buildings, i => i.dealer.id === selectedDealer);
    } else {
      return buildings;
    }
  }
);

export const getEligibleBuildings = (
  subscribedPlans = [NOPLAN_SUBSCRIPTION_PLAN, LIFETIME_SUBSCRIPTION_PLAN, UNSUBSCRIBED_SUBSCRIPTION_PLAN]
) =>
  createSelector(
    [
      state => state.navigation.selectedDealer,
      state => state.navigation.selectedManager,
      state => state.management.buildings
    ],
    (selectedDealer, selectedManager, buildings) => {
      if (selectedManager) {
        return _filter(
          buildings,
          i => i.client.id === selectedManager && !subscribedPlans.includes(i.building_subscription_plan.name)
        );
      } else if (selectedDealer) {
        return _filter(
          buildings,
          i => i.dealer.id === selectedDealer && !subscribedPlans.includes(i.building_subscription_plan.name)
        );
      } else {
        return _filter(buildings, i => !subscribedPlans.includes(i.building_subscription_plan.name));
      }
    }
  );

export const getFilteredBuildingsForSelect = createSelector(
  [state => state.navigation.selectedDealer, state => state.navigation.selectedManager, getFilteredBuildings],
  (selectedDealer, selectedManager, _buildings) => {
    const buildings = (_buildings || []).map(i => ({ ...i, value: i.id, label: i.building_name }));
    if (selectedDealer || selectedManager) {
      buildings.unshift({ value: "ALL_BUILDINGS", label: "All Buildings" });
    }
    return buildings;
  }
);

export const getCompanyType = createSelector(
  [
    state => state.navigation.selectedManager,
    state => state.navigation.selectedBuilding,
    state => state.dealer.companies,
    state => state.management.buildings,
    state => (state.management.company ? state.management.company.type : null)
  ],
  (selectedManager, selectedBuilding, companies, buildings, companyType) => {
    if (companyType) {
      return companyType;
    }

    companyType = "residential";

    if (selectedManager && companies) {
      const filteredCompany = companies.find(i => i.account.id === selectedManager);
      companyType = _get(filteredCompany, "type", companyType);
      return companyType;
    }

    if (selectedBuilding && buildings && companies) {
      const filteredBuilding = buildings.find(i => i.id === selectedBuilding);
      const clientId = _get(filteredBuilding, "client.id", -1);
      const filteredCompany = companies.find(i => i.account.id === clientId);
      companyType = _get(filteredCompany, "type", companyType);
      return companyType;
    }

    return companyType;
  }
);

export const getFilteredAccessList = createSelector(
  [
    state => state.navigation.selectedBuilding,
    state => _get(state, "management.buildings", []),
    state => _get(state, "building.access_list", []),
    state => _get(state, "building.info.client_id")
  ],
  (selectedBuilding, buildings, accessList, client_id) => {
    return buildings
      ? buildings
          .filter(({ id, client }) => id !== selectedBuilding && client.client_id === client_id)
          .map(({ id, building_name }) => {
            const access = accessList.find(access => access.allowed_building_id === id);
            return {
              id: access ? access.id : null,
              allowed_building_id: id,
              building_name,
              can_access: access ? access.can_access : false
            };
          })
      : [];
  }
);

export const dealersSelect = createSelector([state => state.admin.dealers], dealers => {
  return dealers ? dealers.map(i => ({ ...i, value: i.account.id, label: i.name })) : dealers;
});

export const plansSelect = createSelector([state => state.plan.features], features => {
  return (features || []).map(i => ({ ...i }));
});

export const getBuildingsByPlansPermissions = (state, permission, buildings) => {
  const features = plansSelect(state);
  const feature = _first(features.filter(item => item.field_name === permission));

  let allowedBuildings = [];
  if (feature && buildings.length > 0) {
    buildings.forEach(building => {
      if (feature[building.building_subscription_plan.name].enablePermissions) {
        allowedBuildings.push({ ...building, enabled: true });
      } else {
        allowedBuildings.push({ ...building, enabled: false });
      }
    });
  }

  return allowedBuildings;
};

export const getPlanFeatureByFieldName = (state, fieldName) => {
  const planFeatures = _get(state, "plan.features");
  return _filter(planFeatures, i => i.field_name === fieldName)[0];
};

export default {
  getFilterOptions,
  getFilteredManagers,
  getFilteredManagersForSelect,
  getFilteredBuildings,
  getFilteredBuildingsForSelect,
  getFilteredAccessList,
  getEligibleBuildings,
  getCompanyType,
  dealersSelect,
  plansSelect,
  getBuildingsByPlansPermissions,
  getPlanFeatureByFieldName
};

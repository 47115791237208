import { createReducer, createActions } from "reduxsauce";
import { asyncCreatorFactory, notifySuccess } from "../action-utils";

import _pick from "lodash/pick";
import _union from "lodash/union";
import _get from "lodash/get";
import _map from "lodash/map";

import {
  getDealersLazy,
  getBuildingKeyComList as getAdminBuildingKeyComList,
  actions as adminActions,
  setKeycoms as setAdminKeycoms,
} from "./admin";
import { setSelectedManager } from "./navigation";
import { setDoors as setBrivoDoors } from "./brivo";
import { setDoors as setPdkDoors } from "./pdk";
import { setDoors as setHartmannDoors } from "./hartmann";
import { setWebrelays } from "./building";
import { ADMIN_ROLES, isAdmin } from "components/ui/authorized/AuthorizedRole";
import store from "redux/store";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  dealerFetching: ["actionId"],
  dealerSuccess: ["actionId"],
  dealerFailure: ["error", "actionId"],
  dealerSet: ["data", "attr", "actionId"],
  dealerReset: ["exclude"],
});
export const actions = Creators;

/* ------------- Initial state ------------- */
const INITIAL_STATE = {
  companies: null,
  hasNextCompanies: false,
  addCompany: null,
  dealerLogo: null,
  meta: null,
  fetching: [],
  error: [],
  dealerCurrent: null,
  users: null,
};

/* ------------- Reducers ------------- */
export const fetching = (state, { actionId }) => ({
  ...state,
  fetching: [...state.fetching, actionId],
  error: state.error.filter((i) => i.actionId !== actionId),
});
export const set = (state, { data, attr, actionId }) => ({
  ...state,
  [attr]: data,
});
export const success = (state, { actionId }) => ({
  ...state,
  fetching: state.fetching.filter((i) => i !== actionId),
  error: state.error.filter((i) => i.actionId !== actionId),
});
export const failure = (state, { error, actionId }) => ({
  ...state,
  fetching: state.fetching.filter((i) => i !== actionId),
  error: [...state.error, { error, actionId }],
});
export const reset = (state, { exclude = [] }) => ({
  ...INITIAL_STATE,
  ..._pick(state, exclude),
});

/* ------------- Hookup Reducers To Types ------------- */
const dealerReducer = createReducer(INITIAL_STATE, {
  [Types.DEALER_FETCHING]: fetching,
  [Types.DEALER_SUCCESS]: success,
  [Types.DEALER_FAILURE]: failure,
  [Types.DEALER_SET]: set,
  [Types.DEALER_RESET]: reset,
});

/* ------------- Async Actions ------------- */
const asyncCreator = asyncCreatorFactory(
  Creators.dealerFetching,
  Creators.dealerSuccess,
  Creators.dealerFailure
);

export const getCurrentDealer = (dealerId) =>
  asyncCreator({
    actionId: "getCurrentDealer",
    apiCall: (api) => api.dealer.getCurrentDealer(dealerId),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.dealerSet(response.data.data, "dealerCurrent"));
    },
    isNotifyError: false,
    errorPath: "data.description",
  });

export const updateCurrentDealer = (dealerId, data) =>
  asyncCreator({
    actionId: "updateCurrentDealer",
    apiCall: (api) => api.dealer.updateCurrentDealer(dealerId, data),
    onSuccess: (dispatch, response) => {
      notifySuccess("Dealer info updated successfully");
      if (dealerId !== 0) {
        dispatch(getDealersLazy());
      }
    },
    errorPath: "data.description",
  });

export const deleteDealer = (dealer_id) =>
  asyncCreator({
    actionId: "deleteDealer",
    apiCall: (api) => api.dealer.deleteDealer(dealer_id),
    onSuccess: (dispatch, response) => {
      notifySuccess("Dealer deleted successfully");
      dispatch(getDealersLazy());
    },
    errorPath: "data.description",
  });

export const getDealerLogo = (id) =>
  asyncCreator({
    actionId: "getDealerLogo",
    apiCall: (api) => api.dealer.getDealerLogo(id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.dealerSet(response.data, "dealerLogo"));
    },
    isNotifyError: false,
  });

export const updateDealerLogo = (dealerId, data) =>
  asyncCreator({
    actionId: "updateDealerLogo",
    apiCall: (api) => api.dealer.updateDealerLogo(dealerId, data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Logo uploaded successfully");
      dispatch(Creators.dealerSet(response.data.data.s3_url, "dealerLogo"));
      if (dealerId !== 0) {
        dispatch(getDealersLazy());
      }
    },
    errorPath: "data.message",
  });

export const deleteDealerLogo = (dealerId) =>
  asyncCreator({
    actionId: "deleteDealerLogo",
    apiCall: (api) => api.dealer.deleteDealerLogo(dealerId),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Logo deleted successfully");
      dispatch(Creators.dealerSet(null, "dealerLogo"));
    },
    errorPath: "data.message",
  });

export const getManagementCompanies = (id, role, params) =>
  asyncCreator({
    actionId: "getManagementCompanies",
    apiCall: (api) => api.dealer.getManagementCompanies(ADMIN_ROLES.includes(role) ? 0 : id, params),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.dealerSet(response.data.data, "companies"));
    },
    errorPath: "data.description",
  });

export const getManagementCompaniesLazy = ({ page = 1, params = {}, id = 0 } = {}) =>
  asyncCreator({
    actionId: "getManagementCompaniesLazy",
    apiCall: (api, getState) => {
      const selectedDealer = _get(getState(), "navigation.selectedDealer", 0);
      const defaultParams = {
        limit: 24,
        dealer_id: selectedDealer > 0 ? selectedDealer : undefined,
        ...params,
      };
      return api.dealer.getManagementCompaniesLazy(id, page, defaultParams);
    },
    onSuccess: (dispatch, response, getState) => {
      const hasNext = _get(response.data, "has_next");
      const existingCompanies = _get(getState(), "dealer.companies", []) || [];
      const fetchedCompanies = _get(response, "data.data", []) || [];
      const companies = _union(existingCompanies, fetchedCompanies);
      dispatch(Creators.dealerSet(hasNext, "hasNextCompanies"));
      dispatch(Creators.dealerSet(companies, "companies"));
    },
    errorPath: "data.description",
  });

export const addCompany = (data) =>
  asyncCreator({
    actionId: "addCompany",
    apiCall: (api) => api.dealer.addCompany(data),
    onSuccess: (dispatch, response, getState) => {
      const selectedManager = response.data;
      dispatch(setSelectedManager(selectedManager));
      notifySuccess("Company added successfully");
      dispatch(clearManagementCompanies());
      dispatch(getManagementCompaniesLazy());
    },
    errorPath: "data.description",
    isNotifyError: false,
  });

export const getUsers = (dealerId) =>
  asyncCreator({
    actionId: "getUsers",
    apiCall: (api) => api.dealer.getDealerUsers(dealerId),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.dealerSet(response.data.data, "users"));
    },
    errorPath: "data.description",
  });

export const updateUser = (id, data, dealer_id) =>
  asyncCreator({
    actionId: "updateUser",
    apiCall: (api) => api.dealer.updateUser(id, data),
    onSuccess: (dispatch, response) => {
      notifySuccess("User successfully updated");
      dispatch(getUsers(dealer_id));
    },
    errorPath: "data.description",
  });

export const createUser = (dealer_id, data) =>
  asyncCreator({
    actionId: "createUser",
    apiCall: (api) => api.dealer.addUser(dealer_id, data),
    onSuccess: (dispatch, response) => {
      notifySuccess("User successfully added");
      dispatch(getUsers(dealer_id));
    },
    errorPath: "data.description",
  });

export const resendDealerWelcomeEmail = (id) =>
  asyncCreator({
    actionId: "resendDealerWelcomeEmail",
    apiCall: (api) => api.dealer.resendDealerWelcomeEmail(id),
    onSuccess: (dispatch, response) => {
      notifySuccess("Email was successfully sent");
    },
    errorPath: "data.description",
  });

export const deleteUser = (user_id, dealer_id) =>
  asyncCreator({
    actionId: "deleteUser",
    apiCall: (api) => api.dealer.deleteUser(user_id),
    onSuccess: (dispatch, response) => {
      notifySuccess("User successfully deleted");
      dispatch(getUsers(dealer_id));
    },
    errorPath: "data.description",
  });

export const getBuildingKeyComList = (id) =>
  asyncCreator({
    actionId: "getBuildingKeyComList",
    apiCall: (api) => api.dealer.getBuildingKeyComList(id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.dealerSet(response.data.data, "building_keycoms"));
    },
    errorPath: "data.description",
  });

export const updateBuildingKiosk = (id, data, building_id) =>
  asyncCreator({
    actionId: "updateBuildingKiosk",
    apiCall: (api) => api.dealer.updateKiosk(id, data),
    onSuccess: (dispatch, response) => {
      notifySuccess("Keycom successfully updated");
      dispatch(getBuildingKeyComList(building_id));
    },
    errorPath: "data.description",
  });

export const addNewKeycom = (data) =>
  asyncCreator({
    actionId: "addNewKeycom",
    apiCall: (api) => api.dealer.activateKeycom(data),
    onSuccess: (dispatch, response) => {
      const state = store.getState();
      const residentRole = _get(state, "resident.data.role", {});
      const fn = isAdmin(residentRole.name) ? getAdminBuildingKeyComList : getBuildingKeyComList;
      notifySuccess("Keycom successfully added");
      dispatch(fn(data.building_id));
    },
    errorPath: "data.message",
  });

export const getMetaInformation = (id) =>
  asyncCreator({
    actionId: "getMetaInformation",
    apiCall: (api) => api.dealer.getMetaInformation(id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.dealerSet(response.data, "meta"));
    },
    errorPath: "data.message",
  });

export const updateKioskAccessRestrictions = (id, data, admin_keycoms_page = false) =>
  asyncCreator({
    actionId: "updateKioskAccessRestrictions",
    apiCall: (api) => api.dealer.updateKioskAccessRestrictions(id, data),
    onSuccess: (dispatch, response) => {
      const kioskAccessRestrictions = _get(response, "data", []);

      const pathToAdminKeyComs = admin_keycoms_page ? "admin.keycoms" : "admin.building_keycoms";

      const state = store.getState();
      const role = _get(state, "resident.data.role.name", null);
      const _isAdmin = isAdmin(role);
      const pathToKeyComs = _isAdmin ? pathToAdminKeyComs : "dealer.building_keycoms";
      const fn = _isAdmin ? adminActions.adminSet : Creators.dealerSet;
      const newMviKiosks = _map(_get(state, pathToKeyComs, []), (kiosk) => {
        if (kiosk.id === id) {
          kiosk.kiosk_settings.access_restrictions = kioskAccessRestrictions;
        }

        return kiosk;
      });

      notifySuccess("Access restrictions was updated successfully");

      const pathToUpdateAdminKeyComs = admin_keycoms_page ? "keycoms" : "building_keycoms";
      const pathToUpdateKeyComs = _isAdmin ? pathToUpdateAdminKeyComs : "building_keycoms";

      dispatch(fn(newMviKiosks, pathToUpdateKeyComs));
    },
    errorPath: "data.message",
  });

export const updateDoor = (doorId, { fieldName, fieldValue }) =>
  asyncCreator({
    actionId: "updateDoor",
    apiCall: (api) => api.dealer.updateDoor(doorId, { fieldName, fieldValue }),
    onSuccess: async (dispatch, response, getState) => {
      const role = _get(getState(), "resident.data.role.name");

      const currentKeycoms = isAdmin(role)
        ? _get(getState(), "admin.building_keycoms", [])
        : _get(getState(), "dealer.building_keycoms", []);
      const currentBrivoDoors = _get(getState(), "brivo.doors", []);
      const currentPdkDoors = _get(getState(), "pdk.doors", []);
      const currentHartmannDoors = _get(getState(), "hartmann.doors", []);
      const currentWebrelays = _get(getState(), "building.webrelays", []);

      const door_id = response.data;

      const newKeycoms = [
        ...currentKeycoms.map((item) => (item.id === door_id ? { ...item, [fieldName]: fieldValue } : item)),
      ];
      const newBrivoDoors = [...currentBrivoDoors.map((item) => (item.id === door_id ? { ...item, [fieldName]: fieldValue } : item))];
      const newPdkDoors = [...currentPdkDoors.map((item) => (item.id === door_id ? { ...item, [fieldName]: fieldValue } : item))];
      const newHartmannDoors = [...currentHartmannDoors.map((item) => (item.id === door_id ? { ...item, [fieldName]: fieldValue } : item))];
      const newWebrelays = [...currentWebrelays.map((item) => (item.id === door_id ? { ...item, [fieldName]: fieldValue } : item))];

      isAdmin(role)
        ? await dispatch(setAdminKeycoms(newKeycoms))
        : await dispatch(Creators.dealerSet(newKeycoms, "building_keycoms"));
      await dispatch(setBrivoDoors(newBrivoDoors));
      await dispatch(setPdkDoors(newPdkDoors));
      await dispatch(setHartmannDoors(newHartmannDoors));
      await dispatch(setWebrelays(newWebrelays));

      notifySuccess(`Settings updated successfully`);
    },
    errorPath: "data.message",
  });

export const updateDealerAddressLocally = (data) => (dispatch) =>
  dispatch(Creators.dealerSet(data, "dealerCurrent"));

export const clearManagementCompanies = () => actions.dealerSet(null, "companies");
export const setManagementCompanies = (companies) => actions.dealerSet(companies, "companies");

export default dealerReducer;

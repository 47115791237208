import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  layoutFetching: ["actionId"],
  layoutSuccess: ["actionId"],
  layoutFailure: ["error", "actionId"],
  layoutSet: ["data", "attr", "actionId"],
  layoutReset: null,
});
export const actions = Creators;

/* ------------- Initial state ------------- */
const INITIAL_STATE = {
  fetching: [],
  error: [],
};

/* ------------- Reducers ------------- */
export const fetching = (state, { actionId }) => ({
  ...state,
  fetching: [...state.fetching, actionId],
  error: state.error.filter((i) => i.actionId !== actionId),
});
export const set = (state, { data, attr, actionId }) => ({
  ...state,
  [attr]: data,
});
export const success = (state, { actionId }) => ({
  ...state,
  fetching: state.fetching.filter((i) => i !== actionId),
  error: state.error.filter((i) => i.actionId !== actionId),
});
export const failure = (state, { error, actionId }) => ({
  ...state,
  fetching: state.fetching.filter((i) => i !== actionId),
  error: [...state.error, { error, actionId }],
});
export const reset = (state) => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */
const layoutReducer = createReducer(INITIAL_STATE, {
  [Types.LAYOUT_FETCHING]: fetching,
  [Types.LAYOUT_SUCCESS]: success,
  [Types.LAYOUT_FAILURE]: failure,
  [Types.LAYOUT_SET]: set,
  [Types.LAYOUT_RESET]: reset,
});

export default layoutReducer;

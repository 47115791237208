import injectGlobal from "./injectGlobal";

const forms = theme => injectGlobal`
  label {
    margin-bottom: 0.2rem;
  }
  .form-row{
    border: 3px solid transparent;
  }
  .custom-select.is-warning:focus, 
  .form-control.is-warning:focus  { 
    border-color: #f7c315;
    box-shadow: 0 0 0 0.2rem rgba(228, 161, 25, 0.25); 
  }
  .custom-select.is-warning~.warning-feedback, 
  .custom-select.is-warning~.warning-tooltip, 
  .form-control.is-warning~.warning-feedback, 
  .form-control.is-warning~.warning-tooltip {
    display: block;
  }
  .warning-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #f7c315;
  }
  .custom-select.is-warning, .form-control.is-warning {
    border-color: #f7c315;
  }
  .Select-small-width {
    &.Select--single > .Select-control .Select-value {
      padding-left: 0;
      padding-right: 20px;
    }
  }

  .label-info-btn {
    position:absolute;
    top: 4px;
    right: 7px;
    background: #448de4;
    border: 0;
    border-radius: 20px;
    font-size: 10px;
    color: white;
    width: 10px;
    height: 14px;
     display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
 
  .rangeslider .rangeslider__fill, .rangeslider .rangeslider__handle:after {
    background-color: #4892ce !important;
  }
  .rangeslider {
    width: 100%;
  }
  .rangeslider__handle {
    outline: 0;
  }
  select[name="brivo_site"] option:disabled {
        display: none;
    } 
`

export default forms;

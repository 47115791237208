import React from 'react';
import _get from "lodash/get";

import isURL from "validator/lib/isURL";
import MenuBadge from "../MenuBadge";
import { 
  NOPLAN_SUBSCRIPTION_PLAN,
  LIFETIME_SUBSCRIPTION_PLAN,
  UNSUBSCRIBED_SUBSCRIPTION_PLAN,
  ESSENTIALS_SUBSCRIPTION_PLAN
} from "components/ui/subscribed/SubscribedPlan"
import { denyAccessToSections, combineMenuWithPermissions, getListMenuItems } from "../menu-utils"

export const SELECT_PATH = "/app/menu";
export const BG_COLOR = "#5cbea8";
export const ACTIVE_TAB_TITLE = "Messages"
export const BULLETIN_SUBMENU_NAME = "Bulletin Board";
export const ACCOUNT_SUBMENU_NAME = "My Account";
export const MANAGE_SUBMENU_NAME = "Manage Users";
export const KEYCOM_SUBMENU_NAME = "KeyCom® Info";

export const MENU_KEYS = {
    MESSAGES: "resident_messages",
    ACTIVITY: "resident_activity",
    PROMOS: "resident_promos",
    BULLETINBOARD: "resident_bulletinBoard",
    MYACCOUNTSECTION: "resident_myAccountSection",
    MANAGEUSERS: "resident_manageUsers",
    KEYCOMINFO: "resident_keycomInfo",
    BILLINGPORTAL: "resident_billingPortal",
    TRAININGVIDEOS: "resident_training_videos"
}

const getMenuItems = (avatar, messages) => ({
  [MENU_KEYS.MESSAGES]: {
    name: "Messages",
    path: "/app/messages",
    icon: <i className="material-icons">mail</i>,
    badge: messages.length ? <MenuBadge text={messages.length} /> : null
  },
  [MENU_KEYS.ACTIVITY]: { name: "Activity", path: "/app/activity", icon: <i className="material-icons">dialpad</i> },
  [MENU_KEYS.PROMOS]: { name: "Promos", path: "/app/promos", icon: <i className="material-icons">card_giftcard</i> },
  [MENU_KEYS.BULLETINBOARD]: {
    name: "Bulletin Board",
    path: "/app/bulletin_board",
    icon: <i className="fa fa-calendar-alt"/>,
    children: [
      {
        name: "Building Notices",
        path: "/app/bulletin_board/notices",
        className: "icon-with-border",
        activeClassName: "active-with-color icon-with-border-active",
        icon: <i className="fa fa-newspaper"/>,
      },
      {
        name: "Building Calendar",
        path: "/app/bulletin_board/calendar",
        className: "icon-with-border",
        activeClassName: "active-with-color icon-with-border-active",
        icon: <i className="fa fa-calendar-check"/>,
      },
    ]
  },
  [MENU_KEYS.MYACCOUNTSECTION]: {
    name: "My Account",
    path: "/app/my_account",
    icon:
      avatar && isURL(avatar) ? (
        <img className="side-img" src={avatar} alt="" />
      ) : (
        <i className="material-icons">account_circle</i>
      ),
    children: [
      {
        name: "Account Settings",
        path: "/app/my_account/account_settings",
        className: "icon-with-border",
        activeClassName: "active-with-color icon-with-border-active",
        icon: <i className="fa fa-cogs" />
      },
      {
        name: "Password Settings",
        path: "/app/my_account/password_settings",
        className: "icon-with-border",
        activeClassName: "active-with-color icon-with-border-active",
        icon: <i className="fa fa-lock" />
      },
    ],
  },
  [MENU_KEYS.MANAGEUSERS]: {
    name: "Manage Users",
    path: "/app/manage_users",
    icon: <i className="material-icons">supervisor_account</i>,
    children: [
      {
        name: "Account Users",
        path: "/app/manage_users/account_users",
        className: "icon-with-border",
        activeClassName: "active-with-color icon-with-border-active",
        icon: <i className="fa fa-users" />
      },
      {
        name: "MyKey Users",
        path: "/app/manage_users/mykey_users",
        className: "icon-with-border",
        activeClassName: "active-with-color icon-with-border-active",
        icon: <i className="fa fa-qrcode" />
      },
      {
        name: "Visitor MyKeys",
        path: "/app/manage_users/visitor_Mykeys",
        className: "icon-with-border",
        activeClassName: "active-with-color icon-with-border-active",
        icon: <i className="fa fa-id-card" />
      },
    ]
  },
  [MENU_KEYS.KEYCOMINFO]: {
    name: "KeyCom® Info",
    path: "/app/keycom_info",
    icon: <i className="material-icons">desktop_windows</i>,
    children: [
      {
        name: "KeyCom® Settings",
        path: "/app/keycom_info/general_settings",
        className: "icon-with-border",
        activeClassName: "active-with-color icon-with-border-active",
        icon: <i className="fa fa-cogs" />
      },
      {
        name: "Live View",
        path: "/app/keycom_info/live_view",
        className: "icon-with-border",
        activeClassName: "active-with-color icon-with-border-active",
        icon: <i className="fa fa-video-camera" />
      },
    ]
  },
  [MENU_KEYS.BILLINGPORTAL]: {
    name: "Billing Portal",
    path: "/app/billing_portal",
    icon: <i className="material-icons">attach_money</i>
  },
  [MENU_KEYS.TRAININGVIDEOS]: {
    name: "Training Videos",
    path: `/app/training_videos`,
    icon: <i className="fa fa-video" />,
    activeClassName: "active-with-bg"
  },
})

export const getPermissions = (subscriptionPlan) => {
  const staticPermissions = {}

  switch (subscriptionPlan) {
    case ESSENTIALS_SUBSCRIPTION_PLAN:
      denyAccessToSections(staticPermissions, {
        forbiddenSections: [MENU_KEYS.BILLINGPORTAL]
      })
      break;
    
    case LIFETIME_SUBSCRIPTION_PLAN:
      denyAccessToSections(staticPermissions, {
        forbiddenSections: [
          MENU_KEYS.ACTIVITY,
          MENU_KEYS.BILLINGPORTAL,
          MENU_KEYS.MANAGEUSERS
        ]
      })
      break;

    case UNSUBSCRIBED_SUBSCRIPTION_PLAN:
      denyAccessToSections(staticPermissions, {
        allowedSections: [
          MENU_KEYS.PROMOS,
          MENU_KEYS.MYACCOUNTSECTION,
          MENU_KEYS.KEYCOMINFO
        ],
        menuKeys: MENU_KEYS
      })
      break;

    case NOPLAN_SUBSCRIPTION_PLAN:
      denyAccessToSections(staticPermissions, {
        allowedSections: [MENU_KEYS.MYACCOUNTSECTION],
        menuKeys: MENU_KEYS
      })
      break;
  }

  return staticPermissions
}

export const getMenu = (avatar, messages, selectedBuilding) => {
    const subscriptionPlan = _get(selectedBuilding, "building_subscription_plan.name", NOPLAN_SUBSCRIPTION_PLAN)

    const menuItems = getMenuItems(avatar, messages)
    const permissions = getPermissions(subscriptionPlan)

    const menuItemsWithPermissions = combineMenuWithPermissions(menuItems, permissions)

    return getListMenuItems(menuItemsWithPermissions)
}
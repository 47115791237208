import React, { Component } from "react";
import PropTypes from "prop-types";
import Spinner from "components/ui/loading/Spinner";

export class StandardField extends Component {
  static propTypes = {
    input: PropTypes.object, //Provided by Final Form
    meta: PropTypes.object, //Provided by Final Form
    label: PropTypes.node,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.oneOf(["text", "password", "select", "textarea", "email", "checkbox"]),
    autoComplete: PropTypes.string,
    extraContent: PropTypes.node,
    asterisk: PropTypes.bool,
  };
  renderInput = () => {
    const { input, meta, inputClassName, asterisk, className, ...rest } = this.props;
    const isError = (meta.error && meta.touched) || meta.submitError;
    const classes = `${isError ? "is-invalid" : ""} ${meta.valid && meta.touched ? "is-valid" : ""}`;
    if (!rest.type || rest.type === "password" || rest.type === "text" || rest.type === "email") {
      return (
        <input
          {...input}
          type={rest.type || "text"}
          autoComplete={rest.autoComplete}
          className={`form-control ${classes} ${inputClassName || ""}`}
          placeholder={rest.placeholder}
          disabled={rest.disabled}
          maxLength={rest.maxLength}
          minLength={rest.minLength}
          required={rest.required}
          style={rest.style}
        />
      );
    } else if (rest.type === "select") {
      return (
        <select {...input} disabled={rest.disabled} className={`form-control ${classes}`}>
          {this.props.children}
        </select>
      );
    } else if (rest.type === "textarea") {
      return (
        <textarea
          {...input}
          placeholder={rest.placeholder}
          disabled={rest.disabled}
          maxLength={rest.maxLength}
          minLength={rest.minLength}
          required={rest.required}
          style={rest.style}
          className={`form-control ${classes}`}
        />
      );
    } else if (rest.type === "checkbox") {
      return (
        <>
          <input
            {...input}
            type={"checkbox"}
            disabled={rest.disabled}
            style={{ marginLeft: 0, marginTop: "0.2rem" }}
            className={`form-check-input  ${classes}`}
            id={rest.id}
          />
          <label className="form-check-label" style={{ marginLeft: 20 }} htmlFor={rest.id}>
            {this.props.children}
          </label>
        </>
      );
    }
  };
  render() {
    const { input, meta, isLoading, ...rest } = this.props;
    const isError = (meta.error && meta.touched) || meta.submitError;
    return (
      <div className={`form-group ${rest.className || ""}`}>
        {rest.label && (
          <label htmlFor={rest.name}>
            {rest.label} {this.props.asterisk && <span className="asterisk">*</span>}
          </label>
        )}
        {isLoading ? <Spinner /> : this.renderInput()}
        {isError && <div className="invalid-feedback">{meta.error || meta.submitError}</div>}
        {rest.extraContent}
      </div>
    );
  }
}

export const FormOnChangeAdapter = ({ input, inputOnChange, ...otherProps }) => {
  const inputProps = {
    ...input,
    onChange: (e) => {
      input.onChange(e);
      inputOnChange && inputOnChange(e);
    },
  };

  return <StandardField input={inputProps} {...otherProps} />;
};

export default StandardField;

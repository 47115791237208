import React from "react";
import logoName from "assets/MVI-logo-with-name.png";
import logoName2x from "assets/MVI-logo-with-name@2x.png";

const Logo = props => {
  const { small, ...rest } = props;
  const logo = logoName;
  const logo2x = logoName2x;
  const defaultWidth = small ? "38" : "100";
  return (
    <img
      src={logo}
      width={props.width || defaultWidth}
      srcSet={`${logo} 1x, ${logo2x} 2x`}
      alt="MVI Logo"
      {...rest}
    />
  );
};
export default Logo;

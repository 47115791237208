import React, { Component } from "react";

class ErrorPage extends Component {
  state = {
    cannotRead: false
  };
  click = () => {
    this.undefinedFunction();
  };
  click2 = () => {
    const Person = "";
    const p = new Person();
    console.log(p);
  };
  render() {
    return (
      <div className="p-40">
        <p>Execute different errors</p>
        <button className="btn" onClick={e => this.setState({ cannotRead: true })}>
          Cannot read property 'exists' of undefined
        </button>
        {this.state.cannotRead && <div>{this.props.something.exists}</div>}
        <br /><br />
        <button className="btn" onClick={this.click}>
          Not a function
        </button>
        <br /><br />
        <button className="btn" onClick={this.click2}>
          Not constructor
        </button>
      </div>
    );
  }
}

export default ErrorPage;

import React, { Component } from "react";
import PageForm from "components/ui/pageForm/PageForm";
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { StandardField } from "components/ui/forms";
import { validators, composeValidators } from "utils/forms";
import { FORM_ERROR } from "final-form";
import { connect } from "react-redux";
import { forgotUsername } from "redux/reducers/reset";
import _isEmpty from "lodash/isEmpty";

class SendResetLink extends Component {
  onSubmit = async values => {
    try {
      await this.props.dispatch(forgotUsername(values));
    } catch (error) {
      return { [FORM_ERROR]: error.message };
    }
  };
  renderForm = form => {
    return (
      <form onSubmit={form.handleSubmit}>
        {form.submitError && <p className="text-danger">{form.submitError}</p>}
        <Field
          label="Please Enter Your Email"
          type="email"
          placeholder="Email"
          component={StandardField}
          validate={composeValidators(validators.required, validators.email)}
          name="email"
          extraContent={<small className="form-text text-muted">Must be a valid email address</small>}
        />
        <button type="submit" disabled={form.submitting || form.pristine || !_isEmpty(form.errors)} className="btn btn-sm btn-primary btn-block">
          Send My Username
        </button>
      </form>
    );
  };
  render() {
    return (
      <PageForm width="400px">
        <h5 className="text-center">Lets get you back on!</h5>
        <Form onSubmit={this.onSubmit} render={this.renderForm} />
        <hr />
        <Link to="/" className="d-block text-center m-t-20">
          <small>Return to login</small>
        </Link>
      </PageForm>
    );
  }
}

export default connect()(SendResetLink);

import { CancelToken } from "apisauce";
import { v4 as uuidv4 } from "uuid";
import Bottleneck from "bottleneck";

const SESSION_URL = "/upload/session";

/* It uploads files in chunks, and it's able to upload multiple files at the same time */
export default class ChunkedUploader {
  /**
   * It creates a new instance of the class, and sets up two rate limiters, one for the number of chunks
   * that can be uploaded at once, and one for the number of files that can be uploaded at once
   * @param api - The API object that you created in the previous step.
   * @param [maxChunks=10] - The maximum number of chunks that can be uploaded at the same time.
   * @param [maxFiles=5] - The maximum number of files that can be uploaded at the same time.
   */
  constructor(api, maxChunks = 10, maxFiles = 5) {
    this.api = api;
    this.session = uuidv4();

    this.chunksLimiter = new Bottleneck({
      maxConcurrent: maxChunks,
    });

    this.filesLimiter = new Bottleneck({
      maxConcurrent: maxFiles,
    });
  }

  prepareData = (data) => {
    let object = {};
    if (data instanceof FormData) {
      data.forEach((value, key) => {
        if (!Reflect.has(object, key)) {
          object[key] = value;
          return;
        }
        if (!Array.isArray(object[key])) {
          object[key] = [object[key]];
        }
        object[key].push(value);
      });
    } else {
      object = data;
    }

    return object;
  };

  uploadFile = async (
    url,
    data,
    {
      uploadProgress = () => {},
      chunkSize = 20971520,
      method = "post",
      file_tags = ["file"],
      multiple = false,
      ...args
    } = {}
  ) => {
    data = this.prepareData(data);

    const files = file_tags
      .filter((tag) => data[tag])
      .map((tag) => (Array.isArray(data[tag]) ? [...{ file: data[tag], tag }] : { file: data[tag], tag }));

    const others = Object.keys(data)
      .filter((key) => !file_tags.includes(key))
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: data[key] });
      }, {});

    if (!files.length) {
      const formData = new FormData();
      Object.keys(others).forEach((key) => {
        formData.append(key, others[key]);
      });
      formData.append("session", this.session);
      return this.api[method](url, formData, { ...args });
    }

    const filesCount = files.length;
    const filesUploadProgress = Array(filesCount).fill(0);

    const _uploadProgress = (fileIndex, fileProgress) => {
      filesUploadProgress[fileIndex] = fileProgress;
      uploadProgress(filesUploadProgress.reduce((partialSum, acc) => partialSum + acc, 0) / filesCount);
    };

    const filesPromises = [...Array(filesCount).keys()].map((fileIndex) =>
      this.filesLimiter.schedule(
        () =>
          new Promise((resolve, reject) => {
            const file = files[fileIndex].file;
            const file_tag = files[fileIndex].tag;

            const totalChunkCount = Math.ceil(file.size / chunkSize);

            const chunksUploadProgress = Array(totalChunkCount).fill(0);
            const preparedData = { file, ...others };

            if (file.size > chunkSize) {
              const _onUploadProgress = (chunkIndex, chunkProgress) => {
                chunksUploadProgress[chunkIndex] = chunkProgress;
                _uploadProgress(
                  fileIndex,
                  chunksUploadProgress.reduce((partialSum, acc) => partialSum + acc, 0) / totalChunkCount
                );
              };

              this.uploadFileChunked(url, preparedData, {
                totalChunkCount,
                _onUploadProgress,
                chunkSize,
                file_tag,
                method,
                ...args,
              })
                .then((response) => resolve(response))
                .catch((error) => reject(error));
            } else {
              const onUploadProgress = (progressEvent) => {
                _uploadProgress(fileIndex, Math.ceil((progressEvent.loaded / progressEvent.total) * 100));
              };

              const formData = new FormData();
              formData.append("file", file);
              formData.append("file_name", uuidv4() + file.name);
              formData.append("file_tag", file_tag);
              formData.append("session", this.session);
              Object.keys(others).forEach((key) => {
                formData.append(key, others[key]);
              });

              this.api[method](url, formData, { onUploadProgress, ...args })
                .then((response) => resolve(response))
                .catch((error) => reject(error));
            }
          })
      )
    );

    return Promise.all(filesPromises)
      .then((response) => {
        const failedRequests = response.filter((requestStatus) => !requestStatus.ok);
        const canceledRequests = response.filter((requestStatus) => requestStatus.problem === "CANCEL_ERROR");
        if (canceledRequests.length) {
          this.cleanSession(method);
          return { ok: false, data: { message: "Uploading canceled by user" } };
        }
        if (failedRequests.length) {
          this.cleanSession(method);
          return { ok: false, data: { message: "Some files failed to upload" } };
        }
        const formData = new FormData();
        formData.append("session", this.session);
        formData.append("files_count", filesCount);
        formData.append("file_tags", file_tags);
        formData.append("session_upload_done", true);
        Object.keys(others).forEach((key) => {
          formData.append(key, others[key]);
        });
        if (multiple) {
          return this.api[method](url, formData, { ...args });
        } else {
          return response[0];
        }
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  };

  uploadFileChunked = async (
    url,
    data,
    {
      totalChunkCount,
      chunkSize,
      method = "post",
      doneUrl = url,
      doneUrlMethod = method,
      file_tag = undefined,
      cancelToken = CancelToken.source().token,
      ...args
    }
  ) => {
    if ((!data && !data.file) || !chunkSize || !totalChunkCount) {
      console.log("Please provide data.file, chunkSize, totalChunkCount");
      return false;
    }

    const { file, ...others } = data;
    const { _onUploadProgress } = args;

    const filename = uuidv4() + file.name;

    const chunkPromises = [...Array(totalChunkCount).keys()].map((chunkIndex) =>
      this.chunksLimiter.schedule(
        () =>
          new Promise((resolve, reject) => {
            const start = chunkIndex * chunkSize;
            const end = start + chunkSize;
            const chunk = file.slice(start, end);

            const chunkFormData = new FormData();
            chunkFormData.append("session", this.session);
            chunkFormData.append("start", start);
            chunkFormData.append("chunked", true);
            chunkFormData.append("chunk_size", chunkSize);
            chunkFormData.append("file", chunk);
            chunkFormData.append("file_name", filename);
            chunkFormData.append("file_tag", file_tag);

            this.api[method](url, chunkFormData, {
              cancelToken,
              onUploadProgress: (progressEvent) =>
                _onUploadProgress
                  ? _onUploadProgress(
                      chunkIndex,
                      Math.ceil((progressEvent.loaded / progressEvent.total) * 100)
                    )
                  : (progressEvent) => progressEvent,
            })
              .then((response) => resolve(response))
              .catch((error) => reject(error));
          })
      )
    );

    return Promise.all(chunkPromises)
      .then((response) => {
        const failedRequests = response.filter((requestStatus) => !requestStatus.ok);
        const canceledRequests = response.filter((requestStatus) => requestStatus.problem === "CANCEL_ERROR");
        if (canceledRequests.length) {
          return canceledRequests[0];
        }
        if (failedRequests.length) {
          return failedRequests[0];
        }
        const formData = new FormData();
        formData.append("session", this.session);
        formData.append("file_name", filename);
        formData.append("file_size", file.size);
        formData.append("file_tag", file_tag);
        formData.append("chunked", true);
        formData.append("file_upload_done", true);
        Object.keys(others).forEach((key) => {
          formData.append(key, others[key]);
        });

        return this.api[doneUrlMethod](doneUrl, formData, { ...args });
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  };

  cleanSession = () => {
    return this.api.delete(SESSION_URL, { session: this.session });
  };
}

import React, { Component } from "react";
import Loadable from "react-loadable";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import store from "./redux/store";
import Theme from "./styles/theme";
import ErrorBoundary from "./ErrorBoundary";
import PrivateRoute from "./components/router/PrivateRoute";
import PublicRoute from "./components/router/PublicRoute";
import Loading from "./components/ui/loading/Loading";
import ForgotPassword from "./components/pages/login/ForgotPassword";
import DisplaySecurityQuestion from "./components/pages/login/DisplaySecurityQuestion";
import ResetPassword from "./components/pages/login/ResetPassword";
import Contact from "./components/pages/login/Contact";
import SendResetLink from "./components/pages/login/SendResetLink";
import ErrorPage from "./components/ui/errorPage/ErrorPage";
import AppVersion from "./components/ui/appVersion/AppVersion";

const App = Loadable({ loader: () => import("./components/App"), loading: Loading });
const Login = Loadable({ loader: () => import("./components/pages/login/Login"), loading: Loading });
const BrivoAuth = Loadable({ loader: () => import("./components/pages/login/Brivo"), loading: Loading });
const TestRTC = Loadable({ loader: () => import("./components/pages/testrtc/TestRTC"), loading: Loading });
const Registration = Loadable({
  loader: () => import("./components/pages/registration/Registration"),
  loading: Loading
});
const VerifyEmail = Loadable({
  loader: () => import("./components/pages/registration/VerifyEmail"),
  loading: Loading
});

class Routes extends Component {
  render() {
    return (
      <ErrorBoundary>
        <Provider store={store}>
          <Theme>
            <div>
              <ToastContainer 
                position="top-center"
                pauseOnHover
                draggable
              />
              <BrowserRouter>
                <Switch>
                  <PublicRoute exact path="/" component={Login} />
                  <Route exact path="/brivo-auth" component={BrivoAuth} />
                  <Route exact path="/test" component={TestRTC} />
                  <PublicRoute exact path="/login" component={Login} />
                  <Route exact path="/email_verification" component={VerifyEmail} />
                  <Route exact path="/forgot_password" component={ForgotPassword} />
                  <Route exact path="/display_security_question" component={DisplaySecurityQuestion} />
                  <Route exact path="/reset_password" component={ResetPassword} />
                  <Route exact path="/send_reset_link" component={SendResetLink} />
                  <Route exact path="/contact" component={Contact} />
                  <Route path="/registration" component={Registration} />

                  <PrivateRoute path="/app" component={App} />
                  <Route exact path="/error" component={ErrorPage} />

                  <Redirect to="/" />
                </Switch>
              </BrowserRouter>
              <AppVersion/>
            </div>
          </Theme>
        </Provider>
      </ErrorBoundary>
    );
  }
}

export default Routes;

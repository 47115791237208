import React, { Component } from "react";
import PropTypes from "prop-types";
import { generateId } from "utils/utils";
import Popover from "reactstrap/lib/Popover";
import avatar from "assets/avatar.png";

class AvatarPopover extends Component {
  static propTypes = {
    image: PropTypes.string
  };
  state = {
    id: generateId(),
    popoverOpen: false
  };
  toggle = (_to) => () => {
    const to = (_to !== undefined) ? _to : !this.state.popoverOpen
    this.setState({
      popoverOpen: to
    });
  };
  showUser = event => {
    event.target.src = avatar;
    event.target.onerror = "";
  };
  render() {
    const { width, height, borderRadius, image, className } = this.props;
    return (
      <div>
        <Popover
          className="p-1 popover-avatar"
          placement="right"
          isOpen={this.state.popoverOpen}
          target={this.state.id}
          toggle={this.toggle()}
        >
          <img
            id={this.state.id}
            className="rounded"
            src={image}
            width="300"
            height="300"
            alt="Profile"
            onError={event => this.showUser(event)}
          />
        </Popover>
        <img
          id={this.state.id}
          onMouseEnter={this.toggle(true)}
          onMouseLeave={this.toggle(false)}
          src={image}
          className={className}
          width={width ? width : "30"}
          height={height ? height : "30"}
          alt="Profile"
          style={{
            borderRadius: borderRadius ? borderRadius : 50,
            border: "1px solid lightgray"
          }}
          onError={event => this.showUser(event)}
        />
      </div>
    );
  }
}

export default AvatarPopover;

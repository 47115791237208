import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _get from "lodash/get";
import bgImage from "assets/aaron-burson-242135-unsplash.jpg";
import LogoCircle from "components/ui/logos/LogoCircle"

const Styles = styled.div`
  width: 100%;
  min-height: 100vh;
  background: url(${bgImage}) no-repeat;
  background-size: cover;
  background-color: ${props => _get(props, "theme.navigation.globalBackground")};
  display: flex;
  align-items: center;
  justify-content: center;
  .page-form-container {
    background: white;
    width: ${props => props.width || "330px"};
    max-width: 95vw;
  }
`;
class PageForm extends Component {
  static propTypes = {
    width: PropTypes.string,
    contentBelow: PropTypes.node
  };
  render() {
    return (
      <Styles {...this.props}>
        <div>
          <div className="page-form-container p-30 p-10-xs rounded m-b-10 m-t-50">
            <LogoCircle/>
            {this.props.children}
          </div>
          {this.props.contentBelow}
        </div>
      </Styles>
    );
  }
}

export default PageForm;

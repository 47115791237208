import store from "../redux/store";
import { menuPermissionNames } from "utils/accessValues";
import { PermissionNames } from "components/layout/navigation/menu-utils";
import {
  CUSTOM_SUBSCRIPTION_PLAN,
  ESSENTIALS_SUBSCRIPTION_PLAN,
  LIFETIME_SUBSCRIPTION_PLAN,
  NOPLAN_SUBSCRIPTION_PLAN,
  PROFESSIONAL_SUBSCRIPTION_PLAN,
  UNSUBSCRIBED_SUBSCRIPTION_PLAN,
} from "components/ui/subscribed/SubscribedPlan";
import { isResidentialRole, isDealerUpRole } from "components/ui/authorized/AuthorizedRole";
import _get from "lodash/get";
import _values from "lodash/values";
import _isEmpty from "lodash/isEmpty";
import _union from "lodash/union";

export const PERMISSION_TYPE_SHOW = "showPermissions";
export const PERMISSION_TYPE_ENABLE = "enablePermissions";
export const PERMISSION_TYPE_LIMIT = "limitPermissions";

export const UNLIMITED_LIMIT = -1;

class BasicAccessManager {
  static _completeSubscriptionPlans = {
    [ESSENTIALS_SUBSCRIPTION_PLAN]: {},
    [PROFESSIONAL_SUBSCRIPTION_PLAN]: {},
    [CUSTOM_SUBSCRIPTION_PLAN]: {},
    [LIFETIME_SUBSCRIPTION_PLAN]: {},
    [NOPLAN_SUBSCRIPTION_PLAN]: {},
    [UNSUBSCRIBED_SUBSCRIPTION_PLAN]: {},
  };
}

class AccessManager extends BasicAccessManager {
  static resident = null;
  static resident_role = null;

  static selectedBuilding = null;
  static timerAwaitBuilding;

  // ----------- SETUP -----------
  static setupResident() {
    const { resident } = store.getState();
    AccessManager.resident = resident;
    AccessManager.resident_role = _get(resident, "data.role.name");
    AccessManager.selectedBuilding = _get(resident, "building");
  }

  static setup({ complete_plans }) {
    AccessManager.setupResident();

    if (complete_plans)
      AccessManager._completeSubscriptionPlans = {
        ...AccessManager._completeSubscriptionPlans,
        ...complete_plans,
      };
    return true;
  }

  // ----------- PREPARE -----------
  static _prepareInitialData() {
    if (isResidentialRole(AccessManager.resident_role)) AccessManager._prepareResidentInitialData();
    else AccessManager._prepareManagerUpInitialData();
  }

  static _prepareResidentInitialData() {
    if (_isEmpty(AccessManager.selectedBuilding)) AccessManager.setupResident();
  }

  static _prepareManagerUpInitialData() {
    AccessManager.setBuilding();
  }

  static setBuilding() {
    const state = store.getState();
    const building = _get(state, "building.info");
    AccessManager.selectedBuilding = building;
  }

  // ----------- INJECTED -----------

  /**
   * itemMenuName - menu key from menu
   * staticPermissions = {...{ [itemMenuName]: {PermissionType: string[]}, }, } - permissions
   * */
  static injectedPermission(itemMenuName, staticPermission) {
    const adapterKey = _get(menuPermissionNames, itemMenuName);
    const state = store.getState();
    const role = _get(state, "resident.data.role.name")

    if (!adapterKey || isDealerUpRole(role)) return {};

    AccessManager._prepareInitialData();

    let injectedPermission = {};

    const permissions = AccessManager.get_perms_from_subscription_plan();
    const permission = _get(permissions, adapterKey);

    if (permission) {
      const staticPermissionsShow = AccessManager.getStaticPermissionsShow(staticPermission, itemMenuName);
      const staticPermissionsEnable = AccessManager.getStaticPermissionsEnable(
        staticPermission,
        itemMenuName
      );

      const makePerm = (perm, permType, applyPerm) => _get(perm, permType) ? applyPerm : _union(applyPerm, AccessManager._getDenyPermissions());

      injectedPermission = {
        ...injectedPermission,
        ...permission,
        injected: true,
        [PERMISSION_TYPE_SHOW]: makePerm(permission, PERMISSION_TYPE_SHOW, staticPermissionsShow),
        [PERMISSION_TYPE_ENABLE]: makePerm(permission, PERMISSION_TYPE_ENABLE, staticPermissionsEnable),
      };
    }

    return injectedPermission;
  }

  static getStaticPermissionsShow(permissions, key) {
    return AccessManager._getStaticPermissions(permissions, key, PERMISSION_TYPE_SHOW);
  }
  static getStaticPermissionsEnable(permissions, key) {
    return AccessManager._getStaticPermissions(permissions, key, PERMISSION_TYPE_ENABLE);
  }

  static _getStaticPermissions(permissions, key, permissionType) {
    // {[PERMISSION_TYPE]: [...],}
    const permissionTypeValues = _get(permissions, key, {});

    if (!_isEmpty(permissionTypeValues)) {
      return _get(permissionTypeValues, permissionType, []);
    } else return [];
  }

  static injectedPermissionArr(allowedPermissionsArr, permissions, menuItem, selectedBuilding) {
    // for residents role
    if (_isEmpty(AccessManager.selectedBuilding)) AccessManager.selectedBuilding = selectedBuilding;

    let permissionArray = [...allowedPermissionsArr];

    const modifyItem = AccessManager.injectedPermission(menuItem, permissions);

    if (modifyItem.injected) {
      permissionArray = [
        ...permissionArray,
        ...modifyItem[PERMISSION_TYPE_SHOW],
        ...modifyItem[PERMISSION_TYPE_ENABLE],
      ];
    } else {
      // get old permission from menu
      for (let value of _values(permissions[menuItem])) {
        permissionArray = [...permissionArray, ...value];
      }
    }

    return permissionArray;
  }

  // ----------- UTILS -----------
  static get_plan_name_from_selected_building() {
    return _get(AccessManager.selectedBuilding, "building_subscription_plan.name");
  }

  static get_perms_from_subscription_plan() {
    const selectedBuildingPlanName = AccessManager.get_plan_name_from_selected_building();
    return _get(AccessManager._completeSubscriptionPlans, selectedBuildingPlanName);
  }

  static _getDenyPermissions() {
    return [PermissionNames.can_view_tabs_on_any_plan];
  }
}

export default AccessManager;

import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import _get from "lodash/get";

export const ESSENTIALS_SUBSCRIPTION_PLAN = "essentials";
export const PROFESSIONAL_SUBSCRIPTION_PLAN = "professional";
export const CUSTOM_SUBSCRIPTION_PLAN = "custom";
export const LIFETIME_SUBSCRIPTION_PLAN = "lifetime";
export const NOPLAN_SUBSCRIPTION_PLAN = "no";
export const UNSUBSCRIBED_SUBSCRIPTION_PLAN = "unsubscribed";
export const BASIC_SUBSCRIPTION_PLAN = "basic";

export const PRO_PLANS = [PROFESSIONAL_SUBSCRIPTION_PLAN, CUSTOM_SUBSCRIPTION_PLAN];
export const MIDDLE_PLANS = [BASIC_SUBSCRIPTION_PLAN, ESSENTIALS_SUBSCRIPTION_PLAN];
export const LOW_PLANS = [
  NOPLAN_SUBSCRIPTION_PLAN,
  UNSUBSCRIBED_SUBSCRIPTION_PLAN,
  LIFETIME_SUBSCRIPTION_PLAN,
];

export const SUBSCRIPTION_PLANS = [...LOW_PLANS, ...MIDDLE_PLANS, ...PRO_PLANS];

class SubscribedPlan extends Component {
  static propTypes = {
    children: PropTypes.node,
    roles: PropTypes.arrayOf(PropTypes.string), //Allowed roles
  };
  static defaultProps = {
    roles: [],
  };

  render() {
    const { plan, plans } = this.props;
    const isSubscribed = plans.includes(plan);
    return isSubscribed ? this.props.children : null;
  }
}

const mapStateToProps = (state) => {
  const selectedBuilding = _get(state, "building.info");
  const selectedPlan = _get(selectedBuilding, "building_subscription_plan");
  const plan = _get(selectedPlan, "name");

  return {
    selectedBuilding,
    buildingPermissions: selectedPlan,
    plan,
  };
};

export default connect(mapStateToProps)(SubscribedPlan);

import React, { Component } from "react";
import PropTypes from "prop-types";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";

const format = "YYYY-MM-DD";

class DatePickerField extends Component {
  static propTypes = {
    input: PropTypes.object, //Provided by Final Form
    meta: PropTypes.object, //Provided by Final Form
    label: PropTypes.node,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    placeholder: PropTypes.string,
    extraContent: PropTypes.node,
    asterisk: PropTypes.bool
  };
  onApply = (event, picker) => {
    this.props.input.onChange(picker.startDate.format("YYYY-MM-DD"));
  };
  todaysDate = () => {
    let today = new Date();
    return moment(today).format(format);
  };
  formatDate(date){
    return moment(date).format(format);
  }
  renderInput() {
    const { input, meta, inputClassName } = this.props;
    const isError = (meta.error && meta.touched) || meta.submitError;
    const classes = `${isError ? "is-invalid" : ""} ${
      meta.valid && meta.touched ? "is-valid" : ""
    } ${inputClassName}`;
    return (
      <DateRangePicker
        onApply={this.onApply}
        singleDatePicker={true}
        containerStyles={{ width: "100%", display: "inline-block" }}
      >
        <button
          type="button"
          className={`form-control form-control-sm d-flex justify-content-between align-items-center ${classes}`}
          placeholder="Select date range"
        >
          <span style={{ whiteSpace: "nowrap" }}>{input.value ? this.formatDate(input.value) : 'N/A'}</span>
          <i className="fa fa-calendar-alt" />
        </button>
      </DateRangePicker>
    );
  }
  render() {
    const { input, meta, ...rest } = this.props;
    const isError = (meta.error && meta.touched) || meta.submitError;
    return (
      <div className={`form-group ${rest.className || ""}`}>
        {rest.label && (
          <label htmlFor={rest.name}>
            {rest.label} {this.props.asterisk && <span className="asterisk">*</span>}
          </label>
        )}
        {this.renderInput()}
        {isError && (
          <div className="invalid-feedback" style={{ display: "block" }}>
            {meta.error || meta.submitError}
          </div>
        )}
        {rest.extraContent}
      </div>
    );
  }
}

export default DatePickerField;

import isEmail from "validator/lib/isEmail";
import {memoize as _memoize, debounce as _debounce} from "lodash";
import api from "redux/api";

import { mobilePhoneRe } from "./utils";

/**
 * Validators
 */
export const required = value => (value ? undefined : "Required");

export const minLength = min => value =>
  !value ? undefined : value.replace(/ /g,"").length >= min ? undefined : `Should have at least ${min} characters`;

export const maxLength = max => value =>
  !value ? undefined : value.replace(/ /g,"").length <= max ? undefined : `Should not contain more than ${max} characters`;

export const noSpace = value =>
  value ? (!(/\s/.test(value)) ? undefined : "Should not contain any whitespace") : undefined;

export const secretAnswer = value =>
  value ? ((/^\s/.test(value) || /\s$/.test(value)) ? "Must not contain spaces at the beginning and end of a line" : undefined) : undefined;

export const email = value => (!value ? undefined : isEmail(value) ? undefined : "Should be a valid email");

export const phone = value =>
  !value
    ? undefined
    : mobilePhoneRe.test(value) ? undefined : "Should be a valid phone number";

export const zip = value =>
    value ? (/^\d{1,5}$/.test(value) ? undefined : "Should contain up to 5 digits") : undefined;

export const number = value =>
  value ? (/^[\d.]+$/.test(value) ? undefined : "Should contain only numbers") : undefined;

export const numberValid = value =>
  value ? (/^[0-9]*\.?[0-9]+$/.test(value) ? undefined : "Should contain only numbers") : undefined;

export const numberBetween = (min, max) => value =>
  value ? (!numberValid(value) && value >= min && value <= max ? undefined : `Should contain only numbers between ${min} - ${max}`) : undefined;

export const password = value =>
  /\d/.test(value) && /[a-zA-Z]/.test(value)
    ? undefined
    : "Password must contain at least one number, one letter and at least 8 or more characters";

export const keycom = value =>
  value ? (/^\d+$/.test(value) ? undefined : "Should contain between 4 and 8 numerical digits") : undefined;

export const equalTo = (compareTo, compareToName) => value =>
  value === compareTo ? undefined : `Should be equal to ${compareToName}`;

export const usernameAvailable = currentValue =>
  _memoize(async value => {
    if (!currentValue || currentValue !== value) {
      const response = await api.resident.checkUsername(value);
      return response.ok ? undefined : "Username is taken";
    }
  });

export const MYKEY_TYPES = {
  RESIDENT: "resident",
  ADMIN: "admin",
  DEALER: "dealer",
  MANAGEMENT: "management",
  VENDOR: "vendor",
  DELIVERY: "delivery",
  APP: "app",
  VISITOR: "visitor",
}

export const activationCode = (mykeyTypes=[]) => async value => {
  if (value && value.length >= 15) {
    const response = await api.management.validateActivationCode({ data: { mykey_types: mykeyTypes }, code: value });
    return response.ok ? undefined : response.data || "Invalid code";
  } else {
    return undefined;
  }
};

export const checkPassword = _debounce(async value => {
  if (value) {
    const response = await api.account.checkCurrentPassword({old_password: value});
    return response.data.data.verified ? undefined : "Incorrect Password";
  } else {
    return undefined;
  }
}, 100, {
  'leading': true,
  'trailing': false
});

export const kioskSerialNumber = async value => {
  if (value) {
    if (/^K\d{7}$/.test(value)) {
      const response = await api.dealer.validateKioskSerialNumber(value);
      return response.data !== 200 ? response.data : undefined;
    }
    return "Incorrect Serial Number"
  } else {
    return undefined;
  }
}

export const parseToNumber = value =>
  value && !validators.numberValid(value) ? Number(value) : value;

export const url = value => value && (value.startsWith('http://') || value.startsWith('https://')) ? undefined : "Should be a valid URL";




export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const companyTypeOptions = [
  { value: "residential", name: "Residential" },
  { value: "commercial", name: "Commercial" },
  { value: "coop", name: "Co-op" },
  { value: "condo", name: "Condo" },
  { value: "community", name: "Community" },
]

export const buildingTypeOptions = [
  { value: "residential", name: "Residential" },
  { value: "commercial", name: "Commercial" },
  { value: "mixed_use", name: "Mixed-Use" }
]

export const stateOptions = [
  { value: "AL", name: "Alabama" },
  { value: "AK", name: "Alaska" },
  { value: "AZ", name: "Arizona" },
  { value: "AR", name: "Arkansas" },
  { value: "CA", name: "California" },
  { value: "CO", name: "Colorado" },
  { value: "CT", name: "Connecticut" },
  { value: "DE", name: "Delaware" },
  { value: "DC", name: "District Of Columbia" },
  { value: "FL", name: "Florida" },
  { value: "GA", name: "Georgia" },
  { value: "HI", name: "Hawaii" },
  { value: "ID", name: "Idaho" },
  { value: "IL", name: "Illinois" },
  { value: "IN", name: "Indiana" },
  { value: "IA", name: "Iowa" },
  { value: "KS", name: "Kansas" },
  { value: "KY", name: "Kentucky" },
  { value: "LA", name: "Louisiana" },
  { value: "ME", name: "Maine" },
  { value: "MD", name: "Maryland" },
  { value: "MA", name: "Massachusetts" },
  { value: "MI", name: "Michigan" },
  { value: "MN", name: "Minnesota" },
  { value: "MS", name: "Mississippi" },
  { value: "MO", name: "Missouri" },
  { value: "MT", name: "Montana" },
  { value: "NE", name: "Nebraska" },
  { value: "NV", name: "Nevada" },
  { value: "NH", name: "New Hampshire" },
  { value: "NJ", name: "New Jersey" },
  { value: "NM", name: "New Mexico" },
  { value: "NY", name: "New York" },
  { value: "NC", name: "North Carolina" },
  { value: "ND", name: "North Dakota" },
  { value: "OH", name: "Ohio" },
  { value: "OK", name: "Oklahoma" },
  { value: "OR", name: "Oregon" },
  { value: "PA", name: "Pennsylvania" },
  { value: "RI", name: "Rhode Island" },
  { value: "SC", name: "South Carolina" },
  { value: "SD", name: "South Dakota" },
  { value: "TN", name: "Tennessee" },
  { value: "TX", name: "Texas" },
  { value: "UT", name: "Utah" },
  { value: "VT", name: "Vermont" },
  { value: "VA", name: "Virginia" },
  { value: "WA", name: "Washington" },
  { value: "WV", name: "West Virginia" },
  { value: "WI", name: "Wisconsin" },
  { value: "WY", name: "Wyoming)" }
];
export const countryOptions = [
  { value: "US", name: "United States" },
  { value: "AF", name: "Afghanistan" },
  { value: "AL", name: "Albania" },
  { value: "DZ", name: "Algeria" },
  { value: "AS", name: "American Samoa" },
  { value: "AD", name: "Andorra" },
  { value: "AO", name: "Angola" },
  { value: "AI", name: "Anguilla" },
  { value: "AQ", name: "Antarctica" },
  { value: "AG", name: "Antigua and Barbuda" },
  { value: "AR", name: "Argentina" },
  { value: "AM", name: "Armenia" },
  { value: "AW", name: "Aruba" },
  { value: "AU", name: "Australia" },
  { value: "AT", name: "Austria" },
  { value: "AZ", name: "Azerbaijan" },
  { value: "BS", name: "Bahamas" },
  { value: "BH", name: "Bahrain" },
  { value: "BD", name: "Bangladesh" },
  { value: "BB", name: "Barbados" },
  { value: "BY", name: "Belarus" },
  { value: "BE", name: "Belgium" },
  { value: "BZ", name: "Belize" },
  { value: "BJ", name: "Benin" },
  { value: "BM", name: "Bermuda" },
  { value: "BT", name: "Bhutan" },
  { value: "BO", name: "Bolivia" },
  { value: "BA", name: "Bosnia and Herzegovina" },
  { value: "BW", name: "Botswana" },
  { value: "BR", name: "Brazil" },
  { value: "IO", name: "British Indian Ocean Territory" },
  { value: "VG", name: "British Virgin Islands" },
  { value: "BN", name: "Brunei" },
  { value: "BG", name: "Bulgaria" },
  { value: "BF", name: "Burkina Faso" },
  { value: "BI", name: "Burundi" },
  { value: "KH", name: "Cambodia" },
  { value: "CM", name: "Cameroon" },
  { value: "CA", name: "Canada" },
  { value: "CV", name: "Cape Verde" },
  { value: "KY", name: "Cayman Islands" },
  { value: "CF", name: "Central African Republic" },
  { value: "TD", name: "Chad" },
  { value: "CL", name: "Chile" },
  { value: "CN", name: "China" },
  { value: "CX", name: "Christmas Island" },
  { value: "CC", name: "Cocos Islands" },
  { value: "CO", name: "Colombia" },
  { value: "KM", name: "Comoros" },
  { value: "CK", name: "Cook Islands" },
  { value: "CR", name: "Costa Rica" },
  { value: "HR", name: "Croatia" },
  { value: "CU", name: "Cuba" },
  { value: "CW", name: "Curacao" },
  { value: "CY", name: "Cyprus" },
  { value: "CZ", name: "Czech Republic" },
  { value: "CD", name: "Democratic Republic of the Congo" },
  { value: "DK", name: "Denmark" },
  { value: "DJ", name: "Djibouti" },
  { value: "DM", name: "Dominica" },
  { value: "DO", name: "Dominican Republic" },
  { value: "TL", name: "East Timor" },
  { value: "EC", name: "Ecuador" },
  { value: "EG", name: "Egypt" },
  { value: "SV", name: "El Salvador" },
  { value: "GQ", name: "Equatorial Guinea" },
  { value: "ER", name: "Eritrea" },
  { value: "EE", name: "Estonia" },
  { value: "ET", name: "Ethiopia" },
  { value: "FK", name: "Falkland Islands" },
  { value: "FO", name: "Faroe Islands" },
  { value: "FJ", name: "Fiji" },
  { value: "FI", name: "Finland" },
  { value: "FR", name: "France" },
  { value: "PF", name: "French Polynesia" },
  { value: "GA", name: "Gabon" },
  { value: "GM", name: "Gambia" },
  { value: "GE", name: "Georgia" },
  { value: "DE", name: "Germany" },
  { value: "GH", name: "Ghana" },
  { value: "GI", name: "Gibraltar" },
  { value: "GR", name: "Greece" },
  { value: "GL", name: "Greenland" },
  { value: "GD", name: "Grenada" },
  { value: "GU", name: "Guam" },
  { value: "GT", name: "Guatemala" },
  { value: "GG", name: "Guernsey" },
  { value: "GN", name: "Guinea" },
  { value: "GW", name: "Guinea-Bissau" },
  { value: "GY", name: "Guyana" },
  { value: "HT", name: "Haiti" },
  { value: "HN", name: "Honduras" },
  { value: "HK", name: "Hong Kong" },
  { value: "HU", name: "Hungary" },
  { value: "IS", name: "Iceland" },
  { value: "IN", name: "India" },
  { value: "ID", name: "Indonesia" },
  { value: "IR", name: "Iran" },
  { value: "IQ", name: "Iraq" },
  { value: "IE", name: "Ireland" },
  { value: "IM", name: "Isle of Man" },
  { value: "IL", name: "Israel" },
  { value: "IT", name: "Italy" },
  { value: "CI", name: "Ivory Coast" },
  { value: "JM", name: "Jamaica" },
  { value: "JP", name: "Japan" },
  { value: "JE", name: "Jersey" },
  { value: "JO", name: "Jordan" },
  { value: "KZ", name: "Kazakhstan" },
  { value: "KE", name: "Kenya" },
  { value: "KI", name: "Kiribati" },
  { value: "XK", name: "Kosovo" },
  { value: "KW", name: "Kuwait" },
  { value: "KG", name: "Kyrgyzstan" },
  { value: "LA", name: "Laos" },
  { value: "LV", name: "Latvia" },
  { value: "LB", name: "Lebanon" },
  { value: "LS", name: "Lesotho" },
  { value: "LR", name: "Liberia" },
  { value: "LY", name: "Libya" },
  { value: "LI", name: "Liechtenstein" },
  { value: "LT", name: "Lithuania" },
  { value: "LU", name: "Luxembourg" },
  { value: "MO", name: "Macau" },
  { value: "MK", name: "Macedonia" },
  { value: "MG", name: "Madagascar" },
  { value: "MW", name: "Malawi" },
  { value: "MY", name: "Malaysia" },
  { value: "MV", name: "Maldives" },
  { value: "ML", name: "Mali" },
  { value: "MT", name: "Malta" },
  { value: "MH", name: "Marshall Islands" },
  { value: "MR", name: "Mauritania" },
  { value: "MU", name: "Mauritius" },
  { value: "YT", name: "Mayotte" },
  { value: "MX", name: "Mexico" },
  { value: "FM", name: "Micronesia" },
  { value: "MD", name: "Moldova" },
  { value: "MC", name: "Monaco" },
  { value: "MN", name: "Mongolia" },
  { value: "ME", name: "Montenegro" },
  { value: "MS", name: "Montserrat" },
  { value: "MA", name: "Morocco" },
  { value: "MZ", name: "Mozambique" },
  { value: "MM", name: "Myanmar" },
  { value: "NA", name: "Namibia" },
  { value: "NR", name: "Nauru" },
  { value: "NP", name: "Nepal" },
  { value: "NL", name: "Netherlands" },
  { value: "AN", name: "Netherlands Antilles" },
  { value: "NC", name: "New Caledonia" },
  { value: "NZ", name: "New Zealand" },
  { value: "NI", name: "Nicaragua" },
  { value: "NE", name: "Niger" },
  { value: "NG", name: "Nigeria" },
  { value: "NU", name: "Niue" },
  { value: "KP", name: "North Korea" },
  { value: "MP", name: "Northern Mariana Islands" },
  { value: "NO", name: "Norway" },
  { value: "OM", name: "Oman" },
  { value: "PK", name: "Pakistan" },
  { value: "PW", name: "Palau" },
  { value: "PS", name: "Palestine" },
  { value: "PA", name: "Panama" },
  { value: "PG", name: "Papua New Guinea" },
  { value: "PY", name: "Paraguay" },
  { value: "PE", name: "Peru" },
  { value: "PH", name: "Philippines" },
  { value: "PN", name: "Pitcairn" },
  { value: "PL", name: "Poland" },
  { value: "PT", name: "Portugal" },
  { value: "PR", name: "Puerto Rico" },
  { value: "QA", name: "Qatar" },
  { value: "CG", name: "Republic of the Congo" },
  { value: "RE", name: "Reunion" },
  { value: "RO", name: "Romania" },
  { value: "RU", name: "Russia" },
  { value: "RW", name: "Rwanda" },
  { value: "BL", name: "Saint Barthelemy" },
  { value: "SH", name: "Saint Helena" },
  { value: "KN", name: "Saint Kitts and Nevis" },
  { value: "LC", name: "Saint Lucia" },
  { value: "MF", name: "Saint Martin" },
  { value: "PM", name: "Saint Pierre and Miquelon" },
  { value: "VC", name: "Saint Vincent and the Grenadines" },
  { value: "WS", name: "Samoa" },
  { value: "SM", name: "San Marino" },
  { value: "ST", name: "Sao Tome and Principe" },
  { value: "SA", name: "Saudi Arabia" },
  { value: "SN", name: "Senegal" },
  { value: "RS", name: "Serbia" },
  { value: "SC", name: "Seychelles" },
  { value: "SL", name: "Sierra Leone" },
  { value: "SG", name: "Singapore" },
  { value: "SX", name: "Sint Maarten" },
  { value: "SK", name: "Slovakia" },
  { value: "SI", name: "Slovenia" },
  { value: "SB", name: "Solomon Islands" },
  { value: "SO", name: "Somalia" },
  { value: "ZA", name: "South Africa" },
  { value: "KR", name: "South Korea" },
  { value: "SS", name: "South Sudan" },
  { value: "ES", name: "Spain" },
  { value: "LK", name: "Sri Lanka" },
  { value: "SD", name: "Sudan" },
  { value: "SR", name: "Suriname" },
  { value: "SJ", name: "Svalbard and Jan Mayen" },
  { value: "SZ", name: "Swaziland" },
  { value: "SE", name: "Sweden" },
  { value: "CH", name: "Switzerland" },
  { value: "SY", name: "Syria" },
  { value: "TW", name: "Taiwan" },
  { value: "TJ", name: "Tajikistan" },
  { value: "TZ", name: "Tanzania" },
  { value: "TH", name: "Thailand" },
  { value: "TG", name: "Togo" },
  { value: "TK", name: "Tokelau" },
  { value: "TO", name: "Tonga" },
  { value: "TT", name: "Trinidad and Tobago" },
  { value: "TN", name: "Tunisia" },
  { value: "TR", name: "Turkey" },
  { value: "TM", name: "Turkmenistan" },
  { value: "TC", name: "Turks and Caicos Islands" },
  { value: "TV", name: "Tuvalu" },
  { value: "VI", name: "U.S. Virgin Islands" },
  { value: "UG", name: "Uganda" },
  { value: "UA", name: "Ukraine" },
  { value: "AE", name: "United Arab Emirates" },
  { value: "GB", name: "United Kingdom" },
  { value: "UY", name: "Uruguay" },
  { value: "UZ", name: "Uzbekistan" },
  { value: "VU", name: "Vanuatu" },
  { value: "VA", name: "Vatican" },
  { value: "VE", name: "Venezuela" },
  { value: "VN", name: "Vietnam" },
  { value: "WF", name: "Wallis and Futuna" },
  { value: "EH", name: "Western Sahara" },
  { value: "YE", name: "Yemen" },
  { value: "ZM", name: "Zambia" },
  { value: "ZW", name: "Zimbabwe" }
]

export const validators = {
  required,
  minLength,
  maxLength,
  noSpace,
  email,
  phone,
  password,
  keycom,
  number,
  zip,
  numberValid,
  numberBetween,
  equalTo,
  usernameAvailable,
  activationCode,
  checkPassword,
  kioskSerialNumber,
  secretAnswer,
  url
};

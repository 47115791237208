import { createReducer, createActions } from "reduxsauce";
import { asyncCreatorFactory, notifySuccess } from "../action-utils";
import { getCurrentResident } from "./resident";
import { getManagementCompanies } from "./dealer";
import { getBuildingUnitList, getBuildingUnitListPaginated } from "./building";

import _get from "lodash/get";
import _orderBy from "lodash/orderBy";
import _pick from "lodash/pick";
import _union from "lodash/union";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  managementFetching: ["actionId"],
  managementSuccess: ["actionId"],
  managementFailure: ["error", "actionId"],
  managementSet: ["data", "attr", "actionId"],
  managementSetInner: ["data", "attrDomain", "attrSubdomain"],
  managementReset: ["exclude"],
});
export const actions = Creators;

/* ------------- Initial state ------------- */
const INITIAL_STATE = {
  company: null,
  companyLogo: null,
  buildings: null,
  hasNextBuildings: false,
  addBuilding: null,
  users: null,
  userRoles: null,
  vendorsMyKeys: null,
  vendorsMyKeysAccess: { meta: { "total-events": null, "select-fields": null }, data: null },
  vendorsMyKeysTypes: null,
  deliveriesMyKeys: { data: [], addKeyPermission: false },
  deliveriesMyKeysAccess: { meta: { "total-events": null, "select-fields": null }, data: null },
  belongedBuildingPlans: null,
  meta: null,
  heartbeats: null,
  fetching: [],
  error: [],
};

/* ------------- Reducers ------------- */
export const fetching = (state, { actionId }) => ({
  ...state,
  fetching: [...state.fetching, actionId],
  error: state.error.filter((i) => i.actionId !== actionId),
});
export const set = (state, { data, attr, actionId }) => {
  switch (attr) {
    case "vendorsMyKeysAccess":
    case "deliveriesMyKeysAccess": {
      return {
        ...state,
        [attr]: {
          meta: {
            ...state[attr]["meta"],
            "total-events": data["total-events"],
          },
          data: data.data,
        },
      };
    }
    case "vendorsMyKeysAccessFilters":
    case "deliveriesMyKeysAccessFilters": {
      const myKeysSpace = actionId;
      return {
        ...state,
        [myKeysSpace]: {
          ...state[myKeysSpace],
          meta: {
            ...state[myKeysSpace]["meta"],
            "select-fields": data["select-fields"],
          },
        },
      };
    }
    default: {
      return {
        ...state,
        [attr]: data,
      };
    }
  }
};
export const setInner = (state, { data, attrDomain, attrSubdomain }) => {
  return {
    ...state,
    [attrDomain]: {
      ...state[attrDomain],
      [attrSubdomain]: data,
    },
  };
};
export const success = (state, { actionId }) => ({
  ...state,
  fetching: state.fetching.filter((i) => i !== actionId),
  error: state.error.filter((i) => i.actionId !== actionId),
});
export const failure = (state, { error, actionId }) => ({
  ...state,
  fetching: state.fetching.filter((i) => i !== actionId),
  error: [...state.error, { error, actionId }],
});
export const reset = (state, { exclude = [] }) => ({
  ...INITIAL_STATE,
  ..._pick(state, exclude),
});

/* ------------- Hookup Reducers To Types ------------- */
const managementReducer = createReducer(INITIAL_STATE, {
  [Types.MANAGEMENT_FETCHING]: fetching,
  [Types.MANAGEMENT_SUCCESS]: success,
  [Types.MANAGEMENT_FAILURE]: failure,
  [Types.MANAGEMENT_SET]: set,
  [Types.MANAGEMENT_SET_INNER]: setInner,
  [Types.MANAGEMENT_RESET]: reset,
});

/* ------------- Async Actions ------------- */
const asyncCreator = asyncCreatorFactory(
  Creators.managementFetching,
  Creators.managementSuccess,
  Creators.managementFailure
);
export const updateBuildingSubscriptionPlan = (buildingId, subscriptionPlan) =>
  asyncCreator({
    actionId: "updateBuildingPlan",
    apiCall: (api) => api.management.updateBuildingSubscriptionPlan(buildingId, subscriptionPlan),
    onSuccess: async (dispatch, response, getState) => {
      notifySuccess("Building updated successfully");
      dispatch(clearBuildings());
      dispatch(getBuildingsLazy());
    },
    errorPath: "data.description",
  });

export const getCurrentCompany = (managementID) =>
  asyncCreator({
    actionId: "getCurrentCompany",
    apiCall: (api) => api.management.getCurrentCompany(managementID ? managementID : 0),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.managementSet(response.data.data, "company"));
    },
    errorPath: "data.description",
  });

export const updateCurrentCompany = (managementID, data) =>
  asyncCreator({
    actionId: "updateCurrentCompany",
    apiCall: (api) => api.management.updateCurrentCompany(managementID, data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Company info updated successfully");
      if (managementID !== 0) {
        dispatch(getManagementCompanies());
      }
      dispatch(getCurrentResident());
      dispatch(getCurrentCompany(managementID));
      dispatch(clearBuildings());
      dispatch(getBuildingsLazy());
    },
    errorPath: "data.description",
  });

export const deleteCompany = (management_id) =>
  asyncCreator({
    actionId: "deleteCompany",
    apiCall: (api) => api.management.deleteCompany(management_id),
    onSuccess: (dispatch, response) => {
      notifySuccess("Company successfully deleted");
      dispatch(getManagementCompanies());
    },
    errorPath: "data.description",
  });

export const getCompanyLogo = (id) =>
  asyncCreator({
    actionId: "getCompanyLogo",
    apiCall: (api) => api.management.getCompanyLogo(id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.managementSet(response.data, "companyLogo"));
    },
    errorPath: "data.description",
  });

export const updateCompanyLogo = (managementID, data) =>
  asyncCreator({
    actionId: "updateCompanyLogo",
    apiCall: (api) => api.management.updateCompanyLogo(managementID, data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Logo uploaded successfully");
      if (managementID !== 0) {
        dispatch(getManagementCompanies());
      }
      dispatch(Creators.managementSet(response.data.data.s3_url, "companyLogo"));
    },
    errorPath: "data.message",
  });

export const deleteCompanyLogo = (managementID) =>
  asyncCreator({
    actionId: "deleteCompanyLogo",
    apiCall: (api) => api.management.deleteCompanyLogo(managementID),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Logo deleted successfully");
      if (managementID !== 0) {
        dispatch(getManagementCompanies());
      }
      dispatch(Creators.managementSet(null, "companyLogo"));
    },
    errorPath: "data.message",
  });

export const getBuildings = (id, params) =>
  asyncCreator({
    actionId: "getBuildings",
    apiCall: (api) => api.management.buildings(id, params),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.managementSet(response.data.data, "buildings"));
    },
    errorPath: "data.description",
  });

export const getBuildingsLazy = ({ page = 1, params = {}, id = 0 } = {}) =>
  asyncCreator({
    actionId: "getBuildingsLazy",
    apiCall: (api, getState) => {
      const selectedDealer = _get(getState(), "navigation.selectedDealer", 0);
      const selectedManager = _get(getState(), "navigation.selectedManager", 0);
      const defaultParams = {
        limit: 24,
        client_id: selectedManager > 0 ? selectedManager : undefined,
        dealer_id: selectedDealer > 0 ? selectedDealer : undefined,
        ...params,
      };
      return api.management.getBuildingsLazy(id, page, defaultParams);
    },
    onSuccess: (dispatch, response, getState) => {
      const hasNextBuildings = _get(response.data, "has_next");
      const existingBuildings = _get(getState(), "management.buildings", []) || [];
      const fetchedBuildings = _get(response, "data.data", []) || [];
      const buildings = _union(existingBuildings, fetchedBuildings);
      dispatch(Creators.managementSet(hasNextBuildings, "hasNextBuildings"));
      dispatch(Creators.managementSet(buildings, "buildings"));
    },
    errorPath: "data.description",
  });

export const addBuilding = (data, selectedManager) =>
  asyncCreator({
    actionId: "addBuilding",
    apiCall: (api) => api.management.addBuilding(data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Building added successfully");
      dispatch(clearBuildings());
      dispatch(getBuildingsLazy());
    },
    errorPath: "data.description",
    isNotifyError: false,
  });

export const deleteBuilding = (building_id) =>
  asyncCreator({
    actionId: "deleteBuilding",
    apiCall: (api) => api.management.deleteBuilding(building_id),
    onSuccess: (dispatch, response) => {
      notifySuccess("Building successfully deleted");
      dispatch(clearBuildings());
      dispatch(getBuildingsLazy());
    },
    errorPath: "data.description",
    isNotifyError: false,
  });

export const getUsers = (management_id) =>
  asyncCreator({
    actionId: "getUsers",
    apiCall: (api) => api.management.getUsers(management_id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.managementSet(response.data.data, "users"));
    },
    errorPath: "data.description",
  });

export const getUserRoles = () =>
  asyncCreator({
    actionId: "getUserRoles",
    apiCall: (api) => api.management.getUserRoles(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.managementSet(response.data.roles, "userRoles"));
    },
    errorPath: "data.description",
  });

export const createUser = (data, management_id) =>
  asyncCreator({
    actionId: "createUser",
    apiCall: (api) => api.management.createUser(data, management_id),
    errorPath: "data.description",
  });

export const updateUser = (id, data, management_id) =>
  asyncCreator({
    actionId: "updateUser",
    apiCall: (api) => api.management.updateUser(id, data, management_id),
    onSuccess: (dispatch, response) => {
      notifySuccess("User successfully updated");
      dispatch(getUsers(management_id));
    },
    errorPath: "data.description",
  });

export const uploadUserPic = (id, data) =>
  asyncCreator({
    actionId: "uploadUserPic",
    apiCall: (api) => api.management.uploadUserPic(id, data),
    errorPath: "data.description",
  });

export const getUnitUsers = (account_id) =>
  asyncCreator({
    actionId: "getUnitUsers",
    apiCall: (api) => api.management.getUnitUsers(account_id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.managementSet(response.data.data, "unitUsers"));
    },
    errorPath: "data.description",
  });

export const addUnit = (building_id, data) =>
  asyncCreator({
    actionId: "addUnit",
    apiCall: (api) => api.management.addUnit(building_id, data),
    onSuccess: (dispatch, response) => {
      dispatch(getBuildingUnitList(building_id));
      notifySuccess("Unit successfully added");
    },
    errorPath: "data.description",
  });

export const deleteUnit = (unit_id, building_id) =>
  asyncCreator({
    actionId: "deleteUnit",
    apiCall: (api) => api.management.deleteUnit(unit_id),
    onSuccess: (dispatch, response) => {
      building_id && dispatch(getBuildingUnitListPaginated(building_id));
      notifySuccess("Unit successfully deleted");
    },
    errorPath: "data.description",
  });

export const createUserAndUploadPicture = (data, file, management_id) =>
  asyncCreator({
    actionId: "createUserAndUploadPicture",
    apiCall: async (api, getState, dispatch) => {
      const result = await dispatch(createUser(data, management_id));
      const id = _get(result, "response.data.data.id");
      if (id && file) {
        const formData = new FormData();
        formData.append("file", file);
        await dispatch(uploadUserPic(id, formData, management_id));
      }
      return result.response;
    },
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("User successfully created");
      dispatch(getUsers(management_id));
    },
    errorPath: "data.description",
  });

export const updateUserAndUploadPicture = (id, data, file, management_id) =>
  asyncCreator({
    actionId: "updateUserAndUploadPicture",
    apiCall: async (api, getState, dispatch) => {
      const result = await dispatch(updateUser(id, data, management_id));
      if (id && file) {
        const formData = new FormData();
        formData.append("file", file);
        await dispatch(uploadUserPic(id, formData, management_id));
      }
      return result.response;
    },
    onSuccess: (dispatch, response, getState) => {},
    errorPath: "data.description",
  });

export const deleteUser = (user_id, management_id) =>
  asyncCreator({
    actionId: "deleteUser",
    apiCall: (api) => api.management.deleteUser(user_id, management_id),
    onSuccess: (dispatch, response) => {
      notifySuccess("User successfully deleted");
      dispatch(getUsers(management_id));
    },
    errorPath: "data.description",
  });

export const resendManagementEmail = (id) =>
  asyncCreator({
    actionId: "resendEmail",
    apiCall: (api) => api.management.resendManagementEmail(id),
    onSuccess: (dispatch, response) => {
      notifySuccess("Email was successfully sent");
    },
    errorPath: "data.description",
  });

export const activateMyKey = (data) =>
  asyncCreator({
    actionId: "activateMyKey",
    apiCall: (api) => api.management.activateMyKey(data),
    onSuccess: (dispatch, response) => {
      notifySuccess("MyKey added successfully");
    },
    errorPath: "data.description",
  });

export const removeMyKey = (user_id) =>
  asyncCreator({
    actionId: "removeMyKey",
    apiCall: (api) => api.management.removeMyKey(user_id),
    onSuccess: (dispatch, response) => {
      dispatch(getUsers());
      notifySuccess("MyKey removed successfully");
    },
    errorPath: "data.description",
  });

export const resendEmail = (unit_id, building_id) =>
  asyncCreator({
    actionId: "resendEmail",
    apiCall: (api) => api.management.resendEmail(unit_id),
    onSuccess: (dispatch, respose) => {
      building_id && dispatch(getBuildingUnitListPaginated(building_id));
      notifySuccess("Signup email sent");
    },
    errorPath: "data.description",
  });

export const resendSMS = (unit_id, building_id) =>
  asyncCreator({
    actionId: "resendSMS",
    apiCall: (api) => api.management.resendSMS(unit_id),
    onSuccess: (dispatch, respose) => {
      building_id && dispatch(getBuildingUnitListPaginated(building_id));
      notifySuccess("Signup SMS sent");
    },
    errorPath: "data.description",
  });

export const sendBuildingWelcomeSMS = (building_id) =>
  asyncCreator({
    actionId: "sendBuildingWelcomeSMS",
    apiCall: (api) => api.management.sendBuildingWelcomeSMS(building_id),
    onSuccess: (dispatch, respose) => {
      notifySuccess("Building welcome sms sent");
    },
    errorPath: "data.description",
  });

export const sendBuildingWelcomeEmails = (building_id) =>
  asyncCreator({
    actionId: "sendBuildingWelcomeEmails",
    apiCall: (api) => api.management.sendBuildingWelcomeEmails(building_id),
    onSuccess: (dispatch, response) => {
      notifySuccess("Building welcome emails sent");
    },
    errorPath: "data.description",
  });

// VENDORS
export const getVendorsMyKeys = (id, management_id) =>
  asyncCreator({
    actionId: "getVendorsMyKeys",
    apiCall: (api) => api.management.getVendorsMyKeys(id, management_id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.managementSet(response.data.data, "vendorsMyKeys"));
    },
    errorPath: "data.description",
  });

export const getVendorsMyKeysTypes = () =>
  asyncCreator({
    actionId: "getVendorsMyKeysTypes",
    apiCall: (api) => api.management.getVendorsMyKeysTypes(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.managementSet(_orderBy(response.data.types), "vendorsMyKeysTypes"));
    },
    errorPath: "data.description",
  });

export const addVendorMyKey = (data, management_id) =>
  asyncCreator({
    actionId: "addVendorMyKey",
    apiCall: (api) => api.management.addVendorMyKey(data, management_id),
    onSuccess: (dispatch, response) => {
      notifySuccess("Vendor MyKey successfully created");
      dispatch(getVendorsMyKeys(management_id));
    },
    errorPath: "data.message",
    isNotifyError: false,
  });

export const updateVendorMyKey = (mykey_id, data) =>
  asyncCreator({
    actionId: "updateVendorMyKey",
    apiCall: (api) => api.management.updateVendorMyKey(mykey_id, data),
    onSuccess: (dispatch, response) => {
      notifySuccess("Vendor MyKey successfully updated");
    },
    errorPath: "data.message",
    isNotifyError: false,
  });

export const updateDeliveryMyKey = (mykey_id, data) =>
  asyncCreator({
    actionId: "updateDeliveryMyKey",
    apiCall: (api) => api.management.updateDeliveryMyKey(mykey_id, data),
    onSuccess: (dispatch, response) => {
      notifySuccess("Delivery MyKey successfully updated");
    },
    errorPath: "data.message",
    isNotifyError: false,
  });

export const sendVendorMyKey = (data, management_id) =>
  asyncCreator({
    actionId: "sendVendorMyKey",
    apiCall: (api) => api.management.sendVendorMyKey(data, management_id),
    onSuccess: (dispatch, response) => {
      notifySuccess("Vendor MyKey successfully sent");
      dispatch(getVendorsMyKeys(management_id));
    },
    errorPath: "data.description",
  });

export const deleteVendorMyKey = (id, management_id) =>
  asyncCreator({
    actionId: "deleteVendorMyKey",
    apiCall: (api) => api.management.deleteVendorMyKey(id),
    onSuccess: (dispatch, response) => {
      notifySuccess("Vendor MyKey successfully deleted");
      dispatch(getVendorsMyKeys(management_id));
    },
    errorPath: "data.description",
  });

export const resetVendorMyKey = (id, management_id) =>
  asyncCreator({
    actionId: "resetVendorMyKey",
    apiCall: (api) => api.management.resetVendorMyKey(id),
    onSuccess: (dispatch, response) => {
      notifySuccess("MyKey has been reset successfully");
      dispatch(getVendorsMyKeys(management_id));
    },
    errorPath: "data.description",
  });

export const allowAccessVendorMyKey = (id, management_id) =>
  asyncCreator({
    actionId: "allowAccessVendorMyKey",
    apiCall: (api) => api.management.allowAccessVendorMyKey(id),
    onSuccess: (dispatch, response) => {
      notifySuccess("Access re-allowed succesfully");
      dispatch(getVendorsMyKeys(management_id));
    },
    errorPath: "data.description",
  });

export const denyAccessVendorMyKey = (id, management_id) =>
  asyncCreator({
    actionId: "denyAccessVendorMyKey",
    apiCall: (api) => api.management.denyAccessVendorMyKey(id),
    onSuccess: (dispatch, response) => {
      notifySuccess("Access denied succesfully");
      dispatch(getVendorsMyKeys(management_id));
    },
    errorPath: "data.description",
  });

export const getVendorsAccessPaginated = (page, sizePerPage, params, management_id) =>
  asyncCreator({
    actionId: "getVendorsAccessPaginated",
    apiCall: (api) => api.management.getVendorsAccessPaginated(page, sizePerPage, params, management_id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.managementSet(response.data, "vendorsMyKeysAccess"));
    },
    errorPath: "data.message",
  });

export const getVendorsAccessFilters = (management_id) =>
  asyncCreator({
    actionId: "getVendorsAccessFilters",
    apiCall: (api) => api.management.getVendorsAccessFilters(management_id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.managementSet(response.data, "vendorsMyKeysAccessFilters", "vendorsMyKeysAccess"));
    },
    errorPath: "data.message",
  });

// Deliveries
export const getDeliveriesMyKeys = (id) =>
  asyncCreator({
    actionId: "getDeliveriesMyKeys",
    apiCall: (api) => api.management.getDeliveriesMyKeys(id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.managementSetInner(response.data.data, "deliveriesMyKeys", "data"));
    },
    errorPath: "data.description",
  });

export const getDeliveryAddKeyPermission = (management_id) =>
  asyncCreator({
    actionId: "getDeliveryAddKeyPermission",
    apiCall: (api) => api.management.getDeliveryAddKeyPermission(management_id),
    onSuccess: (dispatch, response) => {
      dispatch(
        Creators.managementSetInner(
          response.data.sufficient_permission,
          "deliveriesMyKeys",
          "addKeyPermission"
        )
      );
    },
    errorPath: "data.description",
  });

export const addDeliveryMyKey = (data, management_id) =>
  asyncCreator({
    actionId: "addDeliveryMyKey",
    apiCall: (api) => api.management.addDeliveryMyKey(data, management_id),
    onSuccess: (dispatch, response) => {
      notifySuccess("Delivery MyKey was successfully added");
      dispatch(getDeliveriesMyKeys(management_id));
    },
    errorPath: "data.message",
    isNotifyError: false,
  });

export const deleteDeliveryMyKey = (id, management_id) =>
  asyncCreator({
    actionId: "deleteDeliveryMyKey",
    apiCall: (api) => api.management.deleteDeliveryMyKey(id),
    onSuccess: (dispatch, response) => {
      notifySuccess("Delivery MyKey successfully deleted");
      dispatch(getDeliveriesMyKeys(management_id));
    },
    errorPath: "data.description",
  });

export const resetDeliveryMyKey = (id, management_id) =>
  asyncCreator({
    actionId: "resetDeliveryMyKey",
    apiCall: (api) => api.management.resetDeliveryMyKey(id),
    onSuccess: (dispatch, response) => {
      notifySuccess("MyKey has been reset successfully");
      dispatch(getDeliveriesMyKeys(management_id));
    },
    errorPath: "data.description",
  });

export const allowAccessDeliveryMyKey = (id, management_id) =>
  asyncCreator({
    actionId: "allowAccessDeliveryMyKey",
    apiCall: (api) => api.management.allowAccessDeliveryMyKey(id),
    onSuccess: (dispatch, response) => {
      notifySuccess("Access re-allowed succesfully");
      dispatch(getDeliveriesMyKeys(management_id));
    },
    errorPath: "data.description",
  });

export const denyAccessDeliveryMyKey = (id, management_id) =>
  asyncCreator({
    actionId: "denyAccessDeliveryMyKey",
    apiCall: (api) => api.management.denyAccessDeliveryMyKey(id),
    onSuccess: (dispatch, response) => {
      notifySuccess("Access denied succesfully");
      dispatch(getDeliveriesMyKeys(management_id));
    },
    errorPath: "data.description",
  });

export const getDeliveriesAccessPaginated = (page, sizePerPage, params, management_id) =>
  asyncCreator({
    actionId: "getDeliveriesAccessPaginated",
    apiCall: (api) => api.management.getDeliveriesAccessPaginated(page, sizePerPage, params, management_id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.managementSet(response.data, "deliveriesMyKeysAccess"));
    },
    errorPath: "data.message",
  });

export const getDeliveriesAccessFilters = (management_id) =>
  asyncCreator({
    actionId: "getDeliveriesAccessFilters",
    apiCall: (api) => api.management.getDeliveriesAccessFilters(management_id),
    onSuccess: (dispatch, response) => {
      dispatch(
        Creators.managementSet(response.data, "deliveriesMyKeysAccessFilters", "deliveriesMyKeysAccess")
      );
    },
    errorPath: "data.message",
  });

export const getMetaInformation = (id) =>
  asyncCreator({
    actionId: "getMetaInformation",
    apiCall: (api) => api.management.getMetaInformation(id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.managementSet(response.data, "meta"));
    },
    errorPath: "data.message",
  });

export const updateHeartbeats = (building_id, kiosk_id, value) => {
  return async (dispatch, getState, api) => {
    const heartbeats = _get(getState(), "management.heartbeats", {});
    const newHeartbeats = { ...heartbeats };
    if (newHeartbeats[building_id]) {
      newHeartbeats[building_id]["kiosks"][kiosk_id] = value;

      const statuses = Object.values(newHeartbeats[building_id]["kiosks"]);
      let status;

      if (statuses.every((elem) => elem === true)) {
        status = "online";
      } else if (statuses.every((elem) => elem === false)) {
        status = "offline";
      } else {
        status = "partially_online";
      }

      newHeartbeats[building_id]["status"] = status;
    }
    dispatch(actions.managementSet(newHeartbeats, "heartbeats"));
  };
};

export const companyFirstTouchesMyKey = (id, management_id) =>
  asyncCreator({
    actionId: "firstTouches",
    apiCall: (api) => api.management.companyFirstTouchesMyKey(id),
    isNotifyError: false,
    errorPath: "data.message",
  });

export const updateCompanyAddressLocally = (data) => (dispatch) =>
  dispatch(Creators.managementSet(data, "company"));

export const getCommunities = (management_account_id) =>
  asyncCreator({
    actionId: "getCommunities",
    apiCall: (api) => api.management.getCommunities(management_account_id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.managementSet(response.data.data, "communities"));
    },
    errorPath: "data.message",
  });

export const addCommunity = (data) =>
  asyncCreator({
    actionId: "addCommunity",
    apiCall: (api) => api.management.addCommunity(data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("The community was added successfully.");
      const { selectedManager } = getState().navigation;
      dispatch(getCommunities(selectedManager));
    },
    errorPath: "data.message",
  });

export const updateCommunity = (id, data) =>
  asyncCreator({
    actionId: "updateCommunity",
    apiCall: (api) => api.management.updateCommunity(id, data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("The community was updated successfully.");
      const { selectedManager } = getState().navigation;

      dispatch(getCommunities(selectedManager));
    },
    errorPath: "data.message",
  });

export const deleteCommunity = (id) =>
  asyncCreator({
    actionId: "deleteCommunity",
    apiCall: (api) => api.management.deleteCommunity(id),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("The community was successfully deleted.");
      const { selectedManager } = getState().navigation;
      dispatch(getCommunities(selectedManager));
    },
    errorPath: "data.message",
  });

export const addCommunityKeycom = (params) =>
  asyncCreator({
    actionId: "addCommunityKeycom",
    apiCall: (api) => api.management.activateCommunityKeycom(params),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Keycom successfully added");
    },
    errorPath: "data.message",
  });

export const clearBuildings = () => actions.managementSet(null, "buildings");
export const setBuildings = (buildings) => actions.managementSet(buildings, "buildings");

export default managementReducer;

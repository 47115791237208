import React, { useCallback } from 'react'
import { connect, useSelector } from "react-redux";
import _get from "lodash/get";
import selectors from "redux/selectors";

export const COMPANY_MENU_TITLE = "company_menu_title"
export const COMPANY_MENU_USERS = "company_menu_users"
export const COMPANY_MENU_INFO = "company_menu_info"
export const BUILDING_TITLE = "building_title"
export const BUILDING_MENU_TITLE = "building_menu_title" 
export const BUILDING_ADD_BTN_TEXT = "building_add_btn_text" 
export const BUILDING_CARD_WORD_UNIT = "building_card_word_unit" 
export const BUILDING_CARD_WORD_UNITS = "building_card_word_units" 
export const BUILDING_MENU_DIRECTORY = "building_menu_directory"
export const BUILDING_MENU_UNIT_SETTINGS = "building_menu_unit_settings"
export const BUILDING_MENU_VISITORS = "building_menu_visitors"
export const BUILDING_MENU_INFO = "building_menu_info"
export const TABLE_LISTING = "table_listing" 
export const TABLE_UNIT_SUITE = "table_unit_suite"

export const viewData = {
    residential: {
        company_menu_title: "Companies",
        company_menu_users: "Management Users",
        company_menu_info: "Company Info",
        building_title: "Buildings",
        building_menu_title: "Buildings",
        building_add_btn_text: "Add Building",
        building_card_word_unit: "Unit",
        building_card_word_units: "Units",
        building_menu_directory: "Unit Directory",
        building_menu_unit_settings: "Unit Settings",
        building_menu_visitors: "Resident/Visitors",
        building_menu_info: "Building Info",
        table_listing: "Directory Listing",
        table_unit_suite: "Unit/Suite",
    },
    commercial: {
        company_menu_title: "Ownership",
        company_menu_users: "Ownership Users",
        company_menu_info: "Ownership Info",
        building_title: "Properties",
        building_menu_title: "Properties",
        building_add_btn_text: "Add Property",
        building_card_word_unit: "Tenant",
        building_card_word_units: "Tenants",
        building_menu_directory: "Companies",
        building_menu_visitors: "Employees/Visitors",
        building_menu_info: "Property Info",
        table_listing: "Company Listing",
        table_unit_suite: "Suite",
        building_menu_unit_settings: "Unit Settings",
    },
    coop: {
        company_menu_title: "Companies",
        company_menu_users: "Management Users",
        company_menu_info: "Company Info",
        building_title: "Buildings",
        building_menu_title: "Buildings",
        building_add_btn_text: "Add Building",
        building_card_word_unit: "Unit",
        building_card_word_units: "Units",
        building_menu_directory: "Unit Directory",
        building_menu_visitors: "Resident/Visitors",
        building_menu_info: "Building Info",
        table_listing: "Directory Listing",
        table_unit_suite: "Unit/Suite",
        building_menu_unit_settings: "Unit Settings",
    },
    condo: {
        company_menu_title: "Companies",
        company_menu_users: "Management Users",
        company_menu_info: "Company Info",
        building_title: "Buildings",
        building_menu_title: "Buildings",
        building_add_btn_text: "Add Building",
        building_card_word_unit: "Unit",
        building_card_word_units: "Units",
        building_menu_directory: "Unit Directory",
        building_menu_visitors: "Resident/Visitors",
        building_menu_info: "Building Info",
        table_listing: "Directory Listing",
        table_unit_suite: "Unit/Suite",
        building_menu_unit_settings: "Unit Settings",
    },
    community: {
        company_menu_title: "Ownership",
        company_menu_users: "Company Account Users",
        company_menu_info: "Company Info",
        building_title: "Communities",
        building_menu_title: "Communities",
        building_add_btn_text: "Add Community",
        building_card_word_unit: "Property",
        building_card_word_units: "Properties",
        building_menu_directory: "Property Directory",
        building_menu_visitors: "Resident/Visitors",
        building_menu_info: "Community Info",
        table_listing: "Property Name Listing",
        table_unit_suite: "Property Address",
        building_menu_unit_settings: "Property Settings",
    },
}

export const displayValueByCompanyType = (item, companyType = 'residential') => viewData[companyType.toLowerCase()][item]

export const withCompanyType = (WrappedComponent) => {
    const hocComponent = ({ _selectedManager, _selectedBuildingId, _companyType, ...componentProps }) => {
        const displayValue = (item) => _get(viewData, `${_companyType.toLowerCase()}.${item}`, 'N/A')

        return (<WrappedComponent {...componentProps} displayValue={displayValue} />)
    }

    const mapStateToProps = state => {
        const selectedManager = _get(state, "navigation.selectedManager")
        const selectedBuildingId = _get(state, "navigation.selectedBuilding")
        const companyType = selectors.getCompanyType(state)

        return {
            _selectedManager: selectedManager,
            _selectedBuildingId: selectedBuildingId,
            _companyType: companyType
        };
    };

    return connect(mapStateToProps)(hocComponent)
}

export const useCompanyType = () => {
    const companyType = useSelector(selectors.getCompanyType)

    const displayValue = useCallback(
        (item) => _get(viewData, `${companyType.toLowerCase()}.${item}`, 'N/A'),
        [companyType]
    )

    return displayValue
}

export const displayTitle = (selectedTitle, displayValue) => {
    switch (selectedTitle) {
        case "management companies":
            return displayValue(COMPANY_MENU_TITLE)

        case "management users":
            return displayValue(COMPANY_MENU_USERS)

        case "company info":
            return displayValue(COMPANY_MENU_INFO)

        case "buildings":
            return displayValue(BUILDING_TITLE)

        case "unit directory":
            return displayValue(BUILDING_MENU_DIRECTORY)

        case "resident users":
            return displayValue(BUILDING_MENU_VISITORS)

        case "building info":
            return displayValue(BUILDING_MENU_INFO)

        default:
            return selectedTitle
    }
}
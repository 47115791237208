import React, { Component } from "react";
import { DefaultPlayer as Video } from "react-html5video";
import styled from "styled-components";

export const VideoStyles = styled.div`
  .video-placeholder {
    background: rgba(0, 0, 0, 1);
    width: 80px;
    height: 80px;
    padding: 0.25rem;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    & i {
      color: white;
      font-size: 1.5em;
    }
  }
  img {
    width: 80px;
    height: 80px;
    min-height: 80px;
    max-height: 80px;
    min-width: 80px;
    max-width: 80px;
  }
  .rh5v-DefaultPlayer_component {
    display: flex;
    align-items: center;
    min-height: 80px;
    max-height: 80px;
    min-width: 80px;
    max-width: 80px;
    border-radius: 5px;
    padding: 0;
    font-size: 9px;
  }
  .rh5v-Overlay_inner {
    width: 30px;
    height: 30px;
    margin-left: -12px;
    background: transparent;
  }
  .rh5v-PlayPause_icon {
    padding: 0px;
  }
  .rh5v-PlayPause_component {
    display: flex;
    align-items: center;
  }
  .rh5v-Time_component {
    padding: 0 2px 0 2px;
    display: flex;
    align-items: center;
  }
  .rh5v-Fullscreen_component {
    padding: 0px;
    display: flex;
    align-items: center;
  }
  .rh5v-Fullscreen_icon {
    padding: 0;
  }
  .rh5v-Fullscreen_button {
    padding: 0px;
    width: 20px;
    height: 20px;
  }
  svg.rh5v-Overlay_icon {
    width: 30px;
    height: 30px;
  }
  svg.rh5v-Fullscreen_icon {
    width: 20px;
    height: 20px;
  }
`;

const VideoPlayerStyles = styled.div`
  .video-player-container {
    position: relative;
    width: 80px;
    height: 80px;
  }
  .video-player-placeholder {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    background: rgba(0, 0, 0, 1);
    border-radius: 0.25rem;
    z-index: 1;
  }
  .btn-play {
    position: absolute;
    cursor: pointer;
    z-index: 2;
    color: #fff;
  }
  #poster {
    width: 100%;
    height: 60px;
    min-height: 60px;
  }

  .rh5v-DefaultPlayer_video {
    display: flex;
    align-items: center;
  }
`;
class VideoPlayer extends Component {
  state = {
    showPlaceholder: true,
  };

  setVideoRef = (element) => {
    this.videoEl = element;
  };

  handleClick = () => {
    this.setState({ showPlaceholder: false });
    this.videoEl.videoEl.play();
  };

  render() {
    const { children, ...otherProps } = this.props;
    const { showPlaceholder } = this.state;
    return (
      <VideoPlayerStyles>
        <div className="video-player-container">
          {showPlaceholder && (
            <div className="video-player-placeholder">
              <img src={this.props.poster} id="poster" alt="poster"></img>
              <svg
                height="32"
                viewBox="0 0 48 48"
                width="32"
                xmlns="http://www.w3.org/2000/svg"
                className="btn-play"
                onClick={this.handleClick}
              >
                <path d="M-838-2232H562v3600H-838z" fill="none" />
                <path d="M16 10v28l22-14z" fill="#fff" />
                <path d="M0 0h48v48H0z" fill="none" />
              </svg>
            </div>
          )}
          <Video {...otherProps} ref={this.setVideoRef}>
            {children}
          </Video>
        </div>
      </VideoPlayerStyles>
    );
  }
}

export default VideoPlayer;

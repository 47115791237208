import { MENU_KEYS as BUILDING_MENU_KEYS } from "components/layout/navigation/building-menu/utils";
import { MENU_KEYS as RESIDENT_MENU_KEYS } from "components/layout/navigation/resident-menu/utils.js";
import { MENU_KEYS as SECONDARY_RESIDENT_MENU_KEYS } from "components/layout/navigation/secondary-menu/utils.js";

/*
these features ->
MVI_APP_SEND_VISITOR_MYKEY_VIA_EMAIL: "mvi_app_send_visitor_mykey_via_email",
MVI_APP_SEND_VISITOR_MYKEY_VIA_SMS: "mvi_app_send_visitor_mykey_via_sms",

implements like these

PRIMARY_RESIDENT_SEND_VISITOR_MYKEY_EMAIL PRIMARY_RESIDENT_SEND_VISITOR_MYKEY_SMS
SECONDARY_RESIDENT_SEND_VISITOR_MYKEY_EMAIL SECONDARY_RESIDENT_SEND_VISITOR_MYKEY_SMS

*/

export const accessValues = {
  DIGITAL_VISITOR_MYKEYS_ALLOWED_PER_UNIT_PER_MONTH: "digital_visitor_mykeys_allowed_per_unit_per_month",
  BUILDING_WIDE_MESSAGING: "building_wide_messaging",
  BUILDING_CALENDAR: "building_calendar",
  SECONDARY_USERS_ALLOWED_PER_UNIT: "secondary_users_allowed_per_unit",

  CAN_CHANGE_ROOMMATE_MODE: "can_change_roommate_mode",
  CAN_CHANGE_SEND_VISITOR_MYKEYS: "can_change_send_visitor_mykeys",
  CAN_CHANGE_ADD_SECONDARY_USER: "can_change_add_secondary_user",
  CAN_CHANGE_APP_TO_POTS_CALL_FAILOVER: "can_change_app_to_pots_call_failover",
  CAN_CHANGE_ACCESS_PIN_ALLOWED: "can_change_access_pin_allowed",
  CAN_HIDE_RESIDENT_NAMES: "can_hide_resident_names",
  CAN_HIDE_UNIT_NUMBERS: "can_hide_unit_numbers",

  ABILITY_FOR_RESIDENTS_TO_REMOTELY_CALL_INTERCOM: "ability_for_residents_to_remotely_call_intercom",
  ABILITY_FOR_RESIDENTS_TO_REMOTELY_UNLOCK_DOORS: "ability_for_residents_to_remotely_unlock_doors",
  ELEVATOR_ACCESS_CONTROL_CAPABILITY: "elevator_access_control_capability",
  FACIAL_RECOGNITION_AND_ARTIFICIAL_INTELLIGENCE: "facial_recognition_and_artificial_intelligence",
  AUTOMATIC_FLAGS_AND_EMAIL_ALERTS: "automatic_flags_and_email_alerts",
  DAY_NON_PRIMARY_ACCESS_FLAGS_AND_ALERTING: "day_non_primary_access_flags_and_alerting",
  STATISTICAL_REPORTS_AND_BUILDING_ANALYTICS: "statistical_reports_and_building_analytics",
  VIDEO_LOG_OF_ALL_ACCESS_EVENT_SESSIONS: "video_log_of_all_access_event_sessions",
  HIGH_SECURITY_CONTROLS_OF_RESIDENT_ACCESS_METHODS: "high_security_controls_of_resident_access_methods",
  ADVANCED_PACKAGE_ROOM_MANAGEMENT_SYSTEM_PRICE: "advanced_package_room_management_system_price",
  COMMERCIAL_KEYCOM_AND_WEB_PORTAL_VERSION: "commercial_keycom_and_web_portal_version",
  ILLEGAL_SUBLETTING_AND_UNRECOGNIZED_TAILGATER_FLAGS: "illegal_subletting_and_unrecognized_tailgater_flags",
  RESIDENT_TRACKING_AND_AUDIT_TRAIL: "resident_tracking_and_audit_trail",
  VISITOR_TRACKING_AND_AUDIT_TRAIL: "visitor_tracking_and_audit_trail",
  VENDOR_TRACKING_AND_AUDIT_TRAIL: "vendor_tracking_and_audit_trail",
  DELIVERY_TRACKING_AND_AUDIT_TRAIL: "delivery_tracking_and_audit_trail",
  PHOTO_AUDIT_TRAIL_OF_ALL_ACCESS_EVENTS: "photo_audit_trail_of_all_access_events",
  ENHANCED_FACIAL_RECOGNITION_SECURITY: "enhanced_facial_recognition_security",
  RENT_PAYMENT_VIA_APP: "rent_payment_via_app",
  MAINTENANCE_TRACKING_TICKETING_SYSTEM: "maintenance_tracking_ticketing_system",
  TOTAL_DEVICES_PER_BUILDING: "total_devices_per_building",
  MVI_APP_MY_PROFILE: "mvi_app_my_profile",
  MVI_APP_SETTINGS: "mvi_app_settings",
  MVI_APP_PROMOS: "mvi_app_promos",
  MVI_APP_SETTINGS_RECEIVE_APP_CALLS: "mvi_app_settings_receive_app_calls",
  MVI_APP_CONTACT_US: "mvi_app_contact_us",
  MVI_APP_MESSAGES: "mvi_app_messages",
  MVI_APP_CONNECT_TO_KEYCOM: "mvi_app_connect_to_keycom",
  MVI_APP_DIGITAL_MYKEY: "mvi_app_digital_mykey",
  MVI_APP_SETTINGS_PERSONAL_NOTIFICATIONS: "mvi_app_settings_personal_notifications",
  MVI_APP_SETTINGS_BUILDING_NOTIFICATIONS: "mvi_app_settings_building_notifications",
  MVI_APP_ACTIVITIES: "mvi_app_activities",
  MVI_APP_ADDING_NEW_SECONDARY_USER_VIA_EMAIL: "mvi_app_adding_new_secondary_user_via_email",
  MVI_APP_ADDING_NEW_SECONDARY_USER_VIA_SMS: "mvi_app_adding_new_secondary_user_via_sms",
  MVI_APP_EXISTING_SECONDARY_USERS: "mvi_app_existing_secondary_users",
  MVI_APP_BUILDING_NOTICES: "mvi_app_building_notices",
  MVI_APP_BUILDING_CALENDAR_EVENTS: "mvi_app_building_calendar_events",
  MVI_APP_DELIVERIES: "mvi_app_deliveries",
  MVI_APP_MAINTENANCE: "mvi_app_maintenance",
  MVI_APP_BUILDING_MESSAGING: "mvi_app_building_messaging",
  MVI_APP_REMOTE_OPEN_DOOR: "mvi_app_remote_open_door",

  PRIMARY_RESIDENT_PROMOS: "primary_resident_promos",
  PRIMARY_RESIDENT_MY_ACCOUNT: "primary_resident_my_account",
  PRIMARY_RESIDENT_KEYCOM_INFO: "primary_resident_keycom_info",
  PRIMARY_RESIDENT_MESSAGES: "primary_resident_messages",
  PRIMARY_RESIDENT_BULLETIN_BOARD: "primary_resident_bulletin_board",
  PRIMARY_RESIDENT_ACTIVITY: "primary_resident_activity",
  PRIMARY_RESIDENT_MANAGE_USERS: "primary_resident_manage_users",
  PRIMARY_RESIDENT_ADD_NEW_ACCOUNT_USER: "primary_resident_add_new_account_user",
  PRIMARY_RESIDENT_SEND_VISITOR_MYKEY_EMAIL: "primary_resident_send_visitor_mykey_email",
  PRIMARY_RESIDENT_SEND_VISITOR_MYKEY_SMS: "primary_resident_send_visitor_mykey_sms",
  PRIMARY_RESIDENT_VOICE_MAIL: "primary_resident_voice_mail",
  PRIMARY_RESIDENT_BILING_PORTAL: "primary_resident_biling_portal",
  PRIMARY_RESIDENT_TRAINING_VIDEOS: "primary_resident_training_videos",

  SECONDARY_RESIDENT_MESSAGES: "secondary_resident_messages",
  SECONDARY_RESIDENT_ACTIVITY: "secondary_resident_activity",
  SECONDARY_RESIDENT_PROMOS: "secondary_resident_promos",
  SECONDARY_RESIDENT_BULLETIN_BOARD: "secondary_resident_bulletin_board",
  SECONDARY_RESIDENT_MY_ACCOUNT: "secondary_resident_my_account",
  SECONDARY_RESIDENT_VOICE_MAIL: "secondary_resident_voice_mail",
  SECONDARY_RESIDENT_VISITOR_MYKEY: "secondary_resident_visitor_mykey",
  SECONDARY_RESIDENT_SEND_VISITOR_MYKEY_EMAIL: "secondary_resident_send_visitor_mykey_email",
  SECONDARY_RESIDENT_SEND_VISITOR_MYKEY_SMS: "secondary_resident_send_visitor_mykey_sms",
  SECONDARY_RESIDENT_TRAINING_VIDEOS: "secondary_resident_training_videos",

  MANAGEMENT_UNIT_DIRECTORY: "management_unit_directory",
  MANAGEMENT_UNIT_SETTINGS: "management_unit_settings",
  MANAGEMENT_BUILDING_INFO: "management_building_info",
  MANAGEMENT_DEVICES: "management_devices",
  MANAGEMENT_ACCESS_LIST: "management_access_list",
  MANAGEMENT_ENTRATA: "management_entrata",
  MANAGEMENT_BRIVO: "management_brivo",
  MANAGEMENT_PDK: "management_pdk",
  MANAGEMENT_HARTMANN: "management_hartmann",
  MANAGEMENT_ADD_MYKEY_SET_VENDOR: "management_add_mykey_set_vendor",
  MANAGEMENT_ADD_MYKEY_SET_DELIVERIES: "management_add_mykey_set_deliveries",
  MANAGEMENT_ADD_SECONDARY_USER: "management_add_secondary_user",
  MANAGEMENT_BULLETIN_BOARD: "management_bulletin_board",
  MANAGEMENT_RESIDENT_VISITORS: "management_resident_visitors",
  MANAGEMENT_ACCOUNT_CHANGES: "management_account_changes",
  MANAGEMENT_ACCESS_LOG: "management_access_log",
  MANAGEMENT_MESSAGES: "management_messages",
  MANAGEMENT_MAINTENANCE: "management_maintenance",
  MANAGEMENT_PROMOS: "management_promos",
  MANAGEMENT_EVENTS_VIDEO_LOG: "management_events_video_log",
  MANAGEMENT_PUSH_NOTIFICATIONS: "management_push_notifications",
  MANAGEMENT_ACCOUNTS_ACTIVATED: "management_accounts_activated",
  MANAGEMENT_MYKEYS_ACTIVATED: "management_mykeys_activated",
  MANAGEMENT_MOBILE_APPS_ACTIVATED: "management_mobile_apps_activated",
  MANAGEMENT_ALL_ACCESS_EVENTS: "management_all_access_events",
  MANAGEMENT_ALL_ACCESS_EVENTS_BY_USER_TYPE: "management_all_access_events_by_user_type",
  MANAGEMENT_SEND_MYKEY_LINK_VENDOR: "management_send_mykey_link_vendor",
  MANAGEMENT_FLAGS: "management_flags",
  MANAGEMENT_ALERTS: "management_alerts",
  MANAGEMENT_ANALYTICS: "management_analytics",

  CALLS_POTS_CALLING: "calls_pots_calling",
  CALLS_APP_CALLING_ONLY_PICTURE_OF_VISITOR: "calls_app_calling_only_picture_of_visitor",
  CALLS_APP_CALLING_FULL_2_WAY: "calls_app_calling_full_2_way",
  CALLS_SHARING_VIDEO: "calls_sharing_video",
  CALLS_PC_APP_CALLING: "calls_pc_app_calling",
  CALLS_CALL_TO_KEYCOM_FROM_APP: "calls_call_to_keycom_from_app",

  ACCESS_CONTROL_MYKEY_TABS: "access_control_mykey_tabs",
  ACCESS_CONTROL_ACCESS_PIN_ACCESS: "access_control_access_pin_access",
  ACCESS_CONTROL_DIGITAL_MYKEY: "access_control_digital_mykey",
  ACCESS_CONTROL_VENDOR_MYKEY: "access_control_vendor_mykey",
  ACCESS_CONTROL_DELIVERY_MYKEY: "access_control_delivery_mykey",
  ACCESS_CONTROL_BLUETOOTH_ACCESS: "access_control_bluetooth_access",
  ACCESS_CONTROL_VISITOR_MYKEY: "access_control_visitor_mykey",
  ACCESS_CONTROL_VENDOR_ACCESS: "access_control_vendor_access",
  ACCESS_CONTROL_SEND_MYKEY_LINK_FOR_VENDOR: "access_control_send_mykey_link_for_vendor",
  ACCESS_CONTROL_DELIVERIES_ACCESS: "access_control_deliveries_access",

  KEYCOM_DOOR_OPEN_TIME: "keycom_door_open_time",
  KEYCOM_DIFFERENT_LANGUAGES: "keycom_different_languages",
  KEYCOM_ENTERING_WITHOUT_INTERNET_CONNECTION: "keycom_entering_without_internet_connection",
  KEYCOM_HELP_VIDEO: "keycom_help_video",
  KEYCOM_REX_ENTRY: "keycom_rex_entry",
  KEYCOM_ENABLE_SABBATH_MODE: "keycom_enable_sabbath_mode",
  KEYCOM_HIDE_RESIDENT_NAMES: "keycom_hide_resident_names",
  KEYCOM_HIDE_UNIT_NUMBERS: "keycom_hide_unit_numbers",
  KEYCOM_ENABLE_FACIAL_LIGHTS: "keycom_enable_facial_lights",
  KEYCOM_BLUE_LEDS: "keycom_blue_leds",
  KEYCOM_NEWSFEED: "keycom_newsfeed",
  KEYCOM_HOMESCREEN_MAIN_OPTION: "keycom_homescreen_main_option",
  KEYCOM_HOMESCREEN_SUB_OPTION: "keycom_homescreen_sub_option",
  KEYCOM_NO_TOUCH_PROXIMITY: "keycom_no_touch_proximity",
  KEYCOM_BLUETOOTH_PROXIMITY: "keycom_bluetooth_proximity",
  KEYCOM_RECORD_VIDEO_VOICE_MESSAGE: "keycom_record_video_voice_message",
  KEYCOM_VVM_MESSAGES: "keycom_vvm_messages",
  KEYCOM_MANAGEMENT_BUTTON_TEXT: "keycom_management_button_text",
  KEYCOM_MANAGEMENT_BUTTON_OPTION: "keycom_management_button_option",
  KEYCOM_VIRTUAL_TOUR_URL: "keycom_virtual_tour_url",
};

/****************************** Web App Section */

/****** Features */
export const webAppFeatures = {
  [accessValues.BUILDING_CALENDAR]: accessValues.BUILDING_CALENDAR,

  [accessValues.CAN_CHANGE_ROOMMATE_MODE]: accessValues.CAN_CHANGE_ROOMMATE_MODE,
  [accessValues.CAN_CHANGE_SEND_VISITOR_MYKEYS]: accessValues.CAN_CHANGE_SEND_VISITOR_MYKEYS,
  [accessValues.CAN_CHANGE_ADD_SECONDARY_USER]: accessValues.CAN_CHANGE_ADD_SECONDARY_USER,
  [accessValues.CAN_CHANGE_APP_TO_POTS_CALL_FAILOVER]: accessValues.CAN_CHANGE_APP_TO_POTS_CALL_FAILOVER,
  [accessValues.CAN_CHANGE_ACCESS_PIN_ALLOWED]: accessValues.CAN_CHANGE_ACCESS_PIN_ALLOWED,
  [accessValues.CAN_HIDE_RESIDENT_NAMES]: accessValues.CAN_HIDE_RESIDENT_NAMES,
  [accessValues.CAN_HIDE_UNIT_NUMBERS]: accessValues.CAN_HIDE_UNIT_NUMBERS,

  [accessValues.ELEVATOR_ACCESS_CONTROL_CAPABILITY]: accessValues.ELEVATOR_ACCESS_CONTROL_CAPABILITY,
  [accessValues.FACIAL_RECOGNITION_AND_ARTIFICIAL_INTELLIGENCE]:
    accessValues.FACIAL_RECOGNITION_AND_ARTIFICIAL_INTELLIGENCE,
  [accessValues.AUTOMATIC_FLAGS_AND_EMAIL_ALERTS]: accessValues.AUTOMATIC_FLAGS_AND_EMAIL_ALERTS,
  [accessValues.STATISTICAL_REPORTS_AND_BUILDING_ANALYTICS]:
    accessValues.STATISTICAL_REPORTS_AND_BUILDING_ANALYTICS,
  [accessValues.VIDEO_LOG_OF_ALL_ACCESS_EVENT_SESSIONS]: accessValues.VIDEO_LOG_OF_ALL_ACCESS_EVENT_SESSIONS,
  [accessValues.PHOTO_AUDIT_TRAIL_OF_ALL_ACCESS_EVENTS]: accessValues.PHOTO_AUDIT_TRAIL_OF_ALL_ACCESS_EVENTS,
  [accessValues.ENHANCED_FACIAL_RECOGNITION_SECURITY]: accessValues.ENHANCED_FACIAL_RECOGNITION_SECURITY,

  [accessValues.PRIMARY_RESIDENT_ADD_NEW_ACCOUNT_USER]: accessValues.PRIMARY_RESIDENT_ADD_NEW_ACCOUNT_USER,
  [accessValues.PRIMARY_RESIDENT_SEND_VISITOR_MYKEY_EMAIL]:
    accessValues.PRIMARY_RESIDENT_SEND_VISITOR_MYKEY_EMAIL,
  [accessValues.PRIMARY_RESIDENT_SEND_VISITOR_MYKEY_SMS]:
    accessValues.PRIMARY_RESIDENT_SEND_VISITOR_MYKEY_SMS,
  [accessValues.PRIMARY_RESIDENT_VOICE_MAIL]: accessValues.PRIMARY_RESIDENT_VOICE_MAIL,

  [accessValues.SECONDARY_RESIDENT_VOICE_MAIL]: accessValues.SECONDARY_RESIDENT_VOICE_MAIL,
  [accessValues.SECONDARY_RESIDENT_SEND_VISITOR_MYKEY_EMAIL]:
    accessValues.SECONDARY_RESIDENT_SEND_VISITOR_MYKEY_EMAIL,
  [accessValues.SECONDARY_RESIDENT_SEND_VISITOR_MYKEY_SMS]:
    accessValues.SECONDARY_RESIDENT_SEND_VISITOR_MYKEY_SMS,

  [accessValues.SECONDARY_USERS_ALLOWED_PER_UNIT]: accessValues.SECONDARY_USERS_ALLOWED_PER_UNIT,

  [accessValues.MANAGEMENT_ADD_MYKEY_SET_VENDOR]: accessValues.MANAGEMENT_ADD_MYKEY_SET_VENDOR,
  [accessValues.MANAGEMENT_ADD_MYKEY_SET_DELIVERIES]: accessValues.MANAGEMENT_ADD_MYKEY_SET_DELIVERIES,
  [accessValues.MANAGEMENT_ADD_SECONDARY_USER]: accessValues.MANAGEMENT_ADD_SECONDARY_USER,
  [accessValues.MANAGEMENT_EVENTS_VIDEO_LOG]: accessValues.MANAGEMENT_EVENTS_VIDEO_LOG,
  [accessValues.MANAGEMENT_PUSH_NOTIFICATIONS]: accessValues.MANAGEMENT_PUSH_NOTIFICATIONS,
  [accessValues.MANAGEMENT_ACCOUNTS_ACTIVATED]: accessValues.MANAGEMENT_ACCOUNTS_ACTIVATED,
  [accessValues.MANAGEMENT_MYKEYS_ACTIVATED]: accessValues.MANAGEMENT_MYKEYS_ACTIVATED,
  [accessValues.MANAGEMENT_MOBILE_APPS_ACTIVATED]: accessValues.MANAGEMENT_MOBILE_APPS_ACTIVATED,
  [accessValues.MANAGEMENT_ALL_ACCESS_EVENTS]: accessValues.MANAGEMENT_ALL_ACCESS_EVENTS,
  [accessValues.MANAGEMENT_ALL_ACCESS_EVENTS_BY_USER_TYPE]:
    accessValues.MANAGEMENT_ALL_ACCESS_EVENTS_BY_USER_TYPE,
  [accessValues.MANAGEMENT_SEND_MYKEY_LINK_VENDOR]: accessValues.MANAGEMENT_SEND_MYKEY_LINK_VENDOR,

  [accessValues.MVI_APP_MY_PROFILE]: accessValues.MVI_APP_MY_PROFILE,
  [accessValues.MVI_APP_SETTINGS]: accessValues.MVI_APP_SETTINGS,
  [accessValues.MVI_APP_PROMOS]: accessValues.MVI_APP_PROMOS,
  [accessValues.MVI_APP_SETTINGS_RECEIVE_APP_CALLS]: accessValues.MVI_APP_SETTINGS_RECEIVE_APP_CALLS,
  [accessValues.MVI_APP_CONTACT_US]: accessValues.MVI_APP_CONTACT_US,
  [accessValues.MVI_APP_MESSAGES]: accessValues.MVI_APP_MESSAGES,
  [accessValues.MVI_APP_CONNECT_TO_KEYCOM]: accessValues.MVI_APP_CONNECT_TO_KEYCOM,
  [accessValues.MVI_APP_DIGITAL_MYKEY]: accessValues.MVI_APP_DIGITAL_MYKEY,
  [accessValues.MVI_APP_SETTINGS_PERSONAL_NOTIFICATIONS]:
    accessValues.MVI_APP_SETTINGS_PERSONAL_NOTIFICATIONS,
  [accessValues.MVI_APP_SETTINGS_BUILDING_NOTIFICATIONS]:
    accessValues.MVI_APP_SETTINGS_BUILDING_NOTIFICATIONS,
  [accessValues.MVI_APP_ACTIVITIES]: accessValues.MVI_APP_ACTIVITIES,
  [accessValues.MVI_APP_ADDING_NEW_SECONDARY_USER_VIA_EMAIL]:
    accessValues.MVI_APP_ADDING_NEW_SECONDARY_USER_VIA_EMAIL,
  [accessValues.MVI_APP_ADDING_NEW_SECONDARY_USER_VIA_SMS]:
    accessValues.MVI_APP_ADDING_NEW_SECONDARY_USER_VIA_SMS,
  [accessValues.MVI_APP_EXISTING_SECONDARY_USERS]: accessValues.MVI_APP_EXISTING_SECONDARY_USERS,
  [accessValues.MVI_APP_BUILDING_NOTICES]: accessValues.MVI_APP_BUILDING_NOTICES,
  [accessValues.MVI_APP_BUILDING_CALENDAR_EVENTS]: accessValues.MVI_APP_BUILDING_CALENDAR_EVENTS,
  [accessValues.MVI_APP_DELIVERIES]: accessValues.MVI_APP_DELIVERIES,
  [accessValues.MVI_APP_MAINTENANCE]: accessValues.MVI_APP_MAINTENANCE,
  [accessValues.MVI_APP_BUILDING_MESSAGING]: accessValues.MVI_APP_BUILDING_MESSAGING,
  [accessValues.MVI_APP_REMOTE_OPEN_DOOR]: accessValues.MVI_APP_REMOTE_OPEN_DOOR,

  [accessValues.KEYCOM_DOOR_OPEN_TIME]: accessValues.KEYCOM_DOOR_OPEN_TIME,
  [accessValues.KEYCOM_DIFFERENT_LANGUAGES]: accessValues.KEYCOM_DIFFERENT_LANGUAGES,
  [accessValues.KEYCOM_ENTERING_WITHOUT_INTERNET_CONNECTION]:
    accessValues.KEYCOM_ENTERING_WITHOUT_INTERNET_CONNECTION,
  [accessValues.KEYCOM_HELP_VIDEO]: accessValues.KEYCOM_HELP_VIDEO,
  [accessValues.KEYCOM_REX_ENTRY]: accessValues.KEYCOM_REX_ENTRY,
  [accessValues.KEYCOM_ENABLE_SABBATH_MODE]: accessValues.KEYCOM_ENABLE_SABBATH_MODE,
  [accessValues.KEYCOM_ENABLE_FACIAL_LIGHTS]: accessValues.KEYCOM_ENABLE_FACIAL_LIGHTS,
  [accessValues.KEYCOM_BLUE_LEDS]: accessValues.KEYCOM_BLUE_LEDS,
  [accessValues.KEYCOM_NEWSFEED]: accessValues.KEYCOM_NEWSFEED,
  [accessValues.KEYCOM_HOMESCREEN_MAIN_OPTION]: accessValues.KEYCOM_HOMESCREEN_MAIN_OPTION,
  [accessValues.KEYCOM_HOMESCREEN_SUB_OPTION]: accessValues.KEYCOM_HOMESCREEN_SUB_OPTION,
  [accessValues.KEYCOM_NO_TOUCH_PROXIMITY]: accessValues.KEYCOM_NO_TOUCH_PROXIMITY,
  [accessValues.KEYCOM_BLUETOOTH_PROXIMITY]: accessValues.KEYCOM_BLUETOOTH_PROXIMITY,
  [accessValues.KEYCOM_RECORD_VIDEO_VOICE_MESSAGE]: accessValues.KEYCOM_RECORD_VIDEO_VOICE_MESSAGE,
  [accessValues.KEYCOM_VVM_MESSAGES]: accessValues.KEYCOM_VVM_MESSAGES,
  [accessValues.KEYCOM_MANAGEMENT_BUTTON_TEXT]: accessValues.KEYCOM_MANAGEMENT_BUTTON_TEXT,
  [accessValues.KEYCOM_MANAGEMENT_BUTTON_OPTION]: accessValues.KEYCOM_MANAGEMENT_BUTTON_OPTION,
  [accessValues.KEYCOM_VIRTUAL_TOUR_URL]: accessValues.KEYCOM_VIRTUAL_TOUR_URL,

  [accessValues.TOTAL_DEVICES_PER_BUILDING]: accessValues.TOTAL_DEVICES_PER_BUILDING,
  [accessValues.DIGITAL_VISITOR_MYKEYS_ALLOWED_PER_UNIT_PER_MONTH]:
    accessValues.DIGITAL_VISITOR_MYKEYS_ALLOWED_PER_UNIT_PER_MONTH,
};

/****** Menus */
export const adapterBuildingMenuNames = {
  //***************** CHECKED menu items
  [BUILDING_MENU_KEYS.DIRECTORY]: accessValues.MANAGEMENT_UNIT_DIRECTORY,
  [BUILDING_MENU_KEYS.VISITORS]: accessValues.MANAGEMENT_RESIDENT_VISITORS,
  [BUILDING_MENU_KEYS.ACCOUNTCHANGES]: accessValues.MANAGEMENT_ACCOUNT_CHANGES,
  [BUILDING_MENU_KEYS.ACCESSLOG]: accessValues.MANAGEMENT_ACCESS_LOG,
  [BUILDING_MENU_KEYS.FLAGS]: accessValues.MANAGEMENT_FLAGS,
  [BUILDING_MENU_KEYS.ALERTS]: accessValues.MANAGEMENT_ALERTS,
  [BUILDING_MENU_KEYS.ANALYTICS]: accessValues.MANAGEMENT_ANALYTICS,
  [BUILDING_MENU_KEYS.MESSAGES]: accessValues.MANAGEMENT_MESSAGES,
  [BUILDING_MENU_KEYS.MAINTENANCE]: accessValues.MANAGEMENT_MAINTENANCE,
  [BUILDING_MENU_KEYS.PROMOS]: accessValues.MANAGEMENT_PROMOS,
  [BUILDING_MENU_KEYS.BULLETINBOARD]: accessValues.MANAGEMENT_BULLETIN_BOARD,
  [BUILDING_MENU_KEYS.INFO]: accessValues.MANAGEMENT_BUILDING_INFO,
  [BUILDING_MENU_KEYS.DEVICES]: accessValues.MANAGEMENT_DEVICES,
  [BUILDING_MENU_KEYS.UNITSETTINGS]: accessValues.MANAGEMENT_UNIT_SETTINGS,
  [BUILDING_MENU_KEYS.ACCESSLIST]: accessValues.MANAGEMENT_ACCESS_LIST,
  [BUILDING_MENU_KEYS.ENTRATA]: accessValues.MANAGEMENT_ENTRATA,
  [BUILDING_MENU_KEYS.ENTRATA_AUTH]: accessValues.MANAGEMENT_ENTRATA,
  [BUILDING_MENU_KEYS.ENTRATA_SETTINGS]: accessValues.MANAGEMENT_ENTRATA,
  [BUILDING_MENU_KEYS.ENTRATA_SYNC]: accessValues.MANAGEMENT_ENTRATA,
  [BUILDING_MENU_KEYS.BRIVO]: accessValues.MANAGEMENT_BRIVO,
  [BUILDING_MENU_KEYS.BRIVO_AUTH]: accessValues.MANAGEMENT_BRIVO,
  [BUILDING_MENU_KEYS.BRIVO_SETTINGS]: accessValues.MANAGEMENT_BRIVO,
  [BUILDING_MENU_KEYS.BRIVO_SYNC]: accessValues.MANAGEMENT_BRIVO,
  [BUILDING_MENU_KEYS.PDK]: accessValues.MANAGEMENT_PDK,
  [BUILDING_MENU_KEYS.PDK_AUTH]: accessValues.MANAGEMENT_PDK,
  [BUILDING_MENU_KEYS.PDK_SETTINGS]: accessValues.MANAGEMENT_PDK,
  [BUILDING_MENU_KEYS.PDK_SYNC]: accessValues.MANAGEMENT_PDK,
  [BUILDING_MENU_KEYS.HARTMANN]: accessValues.MANAGEMENT_HARTMANN,
  [BUILDING_MENU_KEYS.HARTMANN_AUTH]: accessValues.MANAGEMENT_HARTMANN,
  [BUILDING_MENU_KEYS.HARTMANN_SETTINGS]: accessValues.MANAGEMENT_HARTMANN,
  [BUILDING_MENU_KEYS.HARTMANN_SYNC]: accessValues.MANAGEMENT_HARTMANN,
};

export const adapterResidentMenuNames = {
  [RESIDENT_MENU_KEYS.MESSAGES]: accessValues.PRIMARY_RESIDENT_MESSAGES,
  [RESIDENT_MENU_KEYS.ACTIVITY]: accessValues.PRIMARY_RESIDENT_ACTIVITY,
  [RESIDENT_MENU_KEYS.PROMOS]: accessValues.PRIMARY_RESIDENT_PROMOS,
  [RESIDENT_MENU_KEYS.BULLETINBOARD]: accessValues.PRIMARY_RESIDENT_BULLETIN_BOARD,
  [RESIDENT_MENU_KEYS.MYACCOUNTSECTION]: accessValues.PRIMARY_RESIDENT_MY_ACCOUNT,
  [RESIDENT_MENU_KEYS.MANAGEUSERS]: accessValues.PRIMARY_RESIDENT_MANAGE_USERS,
  [RESIDENT_MENU_KEYS.KEYCOMINFO]: accessValues.PRIMARY_RESIDENT_KEYCOM_INFO,
  [RESIDENT_MENU_KEYS.BILLINGPORTAL]: accessValues.PRIMARY_RESIDENT_BILING_PORTAL,
  [RESIDENT_MENU_KEYS.TRAININGVIDEOS]: accessValues.PRIMARY_RESIDENT_TRAINING_VIDEOS,
};

export const adapterSecondaryResidentMenuNames = {
  [SECONDARY_RESIDENT_MENU_KEYS.MESSAGES]: accessValues.SECONDARY_RESIDENT_MESSAGES,
  [SECONDARY_RESIDENT_MENU_KEYS.ACTIVITY]: accessValues.SECONDARY_RESIDENT_ACTIVITY,
  [SECONDARY_RESIDENT_MENU_KEYS.PROMOS]: accessValues.SECONDARY_RESIDENT_PROMOS,
  [SECONDARY_RESIDENT_MENU_KEYS.BULLETINBOARD]: accessValues.SECONDARY_RESIDENT_BULLETIN_BOARD,
  [SECONDARY_RESIDENT_MENU_KEYS.MYACCOUNTSECTION]: accessValues.SECONDARY_RESIDENT_MY_ACCOUNT,
  [SECONDARY_RESIDENT_MENU_KEYS.MYKEYS]: accessValues.SECONDARY_RESIDENT_VISITOR_MYKEY,
  [SECONDARY_RESIDENT_MENU_KEYS.TRAININGVIDEOS]: accessValues.SECONDARY_RESIDENT_TRAINING_VIDEOS,
};

export const menuPermissionNames = {
  ...adapterBuildingMenuNames,
  ...adapterResidentMenuNames,
  ...adapterSecondaryResidentMenuNames,
};

export const allWebAppFeatures = {
  ...webAppFeatures,
  ...menuPermissionNames,
};

/* *************************** End Web App Section */

/****************************** Features Section */
export const featuresNames = {
  [accessValues.ACCESS_CONTROL_MYKEY_TABS]: accessValues.ACCESS_CONTROL_MYKEY_TABS,
  [accessValues.ACCESS_CONTROL_ACCESS_PIN_ACCESS]: accessValues.ACCESS_CONTROL_ACCESS_PIN_ACCESS,
  [accessValues.ACCESS_CONTROL_DIGITAL_MYKEY]: accessValues.ACCESS_CONTROL_DIGITAL_MYKEY,
  [accessValues.ACCESS_CONTROL_VENDOR_MYKEY]: accessValues.ACCESS_CONTROL_VENDOR_MYKEY,
  [accessValues.ACCESS_CONTROL_DELIVERY_MYKEY]: accessValues.ACCESS_CONTROL_DELIVERY_MYKEY,
  [accessValues.ACCESS_CONTROL_BLUETOOTH_ACCESS]: accessValues.ACCESS_CONTROL_BLUETOOTH_ACCESS,
  [accessValues.ACCESS_CONTROL_VISITOR_MYKEY]: accessValues.ACCESS_CONTROL_VISITOR_MYKEY,
  [accessValues.ACCESS_CONTROL_VENDOR_ACCESS]: accessValues.ACCESS_CONTROL_VENDOR_ACCESS,
  [accessValues.ACCESS_CONTROL_SEND_MYKEY_LINK_FOR_VENDOR]:
    accessValues.ACCESS_CONTROL_SEND_MYKEY_LINK_FOR_VENDOR,
  [accessValues.ACCESS_CONTROL_DELIVERIES_ACCESS]: accessValues.ACCESS_CONTROL_DELIVERIES_ACCESS,
};

/* **************************** End Features Section */

/****************************** Mobile App Section */
export const mobileAppsNames = {
  [accessValues.CALLS_POTS_CALLING]: accessValues.CALLS_POTS_CALLING,
  [accessValues.CALLS_APP_CALLING_ONLY_PICTURE_OF_VISITOR]:
    accessValues.CALLS_APP_CALLING_ONLY_PICTURE_OF_VISITOR,
  [accessValues.CALLS_APP_CALLING_FULL_2_WAY]: accessValues.CALLS_APP_CALLING_FULL_2_WAY,
  [accessValues.CALLS_SHARING_VIDEO]: accessValues.CALLS_SHARING_VIDEO,
  [accessValues.CALLS_CALL_TO_KEYCOM_FROM_APP]: accessValues.CALLS_CALL_TO_KEYCOM_FROM_APP,
  [accessValues.CALLS_PC_APP_CALLING]: accessValues.CALLS_PC_APP_CALLING,
};
/* **************************** End Mobile App Section */

export const workedFeatures = [
  // building menu
  ...Object.values(adapterBuildingMenuNames),

  // primary resident
  ...Object.values(adapterResidentMenuNames),

  // secondary resident
  ...Object.values(adapterSecondaryResidentMenuNames),
];

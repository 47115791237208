    import React, { Component } from "react";
    import StackTrace from "stacktrace-js";

    window.onerror = function(msg, file, line, col, error) {
      StackTrace.fromError(error).then(err => {
        StackTrace.report(
          err,
          `//${window.location.hostname}:${process.env.REACT_APP_LOGGER_PORT || 3334}/jsnlog.logger`,
          {
            type: "window.onerror",
            url: window.location.href,
            userId: window.userId,
            agent: window.navigator.userAgent,
            date: new Date(),
            msg: msg
          }
        );
      });
    };

    class ErrorBoundary extends Component {
      constructor(props) {
        super(props);
        this.state = { error: null };
      }

      componentDidCatch(error, errorInfo) {
        this.setState({ error });
        StackTrace.fromError(error).then(err => {
          StackTrace.report(
            err,
            `//${window.location.hostname}:${process.env.REACT_APP_LOGGER_PORT || 3334}/jsnlog.logger`,
            {
              type: "React boundary",
              url: window.location.href,
              userId: window.userId,
              agent: window.navigator.userAgent,
              date: new Date(),
              msg: error.toString()
            }
          );
        });
      }

      render() {
        if (this.state.error) {
          //render fallback UI
          return (
            <div className="snap text-center">
              <p>We're sorry — something's gone wrong.</p>
              <p>Our team has been notified</p>
            </div>
          );
        } else {
          //when there's not an error, render children untouched
          return this.props.children;
        }
      }
    }

    export default ErrorBoundary;

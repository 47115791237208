import { createReducer, createActions } from "reduxsauce";
import { asyncCreatorFactory, notifySuccess } from "../action-utils";
import { login } from "./auth";
import _pick from "lodash/pick";
import { isManagerUpRole } from "components/ui/authorized/AuthorizedRole";

export const REGISTRATION_TYPES = {
  PRIMARY_RESIDENT: "primary_resident",
  SECONDARY_RESIDENT: "secondary_resident",
  MANAGEMENT: "management",
  DEALER: "dealer",
  ADMIN: "admin",
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  registrationFetching: ["actionId"],
  registrationSuccess: ["actionId"],
  registrationFailure: ["error", "actionId"],
  registrationSet: ["data", "attr", "actionId"],
  registrationReset: null,
});
export const actions = Creators;

/* ------------- Initial state ------------- */
const INITIAL_STATE = {
  registration_type: null,
  registration_source: 'email',
  questions: null,
  registration_data: null,
  fetching: [],
  error: [],
};

/* ------------- Reducers ------------- */
export const fetching = (state, { actionId }) => ({
  ...state,
  fetching: [...state.fetching, actionId],
  error: state.error.filter((i) => i.actionId !== actionId),
});
export const set = (state, { data, attr, actionId }) => ({
  ...state,
  [attr]: data,
});
export const success = (state, { actionId }) => ({
  ...state,
  fetching: state.fetching.filter((i) => i !== actionId),
  error: state.error.filter((i) => i.actionId !== actionId),
});
export const failure = (state, { error, actionId }) => ({
  ...state,
  fetching: state.fetching.filter((i) => i !== actionId),
  error: [...state.error, { error, actionId }],
});
export const reset = (state) => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */
const registrationReducer = createReducer(INITIAL_STATE, {
  [Types.REGISTRATION_FETCHING]: fetching,
  [Types.REGISTRATION_SUCCESS]: success,
  [Types.REGISTRATION_FAILURE]: failure,
  [Types.REGISTRATION_SET]: set,
  [Types.REGISTRATION_RESET]: reset,
});

/* ------------- Async Actions ------------- */
const asyncCreator = asyncCreatorFactory(
  Creators.registrationFetching,
  Creators.registrationSuccess,
  Creators.registrationFailure
);

export const setRegistrationType = (type) => (dispatch) => {
  dispatch(Creators.registrationSet(type, "registration_type"));
};

export const setRegistrationSource = (source) => (dispatch) => {
  dispatch(Creators.registrationSet(source, "registration_source"));
};

export const verifySecurityCode = (security_code) =>
  asyncCreator({
    actionId: "verifySecurityCode",
    apiCall: (api) => api.user.verifySecurityCode(security_code),
    errorPath: "data.message",
  });

export const getUserDataByToken = (token) =>
  asyncCreator({
    actionId: "getUserDataByToken",
    apiCall: (api) => api.user.getUserDataByToken(token),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.registrationSet(response.data.data, "registration_data"));
    },
    isNotifyError: false,
    errorPath: "data.message",
  });

export const agreeToTerms = (user_id) =>
  asyncCreator({
    actionId: "agreeToTerms",
    apiCall: (api) => api.user.agreeToTerms(user_id),
    errorPath: "data.message",
  });

export const getSecurityQuestions = () =>
  asyncCreator({
    actionId: "getSecurityQuestions",
    apiCall: (api) => api.user.getSecurityQuestions(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.registrationSet(response.data.data, "questions"));
    },
    errorPath: "data.message",
  });

export const sendPwResetLink = ({ data, isNotify = false } = {}) =>
  asyncCreator({
    actionId: "sendPwResetLink",
    apiCall: (api) => api.user.sendPwResetLink(data),
    onSuccess: (dispatch, response) => {
      isNotify && notifySuccess(`A password reset link was sent to: ${response.data}`);
    },
    errorPath: "data.message",
  });

export const resetPwByLink = ({ token, data = {} }) =>
  asyncCreator({
    actionId: "resetPwByLink",
    apiCall: (api) => api.user.resetPwByLink({ token, data }),
    onSuccess: (dispatch, response) => {},
    errorPath: "data.message",
  });

export const verifyEmail = (token) =>
  asyncCreator({
    actionId: "verifyEmail",
    apiCall: (api) => api.user.verifyEmail(token),
    errorPath: "data.message",
  });

export const updateUserInfo = (user_id, data) =>
  asyncCreator({
    actionId: "updateUserInfo",
    apiCall: (api) => api.user.updateUserInfo(user_id, data),
    errorPath: "data.message",
  });

export const activate = (user_id, data) =>
  asyncCreator({
    actionId: "activateAccount",
    apiCall: (api) => api.user.activateAccount(user_id, data),
    onSuccess: (dispatch, response, getState) => {
      const registration_data = getState().registration.registration_data;
      dispatch(
        Creators.registrationSet(
          {
            email_verified: response.data.data.email_verified,
            ...registration_data,
          },
          "registration_data"
        )
      );
    },
    errorPath: "data.message",
  });

export const activateAccount = (user_id, data) => async (dispatch, getState, api) => {
  const ACTION_ID = "activateAccount";
  dispatch(Creators.registrationFetching(ACTION_ID));

  try {
    const role = getState().registration.registration_data.role;
    const activateData = _pick(
      data,
      "password",
      "username",
      "email",
      "security_answer",
      "security_question",
      "source"
    );
    const updateUserData = _pick(
      data,
      "gender",
      "first_name",
      "last_name",
      "email",
      "mobile_phone",
      "keycom_pin"
    );

    await dispatch(activate(user_id, activateData));
    await dispatch(login(data.username, data.password));

    if (isManagerUpRole(role)) {
      await dispatch(updateUserInfo(updateUserData));
    } else {
      await api.resident.updateCurrent({
        ...updateUserData,
        birthday: `${data.year}-${data.month}-${data.day}`,
      });
    }

    dispatch(Creators.registrationSuccess(ACTION_ID));
  } catch (error) {
    dispatch(Creators.registrationFailure(error, ACTION_ID));
  }
};

export default registrationReducer;

import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _union from "lodash/union";
import _get from "lodash/get";
import _without from "lodash/without";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import tableUtils from "utils/table";

const FieldStyles = styled.div`
  .buildings-box {
    padding: 20px;
    border: 1px solid #cecece;
    background: white;
    border-radius: 5px;
  }
  .react-bs-container-body {
    overflow: auto;
  }
`;

class BuildingsField extends Component {
  static propTypes = {
    buildings: PropTypes.array,
  };
  static defaultProps = {
    buildings: [],
  };

  onCheckChange = (building) => (e) => {
    const currentValue = this.props.input.value;
    if (e.target.checked) {
      const newValue = _union(currentValue, [building.id]);
      this.props.input.onChange(newValue);
    } else {
      const newValue = _without(currentValue, building.id);
      this.props.input.onChange(newValue);
    }
  };

  formatAddress = (cell, row) => {
    return `${row.address}, ${row.city}, ${row.state}`;
  };

  handleRowSelect = (row, isSelected, e) => {
    if (_get(row, "_disabled")) return false;
    const currentValue = this.props.input.value;
    if (isSelected) {
      const newValue = _union(currentValue, [row.id]);
      this.props.input.onChange(newValue);
    } else {
      const newValue = _without(currentValue, row.id);
      this.props.input.onChange(newValue);
    }
  };

  handleSelectAll = (isSelected, rows) => {
    if (isSelected) {
      const newValue = this.props.buildings.map((i) => (_get(i, "_disabled") ? null : i.id));
      this.props.input.onChange(newValue);
    } else {
      this.props.input.onChange([]);
    }
  };

  render() {
    const { input, meta, buildings } = this.props;
    const isError = (meta.error && meta.touched) || meta.submitError;
    return (
      <FieldStyles>
        {isError && <small className="text-danger">{meta.error || meta.submitError}</small>}
        <BootstrapTable
          data={buildings}
          bordered={false}
          pagination={false}
          search={false}
          condensed={false}
          striped={true}
          tableHeaderClass="table-data"
          tableBodyClass="table-data"
          exportCSV={false}
          height={300}
          version="4"
          selectRow={{
            mode: "checkbox",
            selected: input.value || [],
            onSelect: this.handleRowSelect,
            onSelectAll: this.handleSelectAll,
          }}
        >
          <TableHeaderColumn dataField="id" isKey={true} export={false} hidden />
          <TableHeaderColumn
            dataField="building_name"
            dataSort={true}
            children="Building Name"
            dataFormat={tableUtils.formatDefault()}
            formatExtraData={{ name: "Building Name" }}
          />
          <TableHeaderColumn
            dataField="address"
            dataSort={true}
            children="Address"
            headerAlign="left"
            dataFormat={tableUtils.formatDefault(this.formatAddress)}
            formatExtraData={{ name: "Address" }}
          />
        </BootstrapTable>
      </FieldStyles>
    );
  }
}

export default BuildingsField;

import React from "react";
import { ThemeProvider, injectGlobal } from "styled-components";
import spacers from "./spacers";
import forms from "./forms";
import tables from "./tables";
import modals from "./modals";

export const theme = {
  color: {
    primary: "#5DBDA8"
  },
  navigation: {
    globalBackground: "#0A1B68",
    globalText: "white",
    contentBackground: "#f9fafe",
    contentText: "#525982",
    contentActiveLinkBackground: "#5cbea8",
    contentActiveLinkColor: "white",
    contentHoverLinkBackground: "rgba(74, 144, 227, .75)",
    contentHoverLinkColor: "white"
  },
  breakpoints: {
    small: "576px",
    medium: "768px",
    large: "992px",
    extraLarge: "1200px"
  }
};

injectGlobal`
  ${spacers()}
  ${forms(theme)}
  ${tables(theme)}
  ${modals(theme)}
  .hidden{
    display: none;
  }
  .first-button-radius{
    border-radius: 4px 0px 0px 4px;
  }
  .last-button-radius{
    border-radius: 0px 4px 4px 0px;
  }
  .full-width-preloader {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
    background: rgba(255,255,255,0.8);
  }
  .tab-title{
    color: #525982;
    font-family: Helvetica;
    font-size: 20px;
    line-height: 14px;
    -webkit-text-transform: uppercase;
    text-transform: capitalize;
    -webkit-text-decoration: none;
    text-decoration: none;
    font-weight: 200;
  }
  .material-back{
    border: none;
    & .material-icons{
      font-size: 2em;
      opacity: .9;
      &:hover{
        cursor: pointer;
        opacity: 1;
      }
    }
  }
  .c-outline-pink{
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #C91481;
    color:transparent;
    transition: all .2s linear;
    &:hover{
      color: #C91481;
    }
  }
  .break-word{
    word-wrap: break-word;
  }
  .half-opacity{
    opacity: .5;
    &:hover{
      cursor: not-allowed !important;
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #CCC;
    border-radius: 5px;
    width: 4px;
  }
  h6.h6-dark-purple {
    font-size: 14px;
    font-weight: bold;
    color: rgb(83, 86, 108);
  }
  .max280 {
    max-width: 280px;
  }
  .max200 {
    max-width: 200px;
  }
  .max160 {
    max-width: 160px;
  }
  .asterisk{
    color: #ff6666;
  }
  .flag-icon{
    font-size: 17px;
  }
  .icon-button {
    background: transparent;
    border: 0;
    cursor: pointer;
  }
  .img-thumbnail{
    height: auto;
    height: 325px;
  }
  .original-pic{
    height: 223px;
  }
  .badge-circle{
    border-radius: 100px;
    width: 15px;
    height: 15px;
    font-size: 8px;
    line-height: 15px;
    cursor: pointer;
    &.small-badge{
      width: 15px;
      height: 15px;
      cursor: auto;
    }
    &.badge-outlined-pink {
      border: 1px solid #C91481;
    }
  }
  .full-height {
    height: 100%;
  }
  .full-width {
    max-width: 100%;
    width: 100%;
  }
  .btn-download{
    border-radius: 3px;
    transition: all .2s linear;
    margin-top: 15px;
    &.bg-transparent{
      &:hover{
        background: #5A5F96 !important;
        color: white;
        cursor: pointer;
      }
    }
  }
  .btn-xxs {
    font-size: .65rem;
  }
  .btn-xs {
  font-size: .75rem;
  }
  .btn-s {
    font-size: .85rem;
  }
  .text-underline {
    text-decoration: underline;
  }
  .res-box{
    border: 1px solid rgba(151,151,151,0.38);
    border-radius: 8px;
    background-color: rgba(255,255,255,0.58);
    height: calc(100% - 10px);
    /* overflow-y: auto; */
  }
  .account{
    & .nav{
      border-bottom: 3px solid lightgray;
      & .nav-item{
        margin-bottom: -3px;
        & .nav-link{
          cursor: pointer;
          transition: all .2s linear;
          border: 0;
          &:hover{
            border-bottom: 3px solid #6DA8D8;
          }
        }
        & .nav-link.active{
          background: transparent;
          border-bottom: 3px solid #4892CE;
        }
      }
    }
    & .bg-primary-blue{
      height: calc(1.8125rem + 8px);
      &:hover{
        background: #3A75A5 !important;
        color: white;
      }
    }
    & .side-img{
      width: 40px;
      height: 40px;
      border-radius: 50px !important;
      border: 1.4px solid #525982;
    }
    .bd-table{
      font-size: 12px;
      &.mykey-users{
        & thead{
          tr{
            background: rgba(88,27,85,.81);
          }
        }
      }
    }
    .man-table{
      font-size: 12px;
      &.mykey-users{
        & thead{
          tr{
            background: #4a90e3;
          }
        }
      }
    }
  }
  .activity-icon{
    width: 30px;
    height: 30px;
    border-radius: 50px !important;
    border: 1.4px solid #525982;
    font-size: 16px;
    font-weight: bold;
    color: #525982;
  }
  .hb_online{
    color: rgb(54, 179, 126);
  }
  .hb_offline{
    color: rgb(255, 86, 48);
  }
  .hb_partially_online{
    color: rgb(255, 210, 31);
  }
  .hb_pending{
    color: rgb(0, 184, 217);
  }
  .rh5v-Volume_icon{
    width: 30px;
    height: 30px;
  }
  .all-building-icon{
    font-size: 20px;
  }
  .logo-inner{
    border: 1px solid rgba(230, 230, 230, .4);
    background: white;
    border-radius: 100%;
    margin-top: -70px;
    width: 80px;
    height: 80px;
    & img{
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }
  .registration-success{
    & .card{
      min-height: 550px;
    }
  }
  .select-nav-outer{
    min-height: 60px;
  }
  header{
    & i{
      cursor: pointer;
    }
  }
  .outer-toolbar-buttons{
    position: relative;
    width: 100%;
    .left-side{
      width: 70%;
      padding-right: 3em;
    }
    .right-side{
      width: 30%;
    }
    margin-bottom: .75rem;
  }
  .action-button{
    border: 1px solid rgba(152,152,152,0.21) !important;
    border-radius: 4px;
    font-size: 14px;
    text-transform: capitalize;
    cursor: pointer;
    cursor: pointer;
    &.submit-button{
      &:hover{
        background-color: #4A9786 !important;
        color: white;
      }
    }
    &.cancel-button{
      &:hover{
        background-color: #D8D8D8 !important;
      }
    }
    &.danger-button{
      &:hover{
        background-color: #990000 !important;
      }
    }
    &.upgrade-button{
      &:hover{
        background: rgba(255,255,255, .69) !important;
      }
    }
    &.apply-button {
      &:hover{
        background-color: #275e8e !important;
      }
    }
    &.scanner-button{
      font-size: 11px;
      padding: 0em;
      padding-top: .1em;
      width: 100%;
      margin-top: -2px;
      height: calc(1.8125rem + 8px);
      &:hover{
        background-color: #275e8e !important;
        color: white;
      }
    }
  }
  .toolbar-button-outer{
    &.col-md-3{
      padding: 0em;
    }
  }
  .toolbar-button{
    border-radius: 4px;
    background: white !important;
    font-size: 12px;
    font-weight: normal;
    height: calc(1.8125rem + 2px);
    /* width: 100px; */
    /* min-width: 100px; */
    border-top: 1px solid transparent;
    -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
    cursor: pointer;
    transition: all .2s linear;
    text-transform: capitalize;
    &.export-button{
      border: 1px solid #536E9F !important;
      & i{
        display: none;
      }
      &:hover{
        background: #536E9F !important;
        color: white;
      }
    }
    &.clear-button{
      border: 1px solid #536E9F !important;
      margin: 0px .5em .5em .5em;
      &:hover{
        background: #536E9F !important;
        color: white;
      }
    }
    &.bg-green{
      &:hover{
        background-color: #458A6A !important;
      }
    }
    &.bg-delivery-green{
      &:hover{
        background-color: #7db237 !important;
      }
    }
  }
  .icon-size{
    font-size: 16px;
  }
  .table-button{
    text-align: center;
    text-transform: uppercase;
    font-size: 9px;
    letter-spacing: .05em;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid rgba(152,152,152,0.21);
    transition: all .3s linear;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.table-button_quarter {
      width:22%;
    }
    &.table-button_third {
      width:30%;
    }
    &.table-button_half {
      width:47%;
    }
    &.table-button_full {
      width: 95%;
      &.button-xs{
        width: 92%;
      }
    }
    &.bg-maroon{
      background: maroon;
      &:hover{
        background-color: #972e2e;
      }
    }
    &.bg-darkgray{
      &:hover{
        background-color: #5a5a5a;
      }
    }
    &.bg-blue{
      &:hover{
        background-color: #20447B !important;
      }
    }
    &.outlined-blue {
      color: #2E5FAC;
      border: 1px solid #2E5FAC;
      background-color: white;
      &:hover{
        background: #2E5FAC;
        color: white;
      }
    }
    &.outlined-gray {
      color: #9b9b9b;
      border: 1px solid #9b9b9b;
      background-color: white;
      &:hover{
        background: #9b9b9b;
        color: white;
      }
    }
    &.outlined-green {
      color: green;
      border: 1px solid green;
      background-color: white;
      &:hover{
        background: darkgreen;
        color: white !important;
      }
    }
    &.with-icon-right {
      position:relative;
      padding-right: 20px;
      i {
        position: absolute;
        top: 50%;
        right: 2px;
        font-size: 18px;
        transform: translateY(-50%);
      }
    }
    &.bg-red{
      &:hover{
        background-color: #990000 !important;
      }
    }
    &.bg-green{
      &:hover{
        background-color: #458A6A !important;
      }
    }
    &.bg-indigo{
      &:hover{
        background-color: #474b76 !important;
      }
    }
    &.bg-primary-blue{
      &:hover{
        background-color: #2175d9 !important;
      }
    }
    &.add-mykey-set{
      &:hover{
        background: #5A5F96 !important;
      }
    }
  }
  .upload-button-mini{
    background-color: #4A90E2 !important;
    border: 1px solid rgba(152,152,152,0.21) !important;
    border-radius: 4px;
    font-size: 12px;
    &:hover{
      background: #2275D7 !important;
    }
  }
  .cancel-button{
    &.mini{
      font-size: 12px;
    }
  }
  .filter-select{
    max-width: 160px;
    /* min-width: 130px; */
    margin-bottom: .5em;
    width: 100%;
  }
  .date-range-filter{
    min-width: 130px !important;
    max-width: 200px !important;
    & i{
      font-size: 12px !important;
    }
  }
  .search-field{
    position: relative;
    i{
      position: absolute;
      top: 30%;
      right: 6%;
      font-size: 12px !important;
    }
    .form-control{
      padding-right: 2em;
    }
  }
  .form-control,
  select.form-control:not([size]):not([multiple]){
    font-size: .8em;
    height: calc(1.8125rem + 2px);
  }
  .material-icons-sm {
    font-size: 16px;
  }
  .flex-one {
    flex:1;
  }
  .card-asset {
    color: inherit;
    background: #F4F5F7;
    border: 0;
    box-shadow: 0px 0px 0px 0px rgba(154, 166, 195, 0.29);
    transition: all .15s ease-in-out;
    cursor: pointer;
    &:hover {
      text-decoration: none;
      transform: translateY(-5px);
      box-shadow: 0px 1px 7px 3px rgba(154, 166, 195, 0.29);
    }
  }
  .small-gutters {
    margin-right: -5px;
    margin-left: -5px;

    > .col,
    > [class*="col-"] {
      padding-right: 5px;
      padding-left: 5px;
    }
  }
  .notif__container {
    z-index: 1500;
    text-align: center;
    .notif {
      display: inline-block;
    }
  }
  .popover.popover-avatar {
    max-width: initial;
  }
  .red-box{
    border: 2px solid #CC0000;
    border-radius: 5px;
  }
  .blue-box{
    border: 2px solid #2E5FAC;
    border-radius: 5px;
  }
  .darkgray-box{
    border: 2px solid #848484;
    border-radius: 5px;
  }
  .alert{
    margin-bottom: 0em;
  }
  .center{
    text-align: center;
  }
  .right{
    text-align: right;
  }
  .left{
    text-align: left;
  }
  .upper-case{
    text-transform: uppercase;
  }
  .capitalize{
    text-transform: capitalize;
  }
  img{
    object-fit: cover;
  }
  .form-group{
    margin-bottom: .5em;
    & textarea{
      height: calc(6.8125rem + 10px);
    }
  }
  .view-details{
    & .title{
      padding-bottom: .5em;
    }
    & .left-side, .right-side{
      width: 47%;
      & .left-side-inner, .right-side-inner{
        border: 1px solid rgba(200, 200, 200, .3);
        padding: .5em;
        border-radius: 5px;
        & .image-side{
          img{
            width: 70px;
            height: 70px;
            border: 2px solid lightgray;
            border-radius: 5px;
            object-fit: cover;
          }
        }
        & .data-side{
          border-left: 1px dotted rgba(200, 200, 200, .3);
        }
      }
    }
    & .message-outer{
      border: 1px solid rgba(200, 200, 200, .3);
      border-radius: 5px;
    }
    & .data-side{
      width: 100%;
      & .row{
        padding: 0em 0em 0em .5em;
        & .text-box-outer{
          text-transform: capitalize;
          width: 43%;
          max-width: 100%;
          &.email{
            width: 100%;
            order: 10;
          }
          & .text-box{
            height: 2em;
            padding: .3em;
            font-size: 12px;
            &.after{
              border: 2px solid #5DBDA8;
            }
          }
        }
      }
    }
  }
  .alert-info{
    &.bg-faded-purple{
      border: 1px solid rgba(88,27,85,0.74);
      text-transform: capitalize;
    }
  }
  .hr-dashed {
    border-style: dashed;
  }
  .border-right-dashed {
    border-right-style: dashed !important;
  }
  .border-left-dashed {
    border-left-style: dashed !important;
  }
  .border-top-dashed {
    border-top-style: dashed !important;
  }
  .border-bottom-dashed {
    border-bottom-style: dashed !important;
  }
  .c-bordeaux{
    color: #864466;
  }
  .c-purple{
    color: rgba(88,27,85,1);
  }
  .c-purple-light{
    color: #7d82a0;
  }
  .c-black{
    color: #000000;
  }
  .c-white{
    color: #FFFFFF;
  }
  .c-pink{
    color: #C91481;
  }
  .c-gray{
    color: gray;
  }
  .c-darkgray{
    color: #323232;
  }
  .c-navy{
    color: navy;
  }
  .c-aqua{
    color: #00A8A1;
  }
  .c-dark-navy{
    color: #323270;
  }
  .c-dark-blue{
    color: #536E9F;
  }
  .c-red{
    color: #CC0000;
  }
  .c-green{
    color: #579578 !important;
  }
  .c-primary-green{
    color: #5DBDA8 !important;
  }
  .c-primary-blue{
    color: #4892ce !important;
  }
  .c-light-green{
    color: #28a745!important;
  }
  .c-link-blue{
    color: #007BFF !important;
    cursor: pointer;
    &:hover{
      color: #0056b3 !important;
      text-decoration: underline !important;
    }
  }
  .f-s-20{
    font-size: 20px;
  }
  .bg-white{
    background-color: #FFFFFF !important;
  }
  .bg-red{
    background: #CC0000 !important;
  }
  .bg-green{
    background: #579578 !important;
  }
  .bg-light-green{
    background: #5cbea8 !important;
  }
  .bg-delivery-green{
    background: #8BC63E !important;
  }
  .bg-primary-green{
    background-color: #5DBDA8 !important;
  }
  .bg-primary-blue{
    background: #4a90e3 !important;
  }
  .bg-blue{
    background: #2E5FAC !important;
  }
  .bg-indigo{
    background: #5A5F96 !important;
  }
  .bg-dark-indigo{
    background-color: rgba(61,74,147,0.82);
  }
  .bg-purple{
    background: rgba(88,27,85,0.74) !important;
  }
  .bg-faded-purple{
    background: rgba(88,27,85,0.2374) !important;
  }
  .bg-pink{
    background: #C91481 !important;
  }
  .bg-gray{
    background-color: #F1F1F1 !important;
  }
  .bg-lightgray{
    background: lightgray;
  }
  .bg-darkgray{
    background: #848484;
  }
  .bg-transparent {
    background: transparent !important;
  }
  .bg-faded-white{
    background: rgba(255,255,255, .3) !important;
  }
  .bg-maroon{
    background: maroon;
  }
  a.c-white:hover {
    color: white;
  }
  .c-indigo{
    color: #5A5F96;
  }
  .outline-primary-blue {
    border: 1px solid #4892ce;
  }
  .outline-primary-green {
    border: 1px solid #5DBDA8;
  }
  .outline-green {
    border: 1px solid #579578;
  }
  .outline-pink {
    border: 1px solid #C91481;
  }
  .outline-indigo {
    border: 1px solid #5A5F96;
  }
  .outline-transparent {
    border: 1px solid transparent;
  }
  .outline-white {
    border: 1px solid white !important;
  }
  .play-button{
    border: 1px solid rgba(88,27,85,0.74);
    border-radius: 50px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  .access-hr{
    border-top: 1px solid rgba(88,27,85,0.274);
    border-bottom: 1px solid rgba(88,27,85,0.274);
    width: 7%;
  }
  .access-modal, .message-modal{
    & img{
      width: 280px;
      height: 280px;
    }
    & video{
      height: 100% !important;
      width: 100% !important;
    }
    & .rh5v-DefaultPlayer_component{
      min-height: 280px;
      height: 100% !important;
      width: 100% !important;
    }
  }
  .delete-trash, .edit-notepad{
    background:transparent;
    border: 0;
    cursor: pointer;
    & i{
      font-size: 16px;
    }
    & .c-gray{
      &:hover{
        color: #939393;
      }
    }
    &.c-red{
      &:hover{
        color: #990000;
      }
    }
    &.c-dark-navy{
      &:hover{
        color: #00004d;
      }
    }
  }
  .edit-notepad{
    padding-top: .1em;
  }
  .rbc-calendar{
    height: 100%;
    width: 100%;
    & .rbc-toolbar{
      position: relative;
      & i{
        cursor: pointer;
      }
      & label{
        min-width: 150px;
        font-size: 14px;
      }
    }
    & .rbc-month-view{
      & .rbc-row{
        &.rbc-month-header{
          background-color: rgba(142,116,151,0.72);
          & .rbc-header{
            padding: .5em;
            color: #FFFFFF;
            text-transform: uppercase;
            font-weight: 350;
            letter-space: .3em;
          }
        }
      }
    }
    & .shadow-button{
      box-sizing: border-box;
      padding: .8em 1.3em;
      font-size: 12px;
      border: 1px solid #FFFFFF;
      border-radius: 5px;
      box-shadow: 0 8px 16px 0 rgba(63,71,117,0.16);
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 0;
      &:hover{
        background: #E6E6E6 !important;
        border: 1px solid #FFFFFF;
      }
    }
    & .rbc-event{
      &.rbc-event-allday{
        box-sizing: border-box;
        border: 1px solid #FFFFFF;
        border-radius: 5px;
        box-shadow: 0 8px 16px 0 rgba(63,71,117,0.16);
        background: rgba(88,27,85,1);
        & .rbc-event-content{
          font-weight: 300;
          font-size: 12px;
          letter-spacing: .05em;
          text-transform: capitalize;
        }
      }
    }
    & .rbc-day-bg{
      &.rbc-today{
        background: rgba(88,27,85,.131) !important;
      }
    }
    & .rbc-date-cell{
      &.rbc-now{
        &.rbc-current{
          & a{
            color: rgba(88, 27, 85, 1);
          }
        }
      }
    }
  }
  .outer-box{
    border: 1px solid rgba(151,151,151,0.38);
    border-radius: 8px;
    background-color: rgba(255,255,255,0.58);
  }
  .res-side{
    & .outer-box{
      margin: 0em;
      margin-bottom: 10px;
    }
  }
  .list-group{
    height: 655px;
    overflow-y: auto;
  }
  .list-group-item{
    &:hover{
      background: #FFFFFF;
      box-shadow: 0 8px 16px 0 rgba(63,71,117,0.16);
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      font-size: 12px;
      border-radius: 5px;
    }
  }
  .calendar-group{
    height: 700px;
  }
  .res-side{
    & .calendar-group{
      height: calc(46vh - 40px);
      min-height: 440px;
      width: 95%;
    }
    & .list-group{
      height: calc(46vh - 40px);
      overflow-y: auto;
      & .list-group-inner{
        height: 100%;
        position: relative;
      }
    }
  }
  .s-info {
  }
  .s-picture {
    height: 450px;
  }
  .s-permissions {
    height: 175px;
  }
  .shadow-box{
    box-sizing: border-box;
    font-size: 12px;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    box-shadow: 0 8px 16px 0 rgba(63,71,117,0.16);
    width: 96%;
    & .notice-title{
      font-size: 12px;
    }
    & i{
      font-size: 18px;
      margin-left: .5em;
    }
  }
  .building-settings{
    & .settings-info{
      width: 90%;
    }
    & .bg-blue{
      &:hover{
        background: #234984 !important;
      }
    }
  }
  .table td, .table th{
    padding: .5em;
  }
  .react-bs-container-header{
    &.table-header-wrapper{
      border-radius: 5px 5px 0px 0px !important;
      z-index: 2;
    }
  }
  div.react-bs-container-body {
    overflow: initial;
  }
  .react-bs-table-pagination{
    .row{
      margin: 0 !important;
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      button{
        background: white;
        padding: .5em;
        font-size: 12px;
        color: gray;
        border: 1px solid lightgray;
      }
      a{
        font-size: .8em;
      }
      .col-md-6.col-xs-6.col-sm-6.col-lg-6{
        display: contents !important;
        .dropdown.react-bs-table-sizePerPage-dropdown{
          margin-right: 1em;
        }
      }
    }
  }
  .react-bs-table-pagination .dropdown .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px;
  }
  .green-tabs {
    .nav-link {
      background: #f8f8f8;
      color: #7b7b7b;
      border-color: #e9ecef #e9ecef #dee2e6;
    }
    .nav-link.active {
      background: #5DBDA8;
      color: white;
      border-color: #5DBDA8;
    }
  }
  .react-toggle--checked {
    div.react-toggle-track {
      background: #4892ce;
    }
    div.react-toggle-thumb {
      border-color: #4892ce;
    }
  }
  div.react-toggle-track {
    background: #d0d1e2;
  }
  div.react-toggle-thumb{
    border-color: #d0d1e2;
  }
  .react-toggle:hover:not(.react-toggle--disabled) div.react-toggle-track {
    background: #7f819c;
  }
  .react-toggle--checked:hover:not(.react-toggle--disabled) div.react-toggle-track {
    background: #2d9ffd;
  }
  .status-select{
    &.active{
      color: green !important;
      font-weight: bold;
    }
    &.qc{
      color: #4a90e3 !important;
      font-weight: bold;
    }
    &.inactive{
      color: rgba(180,0,0,1) !important;
      font-weight: bold;
    }
  }
  .status{
    border-radius: 3px;
    padding-top: .5px;
    padding-bottom: .5px;
    width: 90%;
    &.active{
      background: rgba(87,149,120, .3);
      color: green;
    }
    &.new{
      color: #4a90e3;
      background: rgba(74,144,227, .3);
    }
    &.data_uploading{
      color: #5059e4;
      background: #dee0fa;
    }
    &.registered{
      color: #5059e4;
      background: #dee0fa;
    }
    &.vacant, &.inactive, &.denied, &.expired{
      background: rgba(255,0,0, .2);
      color: rgba(180,0,0,1);
    }
    &.na{
      background: grey;
      color: #FFFFFF;
    }
  }
  .plan-name{
    text-transform: uppercase;
    font-size: 16px;
    &.essentials{
      color: #ffffff !important;
      background: #fc457b !important;
    }
    &.basic{
      color: #ffffff !important;
      background: #f49e5b !important;
    }
    &.professional{
      color: #ffffff !important;
      background: #80d3d2 !important;
    }
    &.custom{
      color: #ffffff !important;
      background: #ffca00 !important;
    }
    &.lifetime{
      color: #ffffff !important;
      background: #8448b0 !important;
    }
    &.no{
      color: #ffffff !important;
      background: #000000 !important;
    }
    &.unsubscribed{
      color: #ffffff !important;
      background: #bcb9b8 !important;
    }
  }
  .unit-user-table{
    max-height: 315px;
    overflow: scroll;
  }
  .dropdown-item {
    font-size: 0.8rem;
    padding: 0.25rem 0.7rem;
    display: flex;
    align-items: center;
    color: #525252;

    &:active {
      color: #525252;
      background-color: #e6e6e6;
    }
    .material-icons {
      font-size: 16px;
      margin-right: 5px;
    }
    .fa {
      margin-right: 5px;
    }
  }
  .btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }
  .mvi-table-dropdown {
    font-size: 0.8rem;
    [data-placement=top-end] {
      &.dropdown-menu {
        border-bottom-width: 5px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-left-color: #bde8ff;
        border-bottom-color: #d0d0d2;
        border-right-color: #bde8ff;
        border-top-color:#bde8ff;
        position: relative;

        &::before {
          content: "▴";
          position: absolute;
          bottom: -29px;
          right: 13px;
          font-size: 27px;
          color: #d0d0d2;
          pointer-events: none;
          transform: rotate(180deg);
        }
      }

    }
    [data-placement=bottom-end]{
      &.dropdown-menu {
        border-top-width: 5px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-left-color: #bde8ff;
        border-bottom-color: #bde8ff;
        border-right-color: #bde8ff;
        border-top-color:#d0d0d2;
        position: relative;

        &::before {
          content: "▴";
          position: absolute;
          top: -29px;
          right: 13px;
          font-size: 27px;
          color: #d0d0d2;
          pointer-events: none;
        }
      }
    }
  }

  @media (max-width: ${theme.breakpoints.large}) {
    .bulletin-board, .building-settings{
      .row{
        margin: 0;
      }
      & .col-md-8{
        min-width: 100%;
      }
    }
    .res-outer-side{
      & .col-md-8{
        &.left-side{
          min-width: 100%;
          margin-bottom: 10px;
        }
      }
    }
    .unit-listing-table, .users-table, .activity-table{
      & colgroup col:last-child{
        width: 10% !important;
        min-width: 10%;
      }
      & colgroup col:first-child{
        width: 7% !important;
        min-width: 7%;
      }
      & colgroup col:nth-child(2){
        width: 5% !important;
        min-width: 5%;
      }
      & colgroup col:nth-child(3){
        width: 7% !important;
        min-width: 7%;
      }
      & .table-button{
        width: 100%;
      }
    }
    .access-table{
      & colgroup col:nth-child(2){
        width: 120px !important;
        min-width: 120px;
      }
      & colgroup col:nth-child(3){
        width: 60px !important;
        min-width: 60px;
      }
      & colgroup col:nth-child(4){
        width: 60px !important;
        min-width: 60px;
      }
      & colgroup col:nth-child(5){
        width: 80px !important;
        min-width: 80px;
      }
    }
  }
  @media (max-width: ${theme.breakpoints.medium}){
    .registration-success{
      & .card{
        margin-bottom: 20px;
      }
    }
    .activity-modal{
      & .view-details{
        & .flex-row{
          flex-wrap: wrap;
        }
        & .left-side, .right-side, .left-side-inner, .right-side-inner{
          width: 100%;
        }
        & .left-side-inner, .right-side-inner{
          width: 100%;
          justify-content: center;
          align-items: center;
          & .image-side{
            border-right: 0;
            border-bottom: 1px dotted lightgray;
            width: 100%;
            justify-content: center;
            align-items: center;
          }
          & .data-side{
            justify-content: center;
            align-items: center;
          }
        }
        &  .right-side{
          margin-top: 10px;
        }
      }
    }
    .s-info{
      height: 100%;
    }
    .s-picture{
      margin-top: 20px;
    }
    .res-box{
      height: 100%;
    }
    .account{
      &.p-30{
        padding: 20px;
      }
      & .col-md-6{
        justify-content: center !important;
        align-items: center !important;
        & .form-row{
          width: 100%;
        }
      }
    }
    .res-side{
      margin-top: 10px;
    }
  }
  @media (max-width: ${theme.breakpoints.small}) {
    display:block;
    .status{
      width: 100%;
      margin-bottom: 10px;
    }
    .res-outer-side{
      &.m-20{
        margin-left: 10px;
        margin-right: 10px;
      }
      & .row{
        margin-right: -15px !important;
      }
      & .nav-link {
        display: block;
        padding: .5rem 0.2rem;
      }
    }
    .building-card{
      width: 100%;
      min-width: 269px;
    }
    .nested-modal{
      & .modal-content{
        margin-top: 22%;
      }
    }
    .access-modal{
      & img{
        width: 100%;
        margin-bottom: 1em;
        margin-top: 1em;
      }
    }
    .modal-body{
      &.p-40{
        padding: 20px;
      }
    }
    .list-group{
      height: 455px;
      overflow-y: auto;
    }
    .calendar-group{
      height: 400px;
    }
    .rbc-calendar{
      & .rbc-toolbar{
        & label{
          min-width: auto;
        }
      }
    }
    .building-settings{
      & .s-picture{
        height: auto;
      }
      & .outer-box{
        margin-left: 0;
        margin-right: 0;
      }
    }
    tbody {
      display:block;
    }
    .toolbar-button-inner{
      &.justify-content-end{
        justify-content: center !important;
      }
    }
    .react-bs-container-header.table-header-wrapper {
      display: none;
    }
    .filter-select, .date-range-filter{
      max-width: 100% !important;
    }
    .unit-listing-table{
      & .table-button{
        width: 100%;
        margin-top: 1em;
        &.add-mykey-set{
          background-size: 7% !important;
        }
        &.bg-green{
          width: 100%;
        }
      }
      &.m-h-20{
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    .users-table{
      & .table-button{
        width: 100%;
        margin-top: 1em;
        & br {
          display: none;
        }
        &.bg-blue, &.bg-red{
          padding: .7em;
        }
      }
      &.m-h-20{
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    .activity-table{
      & .table-button{
        width: 100%;
        margin-top: 1em;
      }
      &.m-h-20{
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    .access-table{
      &.m-h-20{
        margin-left: 10px;
        margin-right: 10px;
      }
      & .d-inline {
        &.d-sm-none{
          display: none !important;
        }
      }
      & .video-log{
        width: 130px;
        justify-content: flex-end !important;
        align-items: center !important;
        padding-bottom: .5em;
      }
      & .access-modal{
        & img{
          width: 100%;
          height: 100%;
        }
        & .rh5v-DefaultPlayer_component{
          margin-bottom: 1em;
          margin-top: 1em;
        }
      }
    }
    tbody tr {
      display:flex;
      flex-wrap: wrap;
      flex-direction: column;
      padding-top: 10px;
      padding-bottom: 10px;
      td {
        padding:.25rem .75rem;
      }
      .table-cell {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #d7dde2;
        .table-cell__value {
          white-space: normal;
          text-align: right;
        }
      }
    }
  }
  .security-text {
    font-family: text-security-disc;
  }
  /* google maps autocomplete fix */
  .pac-container.pac-logo {
    z-index:20000;
  }
  .building-selector-modal-body {
    overflow-x: auto;
    max-height: 400px;
  }
  .select-list-global-promo-add-modal {
    max-height: 250px;
    overflow-y: auto;
  }
`;

const Theme = props => <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
export default Theme;

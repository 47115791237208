import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _union from "lodash/union";
import _without from "lodash/without";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import tableUtils from "utils/table";

const FieldStyles = styled.div`
  .buildings-box {
    padding: 20px;
    border: 1px solid #cecece;
    background: white;
    border-radius: 5px;
  }
  .react-bs-container-body {
    overflow: auto;
  }
`;

class BuildingsEnabledField extends Component {
  static propTypes = {
    buildings: PropTypes.array
  };
  static defaultProps = {
    buildings: []
  };
  onCheckChange = building => e => {
    const currentValue = this.props.input.value;
    if (e.target.checked) {
      const newValue = _union(currentValue, [building.id]);
      this.props.input.onChange(newValue);
    } else {
      const newValue = _without(currentValue, building.id);
      this.props.input.onChange(newValue);
    }
  };
  formatAddress = (cell, row) => {
    return `${row.address}, ${row.city}, ${row.state}`;
  };

  handleRowSelect = (row, isSelected, e) => {
    const currentValue = this.props.input.value;
    if (isSelected) {
      const newValue = _union(currentValue, [row.id]);
      this.props.input.onChange(newValue);
    } else {
      const newValue = _without(currentValue, row.id);
      this.props.input.onChange(newValue);
    }
  };
  handleSelectAll = (isSelected, rows) => {
    if (isSelected) {
      const newValue = this.props.buildings.filter(item => (item.hasOwnProperty('enabled') && item.enabled === true)).map(i => i.id);
      this.props.input.onChange(newValue);
    } else {
      this.props.input.onChange([]);
    }
  };
  render() {
    const { input, meta, buildings } = this.props;
    const isError = (meta.error && meta.touched) || meta.submitError;

    const unselectable = buildings.filter(item => (item.hasOwnProperty('enabled') && item.enabled === false)).map(item => (item.id));
    const selected = input.value.filter(item => (!unselectable.includes(item)));

    return (
      <FieldStyles>
        {isError && <small className="text-danger">{meta.error || meta.submitError}</small>}
        <BootstrapTable
          data={buildings}
          bordered={false}
          pagination={false}
          search={false}
          condensed={false}
          striped={true}
          tableHeaderClass="table-data"
          tableBodyClass="table-data"
          exportCSV={false}
          height={300}
          version="4"
          selectRow={{
            mode: "checkbox",
            selected: selected || [],
            onSelect: this.handleRowSelect,
            onSelectAll: this.handleSelectAll,
            unselectable: unselectable
          }}
        >
          <TableHeaderColumn dataField="id" isKey={true} export={false} hidden />
          <TableHeaderColumn
            dataField="building_name"
            dataSort={true}
            children="Building Name"
            dataFormat={tableUtils.formatDefault()}
            formatExtraData={{ name: "Building Name" }}
          />
          <TableHeaderColumn
            dataField="address"
            dataSort={true}
            children="Address"
            headerAlign="left"
            dataFormat={tableUtils.formatDefault(this.formatAddress)}
            formatExtraData={{ name: "Address" }}
          />
        </BootstrapTable>
      </FieldStyles>
    );
  }
}

export default BuildingsEnabledField;

import { createReducer, createActions } from "reduxsauce";
import { asyncCreatorFactory, notifySuccess } from "../action-utils";
import { getCurrentResident } from "./resident";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  accountFetching: ["actionId"],
  accountSuccess: ["actionId"],
  accountFailure: ["error", "actionId"],
  accountSet: ["data", "attr", "actionId"],
  accountReset: null
});
export const actions = Creators;

/* ------------- Initial state ------------- */
const INITIAL_STATE = {
  messages: null,
  building: null,
  thumbnail: null,
  checkCurrentPassword: undefined,
  fetching: [],
  error: []
};

/* ------------- Reducers ------------- */
export const fetching = (state, { actionId }) => ({
  ...state,
  fetching: [...state.fetching, actionId],
  error: state.error.filter(i => i.actionId !== actionId)
});
export const set = (state, { data, attr, actionId }) => ({
  ...state,
  [attr]: data
});
export const success = (state, { actionId }) => ({
  ...state,
  fetching: state.fetching.filter(i => i !== actionId),
  error: state.error.filter(i => i.actionId !== actionId)
});
export const failure = (state, { error, actionId }) => ({
  ...state,
  fetching: state.fetching.filter(i => i !== actionId),
  error: [...state.error, { error, actionId }]
});
export const reset = state => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */
const accountReducer = createReducer(INITIAL_STATE, {
  [Types.ACCOUNT_FETCHING]: fetching,
  [Types.ACCOUNT_SUCCESS]: success,
  [Types.ACCOUNT_FAILURE]: failure,
  [Types.ACCOUNT_SET]: set,
  [Types.ACCOUNT_RESET]: reset
});

/* ------------- Async Actions ------------- */

const asyncCreator = asyncCreatorFactory(
  Creators.accountFetching,
  Creators.accountSuccess,
  Creators.accountFailure
);

export const resetForm = () =>
  Creators.accountSet("", "checkCurrentPassword");

export const checkCurrentPassword = (password) =>
  asyncCreator({
    actionId: "checkCurrentPassword",
    apiCall: api => api.account.checkCurrentPassword(password),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.accountSet(response.data.data.verified, "checkCurrentPassword"));
    },
    errorPath: "data.description",
    isNotifyError: false,
  });


export const resetPassword = (password) =>
  asyncCreator({
    actionId: "resetPassword",
    apiCall: api => api.account.resetPassword(password),
    onSuccess: (dispatch, response) => {
      notifySuccess("Password was successfully updated");
      dispatch(getCurrentResident());
    },
    errorPath: "data.description",
    isNotifyError: false,
  });

export const getMessages = () =>
  asyncCreator({
    actionId: "getMessages",
    apiCall: api => api.account.getMessages(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.accountSet(response.data.data || [], "messages"));
    },
    errorPath: "data.description",
    isNotifyError: false,
  });

export const deleteMessage = id =>
  asyncCreator({
    actionId: "deleteMessage",
    apiCall: api => api.account.deleteMessage(id),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Message deleted");
      const messages =  getState().account.messages;
      const newMessages = messages.filter(i => i.id !== id);
      dispatch(Creators.accountSet(newMessages, "messages"));
    },
    errorPath: "data.description"
  });

export const listenedTo = id =>
  asyncCreator({
    actionId: "listenedTo",
    apiCall: api => api.account.listenedTo(id),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Message Read");
      dispatch(getMessages());
    },
    errorPath: "data.description"
  });

export const getMessage = id =>
  asyncCreator({
    actionId: "getMessage",
    apiCall: api => api.account.getMessage(id),
    errorPath: "data.description"
  });

export default accountReducer;

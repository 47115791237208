const baseTimezone = process && process.env && process.env.REACT_APP_SERVER_TIMEZONE ? parseInt(process.env.REACT_APP_SERVER_TIMEZONE): 0;
const myTimezone = new Date().getTimezoneOffset() / -60;
let timeZoneDifference = (myTimezone - baseTimezone);


export const DateWithTimezone = (...props) => {
  let parsedDate = new Date(...props);
  return parsedDate + (timeZoneDifference*60*60*1000)
};

export const DateParseWithTimezone = (...props) => {
  let parsedDate = Date.parse(...props);
  return parsedDate + (timeZoneDifference*60*60*1000)
};
import React, { Component } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

const redirect = props => (
  <Redirect
    to={{
      pathname: "/app",
      state: { from: props.location }
    }}
  />
);

class PublicRoute extends Component {
  render() {
    const { component: Component, auth, ...rest } = this.props;
    const token = auth.token;
    return (
      <Route
        {...rest}
        render={props => (!token ? <Component {...props} /> : redirect(props))}
      />
    );
  }
}

export default withRouter(connect(({ auth }) => ({ auth }))(PublicRoute));

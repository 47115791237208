import { createReducer, createActions } from "reduxsauce";
import _pick from "lodash/pick";
import _get from "lodash/get";
import { asyncCreatorFactory, notifyError, notifySuccess } from "../action-utils";
import { getBuildingKeyComList as getAdminBuildingKeyComList } from "redux/reducers/admin";
import { getBuildingKeyComList as getDealerBuildingKeyComList } from "redux/reducers/dealer";
import { isAdmin } from "components/ui/authorized/AuthorizedRole";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  kioskFetching: ["actionId"],
  kioskSuccess: ["actionId"],
  kioskFailure: ["error", "actionId"],
  kioskSet: ["data", "attr", "actionId"],
  kioskReset: ["exclude"],
});
export const actions = Creators;

/* ------------- Initial state ------------- */
const INITIAL_STATE = {
  companies: null,
  addCompany: null,
  kioskLogo: null,
  fetching: [],
  error: [],
  kioskCurrent: null,
  trainingVideoUrl: null,
  screenSaverVideoUrl: null,
  modalSelectedItem: null,
  isModalOpen: null,
};

/* ------------- Reducers ------------- */
export const fetching = (state, { actionId }) => ({
  ...state,
  fetching: [...state.fetching, actionId],
  error: state.error.filter((i) => i.actionId !== actionId),
});
export const set = (state, { data, attr, actionId }) => ({
  ...state,
  [attr]: data,
});
export const success = (state, { actionId }) => ({
  ...state,
  fetching: state.fetching.filter((i) => i !== actionId),
  error: state.error.filter((i) => i.actionId !== actionId),
});
export const failure = (state, { error, actionId }) => ({
  ...state,
  fetching: state.fetching.filter((i) => i !== actionId),
  error: [...state.error, { error, actionId }],
});
export const reset = (state, { exclude = [] }) => ({
  ...INITIAL_STATE,
  ..._pick(state, exclude),
});

/* ------------- Hookup Reducers To Types ------------- */
const kioskReducer = createReducer(INITIAL_STATE, {
  [Types.KIOSK_FETCHING]: fetching,
  [Types.KIOSK_SUCCESS]: success,
  [Types.KIOSK_FAILURE]: failure,
  [Types.KIOSK_SET]: set,
  [Types.KIOSK_RESET]: reset,
});

/* ------------- Async Actions ------------- */
const asyncCreator = asyncCreatorFactory(
  Creators.kioskFetching,
  Creators.kioskSuccess,
  Creators.kioskFailure
);

export const getSipProviders = () =>
  asyncCreator({
    actionId: "getSipProviders",
    apiCall: (api) => api.kiosk.getSipProviders(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.kioskSet(response.data.data, "sip_providers"));
    },
    errorPath: "data.description",
  });

export const getTrainingVideoUrl = () =>
  asyncCreator({
    actionId: "getTrainingVideoUrl",
    apiCall: (api) => api.kiosk.getTrainingVideoUrl(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.kioskSet(response.data.data, "trainingVideoUrl"));
    },
    errorPath: "data.message",
  });

export const uploadTrainingVideo = (data) =>
  asyncCreator({
    actionId: "uploadTrainingVideo",
    apiCall: (api) => api.kiosk.uploadTrainingVideo(data),
    onSuccess: (dispatch, response) => {
      notifySuccess("Training video uploaded successfully");
      dispatch(Creators.kioskSet(response.data.data, "trainingVideoUrl"));
    },
    errorPath: "data.message",
  });

export const getScreenSaverVideoUrl = () =>
  asyncCreator({
    actionId: "getScreenSaverVideoUrl",
    apiCall: (api) => api.kiosk.getScreenSaverVideoUrl(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.kioskSet(response.data.data, "screenSaverVideoUrl"));
    },
    errorPath: "data.message",
  });

export const uploadScreenSaverVideo = (data) =>
  asyncCreator({
    actionId: "uploadScreenSaverVideo",
    apiCall: (api) => api.kiosk.uploadScreenSaverVideo(data),
    onSuccess: (dispatch, response) => {
      notifySuccess("Screen Saver video uploaded successfully");
      dispatch(Creators.kioskSet(response.data.data, "screenSaverVideoUrl"));
    },
    errorPath: "data.message",
  });

export const getKeyComList = () => async (dispatch, getState, api) => {
  const ACTION_ID = "getKeyComList";
  dispatch(Creators.kioskFetching(ACTION_ID));
  try {
    const state = getState();
    const selectedBuilding = _get(state, "navigation.selectedBuilding");
    const role = _get(state, "resident.data.role.name");

    const fn = isAdmin(role) ? getAdminBuildingKeyComList : getDealerBuildingKeyComList;

    dispatch(fn(selectedBuilding));

    dispatch(Creators.kioskSuccess(ACTION_ID));
  } catch (error) {
    dispatch(Creators.kioskFailure(error, ACTION_ID));
  }
};

export const setKioskModal = (isOpen) => (dispatch) => dispatch(Creators.kioskSet(isOpen, "isModalOpen"));
export const setKioskModalItem = (data) => (dispatch) =>
  dispatch(Creators.kioskSet(data, "modalSelectedItem"));

export const openDoor = (kioskId, residentId = null) =>
  asyncCreator({
    actionId: "openDoor",
    apiCall: (api) => api.fmSockets.openDoor(kioskId, residentId),
    onSuccess: (dispatch, response) => {
      try {
        const jsonData = JSON.parse(response.data);

        jsonData.Result
          ? notifySuccess(`Success: ${jsonData.Description}`)
          : notifyError(`Error: ${jsonData.Description}`);
      } catch (e) {
        notifyError("Error with server response");
      }
    },
    errorPath: "data.message",
  });

export const rebootKeyCom = (kioskId, batch = false) =>
  asyncCreator({
    actionId: "rebootKeyCom",
    apiCall: (api) => api.fmSockets.rebootKeyCom(kioskId),
    onSuccess: (dispatch, response) => {
      if (!batch) {
        try {
          const jsonData = JSON.parse(response.data);

          jsonData.Result
            ? notifySuccess(`Success: ${jsonData.Description}`)
            : notifyError(`Error: ${jsonData.Description}`);
        } catch (e) {
          notifyError("Error with server response");
        }
      }
    },
    errorPath: "data.description",
  });
export default kioskReducer;

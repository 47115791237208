import _get from "lodash/get";
import { toast } from "react-toastify";

export const notifySuccess = (message, delay = 2000) =>
  toast.success(message, {
    autoClose: delay,
  });

export const notifyError = (message, delay = 2000) =>
  toast.error(message, {
    autoClose: delay,
  });

/**
 * This function is a factory that standarizes how api calls are made in the app.
 * Be waeare that the SuccessFunction is different from the success callback that. The first
 * removes the actionId from the fetching attribute, the second is a callback that can be use to
 * handle the success data
 * @param {Function} FetchingAction Fucntion for controling what is being fetch
 * @param {Function} SuccessAction Function that tells the control to remove from fetching attribute
 * @param {Function} FailureAction Function that adds an error to the errors attribute
 */

export const asyncCreatorFactory =
  (FetchingAction, SuccessAction, FailureAction) =>
  ({ actionId, apiCall, onSuccess, onError, errorPath, isNotifyError = true }) =>
  async (dispatch, getState, api) => {
    //dispatches the Fetching action
    dispatch(FetchingAction(actionId));
    try {
      //executes the api call
      const response = await apiCall(api, getState, dispatch);
      if (response.ok) {
        //if ok dispatches the fectich control succes action
        dispatch(SuccessAction(actionId));
        //if ok dispatches the succes action
        if (onSuccess) onSuccess(dispatch, response, getState, api);
        //and resolves the promise
        return Promise.resolve({ actionId, response });
      } else {
        //if not ok
        //check if onError function and execute with args
        if (onError) onError(dispatch, response, getState, errorPath);
        //gets a possible error string from the api
        const error = _get(response, errorPath, `${response.problem} at ${actionId}`);
        //dispatches the fetching control error function
        dispatch(FailureAction(error, actionId));
        // notifies the user if enabled
        if (isNotifyError) notifyError(error);
        // and reject promise
        return Promise.reject({ actionId, message: error });
      }
    } catch (error) {
      console.log(error);
      if (isNotifyError) notifyError(error.message);
      dispatch(FailureAction(error.message, actionId));
      return Promise.reject({ actionId, message: error });
    }
  };

export default {
  notifySuccess,
  notifyError,
  asyncCreatorFactory,
};

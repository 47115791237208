export const generateSpacers = (sufix = "") => {
  let spacers = '';
  for (let i = 0; i < 20; i++) {
    spacers += `
      .spacer-${10*i}${sufix} {
        width: 100%;
        clear: both;
        height: ${10*i}px;
      }
      .m-v-${10*i}${sufix} {
        margin-top: ${10*i}px;
        margin-bottom: ${10*i}px;
      }
      .m-h-${10*i}${sufix} {
        margin-left: ${10*i}px;
        margin-right: ${10*i}px;
      }
      .m-${10*i}${sufix} {
        margin: ${10*i}px;
      }
      .b-r-${i}${sufix}{
        border-radius: ${i}px;
      }
      .m-t-${10*i}${sufix} {
        margin-top: ${10*i}px;
      }
      .m-b-${10*i}${sufix} {
        margin-bottom: ${10*i}px;
      }
      .m-r-${10*i}${sufix} {
        margin-right: ${10*i}px;
      }
      .m-l-${10*i}${sufix} {
        margin-left: ${10*i}px;
      }
      .p-${10*i}${sufix} {
        padding: ${10*i}px;
      }
      .p-v-${10*i}${sufix} {
        padding-top: ${10*i}px;
        padding-bottom: ${10*i}px;
      }
      .p-h-${10*i}${sufix} {
        padding-left: ${10*i}px;
        padding-right: ${10*i}px;
      }
      .p-t-${10*i}${sufix} {
        padding-top: ${10*i}px;
      }
      .p-b-${10*i}${sufix} {
        padding-bottom: ${10*i}px;
      }
      .p-r-${10*i}${sufix} {
        padding-right: ${10*i}px;
      }
      .p-l-${10*i}${sufix} {
        padding-left: ${10*i}px;
      }

      //Negatives
      .m-v-n-${10*i}${sufix} {
        margin-top: ${-10*i}px;
        margin-bottom: ${-10*i}px;
      }
      .m-h-n-${10*i}${sufix} {
        margin-left: ${-10*i}px;
        margin-right: ${-10*i}px;
      }
      .m-n-${10*i}${sufix} {
        margin: ${-10*i}px;
      }
      .m-t-n-${10*i}${sufix} {
        margin-top: ${-10*i}px;
      }
      .m-b-n-${10*i}${sufix} {
        margin-bottom: ${-10*i}px;
      }
      .m-r-n-${10*i}${sufix} {
        margin-right: ${-10*i}px;
      }
      .m-l-n-${10*i}${sufix} {
        margin-left: ${-10*i}px;
      }
    `
  }
  return spacers;
}

const spacers = () => {
  return `
    ${generateSpacers()}
    /* Small devices (mobile, 768px and low) */
    @media (max-width: 768px) {
      ${generateSpacers("-xs")}
    }

    @media (min-width: 768px) {
      ${generateSpacers("-sm")}
    }

    @media (min-width: 992px) {
      ${generateSpacers("-md")}
    }

    @media (min-width: 1200px) {
      ${generateSpacers("-lg")}
    }
  `;
}

export default  spacers;

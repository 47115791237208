import { createActions, createReducer } from "reduxsauce";
import { asyncCreatorFactory, notifySuccess } from "../action-utils";
import _pick from "lodash/pick";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  entrataFetching: ["actionId"],
  entrataSuccess: ["actionId"],
  entrataFailure: ["error", "actionId"],
  entrataSet: ["data", "attr", "actionId"],
  entrataReset: null,
});
export const actions = Creators;

/* ------------- Initial state ------------- */
const INITIAL_STATE = {
  isAuthenticated: undefined,
  properties: [],
  units: [],
  syncedUnitsCount: undefined,
  syncedCustomersCount: undefined,
  syncedCount: undefined,
  authSettings: undefined,
  fetching: [],
  error: [],
};

/* ------------- Reducers ------------- */
export const fetching = (state, { actionId }) => ({
  ...state,
  fetching: [...state.fetching, actionId],
  error: state.error.filter((i) => i.actionId !== actionId),
});
export const set = (state, { data, attr, actionId }) => ({
  ...state,
  [attr]: data,
});
export const success = (state, { actionId }) => ({
  ...state,
  fetching: state.fetching.filter((i) => i !== actionId),
  error: state.error.filter((i) => i.actionId !== actionId),
});
export const failure = (state, { error, actionId }) => ({
  ...state,
  fetching: state.fetching.filter((i) => i !== actionId),
  error: [...state.error, { error, actionId }],
});
export const reset = (state, { exclude = [] }) => ({
  ...INITIAL_STATE,
  ..._pick(state, exclude),
});

/* ------------- Hookup Reducers To Types ------------- */
const entrataReducer = createReducer(INITIAL_STATE, {
  [Types.ENTRATA_FETCHING]: fetching,
  [Types.ENTRATA_SUCCESS]: success,
  [Types.ENTRATA_FAILURE]: failure,
  [Types.ENTRATA_SET]: set,
  [Types.ENTRATA_RESET]: reset,
});

/* ------------- Async Actions ------------- */
const asyncCreator = asyncCreatorFactory(
  Creators.entrataFetching,
  Creators.entrataSuccess,
  Creators.entrataFailure
);

export const isAuthenticated = (buildingId) =>
  asyncCreator({
    actionId: "isAuthenticated",
    apiCall: (api) => api.entrata.isAuthenticated(buildingId),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.entrataSet(response.data, "isAuthenticated"));
    },
    onError: (dispatch) => {
      dispatch(Creators.entrataSet(false, "isAuthenticated"));
    },
    errorPath: "data.message",
    isNotifyError: false,
  });

export const retrieveAuthSettings = (buildingId) =>
  asyncCreator({
    actionId: "retrieveAuthSettings",
    apiCall: (api) => api.entrata.retrieveAuthSettings(buildingId),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.entrataSet(response.data.data, "authSettings"));
    },
    errorPath: "data.message",
  });

export const updateAuthSettings = (buildingId, data) =>
  asyncCreator({
    actionId: "updateAuthSettings",
    apiCall: (api) => api.entrata.updateAuthSettings(buildingId, data),
    onSuccess: (dispatch) => {
      notifySuccess("Entrata auth settings updated successfully");
      dispatch(retrieveAuthSettings(buildingId));
    },
    errorPath: "data.message",
  });

export const deleteAuthSettings = (buildingId) =>
  asyncCreator({
    actionId: "deleteAuthSettings",
    apiCall: (api) => api.entrata.deleteAuthSettings(buildingId),
    onSuccess: (dispatch) => {
      notifySuccess("Entrata auth settings removed successfully");
      dispatch(retrieveAuthSettings(buildingId));
    },
    errorPath: "data.message",
  });

export const clearErrors = () => actions.entrataSet([], "error");

export default entrataReducer;

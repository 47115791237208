import React from 'react';
import _get from "lodash/get";

import isURL from "validator/lib/isURL";
import MenuBadge from "../MenuBadge";
import { LOW_PLANS, NOPLAN_SUBSCRIPTION_PLAN } from "components/ui/subscribed/SubscribedPlan"
import { combineMenuWithPermissions, getListMenuItems, denyAccessToSections } from "../menu-utils"

export const SELECT_PATH = "/app/menu";
export const BG_COLOR = "#5cbea8";
export const ACTIVE_TAB_TITLE = "Messages"
export const BULLETIN_SUBMENU_NAME = "Bulletin Board";
export const ACCOUNT_SUBMENU_NAME = "My Account";

export const MENU_KEYS = {
  MESSAGES: "secondary_resident_messages",
  ACTIVITY: "secondary_resident_activity",
  PROMOS: "secondary_resident_promos",
  BULLETINBOARD: "secondary_resident_bulletinBoard",
  MYACCOUNTSECTION: "secondary_resident_myAccountSection",
  MYKEYS: "secondary_resident_myKeys",
  TRAININGVIDEOS: "secondary_resident_training_videos"
}

const getMenuItems = (avatar, messages) => ({
  [MENU_KEYS.MESSAGES]: { 
    name: "Messages", 
    path: "/app/messages", 
    icon: <i className="material-icons">mail</i>, 
    badge: messages.length ? <MenuBadge text={messages.length} />: null 
  },
  [MENU_KEYS.ACTIVITY]: { 
    name: "Activity", 
    path: "/app/activity", 
    icon: <i className="material-icons">dialpad</i> 
  },
  [MENU_KEYS.PROMOS]: { 
    name: "Promos", 
    path: "/app/promos", 
    icon: <i className="material-icons">card_giftcard</i> 
  },
  [MENU_KEYS.BULLETINBOARD]: {
    name: "Bulletin Board",
    path: "/app/bulletin_board",
    icon: <i className="fa fa-calendar"/>,
    children: [
      {
        name: "Building Notices",
        path: "/app/bulletin_board/notices",
        className: "icon-with-border",
        activeClassName: "active-with-color icon-with-border-active",
        icon: <i className="fa fa-newspaper"/>,
      },
      {
        name: "Building Calendar",
        path: "/app/bulletin_board/calendar",
        className: "icon-with-border",
        activeClassName: "active-with-color icon-with-border-active",
        icon: <i className="fa fa-calendar-check"/>,
      },
    ]
  },
  [MENU_KEYS.MYACCOUNTSECTION]: {
    name: "My Account",
    path: "/app/my_account",
    icon:
      avatar && isURL(avatar) ? (
        <img className="side-img" src={avatar} alt="" />
      ) : (
        <i className="material-icons">account_circle</i>
      ),
    children: [
      {
        name: "Account Settings",
        path: "/app/my_account/account_settings",
        className: "icon-with-border",
        activeClassName: "active-with-color icon-with-border-active",
        icon: <i className="fa fa-cogs" />
      },
      {
        name: "Password Settings",
        path: "/app/my_account/password_settings",
        className: "icon-with-border",
        activeClassName: "active-with-color icon-with-border-active",
        icon: <i className="fa fa-lock" />
      },
    ],
  },
  [MENU_KEYS.MYKEYS]: {
    name: "Visitor MyKeys",
    path: "/app/manage_users/visitor_Mykeys",
    className: "icon-with-border",
    activeClassName: "active-with-color icon-with-border-active",
    icon: <i className="fa fa-id-card" />
  },
   [MENU_KEYS.TRAININGVIDEOS]: {
    name: "Training Videos",
    path: `/app/training_videos`,
    icon: <i className="fa fa-video" />,
    activeClassName: "active-with-bg"
  },
})

export const getPermissions = (subscriptionPlan) => {
  const staticPermissions = {}

  if(LOW_PLANS.find(i => i === subscriptionPlan)) {
    denyAccessToSections(staticPermissions, {
      allowedSections: [MENU_KEYS.MYACCOUNTSECTION],
      menuKeys: MENU_KEYS
    })
  }

  return staticPermissions
}

export const getMenu = (avatar, messages, selectedBuilding) => {
    const subscriptionPlan = _get(selectedBuilding, "building_subscription_plan.name", NOPLAN_SUBSCRIPTION_PLAN)
    
    const menuItems = getMenuItems(avatar, messages)
    const permissions = getPermissions(subscriptionPlan)

    const menuItemsWithPermissions = combineMenuWithPermissions(menuItems, permissions)

    return getListMenuItems(menuItemsWithPermissions)
}
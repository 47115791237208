import React, { Component } from "react";
import styled from "styled-components";

const AppVersionStyles = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  font-size: 10px;
  color: white;
  z-index: 999;
  opacity: 0.6;
`;
class AppVersion extends Component {
  render() {
    return <AppVersionStyles>{process.env.REACT_APP_CURRENT_VERSION}</AppVersionStyles>;
  }
}

export default AppVersion;

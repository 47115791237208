import React, { useState } from 'react'

import VideoPlayer, { VideoStyles } from "components/ui/accessTable/VideoPlayer"

const PromoContent = ({ src }) => {
    const [urlType, setUrlType] = useState(null)

    const img = new Image();
    img.onload = function () { setUrlType('image') }
    img.onerror = function () { setUrlType('video') }
    img.src = src;

    if (!urlType) return <span className="text-muted">Loading...</span>

    return (
      <div 
        className="row small-gutters"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {
          urlType === 'video'
          ? (
            <VideoStyles>
              <VideoPlayer
                preload="none"
                controls={["Seek", "Time", "Fullscreen"]}
              >
                <source src={src} type="video/mp4" />
              </VideoPlayer>
            </VideoStyles>
          ) : (
            <img 
              src={src} 
              className='img-fluid img-thumbnail' 
              alt="Building logo" 
              width={'80px'}
              height={'80px'}
              style={{
                  maxHeight: '80px',
                  maxWidth: '80px'
              }}
            />
          )
        }
      </div>
    )
}

export default PromoContent
import { createReducer, createActions } from "reduxsauce";
import _get from "lodash/get";
import _set from "lodash/set";
import _filter from "lodash/filter";
import _union from "lodash/union";

import { notifySuccess, notifyError, asyncCreatorFactory } from "../action-utils";
import { setBuildings } from "./management";
import { setManagementCompanies } from "./dealer";
import { getBuildingInfo } from "redux/reducers/building";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  adminFetching: ["actionId"],
  adminSuccess: ["actionId"],
  adminFailure: ["error", "actionId"],
  adminSet: ["data", "attr", "namespaceToSet"],
  adminReset: null,
});
export const actions = Creators;

/* ------------- Initial state ------------- */
const INITIAL_STATE = {
  dealers: null,
  hasNextDealers: false,
  admins: null,
  roles: [],
  addDealer: null,
  keycoms: null,
  keycom_statuses: null,
  analytics: null,
  global_keycom_ads: null,
  available_buildings: null,
  ad_analytics: null,
  notices: null,
  events: null,
  promo: null,
  promos: null,
  meta: null,
  companyGroups: null,
  pin: null,
  mobileSettings: {},
  fetching: [],
  error: [],
  softwareVersions: {},
  trainingVideos: [],
  kioskVersions: [],
  versionsInKiosks: [],
  login_records: { meta: { "total-records": null, "select-fields": null }, data: null },
  full_access_log: { meta: { "total-records": null, "select-fields": null }, data: null },
  allUsers: {},
  push_notification_rules: {},
  dealerNames: [],
  managementNames: [],
  buildingNames: [],
  unitNames: [],
  slowRequests: {},
  helpCenterTickets: {},
};

/* ------------- Reducers ------------- */
export const fetching = (state, { actionId }) => ({
  ...state,
  fetching: [...state.fetching, actionId],
  error: state.error.filter((i) => i.actionId !== actionId),
});
export const set = (state, { data, attr, namespaceToSet }) => {
  switch (attr) {
    case "full_access_log": {
      return {
        ...state,
        [attr]: {
          meta: {
            ...state[attr]["meta"],
            "total-records": data["total"],
          },
          data: data.data,
        },
      };
    }
    case "full_access_log_filters": {
      return {
        ...state,
        [namespaceToSet]: {
          ...state[namespaceToSet],
          meta: {
            ...state[namespaceToSet]["meta"],
            "select-fields": data["select-fields"],
          },
        },
      };
    }
    default: {
      return {
        ...state,
        [attr]: data,
      };
    }
  }
};
export const success = (state, { actionId }) => ({
  ...state,
  fetching: state.fetching.filter((i) => i !== actionId),
  error: state.error.filter((i) => i.actionId !== actionId),
});
export const failure = (state, { error, actionId }) => ({
  ...state,
  fetching: state.fetching.filter((i) => i !== actionId),
  error: [...state.error, { error, actionId }],
});
export const reset = (state) => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */
const adminReducer = createReducer(INITIAL_STATE, {
  [Types.ADMIN_FETCHING]: fetching,
  [Types.ADMIN_SUCCESS]: success,
  [Types.ADMIN_FAILURE]: failure,
  [Types.ADMIN_SET]: set,
  [Types.ADMIN_RESET]: reset,
});

/* ------------- Async Actions ------------- */
const asyncCreator = asyncCreatorFactory(
  Creators.adminFetching,
  Creators.adminSuccess,
  Creators.adminFailure
);

export const getAdmins = () =>
  asyncCreator({
    actionId: "getAdmins",
    apiCall: (api) => api.admin.getAdmins(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.adminSet(response.data.data, "admins"));
    },
    errorPath: "data.description",
  });

export const createAdmin = (data) =>
  asyncCreator({
    actionId: "createAdmin",
    apiCall: (api) => api.admin.createAdmin(data),
    onSuccess: (dispatch, response) => {
      notifySuccess("Admin added successfully");
      dispatch(getAdmins());
    },
    errorPath: "data.description",
  });

export const editAdmin = (user_id, data) =>
  asyncCreator({
    actionId: "editAdmin",
    apiCall: (api) => api.admin.editAdmin(user_id, data),
    onSuccess: (dispatch, response) => {
      notifySuccess("Admin updated successfully");
      dispatch(getAdmins());
    },
    errorPath: "data.description",
  });

export const deleteAdmin = (user_id) =>
  asyncCreator({
    actionId: "deleteAdmin",
    apiCall: (api) => api.admin.deleteAdmin(user_id),
    onSuccess: (dispatch) => {
      notifySuccess("Admin deleted");
      dispatch(getAdmins());
    },
    errorPath: "data.description",
  });

export const deleteUserPermanently = (user_id, data = {}) =>
  asyncCreator({
    actionId: "deleteUserPermanently",
    apiCall: (api) => api.admin.deleteUserPermanently(user_id, data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("User deleted");
      const currentInfo = _get(getState(), "admin.allUsers");
      const users = _get(getState(), "admin.allUsers.data", []);
      const filteredUsers = users.filter((user) => user.id !== user_id);
      const nextInfo = _set(currentInfo, "data", filteredUsers);
      dispatch(Creators.adminSet(nextInfo, "allUsers"));
    },
    errorPath: "data.description",
  });

export const resendEmail = (user_id) =>
  asyncCreator({
    actionId: "resendEmail",
    apiCall: (api) => api.admin.resendEmail(user_id),
    onSuccess: (dispatch, response) => {
      notifySuccess("Resend successfully");
    },
    errorPath: "data.description",
  });

export const getDealers = () =>
  asyncCreator({
    actionId: "getDealers",
    apiCall: (api) => api.admin.getDealers(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.adminSet(response.data.data, "dealers"));
    },
    errorPath: "data.description",
  });

export const getDealersLazy = ({ page = 1, params = {} } = {}) =>
  asyncCreator({
    actionId: "getDealersLazy",
    apiCall: (api) => api.admin.getDealersLazy(page, { limit: 24, ...params }),
    onSuccess: (dispatch, response, getState) => {
      const hasNextDealers = _get(response.data, "has_next");
      const existingDealers = _get(getState(), "admin.dealers", []) || [];
      const fetchedDealers = _get(response, "data.data", []) || [];
      const dealers = _union(existingDealers, fetchedDealers);
      dispatch(Creators.adminSet(hasNextDealers, "hasNextDealers"));
      dispatch(Creators.adminSet(dealers, "dealers"));
    },
    errorPath: "data.description",
  });

export const addDealer = (data) =>
  asyncCreator({
    actionId: "addDealer",
    apiCall: (api) => api.admin.addDealer(data),
    onSuccess: (dispatch, response) => {
      notifySuccess("Dealer added successfully");
      dispatch(clearDealers());
      dispatch(getDealersLazy());
    },
    errorPath: "data.description",
    isNotifyError: false,
  });

export const createDealer = (data) =>
  asyncCreator({
    actionId: "createDealer",
    apiCall: (api) => api.admin.createDealer(data),
    onSuccess: (dispatch, response) => {
      notifySuccess("New dealer has been added");
    },
    errorPath: "data.description",
  });

export const getKeyComList = () =>
  asyncCreator({
    actionId: "getKeyComList",
    apiCall: (api) => api.admin.getKeyComList(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.adminSet(response.data.data, "keycoms"));
    },
    errorPath: "data.description",
  });

export const getKeyComStatusesFilters = () =>
  asyncCreator({
    actionId: "getKeyComStatusesFilters",
    apiCall: (api) => api.admin.getKeyComStatusesFilters(),
    onSuccess: (dispatch, response, getState) => {
      const state = getState();
      const data = _get(state, "admin.keycom_statuses.data", []);
      const meta = _get(state, "admin.keycom_statuses.meta", {});
      const statuses = {
        data,
        meta: {
          ...meta,
          ...response.data,
        },
      };
      dispatch(Creators.adminSet(statuses, "keycom_statuses"));
    },
    errorPath: "data.description",
  });

export const getKeyComStatusesListPaginated = (page, sizePerPage, params) =>
  asyncCreator({
    actionId: "getKeyComStatusesListPaginated",
    apiCall: (api) => api.admin.getKeyComStatusesListPaginated(page, sizePerPage, params),
    onSuccess: (dispatch, response, getState) => {
      const state = getState();
      const meta = _get(state, "admin.keycom_statuses.meta", {});
      const statuses = {
        data: response.data.keycom_statuses,
        meta: {
          ...meta,
          total_records: response.data.total_records,
        },
      };
      dispatch(Creators.adminSet(statuses, "keycom_statuses"));
    },
    errorPath: "data.description",
  });

export const updateKeyCom = (kiosk_id, data) =>
  asyncCreator({
    actionId: "updateKeyCom",
    apiCall: (api) => api.admin.updateKeyCom(kiosk_id, data),
    onSuccess: (dispatch, response, getState) => {
      const currentKeycoms = _get(getState(), "admin.keycoms", []);
      const kiosk = response.data.data;
      const newKeycoms = [...currentKeycoms.map((item) => (item.id === kiosk.id ? kiosk : item))];
      dispatch(Creators.adminSet(newKeycoms, "keycoms"));
      notifySuccess(`KeyCom updated successfully`);
    },
    errorPath: "data.message",
  });

export const deleteKiosk = (kioskId) =>
  asyncCreator({
    actionId: "deleteKiosk",
    apiCall: (api) => api.admin.deleteKiosk(kioskId),
    onSuccess: (dispatch, response, getState) => {
      const kiosks = getState().admin.keycoms;
      const newKiosks = _filter(kiosks, (i) => i.id !== kioskId);
      dispatch(Creators.adminSet(newKiosks, "keycoms"));
      notifySuccess("Kiosk deleted successfully");
    },
    errorPath: "data.description",
  });

export const getKeyComAfterUpdate = (kiosk_id) =>
  asyncCreator({
    actionId: "getKeyComAfterUpdate",
    apiCall: (api) => api.admin.getKeyComAfterUpdate(kiosk_id),
    onSuccess: (dispatch, response, getState) => {
      const currentKeycoms = _get(getState(), "admin.keycoms", []);
      const kiosk = response.data.data;
      const newKeycoms = [...currentKeycoms.map((item) => (item.id === kiosk.id ? kiosk : item))];
      dispatch(Creators.adminSet(newKeycoms, "keycoms"));
    },
    errorPath: "data.message",
  });

export const getLoginRecords = (page, perPage, params) =>
  asyncCreator({
    actionId: "getLoginRecords",
    apiCall: (api) => api.admin.getLoginRecords(page, perPage, params),
    onSuccess: (dispatch, response, getState) => {
      const state = getState();
      const meta = _get(state, "admin.login_records.meta", {});
      const records = {
        data: response.data.data,
        meta: {
          ...meta,
          "total-records": response.data.total,
        },
      };

      dispatch(Creators.adminSet(records, "login_records"));
    },
    errorPath: "data.description",
  });

export const getLoginRecordsFilters = () =>
  asyncCreator({
    actionId: "getLoginRecordsFilters",
    apiCall: (api) => api.admin.getLoginRecordsFilters(),
    onSuccess: (dispatch, response, getState) => {
      const state = getState();
      const data = _get(state, "admin.login_records.data", []);
      const meta = _get(state, "admin.login_records.meta", {});
      const records = {
        data,
        meta: {
          ...meta,
          ...response.data,
        },
      };

      dispatch(Creators.adminSet(records, "login_records"));
    },
    errorPath: "data.description",
  });

export const getIntegrationsLoginRecords = (page, perPage, params) =>
  asyncCreator({
    actionId: "getIntegrationsLoginRecords",
    apiCall: (api) => api.admin.getIntegrationsLoginRecords(page, perPage, params),
    onSuccess: (dispatch, response, getState) => {
      const state = getState();
      const meta = _get(state, "admin.integrations_login_records.meta", {});
      const records = {
        data: response.data.data,
        meta: {
          ...meta,
          total_records: response.data.total_records,
        },
      };

      dispatch(Creators.adminSet(records, "integrations_login_records"));
    },
    errorPath: "data.message",
  });

export const getIntegrationsLoginRecordFilters = () =>
  asyncCreator({
    actionId: "getIntegrationsLoginRecordFilters",
    apiCall: (api) => api.admin.getIntegrationsLoginRecordFilters(),
    onSuccess: (dispatch, response, getState) => {
      const state = getState();
      const data = _get(state, "admin.integrations_login_records.data", []);
      const meta = _get(state, "admin.integrations_login_records.meta", {});
      const records = {
        data,
        meta: {
          ...meta,
          ...response.data,
        },
      };

      dispatch(Creators.adminSet(records, "integrations_login_records"));
    },
    errorPath: "data.message",
  });

export const getUsersRecords = (page, perPage, params) =>
  asyncCreator({
    actionId: "getUsersRecords",
    apiCall: (api) => api.admin.getUsersRecords(page, perPage, params),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.adminSet(response.data, "allUsers"));
    },
    errorPath: "data.message",
  });

export const updateHeartbeatStatus = (serial_number, status) =>
  asyncCreator({
    actionId: "updateHeartbeatStatus",
    apiCall: (api) => api.admin.updateHeartbeatStatus(serial_number, status),
    onSuccess: (dispatch, response) => {
      notifySuccess(`Status has been updated to ${status}`);
    },
    errorPath: "data.description",
  });

export const getAnalytics = (dateRange) =>
  asyncCreator({
    actionId: "getAnalytics",
    apiCall: (api) => api.admin.getAnalytics(dateRange),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.adminSet(response.data, "analytics"));
    },
    errorPath: "data.description",
  });

export const getAvailableBuildings = () =>
  asyncCreator({
    actionId: "getAvailableBuildings",
    apiCall: (api) => api.admin.getAvailableBuildings(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.adminSet(response.data.data, "available_buildings"));
    },
    errorPath: "data.description",
  });

export const getGlobalKeycomAds = () =>
  asyncCreator({
    actionId: "getGlobalKeycomAds",
    apiCall: (api) => api.admin.getGlobalKeycomAds(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.adminSet(response.data.data, "global_keycom_ads"));
    },
    errorPath: "data.description",
  });

export const createGlobalKeycomAds = (data, { ...args }) =>
  asyncCreator({
    actionId: "createGlobalKeycomAds",
    apiCall: (api) => api.admin.createGlobalKeycomAds(data, { ...args }),
    onSuccess: (dispatch, response) => {
      dispatch(getGlobalKeycomAds());
      notifySuccess("Promos has been created");
    },
    errorPath: "data.message",
    onError: (dispatch, response, getState, errorPath) => {
      const { problem } = response;
      const error = _get(response, errorPath);
      if (problem === "CANCEL_ERROR") {
        notifyError("Uploading canceled by user.");
      } else {
        console.log(error);
        notifyError(error);
      }
    },
    isNotifyError: false,
  });

export const getAdAnalytics = () =>
  asyncCreator({
    actionId: "getAdAnalytics",
    apiCall: (api) => api.admin.getAdAnalytics(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.adminSet(response.data.data, "ad_analytics"));
    },
    errorPath: "data.description",
  });

export const getFullAccessLog = (page, perPage, params) =>
  asyncCreator({
    actionId: "getFullAccessLog",
    apiCall: (api) => api.admin.getFullAccessLog(page, perPage, params),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.adminSet(response.data, "full_access_log"));
    },
    errorPath: "data.description",
  });

export const getFullAccessLogFilters = () =>
  asyncCreator({
    actionId: "getFullAccessLogFilters",
    apiCall: (api) => api.admin.getFullAccessLogFilters(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.adminSet(response.data, "full_access_log_filters", "full_access_log"));
    },
    errorPath: "data.description",
  });

export const getBuildingKeyComList = (id) =>
  asyncCreator({
    actionId: "getBuildingKeyComList",
    apiCall: (api) => api.admin.getBuildingKeyComList(id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.adminSet(response.data.data, "building_keycoms"));
    },
    errorPath: "data.description",
  });

export const updateKioskSoftwareVersion = (id, data) =>
  asyncCreator({
    actionId: "updateKioskSoftwareVersion",
    apiCall: (api) => api.admin.updateKioskSoftwareVersion(id, data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Keycom Deployment successfully updated");

      const version = response.data.data;
      const is_default = _get(version, "is_default");
      const currentInfo = _get(getState(), "admin.softwareVersions", {});
      const key = is_default ? "defaultSoftwareVersion" : "currentSoftwareVersion";
      let nextInfo = _set(currentInfo, key, version);
      if (is_default) nextInfo = _set(currentInfo, "currentSoftwareVersion", {});

      const keycoms = _get(getState(), "admin.keycoms", []);
      const newKeycoms = keycoms.map((item) => {
        if (item.id === id) {
          return _set(item, "kiosk_software_version", version);
        }
        return item;
      });

      dispatch(Creators.adminSet(newKeycoms, "keycoms"));
      dispatch(Creators.adminSet(nextInfo, "softwareVersions"));
    },
    errorPath: "data.message",
  });

export const updateBuildingKiosk = (id, data, building_id) =>
  asyncCreator({
    actionId: "updateBuildingKiosk",
    apiCall: (api) => api.admin.updateKiosk(id, data),
    onSuccess: (dispatch, response) => {
      notifySuccess("Keycom successfully updated");
      if (building_id) {
        dispatch(getBuildingKeyComList(building_id));
        dispatch(getBuildingInfo(building_id));
      } else {
        dispatch(getKeyComAfterUpdate(id));
      }
    },
    errorPath: "data.message",
  });

export const getGlobalEvents = () =>
  asyncCreator({
    actionId: "getGlobalEvents",
    apiCall: (api) => api.admin.getEvents(),
    onSuccess: (dispatch, response, getState) => {
      dispatch(Creators.adminSet(response.data.data, "events"));
    },
    errorPath: "data.message",
  });

export const createMultipleEvents = (data) =>
  asyncCreator({
    actionId: "createMultipleEvents",
    apiCall: (api) => api.admin.createMultipleEvents(data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess(
        "Calendar events will be added gradually. Upon completion of the process, you will be notified.",
        5000
      );
      setTimeout(() => {
        dispatch(getGlobalEvents());
        notifySuccess("Global calendar events added successfully");
      }, 10000);
    },
    errorPath: "data.message",
  });

export const editGlobalEvent = (id, data) =>
  asyncCreator({
    actionId: "editGlobalEvent",
    apiCall: (api) => api.management.editBuildingEvent(id, data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Global calendar event updated succesfully");
      dispatch(getGlobalEvents());
    },
    errorPath: "data.message",
  });

export const deleteGlobalEvent = (id) =>
  asyncCreator({
    actionId: "deleteGlobalEvent",
    apiCall: (api) => api.management.deleteBuildingEvent(id),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Global calendar event deleted succesfully");
      dispatch(getGlobalEvents());
    },
    errorPath: "data.message",
  });

export const deleteEventsGroup = (group_id) =>
  asyncCreator({
    actionId: "deleteEventsGroup",
    apiCall: (api) => api.admin.deleteEventsGroup(group_id),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess(
        "Calendar events will be remove gradually. Upon completion of the process, you will be notified.",
        5000
      );
      setTimeout(() => {
        dispatch(getGlobalEvents());
        notifySuccess("Events group deleted succesfully");
      }, 10000);
    },
    errorPath: "data.message",
  });

export const getGlobalNotices = () =>
  asyncCreator({
    actionId: "getGlobalNotices",
    apiCall: (api) => api.admin.getNotices(),
    onSuccess: (dispatch, response, getState) => {
      dispatch(Creators.adminSet(response.data.data, "notices"));
    },
    errorPath: "data.message",
  });

export const createMultipleNotices = (data) =>
  asyncCreator({
    actionId: "createMultipleNotices",
    apiCall: (api) => api.admin.createMultipleNotices(data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess(
        "Notices will be added gradually. Upon completion of the process, you will be notified.",
        5000
      );
      setTimeout(() => {
        dispatch(getGlobalNotices());
        notifySuccess("Global Notices added successfully");
      }, 10000);
    },
    errorPath: "data.message",
  });

export const editGlobalNotice = (id, data) =>
  asyncCreator({
    actionId: "editBuildingNotice",
    apiCall: (api) => api.management.editBuildingNotice(id, data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Global Notice updated succesfully");
      dispatch(getGlobalNotices());
    },
    errorPath: "data.message",
  });

export const deleteGlobalNotice = (id) =>
  asyncCreator({
    actionId: "deleteBuildingNotice",
    apiCall: (api) => api.management.deleteBuildingNotice(id),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Global Notice deleted succesfully");
      dispatch(getGlobalNotices());
    },
    errorPath: "data.message",
  });

export const deleteNoticesGroup = (group_id) =>
  asyncCreator({
    actionId: "deleteNoticesGroup",
    apiCall: (api) => api.admin.deleteNoticesGroup(group_id),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess(
        "Notices will be added gradually. Upon completion of the process, you will be notified.",
        5000
      );
      setTimeout(() => {
        dispatch(getGlobalNotices());
        notifySuccess("Notices group deleted succesfully");
      }, 10000);
    },
    errorPath: "data.message",
  });

export const changeManager = ({ building_id, client_id } = {}) =>
  asyncCreator({
    actionId: "changeManager",
    apiCall: (api) => api.admin.changeManager({ building_id, client_id }),
    onSuccess: (dispatch, response, getState) => {
      const buildings = _get(getState(), "management.buildings", []);
      const buildingIdx = buildings.findIndex((building) => building.id === building_id);
      const managementCompany = _get(getState(), "dealer.companies", []).find(
        (company) => company.id === client_id
      );

      if (buildingIdx !== -1 && managementCompany) {
        const building = buildings[buildingIdx];
        const managementId = _get(managementCompany, "id", null);
        const managementName = _get(managementCompany, "name", null);
        const managementAccountId = _get(managementCompany, "account.id", null);
        buildings[buildingIdx] = {
          ...building,
          client: { id: managementAccountId, name: managementName, client_id: managementId },
        };
        dispatch(setBuildings(buildings));
      }
      notifySuccess("Manager changed successfully");
    },
    errorPath: "data.message",
  });

export const changeDealer = ({ dealer_id, client_id } = {}) =>
  asyncCreator({
    actionId: "changeDealer",
    apiCall: (api) => api.admin.changeDealer({ client_id, dealer_id }),
    onSuccess: (dispatch, response, getState) => {
      const companies = _get(getState(), "dealer.companies", []);
      const companyIdx = companies.findIndex((company) => company.id === client_id);
      const dealer = _get(getState(), "admin.dealers", []).find((dealer) => dealer.id === dealer_id);

      if (companyIdx !== -1 && dealer) {
        const company = companies[companyIdx];
        companies[companyIdx] = {
          ...company,
          dealer,
        };
        dispatch(setManagementCompanies(companies));
      }
      notifySuccess("Dealer changed successfully");
    },
    errorPath: "data.message",
  });

export const getMykeyPromo = () =>
  asyncCreator({
    actionId: "getMykeyPromo",
    apiCall: (api) => api.admin.getMykeyPromo(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.adminSet(response.data.data, "promo"));
    },
    errorPath: "data.message",
  });

export const addMykeyPromo = (data) =>
  asyncCreator({
    actionId: "addMykeyPromo",
    apiCall: (api) => api.admin.addMykeyPromo(data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Promo added successfully");
      const prevPromo = _get(getState(), "admin.promo", {});
      const newPromo = { ...prevPromo, ...response.data.data };
      dispatch(Creators.adminSet(newPromo, "promo"));
    },
    errorPath: "data.message",
  });

export const uploadPromoImg = (data) =>
  asyncCreator({
    actionId: "uploadPromoImg",
    apiCall: (api) => api.admin.uploadPromoImg(data),
    onSuccess: (dispatch, response, getState) => {
      const prevPromo = _get(getState(), "admin.promo", {});
      const newPromo = { ...prevPromo, img_url: response.data.data };
      dispatch(Creators.adminSet(newPromo, "promo"));
    },
    errorPath: "data.message",
  });

export const getMykeyPushedPromo = () =>
  asyncCreator({
    actionId: "getMykeyPushedPromo",
    apiCall: (api) => api.admin.getMykeyPushedPromo(),
    onSuccess: (dispatch, response, getState) => {
      dispatch(Creators.adminSet(response.data.data, "promos"));
    },
    errorPath: "data.message",
  });

export const getMetaInformation = () =>
  asyncCreator({
    actionId: "getMetaInformation",
    apiCall: (api) => api.admin.getMetaInformation(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.adminSet(response.data, "meta"));
    },
    errorPath: "data.message",
  });

export const getIosMinVersion = () =>
  asyncCreator({
    actionId: "getIosMinVersion",
    apiCall: (api) => api.mobile.getIosMinVersion(),
    onSuccess: (dispatch, response, getState) => {
      const prevMobileSettings = getState().admin.mobileSettings;
      const newMobileSettings = {
        ...prevMobileSettings,
        iosMinVersion: response.data,
      };
      dispatch(Creators.adminSet(newMobileSettings, "mobileSettings"));
    },
    errorPath: "data.message",
  });

export const getAndroidMinVersion = () =>
  asyncCreator({
    actionId: "getAndroidMinVersion",
    apiCall: (api) => api.mobile.getAndroidMinVersion(),
    onSuccess: (dispatch, response, getState) => {
      const prevMobileSettings = getState().admin.mobileSettings;
      const newMobileSettings = {
        ...prevMobileSettings,
        androidMinVersion: response.data,
      };
      dispatch(Creators.adminSet(newMobileSettings, "mobileSettings"));
    },
    errorPath: "data.message",
  });

export const updateIosMinVersion = (data) =>
  asyncCreator({
    actionId: "updateIosMinVersion",
    apiCall: (api) => api.mobile.updateIosMinVersion(data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Ios min version changed successfully");
      const prevMobileSettings = getState().admin.mobileSettings;
      const newMobileSettings = {
        ...prevMobileSettings,
        iosMinVersion: response.data,
      };
      dispatch(Creators.adminSet(newMobileSettings, "mobileSettings"));
    },
    errorPath: "data.message",
  });

export const updateAndroidMinVersion = (data) =>
  asyncCreator({
    actionId: "updateAndroidMinVersion",
    apiCall: (api) => api.mobile.updateAndroidMinVersion(data),
    onSuccess: (dispatch, response, getState) => {
      notifySuccess("Android min version changed successfully");
      const prevMobileSettings = getState().admin.mobileSettings;
      const newMobileSettings = {
        ...prevMobileSettings,
        androidMinVersion: response.data,
      };
      dispatch(Creators.adminSet(newMobileSettings, "mobileSettings"));
    },
    errorPath: "data.message",
  });

export const getCompanyGroups = () =>
  asyncCreator({
    actionId: "getCompanyGroups",
    apiCall: (api) => api.admin.getCompanyGroups(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.adminSet(response.data.data, "companyGroups"));
    },
    errorPath: "data.message",
  });

export const addCompanyGroup = (data) =>
  asyncCreator({
    actionId: "addCompanyGroup",
    apiCall: (api) => api.admin.addCompanyGroup(data),
    onSuccess: (dispatch, response) => {
      notifySuccess("The company group was added successfully.");
      dispatch(getCompanyGroups());
    },
    errorPath: "data.message",
  });

export const updateCompanyGroup = (id, data) =>
  asyncCreator({
    actionId: "updateCompanyGroup",
    apiCall: (api) => api.admin.updateCompanyGroup(id, data),
    onSuccess: (dispatch, response) => {
      notifySuccess("The company group was updated successfully.");
      dispatch(getCompanyGroups());
    },
    errorPath: "data.message",
  });

export const deleteCompanyGroup = (id) =>
  asyncCreator({
    actionId: "deleteCompanyGroup",
    apiCall: (api) => api.admin.deleteCompanyGroup(id),
    onSuccess: (dispatch, response) => {
      notifySuccess("The company group was successfully deleted.");
      dispatch(getCompanyGroups());
    },
    errorPath: "data.message",
  });

export const getPin = () =>
  asyncCreator({
    actionId: "getPin",
    apiCall: (api) => api.admin.getPin(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.adminSet(response.data, "pin"));
    },
    errorPath: "data.message",
  });

export const updatePin = (id, data) =>
  asyncCreator({
    actionId: "updatePin",
    apiCall: (api) => api.admin.updatePin(id, data),
    onSuccess: (dispatch, response) => {
      notifySuccess("The admin pin was updated successfully.");
      dispatch(Creators.adminSet(response.data, "pin"));
    },
    errorPath: "data.message",
  });

export const getKioskSoftwareVersion = (id = null) =>
  asyncCreator({
    actionId: "KioskSoftwareVersionGet",
    apiCall: (api) => api.admin.getKioskSoftwareVersion(id),
    onSuccess: (dispatch, response, getState) => {
      const version = response.data.data;
      const currentInfo = _get(getState(), "admin.softwareVersions", {});
      const is_default = _get(version, "is_default");
      const kiosk_id = _get(version, "kiosk_id");
      let nextInfo;
      if (is_default || !kiosk_id) {
        nextInfo = _set(currentInfo, "defaultSoftwareVersion", version);
        nextInfo = _set(currentInfo, "currentSoftwareVersion", {});
      } else {
        nextInfo = _set(currentInfo, "currentSoftwareVersion", version);
      }

      dispatch(Creators.adminSet(nextInfo, "softwareVersions"));
    },
    errorPath: "data.message",
  });

export const getKioskVersions = () =>
  asyncCreator({
    actionId: "getKioskVersions",
    apiCall: (api) => api.admin.getKioskVersions(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.adminSet(response.data, "kioskVersions"));
    },
    errorPath: "data.message",
  });
export const getVersionsInKiosks = () =>
  asyncCreator({
    actionId: "getVersionsInKiosks",
    apiCall: (api) => api.admin.getVersionsInKiosks(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.adminSet(response.data, "versionsInKiosks"));
    },
    errorPath: "data.message",
  });

export const getTrainingVideos = (for_who = "all") =>
  asyncCreator({
    actionId: "getTrainingVideos",
    apiCall: (api) => api.admin.getTrainingVideos(for_who),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.adminSet(response.data, "trainingVideos"));
    },
    errorPath: "data.message",
  });
export const updateTrainingVideo = (id, data, { ...args }) =>
  asyncCreator({
    actionId: "updateTrainingVideo",
    apiCall: (api) => api.admin.updateTrainingVideo(id, data, { ...args }),
    onSuccess: (dispatch, response, getState) => {
      dispatch(getTrainingVideos());
      notifySuccess("The promo was successfully updated");
    },
    errorPath: "data.message",
    onError: (dispatch, response, getState, errorPath) => {
      const { problem } = response;
      const error = _get(response, errorPath);
      if (problem === "CANCEL_ERROR") {
        notifyError("Uploading canceled by user.");
      } else {
        console.log(error);
        notifyError(error);
      }
    },
    isNotifyError: false,
  });

export const addTrainingVideo = (data, { ...args }) =>
  asyncCreator({
    actionId: "addTrainingVideo",
    apiCall: (api) => api.admin.addTrainingVideo(data, { ...args }),
    onSuccess: (dispatch, response, getState) => {
      dispatch(getTrainingVideos());
      notifySuccess("New promo was successfully created");
    },
    errorPath: "data.message",
  });

export const deleteTrainingVideo = (id) =>
  asyncCreator({
    actionId: "deletePromo",
    apiCall: (api) => api.admin.deleteTrainingVideo(id),
    onSuccess: (dispatch, response, getState) => {
      dispatch(getTrainingVideos());
      notifySuccess("The promo was successfully deleted");
    },
    errorPath: "data.message",
  });

export const addKioskVersion = (data) =>
  asyncCreator({
    actionId: "addKioskVersion",
    apiCall: (api) => api.admin.addKioskVersion(data),
    onSuccess: (dispatch, response, getState) => {
      dispatch(getKioskVersions());
      notifySuccess("New kiosk version was successfully added");
    },
    errorPath: "data.message",
  });

export const deleteKioskVersion = (version) =>
  asyncCreator({
    actionId: "deleteKioskVersion",
    apiCall: (api) => api.admin.deleteKioskVersion(version),
    onSuccess: (dispatch, response, getState) => {
      dispatch(getKioskVersions());
      notifySuccess("The kiosk version was successfully deleted");
    },
    errorPath: "data.message",
  });

export const clearKeycoms = () => actions.adminSet(null, "keycoms");

export const getPushNotificationRulesFilters = () =>
  asyncCreator({
    actionId: "getPushNotificationRulesFilters",
    apiCall: (api) => api.admin.getPushNotificationRulesFilters(),
    onSuccess: (dispatch, response, getState) => {
      const state = getState();
      const data = _get(state, "admin.push_notification_rules.data", []);
      const meta = _get(state, "admin.push_notification_rules.meta", {});
      const settings = {
        data,
        meta: {
          ...meta,
          ...response.data,
        },
      };
      dispatch(Creators.adminSet(settings, "push_notification_rules"));
    },
    errorPath: "data.message",
  });

export const getPushNotificationRulesPaginated = (page, sizePerPage, params) =>
  asyncCreator({
    actionId: "getPushNotificationRulesPaginated",
    apiCall: (api) => api.admin.getPushNotificationRulesPaginated(page, sizePerPage, params),
    onSuccess: (dispatch, response, getState) => {
      const state = getState();
      const meta = _get(state, "admin.push_notification_rules.meta", {});
      const settings = {
        data: response.data.push_notification_rules,
        meta: {
          ...meta,
          totalRules: response.data.total_rules,
        },
      };

      dispatch(Creators.adminSet(settings, "push_notification_rules"));
    },
    errorPath: "data.description",
    isNotifyError: false,
  });

export const addPushNotificationRule = (ruleData) =>
  asyncCreator({
    actionId: "addPushNotificationRule",
    apiCall: (api) => api.admin.addPushNotificationRule(ruleData),
    onSuccess: (dispatch, response, getState) => {
      dispatch(getPushNotificationRulesFilters());
      dispatch(getPushNotificationRulesPaginated());
      notifySuccess("Push notification rule was successfully added");
    },
    errorPath: "data.message",
  });

export const changePushNotificationRule = (ruleId, ruleData) =>
  asyncCreator({
    actionId: "changePushNotificationRule",
    apiCall: (api) => api.admin.changePushNotificationRule(ruleId, ruleData),
    onSuccess: (dispatch, response, getState) => {
      dispatch(getPushNotificationRulesFilters());
      dispatch(getPushNotificationRulesPaginated());
      notifySuccess("Push notification rule was successfully changed");
    },
    errorPath: "data.message",
  });

export const deletePushNotificationRule = (ruleId) =>
  asyncCreator({
    actionId: "deletePushNotificationRule",
    apiCall: (api) => api.admin.deletePushNotificationRule(ruleId),
    onSuccess: (dispatch, response, getState) => {
      dispatch(getPushNotificationRulesFilters());
      dispatch(getPushNotificationRulesPaginated());
      notifySuccess("Push notification rule was successfully deleted");
    },
    errorPath: "data.message",
  });

export const getRoles = () =>
  asyncCreator({
    actionId: "getRoles",
    apiCall: (api) => api.admin.getRoles(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.adminSet(response.data, "roles"));
    },
    errorPath: "data.message",
  });

export const updateRolesActiationCodeExpirationTime = (settings) =>
  asyncCreator({
    actionId: "updateRolesActiationCodeExpirationTime",
    apiCall: (api) => api.admin.updateRolesActiationCodeExpirationTime(settings),
    onSuccess: (dispatch, response) => {
      notifySuccess(response.data.message);
    },
    errorPath: "data.message",
  });

export const getDealerNames = () =>
  asyncCreator({
    actionId: "getDealerNames",
    apiCall: (api) => api.admin.getDealerNames(),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.adminSet(response.data, "dealerNames"));
    },
    errorPath: "data.message",
  });

export const getManagementNames = ({ dealer_id, dealer_account_id } = {}) =>
  asyncCreator({
    actionId: "getManagementNames",
    apiCall: (api) => api.admin.getManagementNames({ dealer_id, dealer_account_id }),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.adminSet(response.data, "managementNames"));
    },
    errorPath: "data.message",
  });

export const getBuildingNames = (params) =>
  asyncCreator({
    actionId: "getBuildingNames",
    apiCall: (api) => api.admin.getBuildingNames(params),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.adminSet(response.data, "buildingNames"));
    },
    errorPath: "data.message",
  });

export const getUnitNames = (building_id) =>
  asyncCreator({
    actionId: "getUnitNames",
    apiCall: (api) => api.admin.getUnitNames(building_id),
    onSuccess: (dispatch, response) => {
      dispatch(Creators.adminSet(response.data, "unitNames"));
    },
    errorPath: "data.message",
  });

export const moveUser = (params, user_id) =>
  asyncCreator({
    actionId: "moveUser",
    apiCall: (api) => api.admin.moveUser(params, user_id),
    onSuccess: async (dispatch, response, getState) => {
      await dispatch(getUsersRecords());
      notifySuccess(response.data.message);
    },
    errorPath: "data.message",
  });

export const getSlowRequestsReport = (page = 1, per_page = 50, params = {}) =>
  asyncCreator({
    actionId: "getSlowRequestsReport",
    apiCall: (api) => api.admin.getSlowRequestsReport({ page, per_page, ...params }),
    onSuccess: (dispatch, response, getState) => {
      const { total_count, api_usage_records } = response.data;
      const meta = _get(getState(), "admin.slowRequests.meta", {});
      const updatedMeta = { ...meta, total_count };
      const records = { data: api_usage_records, meta: updatedMeta };
      dispatch(Creators.adminSet(records, "slowRequests"));
    },
    errorPath: "data.message",
  });

export const getSlowRequestsReportFilters = () =>
  asyncCreator({
    actionId: "getSlowRequestsReportFilters",
    apiCall: (api) => api.admin.getSlowRequestsReportFilters(),
    onSuccess: (dispatch, response, getState) => {
      const filters = response.data;
      const meta = _get(getState(), "admin.slowRequests.meta", {});
      const data = _get(getState(), "admin.slowRequests.data", []);
      const updatedMeta = { ...meta, ...filters };
      const records = { data, meta: updatedMeta };
      dispatch(Creators.adminSet(records, "slowRequests"));
    },
    errorPath: "data.message",
  });

export const clearSlowRequestsReport = () =>
  asyncCreator({
    actionId: "clearSlowRequestsReport",
    apiCall: (api) => api.admin.clearSlowRequestsReport(),
    onSuccess: (dispatch, response, getState) => {
      dispatch(getSlowRequestsReport());
    },
    errorPath: "data.message",
  });

export const getHelpCenterTickets = (page = 1, per_page = 50, params = {}, tableRef=null) =>
  asyncCreator({
    actionId: "getHelpCenterTickets",
    apiCall: (api) => api.admin.getHelpCenterTickets({ page, per_page, ...params }),
    onSuccess: (dispatch, response, getState) => {
      const { meta, data } = response.data;
      const current_meta = _get(getState(), "admin.helpCenterTickets.meta", {});
      const updatedMeta = { ...current_meta, ...meta };
      const records = { data, meta: updatedMeta };

      dispatch(Creators.adminSet(records, "helpCenterTickets"));
    },
    errorPath: "data.message",
    onError: () => {
      if (tableRef) {
        tableRef.onClearFiltersClick()
      }
    },
  });

export const getHelpCenterTicketsFilters = () =>
  asyncCreator({
    actionId: "getHelpCenterTicketsFilters",
    apiCall: (api) => api.admin.getHelpCenterTicketsFilters(),
    onSuccess: (dispatch, response, getState) => {
      const filters = response.data;
      const meta = _get(getState(), "admin.helpCenterTickets.meta", {});
      const data = _get(getState(), "admin.helpCenterTickets.data", []);
      const updatedMeta = { ...meta, ...filters };
      const records = { data, meta: updatedMeta };

      dispatch(Creators.adminSet(records, "helpCenterTickets"));
    },
    errorPath: "data.message",
  });

export const updateHelpCenterTicketStatus = (ticket_id, params) =>
  asyncCreator({
    actionId: "updateHelpCenterTicketStatus",
    apiCall: (api) => api.admin.updateHelpCenterTicketStatus(ticket_id, params),
    onSuccess: (dispatch, response, getState) => {
      dispatch(getHelpCenterTickets());
    },
    errorPath: "data.message",
  });

export const getBuildingChangeLog = (page = 1, per_page = 50, params = {}) =>
  asyncCreator({
    actionId: "getBuildingChangeLog",
    apiCall: (api) => api.admin.getBuildingChangeLog({ page, per_page, ...params }),
    onSuccess: (dispatch, response, getState) => {
      const { meta, data } = response.data;
      const current_meta = _get(getState(), "admin.buildingChangeLog.meta", {});
      const updatedMeta = { ...current_meta, ...meta };
      const records = { data, meta: updatedMeta };

      dispatch(Creators.adminSet(records, "buildingChangeLog"));
    },
    errorPath: "data.message",
  });

export const getBuildingChangeLogFilters = () =>
  asyncCreator({
    actionId: "getBuildingChangeLogFilters",
    apiCall: (api) => api.admin.getBuildingChangeLogFilters(),
    onSuccess: (dispatch, response, getState) => {
      const filters = response.data;
      const meta = _get(getState(), "admin.buildingChangeLog.meta", {});
      const data = _get(getState(), "admin.buildingChangeLog.data", []);
      const updatedMeta = { ...meta, ...filters };
      const records = { data, meta: updatedMeta };

      dispatch(Creators.adminSet(records, "buildingChangeLog"));
    },
    errorPath: "data.message",
  });

export const clearDealers = () => actions.adminSet(null, "dealers");
export const clearDealerNames = () => actions.adminSet([], "dealerNames");
export const clearManagementNames = () => actions.adminSet([], "managementNames");
export const clearBuildingNames = () => actions.adminSet([], "buildingNames");
export const clearUnitNames = () => actions.adminSet([], "unitNames");
export const setKeycoms = (keycoms) => actions.adminSet(keycoms, "building_keycoms");

export default adminReducer;

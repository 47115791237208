import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import isURL from "validator/lib/isURL";
import avatar from "assets/avatar.png";
import moment from "moment";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _mapValues from "lodash/mapValues";
import Loadable from "react-loadable";
import AvatarPopover from "components/ui/avatarPopover/AvatarPopover";
import queryString from "query-string";
import qrCellIcon from "assets/QR-in-mobile-icon.svg";
import mykeyTabIcon from "assets/mvi-icons/mykey-tab.png";
import appCallIcon from "assets/mvi-icons/app-call.svg";
import webrelayIcon from "assets/mvi-icons/webrelay.svg";
import incomingCallIcon from "assets/mvi-icons/incoming-call.svg";
import digitalMykeyIcon from "assets/mvi-icons/digital-mykey.png";
import visitorMykeyIcon from "assets/mvi-icons/visitor-mykey.png";
import landlineCallIcon from "assets/mvi-icons/landline-call.png";
import accessPinIcon from "assets/mvi-icons/access-pin.png";
import {
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Collapse,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import { MANAGER_ROLES, DEALER_ROLES } from "components/ui/authorized/AuthorizedRole";
import { mobilePhoneRe } from "./utils";
import { connect } from "react-redux";
import styled from "styled-components";
import { DateParseWithTimezone } from "utils/timezone";
import PromoContent from "components/pages/buildings/promos/PromoContent";

const isNA = (value) => value === null || value === undefined || value === "N/A";

const onAvatarImageError = (e) => (e.target.src = avatar);
/**
 * DateRange filter function
 */
class LoadDate extends React.Component {
  render() {
    return null;
  }
}
const DateRangeFilter = Loadable({
  loader: () => import("components/ui/dateRange/DateRangeFilter"),
  loading: LoadDate,
});

/**
 * Data table formats
 */
const formatDefault =
  (formatFn) =>
  (cell, row, formatExtraData = {}, rowIdx) => {
    const { name } = formatExtraData;
    const value = formatFn ? formatFn(cell, row, formatExtraData, rowIdx) : cell;
    if ((name === "Email" && !value) || (name === "Access Control" && !value)) {
      return <span className="text-muted"> N/A </span>;
    }
    return (
      <div className="table-cell">
        {name && <span className="text-muted d-block d-sm-none table-cell__label">{name}</span>}
        <span className={"break-word d-block table-cell__value " + (_get(row, "_disabled", false) ? "text-muted" : "")}>{value}</span>
      </div>
    );
  };
const formatUsername = (cell, row, formatExtraData, rowIdx) => {
  if (row && row.status !== "New") {
    return cell && <span> {cell} </span>;
  } else return <span className="text-muted"> N/A </span>;
};
const formatFullName = (cell, row, formatExtraData, rowIdx) => {
  if (!cell && !row.last_name) {
    return (
      <span className="text-muted">{row.status === "New" ? "Available to setup at KeyCom®" : "N/A"}</span>
    );
  }
  return (
    cell &&
    row && (
      <span>
        {cell} {row.last_name}
      </span>
    )
  );
};
const formatBothNames = (cell, row, formatExtraData, rowIdx) => {
  const isAllNA = isNA(cell) || isNA(row);
  return isAllNA ? (
    "N/A"
  ) : (
    <span>
      {cell} <br /> {row.last_name}
    </span>
  );
};

const formatUserType = (cell, row, formatExtraData, rowIdx) => {
  if (MANAGER_ROLES.includes(cell))
    return (
      <div>
        <i className="fa icon-size mr-2 fa-user-tie" /> Management
      </div>
    );
  if (DEALER_ROLES.includes(cell))
    return (
      <div>
        <i className="fa icon-size mr-2 fa-user-cog" /> Dealer
      </div>
    );

  return (
    <div>
      <i className="fa icon-size mr-2 fa-user" /> Resident
    </div>
  );
};

const formatUnitDirectory = (cell, row, formatExtraData, rowIdx) => {
  const image = row.directory_image && isURL(row.directory_image) ? row.directory_image : avatar;
  const isAllNA = isNA(row.unit) && isNA(row.listing_name);
  return (
    row && (
      <div className="d-flex align-items-center p-l-10">
        <div>
          <AvatarPopover image={image} borderRadius={5} width={"50"} height={"50"} />
        </div>
        <div className="m-l-10 d-flex flex-column align-items-start" style={{ fontWeight: "bold" }}>
          {isAllNA ? (
            <span className="text-left">N/A</span>
          ) : (
            <>
              <span className="text-left">{row.unit}</span>
              <span className="text-left">{row.listing_name}</span>
            </>
          )}
        </div>
      </div>
    )
  );
};

const formatListName = (cell, row, formatExtraData, rowIdx) => {
  return (
    cell && (
      <span className="text-uppercase">
        <b>{cell}</b>
      </span>
    )
  );
};
const formatStatus = (cell, row, formatExtraData, rowIdx) => {
  const text = cell.split("_").length > 1 ? "N/A" : cell;
  const status = text === "N/A" || !text ? "na" : text.toLowerCase().replace(" ", "_");
  return cell && <div className={`p-l-10 p-r-10 status center ${status}`}>{text}</div>;
};
const formatPhone = (cell, row, formatExtraData, rowIdx) => {
  if (!cell) {
    return <span className="text-muted"> N/A </span>;
  }
  return cell && mobilePhoneRe.test(cell) ? cell.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3") : cell;
};
const formatDate =
  (format = "MMM DD YY, h:mmA", isLocal = true) =>
  (cell, row, formatExtraData, rowIdx) => {
    if (!cell || cell === "N/A") {
      return <span className="text-muted"> N/A </span>;
    }
    return isLocal
      ? moment(cell).parseZone().local().format(format)
      : moment(cell).parseZone(false).format(format);
  };
const formatDateWithoutTime =
  (format = "MMM DD, YY") =>
  (cell, row, formatExtraData, rowIdx) => {
    if (!cell || cell === "N/A") {
      return <span className="text-muted"> N/A </span>;
    }
    return moment(cell).format(format);
  };
const formatDateAgo =
  (local = false) =>
  (cell, row, formatExtraData, rowIdx) => {
    if (!cell || cell === "N/A") {
      return <span className="text-muted"> N/A </span>;
    }
    return local ? moment(cell).parseZone().local().fromNow() : moment(cell).fromNow();
  };
const formatCSVDate =
  (format = "MMM DD YY, h:mmA") =>
  (cell, row) =>
    moment(cell).format(format);
const formatCSVUserType = (cell, row) => {
  if (MANAGER_ROLES.includes(cell)) return "Management";
  if (DEALER_ROLES.includes(cell)) return "Dealer";
  return "Resident";
};

export const formatListing = (cell, row, formatExtraData, rowIdx) => {
  const image = row.image_url && isURL(row.image_url) ? row.image_url : avatar;
  return (
    <span className="row d-flex align-items-center p-l-30 flex-nowrap">
      <AvatarPopover image={image} />
      {cell && (
        <span className="text-uppercase p-l-10 text-truncate">
          <b>{cell}</b>
        </span>
      )}
    </span>
  );
};
export const formatUserListing = (cell, row, formatExtraData, rowIdx) => {
  const image = row.facial_rek && isURL(row.facial_rek) ? row.facial_rek : avatar;
  return (
    <span className="row d-flex align-items-center p-l-30">
      <AvatarPopover image={image} />
      {cell && row && (
        <span className="text-uppercase p-l-10">
          <b>
            {cell} {row.last_name}
          </b>
        </span>
      )}
    </span>
  );
};
export const formatFullNameAndPicture = (cell, row, formatExtraData, rowIdx) => {
  const { lineBreak } = formatExtraData;
  const image = row.image_url && isURL(row.image_url) ? row.image_url : avatar;
  return (
    <span className="d-flex align-items-center">
      <AvatarPopover image={image} />
      {row && (
        <span className="p-l-10 overflow-auto">
          {lineBreak ? (
            <>
              <b className="d-block">{cell}</b>
              <b>{row.last_name}</b>
            </>
          ) : (
            <b>
              {cell} {row.last_name}
            </b>
          )}
        </span>
      )}
    </span>
  );
};
export const formatAvatar = (cell, row, formatExtraData, rowIdx) => {
  const image = cell && isURL(cell) ? cell : avatar;
  return <AvatarPopover image={image} />;
};
export const formatListened = (cell, row, formatExtraData, rowIdx) => {
  return (
    cell === 0 && (
      <span className="c-pink">
        <strong>NEW!</strong>
      </span>
    )
  );
};
export const formatDuration = (cell, row, formatExtraData, rowIdx) => {
  const icon = iconsMap[cell];
  return (
    <span>
      {icon} <span className="m-l-10">{cell} </span>
    </span>
  );
};

export const formatUserContacts = (cell, row) => {
  return (
    <>
      <span className="overflow-auto break-word">
        <strong>email: </strong>
        {row["email"]}
      </span>
      {row["mobile_phone"] && (
        <span className="overflow-auto break-word">
          <br />
          <strong>tel. </strong>
          {row["mobile_phone"]}
        </span>
      )}
    </>
  );
};

export const formatUser = (cell, row) => {
  return (
    <>
      <span>{formatUserType(cell)}</span>
      <span>
        <strong>role:</strong> {cell}
      </span>
    </>
  );
};

const qrIconStyles = {
  border: 0,
  borderRadius: "0 !important",
  overflow: "initial",
  width: "18px",
  marginLeft: "-4px",
};
const customIconStyles = {
  border: 0,
  borderRadius: "0 !important",
  overflow: "initial",
  width: 16,
  marginRight: 4,
};
const svgIconStyles = {
  border: 0,
  borderRadius: "0 !important",
  overflow: "initial",
  width: 32,
  marginRight: 4,
};
const iconsMap = {
  "MyKey Entry": <i className="fa icon-size mr-1 fa-qrcode" />,
  "Digital MyKey Entry": <img src={qrCellIcon} style={qrIconStyles} alt="Digital MyKey" />,
  "Landline Entry": <i className="fa icon-size mr-1 fa-phone" />,
  "Access Pin Entry": <i className="fa icon-size mr-1 fa-table" />,
  "App Call Entry": <i className="fa icon-size mr-1 fa-mobile" />,
  "Bluetooth Entry": <i className="fab icon-size mr-1 fa-bluetooth-b" />,
  Tailgater: <i className="fa icon-size mr-1 fa-walking" />,
  Resident: <i className="fa icon-size mr-1 fa-user" />,
  Maintenance: <i className="fa icon-size mr-1 fa-wrench" />,
  Vendor: <i className="fa icon-size mr-1 fa-wrench" />,
  Visitor: <i className="fa icon-size mr-1 fa-address-card" />,
  Delivery: <i className="fab icon-size mr-1 fa-dropbox" />,
  "Delivery Arrived": <i className="fab icon-size mr-1 fa-dropbox" />,
  Management: <i className="material-icons icon-size">assignment_ind</i>,
  "MyKey Assigned": <i className="fa icon-size mr-1 fa-qrcode" />,
  "MyKey Deactivated": <i className="fa icon-size mr-1 fa-qrcode" />,
  "Mykey Set Added": <i className="fa icon-size mr-1 fa-qrcode" />,
  "Visitor Mykey Sent": <i className="fa icon-size mr-1 fa-address-card" />,
  "User Added": <i className="fa icon-size mr-1 fa-user-plus" />,
  "Secondary Resident Added": <i className="fa icon-size mr-1 fa-user-plus" />,
  "User Modified": <i className="fas icon-size mr-1 fa-user-edit" />,
  "User Edited": <i className="fas icon-size mr-1 fa-user-edit" />,
  "User Removed": <i className="fa icon-size mr-1 fa-user-times" />,
  "User Denied": <i className="fa icon-size mr-1 fa-ban" />,
  "Unit Activated": <i className="fas icon-size mr-1 fa-door-open" />,
  "User Re-allowed": <i className="fas icon-size mr-1 fa-door-open" />,
  "Unit Assigned": <i className="fas icon-size mr-1 fa-door-closed" />,
  "Unit Vacated": <i className="fas icon-size mr-1 fa-dolly" />,
  "Unit Edited": <i className="fa icon-size mr-1 fa-edit" />,
  "24 Hours": <i className="fa icon-size mr-1 fa-clock" />,
  "Day Hours (6AM-6PM)": <i className="fa icon-size mr-1 fa-sun" />,
  "Night Hours (6PM-6AM)": <i className="fa icon-size mr-1 fa-moon" />,
  Bluetooth: <i className="fab icon-size mr-1 fa-bluetooth-b" />,
  "MyKey Tab": <img src={mykeyTabIcon} style={customIconStyles} alt="MyKey Tab" />,
  "App Call": <img src={appCallIcon} style={svgIconStyles} alt="App Call" />,
  "Incoming Call": <img src={incomingCallIcon} style={svgIconStyles} alt="Incoming Call" />,
  "Digital MyKey": <img src={digitalMykeyIcon} style={customIconStyles} alt="Digital MyKey" />,
  "Bluetooth APP": <i className="fab icon-size mr-1 fa-bluetooth" />,
  "Visitor MyKey": <img src={visitorMykeyIcon} style={customIconStyles} alt="Visitor MyKey" />,
  "Landline Call": <img src={landlineCallIcon} style={customIconStyles} alt="Landline Call" />,
  "Access Pin": <img src={accessPinIcon} style={customIconStyles} alt="Access Pin" />,
  "Brivo Key": <i className="fa icon-size mr-1 fa-key" />,
  "Hartmann Key": <i className="fa icon-size mr-1 fa-key" />,
  "Pdk Key": <i className="fa icon-size mr-1 fa-key" />,
  REX: <i className="fa icon-size mr-1 fa-sign-out-alt" />,
  "Door Open Alert": <i className="fa icon-size mr-1 fa-sign-out-alt" />,
  Exit: <i className="fa icon-size mr-1 fa-sign-out-alt" />,
  "Remote Access": <i className="fa icon-size mr-1 fa-rss" />,
  "Call To KeyCom": <i className="fa icon-size mr-1 fa-phone" />,
  "Webrelay Toggle": <img src={webrelayIcon} style={customIconStyles} alt="Webrelay Toggle" />,
};

const formatWithicon = (cell, row) => {
  const icon = iconsMap[cell];
  return (
    <span>
      {icon} <span className="m-l-10">{cell} </span>
    </span>
  );
};
const formatActivityIcon = (cell, row) => {
  if (cell === "User Removed" || cell === "User Added") {
    const image = row.user_photo && isURL(row.user_photo) ? row.user_photo : avatar;
    return (
      <span className="d-flex flex-row justify-content-center align-items-center">
        <AvatarPopover image={image} />
      </span>
    );
  } else {
    const icon = iconsMap[cell];
    const iconSymbol = typeof icon === "string" ? <i className={`fa ${icon}`} /> : icon;
    return (
      <span className="d-flex flex-row justify-content-center align-items-center">
        <span className="activity-icon d-flex flex-column text-center justify-content-center align-items-center">
          {iconSymbol}
        </span>
      </span>
    );
  }
};

const formatActivationCode = (cell, row) => {
  if (row && row.activation_code && (row.status === "New" || row.status === "Active")) {
    return cell && cell.match && cell.match(/\d{4}|\d+/g) ? cell.match(/\d{4}|\d+/g).join("-") : cell;
  } else return "N/A";
};
export const formatEssentials = (cell, row, formatExtraData, rowIdx) => {
  return cell && <span className="c-pink">{cell}</span>;
};
export const formatProfessional = (cell, row, formatExtraData, rowIdx) => {
  return cell && <span className="c-aqua">{cell}</span>;
};
export const formatCustom = (cell, row, formatExtraData, rowIdx) => {
  return cell && <span className="c-navy">{cell}</span>;
};

export const renderCaret = (direction, fieldName) => {
  const style = {
    position: "absolute",
    top: 0,
    right: 0,
    marginTop: "0.5rem",
    display: "flex",
    alignItems: "center",
  };
  if (direction === "asc") {
    return (
      <div style={style}>
        <i className="fa fa-sort-up" />
      </div>
    );
  }
  if (direction === "desc") {
    return (
      <div style={style}>
        <i className="fa fa-sort-down" />
      </div>
    );
  }
  return (
    <div style={style}>
      <i className="fa fa-sort" />
    </div>
  );
};

/**
 * DateRange filter function
 */
export const dateRangeFilterFn = (startDate, endDate) => (targetValue) => {
  const targetDate = moment(targetValue);
  return startDate < targetDate && targetDate < endDate;
};
/**
 * Utility function to create the bootstrap table filter object to pass to handleFilterData
 */
export const resolveDateFilterObject = (_startDate, _endDate) => {
  const startDate = _startDate.isMoment ? _startDate : moment(_startDate);
  const endDate = _endDate.isMoment ? _endDate : moment(_endDate);
  return {
    value: {
      callback: dateRangeFilterFn(startDate, endDate),
    },
    startDate,
    endDate,
    type: "CustomFilter",
    props: {},
  };
};

/**
 * Persist changes in table to the url
 */
const mapFiltersValueToURL = (v) => {
  if (v.startDate && v.endDate) {
    return {
      startDate: v.startDate.format(),
      endDate: v.endDate.format(),
    };
  } else {
    return v;
  }
};

const tableChangeToUrl = function () {
  setTimeout(() => {
    const push = _get(this, "props.history.replace");
    const location = _get(this, "props.location.pathname");
    const search = _get(this, "props.location.search");

    if (push && location) {
      let currentSearch = queryString.parse(search);
      //Resolving filters
      const filters = _mapValues(_get(this, "table.store.filterObj", {}), mapFiltersValueToURL);
      if (!_isEmpty(filters)) {
        if (!(currentSearch.filters === JSON.stringify(filters))) {
          currentSearch.filters = JSON.stringify(filters);
        }
      } else delete currentSearch.filters;

      //Resolving sorts
      const sortList = _get(this, "table.store.sortList", {});
      if (!_isEmpty(sortList)) {
        if (!(currentSearch.sortList === JSON.stringify(sortList))) {
          currentSearch.sortList = JSON.stringify(sortList);
        }
      } else delete currentSearch.sortList;

      //Resolving search
      const searchText = _get(this, "table.store.searchText");
      if (searchText) {
        currentSearch.search = searchText;
      } else delete currentSearch.search;

      const query = queryString.stringify(currentSearch);
      push(`${location}?${query}`);
    }
  }, 5);
};

const defaultOptionsInUrl = function (table) {
  const params = queryString.parse(_get(window, "location.search"));
  setTimeout(() => {
    if (params.filters && table) {
      const filters = _mapValues(JSON.parse(params.filters), (i) =>
        i.startDate && i.endDate ? resolveDateFilterObject(i.startDate, i.endDate) : i
      );
      table.handleFilterData(filters);
    }
    if (params.sortList && table) {
      const sortList = JSON.parse(params.sortList).reverse();
      table.handleSort(
        sortList.map((i) => i.order),
        sortList.map((i) => i.sortField)
      );
    }
  }, 5);
};

const defaultSearchInUrl = function () {
  const params = queryString.parse(_get(window, "location.search"));
  return params.search;
};

/**
 * Default pagination settings
 */
const defaultPaginationSettings = {
  sizePerPageList: [
    { text: "Display 50 Per Page", value: 50 },
    { text: "Display 100 Per Page", value: 100 },
    { text: "Display 200 Per Page", value: 200 },
  ],
  sizePerPage: 50,
  pageStartIndex: 1,
  paginationSize: 3,
  prePage: "Prev",
  nextPage: "Next",
  firstPage: "First",
  lastPage: "Last",
};

const promoPaginationSettings = {
  sizePerPageList: [
    { text: "Display 10 Per Page", value: 10 },
    { text: "Display 20 Per Page", value: 20 },
    { text: "Display 50 Per Page", value: 50 },
  ],
  sizePerPage: 10,
  pageStartIndex: 1,
  paginationSize: 10,
  prePage: "Prev",
  nextPage: "Next",
  firstPage: "First",
  lastPage: "Last",
};

/**
 * ToolBarCreator
 * Is a class that needs to be innitiaded with the context that contains the reference of the
 * Bootstraptable at this.table. With this we have the context to add the correct methods to this class
 */

const SearchField = ({ isDesktop, components }) =>
  components && components.searchField ? (
    <div className="search-field col-sm-3 filter-select" style={{ padding: !isDesktop && 0 }}>
      {components.searchField}
      <i className="fa fa-search c-gray" />
    </div>
  ) : null;

const ClearButton = ({ isDesktop, title, onClearFiltersClick, onClick = () => {}, ...otherProps }) => {
  return (
    <button
      className="btn toolbar-button c-dark-blue clear-button"
      onClick={() => {
        onClearFiltersClick();
        onClick();
      }}
      style={{ width: !isDesktop && "100%", margin: !isDesktop && 0 }}
      {...otherProps}
    >
      {title ? title : "Clear"}
    </button>
  );
};

const OptionsButton = ({ extraButtons, isDesktop, handleExportCSV, title, style }) => {
  return (
    <UncontrolledButtonDropdown style={{ width: !isDesktop && "100%" }}>
      <DropdownToggle size="sm" color="primary" style={{ width: 140, ...style }}>
        {title ? title : "Options"}
      </DropdownToggle>
      <DropdownMenu right={isDesktop}>
        {extraButtons}
        <DropdownItem onClick={handleExportCSV}>
          <i className="fa fa-file-export" />
          Export CSV
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

class DesktopToolBar extends React.Component {
  render() {
    const {
      filters,
      customFilters,
      leftColClassName,
      rightColClassName,
      components,
      extraButtons,
      isDesktop,
      renderFilter,
      renderCustomFilter,
      handleExportCSV,
      onFilterChange,
      onClearFiltersClick,
      withoutOptions,
    } = this.props;
    const { toolbartExtraButtons = () => {} } = _get(this, "props.context.props", {});

    return (
      <>
        <div
          className={`row small-gutters d-flex justify-content-start ${
            withoutOptions ? "col-md-12" : leftColClassName
          }`}
        >
          <ClearButton isDesktop={isDesktop} onClearFiltersClick={onClearFiltersClick} />
          <SearchField isDesktop={isDesktop} components={components} />
          {filters && filters.map && filters.map(renderFilter)}
          {customFilters &&
            customFilters.map &&
            customFilters.map((filter) => renderCustomFilter(filter, isDesktop))}
        </div>
        <div
          className={`toolbar-button-outer ${!withoutOptions && rightColClassName}`}
          style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-start" }}
        >
          {toolbartExtraButtons(onFilterChange)}
          {!withoutOptions && (
            <div className="toolbar-button-inner small-gutters d-flex flex-row justify-content-end">
              <OptionsButton
                isDesktop={isDesktop}
                extraButtons={extraButtons}
                handleExportCSV={handleExportCSV}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

const MobileToolBarContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 16px;
`;

const MobileToolBarContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin-bottom: 15px;
  background: #fff;
  padding: 3px 0;
`;

const MobileToolBarContentItem = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledColapse = styled.div`
  padding-bottom: 10px;
  width: 100%;
  .card-body {
    padding: 0;
  }
  .list-group {
    height: auto;
    &-item:hover {
      border: 1px solid rgba(0, 0, 0, 0.125);
      box-shadow: inherit;
      box-sizing: inherit;
      font-size: inherit;
      border-radius: inherit;
    }
    &-item:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    &-item:last-child {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;

class MobileToolBar extends React.Component {
  state = {
    isOpen: false,
  };

  handleToggle = () => this.setState({ isOpen: !this.state.isOpen });

  render() {
    const {
      context,
      isDesktop,
      components,
      filters,
      customFilters,
      extraButtons,
      handleExportCSV,
      renderFilter,
      renderCustomFilter,
      onClearFiltersClick,
      onFilterChange,
    } = this.props;
    const dataQuantity = _get(context, "table.body.props.data.length");
    const { toolbartExtraButtons = () => {} } = _get(this, "props.context.props", {});

    return (
      <MobileToolBarContainer>
        <SearchField isDesktop={isDesktop} components={components} />

        <MobileToolBarContent>
          <MobileToolBarContentItem>
            <OptionsButton
              isDesktop={isDesktop}
              extraButtons={extraButtons}
              handleExportCSV={handleExportCSV}
              style={{ width: "100%" }}
            />
          </MobileToolBarContentItem>

          <MobileToolBarContentItem>
            <span className="text-muted d-block d-sm-none">
              {dataQuantity ? `${dataQuantity} Results` : "N/A"}
            </span>
          </MobileToolBarContentItem>

          <MobileToolBarContentItem>
            <Button size="sm" onClick={this.handleToggle} style={{ width: "100%" }}>
              {this.state.isOpen ? "Close" : "Filters"}
            </Button>
          </MobileToolBarContentItem>
        </MobileToolBarContent>

        <StyledColapse>
          <Collapse isOpen={this.state.isOpen}>
            <ListGroup>
              {filters &&
                filters.map &&
                filters.map((filter, key) => <ListGroupItem key={key}>{renderFilter(filter)}</ListGroupItem>)}
              {toolbartExtraButtons && <ListGroupItem>{toolbartExtraButtons(onFilterChange)}</ListGroupItem>}
              {customFilters &&
                customFilters.map &&
                customFilters.map((filter) => (
                  <ListGroupItem>{renderCustomFilter(filter, isDesktop)}</ListGroupItem>
                ))}
              <ListGroupItem>
                <ClearButton
                  isDesktop={isDesktop}
                  onClearFiltersClick={onClearFiltersClick}
                  onClick={this.handleToggle}
                />
              </ListGroupItem>
            </ListGroup>
          </Collapse>
        </StyledColapse>
      </MobileToolBarContainer>
    );
  }
}

class ToolBar extends React.Component {
  static propTypes = {
    filters: PropTypes.array,
    extraButtons: PropTypes.node,
    leftColClassName: PropTypes.string,
    rightColClassName: PropTypes.string,
    withoutOptions: PropTypes.bool,
    onDatePeriodChange: PropTypes.func,
    onClearFiltersClick: PropTypes.func,
  };

  static defaultProps = {
    leftColClassName: "col-md-12 col-lg-7 col-xl-8",
    rightColClassName: "col-md-12 col-lg-5 col-xl-4",
    withoutOptions: false,
    onDatePeriodChange: null,
    onClearFiltersClick: null,
  };

  componentDidMount() {
    document.getElementById("mainContent").addEventListener("scroll", this.scrollListener);
  }

  componentWillUnmount() {
    document.getElementById("mainContent").removeEventListener("scroll", this.scrollListener);
  }

  scrollListener = (e) => {
    if (e.target.scrollTop > 38) e.target.querySelector(".react-bs-table-tool-bar").classList.add("stuck");
    else e.target.querySelector(".react-bs-table-tool-bar").classList.remove("stuck");
  };

  onFilterChange = (filter) => (e) => {
    const { name, type } = filter;
    if (this.props.onDatePeriodChange && type === "datePeriodSelect") {
      this.props.onDatePeriodChange(e.target.value);
    }
    const filterObj = this.props.context.table.store.filterObj;

    let value;
    if (type === "dateRange") {
      const { startDate, endDate } = e.picker;
      value = resolveDateFilterObject(startDate, endDate);
    } else if (type === "datePeriodSelect") {
      let startDate = moment().subtract(30, "days");
      let endDate = moment();
      if (e.target.value) {
        switch (e.target.value) {
          case "this_week": {
            startDate = moment().startOf("isoWeek");
            endDate = moment().endOf("isoWeek");
            break;
          }
          case "this_month": {
            startDate = moment().startOf("month");
            endDate = moment().endOf("month");
            break;
          }
          case "this_quarter": {
            startDate = moment().startOf("quarter");
            endDate = moment().endOf("quarter");
            break;
          }
          case "this_year": {
            startDate = moment().startOf("year");
            endDate = moment().endOf("year");
            break;
          }
          case "past_7_days": {
            startDate = moment().subtract(7, "days");
            break;
          }
          case "past_30_days": {
            startDate = moment().subtract(30, "days");
            break;
          }
          case "past_90_days": {
            startDate = moment().subtract(90, "days");
            break;
          }
          case "past_365_days": {
            startDate = moment().subtract(365, "days");
            break;
          }
          default: {
            break;
          }
        }
      } else {
        if (filterObj) {
          delete filterObj[name];
          this.props.context.table.handleFilterData({ ...filterObj });
        }
        return;
      }
      value = { ...resolveDateFilterObject(startDate, endDate), label: e.target.value };
    } else {
      value = {
        value: e.target.value,
        type: "TextFilter",
        ...(e.target.value ? { props: { cond: "eq" } } : {}),
      };
    }
    this.props.context.table.handleFilterData({ ...filterObj, [name]: value });

    const remote = this.props.context.table.props.remote;
    if (remote) {
      this.props.context.table.store.filterObj = { ...filterObj, [name]: value };
    }
  };

  onClearFiltersClick = (e) => {
    // this.props.context.table.reset();
    if (!this.props.onClearFiltersClick) {
      this.props.context.table.handleFilterData({});
      this.props.context.table.handleSearch("");
    }
    const search = _get(this, "props.context.table.toolbar.seachInput");
    search && (search.value = "");

    const searchField = ReactDOM.findDOMNode(this.props.context.table.toolbar.seachInput);
    if (searchField) searchField.value = "";

    const remote = this.props.context.table.props.remote;
    if (remote) {
      this.props.context.table.store.filterObj = {};
    }
    if (this.props.onDatePeriodChange) {
      this.props.onDatePeriodChange();
    }
    if (this.props.onClearFiltersClick) {
      this.props.onClearFiltersClick();
    }
    if (this.props.customFilters) {
      this.props.customFilters.forEach((filter) => {
        if (filter.onClearFiltersClick) {
          filter.onClearFiltersClick();
        }
      });
    }
  };

  renderFilter = (filter) => {
    if (filter.options) {
      return this.renderSelect(filter);
    } else if (filter.type === "dateRange") {
      return this.renderDateRange(filter);
    } else if (filter.type === "checkbox") {
      return this.renderCheckbox(filter);
    }
  };

  renderCustomFilter = (filter, isDesktop) => {
    return <filter.component key={filter.name} isDesktop={isDesktop} {...filter} />;
  };

  renderDateRange = (filter) => {
    const { isDesktop } = this.props;
    const filterObj = _get(this, `props.context.table.store.filterObj.${filter.name}`);
    const initialSettings = _get(filter, `initialSettings`, {});

    return (
      <div
        key={filter.name}
        className="col-sm-3 filter-select"
        style={{ margin: !isDesktop && 0, padding: !isDesktop && 0 }}
      >
        <DateRangeFilter
          isDesktop={isDesktop}
          initialSettings={initialSettings}
          filterObj={filterObj}
          onApply={this.onFilterChange(filter)}
        />
      </div>
    );
  };

  renderCheckbox = (filter) => {
    const { isDesktop } = this.props;
    const filterObj = _get(this, `props.context.table.store.filterObj`, {});

    const value = _get(filterObj, `${filter.name}.value`, false);
    const setValue = (v) => {
      if (typeof v === "string") return !(v === "true");
      else return !v;
    };

    return (
      <div
        key={filter.name}
        className={"col-sm-2 filter-checkbox"}
        style={{ margin: !isDesktop && 0, padding: !isDesktop && 0 }}
      >
        <div className={"form-check"}>
          <input
            id={filter.name}
            type={"checkbox"}
            value={setValue(value)}
            onChange={this.onFilterChange(filter)}
            className={"form-check-input"}
          />
          <label htmlFor={filter.name} className={"form-check-label"}>
            {filter.label}
          </label>
        </div>
      </div>
    );
  };

  renderSelect = (filter) => {
    const SelectContainer = styled.div`
      ${({ isDesktop }) =>
        !isDesktop &&
        `
        select {
          margin: 0;
          padding: 0;
        }
      `}
    `;

    const { isDesktop } = this.props;
    const filterObj = _get(this, "props.context.table.store.filterObj");
    const capitalizedSelect = _get(this, "props.context.props.capitalizedSelect", false);
    const hidden = _get(filter, "hidden", false);

    return (
      <div
        key={filter.name}
        className={`col-sm-3 filter-select ${hidden ? "hidden" : ""}`}
        style={{ margin: !isDesktop && 0, padding: !isDesktop && 0 }}
      >
        <SelectContainer isDesktop={isDesktop}>
          <select
            className="form-control form-control-sm m-b-10-xs"
            onChange={this.onFilterChange(filter)}
            value={_get(filterObj, `${filter.name}.value`, "")}
          >
            <option value="">{filter.placeholder || "Select"}</option>
            {filter.options
              .filter((i) => i)
              .map((i, idx) => {
                if (typeof i === "string") {
                  return (
                    <option key={i} value={i}>
                      {capitalizedSelect ? i.replace(/^\w/, (c) => c.toUpperCase()) : i}
                    </option>
                  );
                } else if (typeof i === "object" && i.value && i.label) {
                  return (
                    <option key={`${i.value}${idx}`} value={i.value}>
                      {i.label.replace(/^\w/, (c) => c.toUpperCase())}
                    </option>
                  );
                }
                return null;
              })}
          </select>
        </SelectContainer>
      </div>
    );
  };

  renderFixedPeriodsRange = (filter) => {
    const SelectContainer = styled.div`
      ${({ isDesktop }) =>
        !isDesktop &&
        `
        select {
          margin: 0;
          padding: 0;
        }
      `}
    `;
    const { isDesktop } = this.props;
    const filterObj = _get(this, "props.context.table.store.filterObj");
    const capitalizedSelect = _get(this, "props.context.props.capitalizedSelect", false);
    return (
      <div
        key={filter.name}
        className="col-sm-3 filter-select"
        style={{ margin: !isDesktop && 0, padding: !isDesktop && 0 }}
      >
        <SelectContainer isDesktop={isDesktop}>
          <select
            className="form-control form-control-sm m-b-10-xs"
            onChange={this.onFilterChange(filter)}
            value={_get(filterObj, `${filter.name}.value`, "")}
          >
            <option value="">{filter.placeholder || "Select"}</option>
            {filter.options
              .filter((i) => i)
              .map((i, idx) => {
                if (typeof i === "string") {
                  return (
                    <option key={i} value={i}>
                      {capitalizedSelect ? i.replace(/^\w/, (c) => c.toUpperCase()) : i}
                    </option>
                  );
                } else if (typeof i === "object" && i.value && i.label) {
                  return (
                    <option key={`${i.value}${idx}`} value={i.value}>
                      {i.label.replace(/^\w/, (c) => c.toUpperCase())}
                    </option>
                  );
                }
                return null;
              })}
          </select>
        </SelectContainer>
      </div>
    );
  };

  render() {
    const { isDesktop, event } = this.props;
    const handleExportCSV = _get(event, "exportCSV", () => {});

    return isDesktop ? (
      <DesktopToolBar
        {...this.props}
        renderFilter={this.renderFilter}
        renderCustomFilter={this.renderCustomFilter}
        onClearFiltersClick={this.onClearFiltersClick}
        onFilterChange={this.onFilterChange}
        handleExportCSV={handleExportCSV}
      />
    ) : (
      <MobileToolBar
        {...this.props}
        renderFilter={this.renderFilter}
        renderCustomFilter={this.renderCustomFilter}
        onFilterChange={this.onFilterChange}
        onClearFiltersClick={this.onClearFiltersClick}
        handleExportCSV={handleExportCSV}
      />
    );
  }
}

ToolBar = connect(({ navigation }) => ({ isDesktop: navigation.isDesktop }))(ToolBar);

class UpgradeToolBar extends React.Component {
  static propTypes = {
    filters: PropTypes.array,
    extraButtons: PropTypes.node,
    leftColClassName: PropTypes.string,
    rightColClassName: PropTypes.string,
  };
  static defaultProps = {
    leftColClassName: "col-md-8",
    rightColClassName: "col-md-4",
  };
  componentDidMount() {
    document.getElementById("mainContent").addEventListener("scroll", this.scrollListener);
  }
  componentWillUnmount() {
    document.getElementById("mainContent").removeEventListener("scroll", this.scrollListener);
  }
  scrollListener = (e) => {
    if (e.target.scrollTop > 38) e.target.querySelector(".react-bs-table-tool-bar").classList.add("stuck");
    else e.target.querySelector(".react-bs-table-tool-bar").classList.remove("stuck");
  };
  render() {
    return (
      <div className="m-v-10 m-h-20">
        This is a <b className="c-aqua">Professional</b> or <b className="c-navy">Custom</b> plan feature. To
        upgrade your account and access these great features, contact MVI.
      </div>
    );
  }
}

const RowDropdownMenu = ({ children }) => (
  <UncontrolledButtonDropdown className="mvi-table-dropdown">
    <DropdownToggle size="sm" color="link">
      <i className="material-icons">more_vert</i>
    </DropdownToggle>
    <DropdownMenu right>{children}</DropdownMenu>
  </UncontrolledButtonDropdown>
);

const getOptions = (data, field) => {
  return [...new Set(data.map((item) => item[field]))];
};

const getTimeDifference = (data) => {
  let delta = (DateParseWithTimezone(data) - +new Date()) / 1000;
  const days = Math.floor(delta / 86400);
  delta -= days * 86400;

  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  const minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;
  const seconds = Math.floor(delta % 60);
  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

const dateTimePromoFormatter = (cell) => {
  if (cell) {
    const { days, hours, minutes } = getTimeDifference(cell);

    if (days < 0 || hours < 0 || minutes < 0) return <span className="text-muted">Expired</span>;

    return `${days} day(s), ${hours} hour(s) ${minutes} minute(s)`;
  }
  return <span className="text-muted">Never</span>;
};

const formatLink = (cell, row) => {
  if (!cell || cell === "N/A") {
    return <span className="text-muted"> N/A </span>;
  }

  return (
    <a target="_blank" rel="noopener noreferrer" href={cell}>
      {cell}
    </a>
  );
};

const formatAdVideo = (cell, row) => {
  const isUrl = isURL(cell) ? cell : null;

  if (isUrl) {
    return <PromoContent src={cell} />;
  }

  return <span className="text-muted">N/A</span>;
};

const createDateTimeFormatter = (cell, row) => {
  return <span>{new Date(cell).toLocaleString()}</span>;
};

const renderBuildingFields = (cell, row) => {
  if (!cell || cell === "N/A") {
    return <span className="text-muted"> N/A </span>;
  }

  return <span>{cell}</span>;
};

const renderKeyComFields = (cell, row) => {
  if (!cell || cell === "N/A") {
    return <span className="text-muted"> All </span>;
  }

  return <span>{cell}</span>;
};

export const datePeriodSelectFilterValues = (field_name, placeholder, options) => {
  return {
    type: "datePeriodSelect",
    name: field_name,
    placeholder: placeholder,
    options: options || [
      { label: "This Calendar Week", value: "this_week" },
      { label: "This Calendar Month", value: "this_month" },
      { label: "This Calendar Quarter", value: "this_quarter" },
      { label: "This Calendar Year", value: "this_year" },
      { label: "Past 7 Days", value: "past_7_days" },
      { label: "Past 30 Days", value: "past_30_days" },
      { label: "Past 90 Days", value: "past_90_days" },
      { label: "Past 365 Days", value: "past_365_days" },
    ],
  };
};

export const isValidFormat = (value, row, key) =>
  !row[key] ? <span style={{ color: "red" }}>{value}</span> : value;

export default {
  ToolBar,
  UpgradeToolBar,
  RowDropdownMenu,
  DropdownItem,
  onAvatarImageError,
  formatFullName,
  formatFullNameAndPicture,
  formatBothNames,
  formatListName,
  formatUnitDirectory,
  formatPhone,
  formatAvatar,
  formatListing,
  formatUserListing,
  formatStatus,
  formatCSVDate,
  formatCSVUserType,
  formatListened,
  formatDate,
  formatDateWithoutTime,
  formatDateAgo,
  formatDuration,
  formatWithicon,
  formatActivityIcon,
  formatDefault,
  formatActivationCode,
  formatUsername,
  formatUserType,
  formatEssentials,
  formatProfessional,
  formatCustom,
  tableChangeToUrl,
  defaultOptionsInUrl,
  defaultSearchInUrl,
  defaultPaginationSettings,
  promoPaginationSettings,
  formatUserContacts,
  formatUser,
  renderCaret,
  getOptions,
  getTimeDifference,
  dateTimePromoFormatter,
  formatLink,
  formatAdVideo,
  createDateTimeFormatter,
  renderBuildingFields,
  renderKeyComFields,
  datePeriodSelectFilterValues,
};

import React from "react";
import _xor from "lodash/xor";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";

import MenuBadge from "../MenuBadge";
import AccessManager, { PERMISSION_TYPE_ENABLE } from "utils/accessManager";

export const RolePermissionNames = {
  can_add_or_delete_dealer_users: "can_add_or_delete_dealer_users",
  // building-menu
  can_view_tabs_on_any_plan: "can_view_tabs_on_any_plan", // roleType admin only
  can_view_building_keycom_tab: "can_view_building_keycom_tab", // roleType
  can_view_access_list_tab: "can_view_access_list_tab", // roleType
  can_view_entrata_tab: "can_view_entrata_tab", // roleType
  can_view_brivo_tab: "can_view_brivo_tab", // roleType
  can_view_pdk_tab: "can_view_pdk_tab", // roleType
  can_view_hartmann_tab: "can_view_hartmann_tab", // roleType
  can_view_building_promo_tab: "can_view_building_promo_tab", // roleType
  can_enable_building_messages_tab: "can_enable_building_messages_tab", // roleType
  // manager-menu
  can_view_management_users_tab: "can_view_management_users_tab", // roleType
  can_view_company_info_tab: "can_view_company_info_tab", // roleType
};
export const PermissionNames = {
  ...RolePermissionNames,
  // building-menu
  can_view_photo_files_section: "can_view_photo_files_section", // building perms
  can_view_facial_rec_column_user_changes: "can_view_facial_rec_column_user_changes", // building perms
  can_view_flag_column_user_changes: "can_view_flag_column_user_changes", // building perms
  can_view_facial_rec_column_access: "can_view_facial_rec_column_access", // building perms
  can_view_flag_column_access: "can_view_flag_column_access", // building perms
  can_view_interim_video: "can_view_interim_video", // building perms
  can_view_flag_summary_content: "can_view_flag_summary_content", // building perms
  can_view_alerts_content: "can_view_alerts_content", // building perms
  can_view_analytics_content: "can_view_analytics_content", // building perms
  can_view_change_roommate_mode: "can_view_change_roommate_mode", // building perms
  app_to_pots_call_failover: "app_to_pots_call_failover",
  access_pin_allowed: "access_pin_allowed",
  is_sabbath_aware: "is_sabbath_aware",
  hide_resident_names: "hide_resident_names",
  hide_unit_numbers: "hide_unit_numbers",

  /// BuildingInfoPermissions && EditUnitWizard
  can_view_flag_add_secondary_user: "can_view_flag_add_secondary_user", // building perms
  can_view_flag_send_visitor_mykeys: "can_view_flag_send_visitor_mykeys", // building perms

  // ManageAccountUsers
  can_add_secondary_user: "can_add_secondary_user", // Account/Building field

  // EditUnitWizard
  can_send_visitor_mykeys: "can_send_visitor_mykeys", // Account/Building field
  roommate_mode: "roommate_mode", // Unit/Building field

  // AccessTable
  can_view_first_touches_toggle_button: "can_view_first_touches_toggle_button", // building perms

  management_add_mykey_set_vendor: "management_add_mykey_set_vendor"
};

export const renderBadge = (obj, attr) => (obj && obj[attr] ? <MenuBadge text={obj[attr]} /> : null);
export const renderRightBadge = (obj, attr) =>
  obj && obj[attr] ? (
    <MenuBadge
      text={obj[attr]}
      type="outlined"
      style={{
        top: 11,
        left: "initial",
        right: 15
      }}
    />
  ) : null;

export const addNewPermissions = ({
  staticPermissions = {},
  sectionsToChange = [],
  permissionType = PERMISSION_TYPE_ENABLE,
  newPermissions = [PermissionNames.can_view_tabs_on_any_plan]
} = {}) => {
  for (let key of sectionsToChange) {
    if (!staticPermissions[key]) {
      staticPermissions[key] = {};
    }

    if (!staticPermissions[key][permissionType]) {
      staticPermissions[key][permissionType] = [];
    }

    staticPermissions[key][permissionType] = [...staticPermissions[key][permissionType], ...newPermissions];
  }

  return staticPermissions;
};

export const denyAccessToSections = (
  staticPermissions,
  { menuKeys = {}, forbiddenSections = null, allowedSections = null } = {}
) => {
  const sectionsToChange = allowedSections
    ? _xor(Object.values(menuKeys), allowedSections)
    : forbiddenSections;

  if (sectionsToChange) {
    addNewPermissions({
      staticPermissions,
      sectionsToChange
    });
  }
};

const applyInjectedPermissions = (key, menuItem, staticPermissions) => {
  const modifyItem = AccessManager.injectedPermission(key, staticPermissions);
  if (!_isEmpty(modifyItem)) return { ...menuItem, ...modifyItem };
  else return { ...menuItem };
};

const applyStaticPermissions = (key, menuItem, staticPermissions) => {
  if (key && staticPermissions[key]) return { ...menuItem, ...staticPermissions[key] };
  else return { ...menuItem };
};

const applyPermissions = (key, menuItem, staticPermissions) => {
  let item = applyStaticPermissions(key, menuItem, staticPermissions);
  return applyInjectedPermissions(key, item, staticPermissions);
};

export const combineMenuWithPermissions = (menuItems, staticPermissions) => {
  const menuItemsWithPermissions = {};

  for (let key of Object.keys(menuItems)) {
    let menuItem = { ...menuItems[key] };

    if (menuItem.submenu) {
      menuItem.submenu = combineSubMenuWithPermissions(menuItem.submenu, staticPermissions);
    }

    menuItemsWithPermissions[key] = applyPermissions(key, menuItem, staticPermissions);
  }

  return menuItemsWithPermissions;
};

// the same, but the key is inside the object
export const combineSubMenuWithPermissions = (menuItems, staticPermissions) => {
  const menuItemsWithPermissions = {};

  for (let menuName of Object.keys(menuItems)) {
    let menuItem = { ...menuItems[menuName] };

    if (menuItem.children) {
      menuItem.children = menuItem.children.map(child => {
        let key = _get(child, "applyStaticPermissionKey");
        return applyPermissions(key, child, staticPermissions);
      });
    }

    menuItemsWithPermissions[menuName] = menuItem;
  }
  return menuItemsWithPermissions;
};

export const getListMenuItems = menuItems => Object.keys(menuItems).map(key => menuItems[key]);

import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import PageForm from "components/ui/pageForm/PageForm";
import { Form, Field } from "react-final-form";
import { forgotUsername, forgotPasswordQuestion } from "redux/reducers/reset";
import { ActivationCodeField, StandardField } from "components/ui/forms";
import { validators, composeValidators } from "utils/forms";
import { FORM_ERROR } from "final-form";
import _isEmpty from "lodash/isEmpty";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmitUsername = async (values) => {
    try {
      await dispatch(forgotUsername(values));
    } catch (error) {
      return { [FORM_ERROR]: error.message || "Invalid Activation Code" };
    }
  };

  const onSubmitPassword = async (values) => {
    try {
      const result = await dispatch(forgotPasswordQuestion(values));
      const question = result.response.data.data.security_question;
      const path = question
        ? `/display_security_question?question=${question}&username=${values.username}`
        : `/contact?username=${values.username}`;
      history.push(path);
    } catch (error) {
      return { [FORM_ERROR]: "Invalid Username" };
    }
  };

  const renderUsernameForm = (form) => {
    return (
      <form onSubmit={form.handleSubmit}>
        {form.submitError && <p className="text-danger">{form.submitError}</p>}
        <Field
          label="Forgot your Username?"
          component={ActivationCodeField}
          name="security_code"
          validate={composeValidators(validators.required, validators.minLength(16))}
          extraContent={<small className="form-text text-muted">Must be a 16 digit code.</small>}
        />
        <button
          type="submit"
          disabled={form.pristine || !_isEmpty(form.errors)}
          className="btn btn-sm btn-primary btn-block"
          onClick={() => {
            form.change("sending_method", "email");
          }}
        >
          Send By Email
        </button>
        <button
          type="submit"
          disabled={form.pristine || !_isEmpty(form.errors)}
          className="btn btn-sm btn-primary btn-block"
          onClick={() => {
            form.change("sending_method", "sms");
          }}
        >
          Send By SMS
        </button>
      </form>
    );
  };

  const renderPasswordForm = (form) => {
    return (
      <form onSubmit={form.handleSubmit}>
        {form.submitError && <p className="text-danger">{form.submitError}</p>}
        <Field
          label="Forgot your Password?"
          placeholder="Enter your username"
          validate={composeValidators(validators.noSpace, validators.required)}
          component={StandardField}
          name="username"
          maxLength={32}
        />
        <button
          type="submit"
          disabled={form.pristine || !_isEmpty(form.errors)}
          className="btn btn-sm btn-primary btn-block"
        >
          Reset Password
        </button>
      </form>
    );
  };

  return (
    <PageForm width="400px">
      <h5 className="text-center">Lets get you back on!</h5>
      <hr />

      <Form onSubmit={onSubmitUsername} render={renderUsernameForm} />

      <h6 className="m-t-20 text-center text-muted">OR</h6>

      <Form onSubmit={onSubmitPassword} render={renderPasswordForm} />
      <hr />

      <Link to="/registration/security_code" className="d-block text-center m-t-20">
        <small>First Time User / Sign Up</small>
      </Link>

      <Link to="/send_reset_link" className="d-block text-center m-t-20">
        <small>Lost My Activation Code</small>
      </Link>

      <Link to="/" className="d-block text-center m-t-20">
        <small>Return to login</small>
      </Link>
    </PageForm>
  );
};

export default ForgotPassword;

import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import Routes from "./Routes";
import { unregister } from './registerServiceWorker';
// import registerServiceWorker from "./registerServiceWorker"; //seems react-loadable and service worker dont work well together
import "./index.css";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import "react-html5video/dist/styles.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "text-security/dist/text-security-disc.css";
import "react-toggle/style.css"
import 'react-rangeslider/lib/index.css'
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'leaflet-geosearch/dist/geosearch.css';
import './utils/timezone'






ReactDOM.render(<Routes />, document.getElementById("root"));
// registerServiceWorker();
unregister();

if (module.hot) {
  module.hot.accept();
}
